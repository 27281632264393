import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { RecordContextProvider, useRecordContext, useRefresh, useTranslate, useUpdate, Form, useNotify } from "react-admin"
import { IContact, IOwner, IProspect } from "../../../types/Prospect"
import { OwnerCard } from './OwnerCard';
import { useState } from 'react';
import { OwnerForm } from '../../Forms/OwnerForm';
import { useFormContext } from 'react-hook-form';

const EditForm = (props: { prospectId: string, ownerId: string, onSuccess: () => void }) => {
    const [updateOne] = useUpdate();
    const notify = useNotify();
    const form = useFormContext();
    const t = useTranslate();

    const updateOwner = () => {
        const formValues = form.getValues();

        // Sanitize empty values for zipCode and cityName by setting to undefined
        if (formValues.zipCode === "" || formValues.zipCode === null) {
            formValues.zipCode = undefined
        }

        if (formValues.cityName === "" || formValues.cityName === null) {
            formValues.cityName = undefined
        }

        if (!formValues.lastName) {
            return form.setError('lastName', { type: 'required', message: t('ra.validation.required') });
        }

        const gender = formValues.gender ? t(`prospect.owners.gendersShort.${formValues.gender}`) : "";
        const fullName = `${gender} ${formValues.lastName} ${formValues.firstName ?? ""}`;
        updateOne(`prospects/${props.prospectId}/owners`,
            { id: props.ownerId, data: { ...formValues, fullName, gender: formValues.gender !== null ? formValues.gender : undefined } },
            {
                onSuccess: () => {
                    props.onSuccess();
                },
                onError: () => {
                    notify('BasicError', { type: 'error' });
                }
            });
    };

    return <Box className="flex flex-col gap-2 p-4">
        <OwnerForm mode="edit" />
        <Button onClick={() => updateOwner()}>{t('ra.action.save')}</Button>
    </Box>
}
export const OwnerList = (props:
    {
        onMouseEnter: (ownerId: string) => void,
        onMouseLeave: (ownerId: string) => void,
        selectedParcelIds: string[],
        variant: 'normal' | 'compact'
    }) => {
    const { onMouseEnter, onMouseLeave, selectedParcelIds, variant } = props;
    const prospect = useRecordContext<IProspect>();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const [owner, setOwner] = useState<IOwner | null>(null);

    if (!prospect) return null;

    const filteredOwners =
        selectedParcelIds.length > 0 ?
            prospect?.owners?.filter(owner => {
                return prospect.parcels?.some(parcel => selectedParcelIds.includes(parcel.id) && parcel.owners.some(parcelOwner => parcelOwner.id === owner.id));
            }) : prospect?.owners;


    return <Box className="flex flex-col">
        {filteredOwners?.map((owner: IOwner) => {
            return <RecordContextProvider value={owner}>
                <Box key={owner.id}
                    onMouseEnter={() => onMouseEnter(owner.id)}
                    onMouseLeave={() => onMouseLeave(owner.id)}
                    className="sticky top-0 z-10 pb-4 bg-white"
                >
                    <OwnerCard variant={variant} type='owner' prospect={prospect} prospectId={prospect?.id} owner={owner} onOpenEdit={(owner: IOwner) => { setOwner(owner); setOpen(true) }} />
                </Box>
            </RecordContextProvider>
        })}
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="owner-form-dialog"
        >
            <RecordContextProvider value={owner!}>
                <Form sanitizeEmptyValues>
                    <EditForm prospectId={prospect?.id} ownerId={owner?.id!}
                        onSuccess={() => { setOpen(false); refresh(); }}
                    />
                </Form>
            </RecordContextProvider>
        </Dialog>
    </Box>
}

OwnerList.defaultProps = {
    variant: 'normal'
}