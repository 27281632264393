import {
    useTranslate,
    usePermissions,
    SelectInput,
    useGetIdentity,
    useListContext,
    NumberInput,
    DateInput,
    useGetOne,
    SelectArrayInput,
    useGetList,
} from 'react-admin';
import isEqual from 'lodash/isEqual';
import { useSavedQueries, extractValidSavedQueries } from 'react-admin';

// Mui
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import { useForm, FormProvider } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';

import { LeadScoreInput } from '../Inputs/LeadScoreInput';
import { isSuperAdmin } from '../../helpers/helpers';
import { allPipeStatuses, defaultPipeStatuses } from '../../helpers/constants';
import { CustomSelectArray } from '../Inputs/CustomSelectArray';
import { Tag } from '../Chips/Tag';
import StatusChip from '../Chips/StatusChip';
import { LightTooltip } from '../layout/LightTooltip';
import { ProspectTag } from '../../types/ProspectTag';

export const ProspectsFiltersForm = (props: { type: 'new' | 'current', onSaveFilters?: () => void }) => {
    const t = useTranslate();
    const {
        resource,
        filterValues,
        setFilters,
        displayedFilters,
        sort,
        perPage,
    } = useListContext();

    const [savedQueries, setSavedQueries] = useSavedQueries(resource);
    const validSavedQueries = extractValidSavedQueries(savedQueries);

    const user = useGetIdentity();
    const { permissions } = usePermissions();
    const pipeStatuses = isSuperAdmin(permissions) ? allPipeStatuses : defaultPipeStatuses;

    const { data: teams } = useGetList('teams', { pagination: { page: 1, perPage: 1000 } });
    const { data: users } = useGetList('users', { pagination: { page: 1, perPage: 1000 } });
    const { data: prospectTags } = useGetList<ProspectTag>('prospectTags');

    const form = useForm({
        defaultValues: filterValues,
    });

    const hasSavedCurrentFilterValue = validSavedQueries.some(savedQuery => {
        let formValues = form.getValues();
        //Clean null and undefined values
        Object.keys(formValues).forEach(key => formValues[key] == null && delete formValues[key]);
        return isEqual(savedQuery.value, {
            filter: formValues,
            sort,
            perPage,
            displayedFilters,
        })
    }
    );
    const hasFilterValues = !isEqual(form.getValues(), {});

    const onSubmit = (values: any) => {
        if (Object.keys(values).length > 0) {
            setFilters(values, undefined, false);
        }
    };

    const onSave = () => {
        if (Object.keys(form.getValues()).length > 0) {
            setFilters(form.getValues(), undefined, false);
        }
        props.onSaveFilters && props.onSaveFilters();
    }

    const resetFilter = () => {
        setFilters({}, [], false);
        form.reset({ minCreatedAt: null, maxCreatedAt: null, minHouseSurface: null, minFieldSurface: null, maxHouseSurface: null, maxFieldSurface: null, type: null, pipeStatus: null, tags: null, teamIds: null, origin: null, userIds: null, minFloorArea: null, minProjectedRevenue: null, minNumberOfUnits: null, minLeadScore: null });
    };

    const choices = user?.data?.pipeStatuses?.length > 0 ?
        user?.data?.pipeStatuses?.map((status: { name: string, value: string, color: string }) => {
            return { id: status.value, name: status.name, color: status.color }
        }) :
        pipeStatuses.map((status: string) => {
            return { id: status, name: t(`prospect.statuses.${status}`) }
        });

    return <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col p-2">
            <InputLabel>{t('prospect.property.createdAt')}</InputLabel>
            <div className="flex gap-2">
                <DateInput sx={{ "& legend": { width: "0px" } }} label={false} source="minCreatedAt" alwaysOn className='rounded-md' />
                <DateInput sx={{ "& legend": { width: "0px" } }} label={false} source="maxCreatedAt" alwaysOn className='rounded-md' />
            </div>
            <InputLabel>{t('prospect.property.houseSurface')}</InputLabel>
            <div className="flex gap-2">
                <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="minHouseSurface" alwaysOn className='rounded-md' placeholder='Minimum' />
                <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="maxHouseSurface" alwaysOn className='rounded-md' placeholder='Maximum' />
            </div>
            <InputLabel>{t('prospect.property.fieldSurface')}</InputLabel>
            <div className="flex gap-2">
                <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="minFieldSurface" alwaysOn className='rounded-md' placeholder='Minimum' />
                <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="maxFieldSurface" alwaysOn className='rounded-md' placeholder='Maximum' />
            </div>
            <div className="flex gap-2">
                <div>
                    <InputLabel>{t('prospect.property.projects.floorArea')}</InputLabel>
                    <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="minFloorArea" alwaysOn className='rounded-md' placeholder='Minimum' />
                </div>
                <div>
                    <InputLabel>{t('prospect.property.projects.projectedRevenue')}</InputLabel>
                    <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="minProjectedRevenue" alwaysOn className='rounded-md' placeholder='Minimum' />
                </div>
            </div>
            <div className="flex gap-2">
                <div>
                    <InputLabel>{t('prospect.property.projects.numberOfUnits')}</InputLabel>
                    <NumberInput sx={{ "& legend": { width: "0px" } }} label={false} source="minNumberOfUnits" alwaysOn className='rounded-md' placeholder='Minimum' />
                </div>
                <div>
                    <InputLabel>{"Chaleur"}</InputLabel>
                    <LeadScoreInput name="minLeadScore" />
                </div>
            </div>
            <div className="flex gap-2">
                <div>
                    <SelectInput source="type" choices={[
                        { id: "house", name: t('prospect.type.house') },
                        { id: "field", name: t('prospect.type.field') },
                        { id: "building", name: t('prospect.type.building') },
                        { id: "appartment", name: t('prospect.type.appartment') },
                        { id: "warehouse", name: t('prospect.type.warehouse') },
                        { id: "garage", name: t('prospect.type.garage') },
                        { id: "other", name: t('prospect.type.other') },
                    ]} alwaysOn className='rounded-md' />
                </div>
                <div>
                    <SelectArrayInput
                        choices={choices}
                        label={t('prospect.property.status')}
                        name="pipeStatus"
                        source="pipeStatus"
                        alwaysOn
                        className='rounded-md'
                        sx={{
                            marginTop: '8px',
                            "& label": {
                                "&[data-shrink=false]": {
                                    top: "-7px",
                                },
                            },
                            "& .MuiSelect-select": {
                                padding: "2px 32px 2px 8px",
                                minHeight: "32px"
                            }
                        }}
                        options={{
                            renderValue: (selected: any) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                                    <>
                                        {selected.slice(0, 1).map((type: string) => {
                                            let item = choices.find((i: any) => i.id === type);
                                            return <StatusChip status={type} size="small" />
                                        })}
                                        {selected?.length > 1 &&
                                            <LightTooltip
                                                title={
                                                    <>
                                                        {selected.slice(1).map((type: string) => {
                                                            let item = choices.find((i: any) => i.id === type);
                                                            return <StatusChip status={type} size="small" />
                                                        })}
                                                    </>
                                                }
                                            >
                                                <Chip label={`+${selected.length - 1}`} />
                                            </LightTooltip>
                                        }
                                    </>
                                </Box>
                            )
                        }}
                    />
                </div>
            </div>
            <div className="flex gap-2">
                <div>
                    <InputLabel>{t('menu.users')}</InputLabel>
                    <SelectArrayInput
                        choices={users?.map((user: { id: string, firstName: string, lastName: string }) => {
                            return { id: user.id, name: `${user.firstName} ${user.lastName}` }
                        })}
                        label={false}
                        name="userIds"
                        source="userIds"
                        alwaysOn
                        className='rounded-md'
                    />
                </div>
                <div>
                    <InputLabel>{t('menu.teams')}</InputLabel>
                    <SelectArrayInput
                        choices={teams?.map((team: { id: string, name: string }) => {
                            return { id: team.id, name: team.name }
                        })}
                        label={false}
                        name="teamIds"
                        source="teamIds"
                        alwaysOn
                        className='rounded-md'
                    />
                </div>
            </div>
            <div className="flex gap-2">
                <div>
                    <InputLabel>{t('prospect.property.origin')}</InputLabel>
                    <SelectArrayInput
                        choices={["kaptcher", "jvmi", "creation"].map((origin: string) => {
                            return { id: origin, name: t(`prospect.origins.${origin}`) }
                        })}
                        label={false}
                        name="origin"
                        source="origin"
                        alwaysOn
                        className='rounded-md'
                    />
                </div>
                <div>
                    <InputLabel>{t('prospect.property.tags')}</InputLabel>
                    <SelectArrayInput
                        choices={prospectTags && prospectTags?.length > 0 ?
                            prospectTags?.map((tag: { id: string, name: string, bgColor: string }) => {
                                return { id: tag.id, name: tag.name, color: tag.bgColor }
                            }) :
                            []
                        }
                        label={false}
                        name="tags"
                        source="tags"
                        alwaysOn
                        className='rounded-md'
                        optionText={(choice: { id: string, name: string, color: string }) => <Tag label={choice.name} color={choice.color} />}
                        sx={{ "& .MuiChip-root": { backgroundColor: "white" }, "& legend": { width: 0 } }}
                    />
                </div>
            </div>
            {!props.onSaveFilters &&
                <div className="mb-2 mt-2 w-full justify-between flex">
                    <MUIButton variant="outlined" onClick={resetFilter}>{t('buttons.reset')}</MUIButton>
                    <MUIButton variant="contained" type="submit">{t('buttons.filter')}</MUIButton>
                </div>
            }
            {props.onSaveFilters &&
                <div className="mb-2 mt-2 w-full justify-end flex">
                    <MUIButton variant="contained" disabled={!hasFilterValues || hasSavedCurrentFilterValue} onClick={onSave}>
                        Enregistrer la vue
                    </MUIButton>
                </div>
            }
        </form>
    </FormProvider>
}