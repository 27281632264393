export const spreadsheetImportTranslations = {
    spreadsheetImport: {
        uploadStep: {
            title: "Télécharger le fichier",
            manifestTitle: "Données attendues :",
            manifestDescription: "(Vous aurez la possibilité de renommer ou de supprimer des colonnes aux étapes suivantes)",
            maxRecordsExceeded: "Trop d'enregistrements. Jusqu'à ${maxRecords} autorisés",
            dropzone: {
                title: "Téléchargez un fichier .xlsx, .xls ou .csv",
                errorToastDescription: "téléchargement rejeté",
                activeDropzoneTitle: "Déposez le fichier ici...",
                buttonTitle: "Sélectionner un fichier",
                loadingTitle: "Traitement...",
            },
            selectSheet: {
                title: "Sélectionnez la feuille à utiliser",
                nextButtonTitle: "Suivant",
                backButtonTitle: "Retour",
            },
        },
        selectHeaderStep: {
            title: "Sélectionnez la ligne d'en-tête",
            nextButtonTitle: "Suivant",
            backButtonTitle: "Retour",
        },
        matchColumnsStep: {
            title: "Faire correspondre les colonnes",
            nextButtonTitle: "Suivant",
            backButtonTitle: "Retour",
            userTableTitle: "Votre tableau",
            templateTitle: "Deviendra",
            selectPlaceholder: "Sélectionner une colonne...",
            ignoredColumnText: "Colonne ignorée",
            subSelectPlaceholder: "Sélectionner...",
            matchDropdownTitle: "Correspondre",
            unmatched: "Non apparié",
            duplicateColumnWarningTitle: "Une autre colonne non sélectionnée",
            duplicateColumnWarningDescription: "Les colonnes ne peuvent pas être dupliquées",
        },
        validationStep: {
            title: "Valider les données",
            nextButtonTitle: "Confirmer",
            backButtonTitle: "Retour",
            noRowsMessage: "Aucune donnée trouvée",
            noRowsMessageWhenFiltered: "Aucune donnée contenant des erreurs",
            discardButtonTitle: "Supprimer les lignes sélectionnées",
            filterSwitchTitle: "Afficher uniquement les lignes avec des erreurs",
        },
        alerts: {
            confirmClose: {
                headerTitle: "Quitter le flux d'importation",
                bodyText: "Êtes-vous sûr ? Vos informations actuelles ne seront pas sauvegardées.",
                cancelButtonTitle: "Annuler",
                exitButtonTitle: "Quitter le flux",
            },
            submitIncomplete: {
                headerTitle: "Erreurs détectées",
                bodyText: "Il y a encore des lignes contenant des erreurs. Les lignes avec des erreurs seront ignorées lors de la soumission.",
                bodyTextSubmitForbidden: "Il y a encore des lignes contenant des erreurs.",
                cancelButtonTitle: "Annuler",
                finishButtonTitle: "Soumettre",
            },
            submitError: {
                title: "Erreur",
                defaultMessage: "Une erreur s'est produite lors de la soumission des données",
            },
            unmatchedRequiredFields: {
                headerTitle: "Toutes les colonnes ne sont pas appariées",
                bodyText: "Il y a des colonnes requises qui ne sont pas appariées ou ignorées. Voulez-vous continuer ?",
                listTitle: "Colonnes non appariées :",
                cancelButtonTitle: "Annuler",
                continueButtonTitle: "Continuer",
            },
            toast: {
                error: "Erreur",
            },
        },
        validations: {
            required: "${fieldName} est obligatoire",
        }
    }
}