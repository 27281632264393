import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ArrowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M18.75 23.75L10 15L18.75 6.25" fill="none" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    )
}

