import { Layer, Source } from "react-map-gl"

export const FloodLayer = (props: { opacity?: number }) => {
    return (
        <>
            <Source id="raster-flood" type="raster" tiles={["https://georisques.gouv.fr/services?language=fre&version=1.3.0&service=WMS&bbox={bbox-epsg-3857}&request=GetMap&crs=EPSG:3857&layers=LIMITETRI_FXX&width=256&height=256&format=image/png&styles=inspire_common:DEFAULT"]} tileSize={256}>
                <Layer id="raster-flood" type="raster" source="raster-flood" paint={{ 'raster-opacity': props.opacity ?? 0.7 }} />
            </Source>
            <Source id="raster-flood3" type="raster" tiles={["https://georisques.gouv.fr/services?language=fre&version=1.3.0&service=WMS&bbox={bbox-epsg-3857}&request=GetMap&crs=EPSG:3857&layers=ALEA_SYNT_01_01FOR_FXX&width=256&height=256&format=image/png&styles=inspire_common:DEFAULT"]} tileSize={256}>
                <Layer id="raster-flood3" type="raster" source="raster-flood3" paint={{ 'raster-opacity': props.opacity ?? 0.7 }} />
            </Source>
            <Source id="raster-flood4" type="raster" tiles={["https://georisques.gouv.fr/services?language=fre&version=1.3.0&service=WMS&bbox={bbox-epsg-3857}&request=GetMap&crs=EPSG:3857&layers=ALEA_SYNT_01_02MOY_FXX&width=256&height=256&format=image/png&styles=inspire_common:DEFAULT"]} tileSize={256}>
                <Layer id="raster-flood4" type="raster" source="raster-flood4" paint={{ 'raster-opacity': props.opacity ?? 0.7 }} />
            </Source>
            <Source id="raster-flood5" type="raster" tiles={["https://georisques.gouv.fr/services?language=fre&version=1.3.0&service=WMS&bbox={bbox-epsg-3857}&request=GetMap&crs=EPSG:3857&layers=ALEA_SYNT_01_03MCC_FXX&width=256&height=256&format=image/png&styles=inspire_common:DEFAULT"]} tileSize={256}>
                <Layer id="raster-flood5" type="raster" source="raster-flood5" paint={{ 'raster-opacity': props.opacity ?? 0.7 }} />
            </Source>
        </>
    )
}
