export default function Link(theme) {
    return {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.darker,
                    fontWeight: 500,
                }
            }
        }
    }
}