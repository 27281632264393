import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { baseMessages } from './i18n/fr/base';
import { prospectMessages } from './i18n/fr/prospect';
import { userMessages } from './i18n/fr/user';
import { targetingMessages } from './i18n/fr/targeting';
import { organisationMessages } from './i18n/fr/organisation';
import { dashboardMessages } from './i18n/fr/dasboard';
import { teamMessages } from './i18n/fr/team';
import { contactMessages } from './i18n/fr/contact';
import { spreadsheetImportTranslations } from './i18n/fr/spreadsheetImport';
import { fileMessages } from './i18n/fr/file';
import { parcelMessages } from './i18n/fr/parcel';
import { permitMessages } from './i18n/fr/permits';
import { taxesMessages } from './i18n/fr/taxes';
import { tagsMessages } from './i18n/fr/tags';

const messages = {
    fr: {
        ...frenchMessages,
        ...baseMessages,
        ...prospectMessages,
        ...userMessages,
        ...targetingMessages,
        ...organisationMessages,
        ...dashboardMessages,
        ...teamMessages,
        ...contactMessages,
        ...spreadsheetImportTranslations,
        ...fileMessages,
        ...parcelMessages,
        ...permitMessages,
        ...taxesMessages,
        ...tagsMessages,
    },
};

export const i18nProvider = polyglotI18nProvider(locale => messages["fr"]);