import { useGetList } from "react-admin"
import { CustomCard } from "../layout/CustomCard";
import { PageTitle } from "../layout/PageTitle";
import { RecommendationCard } from "./RecommendationCard";

export const RecommendationList = (props: { direction: string }) => {
    const { data } = useGetList('recommendations', {},
        {
            refetchOnWindowFocus: false
        });

    return data && data?.length > 0 ?
        <CustomCard noShadow={true} className={'py-4 px-4'} containerClass="mb-8">
            <PageTitle title="Biens Similaires" />
            <div className={`flex gap-2 mt-5 flex-${props.direction}`}>
                {data && data.map((record: any) => {
                    return <RecommendationCard recommendation={{ ...record }} />
                })}
            </div>
        </CustomCard>
        : <></>
}

RecommendationList.defaultProps = {
    direction: "col"
}