import { useRef, useState } from 'react';
import {
    useTranslate,
    useListContext,
    useUpdateMany,
    useNotify,
    useRefresh,
    useUnselect,
    useGetIdentity,
} from 'react-admin';
import { dataProvider } from '../../dataProvider';
import { SplitClient } from '@splitsoftware/splitio-react';

//Components
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';
import TransferProspectButton from './TransferProspectButton';
import { EmailSender } from '../Emails/EmailSender';

// Mui
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

//Icons
import { VoiceMailIcon } from '../Icons/VoiceMailIcon';
import EmailIcon from '@mui/icons-material/AlternateEmailRounded';
import { MailIcon } from '../Icons/MailIcon';

import theme from '../../theme';
import { Divider } from '../layout/Divider';
import { IProspect } from '../../types/Prospect';

export const ProspectBulkActionsButton = () => {
    const { data: prospects, selectedIds, onUnselectItems, filter } = useListContext();
    const [update] = useUpdateMany();
    const [, forceUpdate] = useState({}); // Use state to force a re-render
    const notify = useNotify();
    const refresh = useRefresh();
    const unselect = useUnselect('prospects');
    const isSendingRef = useRef(false); // Add state for sending status
    const t = useTranslate();
    const user = useGetIdentity();

    const handleClickStatus = (status: "accepted" | "rejected") => {
        window.analytics?.track('Used Bulk Action', { action: "changeStatus", status });

        update(
            "prospects",
            { ids: selectedIds, data: { status } },
            {
                onSuccess: () => {
                    refresh();
                    onUnselectItems();
                },
                onError: () => {
                    notify('BasicError', { type: 'error' });
                }
            }
        )
    }

    const updatePipeStatus = (failedIds: string[]) => {
        let successIds = selectedIds.filter((id: string) => !failedIds.includes(id));
        update(
            "prospects",
            { ids: successIds, data: { pipeStatus: "contacted" } },
            {
                onSuccess: () => {
                    refresh();
                    onUnselectItems();
                },
                onError: () => {
                    notify('BasicError', { type: 'error' });
                }
            }
        )
    }

    const sendVoiceMails = async () => {
        window.analytics?.track('Used Bulk Action', { action: "sendVoiceMails" });

        const failedIds: string[] = [];

        const promises = selectedIds.map((id: string) => {
            return new Promise((resolve, reject) => {
                return dataProvider.create(`prospects/${id}/send-notification`, { data: { channel: "voicemail", title: t('prospect.actions.sendNotification.voicemail.success') } })
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(() => {
                        failedIds.push(id);
                        reject({ data: { id } });
                    })
            })
        });

        Promise.allSettled(promises)
            .then((promises) => {
                if (failedIds.length > 0) {
                    notify(`Erreur pour les prospects suivants: ${failedIds.map((id) => prospects?.find((item: any) => item.id === id)?.name).join(", ")}`, { type: "error" });
                    //Unselect all items that did not fail
                    unselect(selectedIds.filter((id: string) => !failedIds.includes(id)));
                } else {
                    notify(
                        <Alert severity="success" className='flex items-center'>
                            <Box className="flex items-center gap-2">
                                {t('prospect.actions.sendNotification.voicemail.success')}
                                <Button onClick={() => updatePipeStatus(failedIds)}>
                                    {t('prospect.actions.updateStatus')}
                                </Button>
                            </Box>
                        </Alert>,
                        { type: 'success', autoHideDuration: 10000 }
                    );
                    onUnselectItems();
                }
            })
            .catch((error) => {
                notify(`BasicError`);
            })
    }

    const handleClick = () => {
        isSendingRef.current = true; // Set the sending status to false when done
        forceUpdate({});
        setTimeout(() => {
            if (isSendingRef.current) {
                isSendingRef.current = false; // Set the sending status to false when done
                sendVoiceMails(); // Call sendVoiceMail method after 2 seconds
            }
        }, 4000);
    };

    const handleCancel = () => {
        isSendingRef.current = false; // Set the sending status to false when done
        forceUpdate({});
    };

    const EmailBulkButton = (props: { channel: "email" | "postMail" }) => {
        const { data: prospects, selectedIds, onUnselectItems, filter, refetch } = useListContext<IProspect>();
        const [dialogOpen, setDialogOpen] = useState(false);
        const t = useTranslate();

        const { channel } = props;

        return <>
            <Tooltip title={t(`prospect.actions.sendNotification.${channel}.title`)} >
                <IconButton color="primary" onClick={(e) => { e.stopPropagation(); setDialogOpen(true) }}>
                    {channel === "email" ?
                        <EmailIcon color='primary' />
                        : <MailIcon stroke={theme.palette.primary.main} />
                    }
                </IconButton>
            </Tooltip>
            <EmailSender
                prospectIds={selectedIds}
                prospectEmails={prospects?.filter((prospect: IProspect) => selectedIds.includes(prospect.id)).map((prospect: IProspect) => prospect.email) ?? []}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                defaultMode='template'
                onSuccess={refetch}
                channel={channel}
            />
        </>
    }

    return filter?.status === "pending" ?
        <Box className="items-center flex">
            <Divider />
            <Button
                color='error'
                onClick={() => handleClickStatus("rejected")}>{t('buttons.reject')}
            </Button >
            <Divider />
            <Button
                onClick={() => handleClickStatus("accepted")}>{t('buttons.accept')}
            </Button >
        </Box>
        :
        <Box className="items-center flex">
            <Divider />
            <TransferProspectButton variant='text' prospects={prospects} prospectIds={selectedIds} onTransferred={onUnselectItems} />
            <Divider />
            <SingleSplitTreatment
                name="send-notification-phone"
                render={
                    <Box>
                        {isSendingRef.current === false ?
                            <Tooltip title={t('prospect.actions.sendNotification.voicemail.title')} >
                                <IconButton color="primary" onClick={(e) => { e.stopPropagation(); handleClick() }}>
                                    <VoiceMailIcon stroke="none" fill={theme.palette.primary.main} />
                                </IconButton>
                            </Tooltip>
                            :
                            <button className='text-error underline' onClick={handleCancel}>
                                <Typography fontWeight={500} variant="body2">
                                    {t('ra.action.cancel')}
                                </Typography>
                            </button>
                        }
                    </Box>
                }
                offRender={<></>}
            />
            <SingleSplitTreatment
                name="send-notification-email"
                render={<>
                    <Divider />
                    <EmailBulkButton channel="email" />
                </>}
                offRender={<></>}
            />
            <SplitClient splitKey={user?.data?.organisationId} trafficType='account'>
                <SingleSplitTreatment
                    name="send-notification-postmail"
                    render={<>
                        <Divider />
                        <EmailBulkButton channel="postMail" />
                    </>}
                    offRender={<></>}
                />
            </SplitClient>
        </Box>
}