import { useRecordContext, useTranslate, ReferenceArrayField, WithListContext, Edit, ReferenceArrayInput, RecordContextProvider, AutocompleteArrayInput, Form, SaveButton } from "react-admin"
import { IProspect } from "../../../types/Prospect"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OwnerCard } from "../Owners/OwnerCard";
import { BackButton } from "../../Buttons/BackButton";


export const ContactTab = (props:
    {
        displayType: 'list' | 'form',
        onSuccess: () => void,
        onBack: () => void
    }) => {
    const prospect = useRecordContext<IProspect>();
    const t = useTranslate();
    const { displayType, onBack, onSuccess } = props;

    if (!prospect) return null;

    return <>
        {displayType === 'form' &&
            <Edit actions={false}
                transform={(data: any) => ({
                    contactIds: data.contactIds
                })}
                redirect={false}
                mutationMode="pessimistic"
                mutationOptions={{
                    onSuccess: () => {
                        onSuccess();
                    }
                }}
                title={' '}
            >
                <Box className="flex items-center gap-2 py-2 mb-4 border-b-2 border-neutral-100">
                    <BackButton onClick={onBack} />
                    <Box className="font-semibold text-sm">{t('contact.actions.addContact')}</Box>
                </Box>
                <Form>
                    <Typography variant="h6">{t('prospect.property.contacts')}</Typography>
                    <ReferenceArrayInput reference="contacts" source="contactIds">
                        <AutocompleteArrayInput sx={{ width: '100%' }} debounce={500} emptyText={t('targeting.create.noUser')} label="" optionText={(choice) => `${choice?.fullName} : ${t(`contact.roles.${choice?.role}`)}`} />
                    </ReferenceArrayInput>
                    <SaveButton />
                </Form>
            </Edit>
        }
        {displayType === 'list' &&
            <ReferenceArrayField reference="contacts" source="contactIds" record={prospect}>
                <WithListContext render={({ data }) => (
                    <Box className="flex flex-col gap-4">
                        {data?.map((contact: any) => {
                            return <RecordContextProvider value={contact}>
                                <OwnerCard type='contact' prospect={prospect} prospectId={prospect?.id} owner={contact} />
                            </RecordContextProvider>
                        })}
                    </Box>
                )}
                />
            </ReferenceArrayField>
        }
    </>
}