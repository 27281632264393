import { useState } from 'react';
import { NoBorderToggleButton, StyledToggleButtonGroup } from '../../Buttons/StyledToggleButtonGroup';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import { MeasureDistance } from '../Tools/MeasureDistance';

export const ToolsController = (props: { setIsToolOn: (val: boolean) => void }) => {
    const [activatedTool, setActivatedTool] = useState<string | null>(null);

    const handleToolChange = (
        tool: string,
    ) => {
        if (tool !== null) {
            //If same, deactivate
            if (tool === activatedTool) {
                setActivatedTool(null);
                props.setIsToolOn(false);
                return;
            }
            setActivatedTool(tool);
            props.setIsToolOn(true);
            window.analytics?.track('Activated Tool', { tool });
        }
    };

    const tools: [{ value: string, icon: JSX.Element }] = [
        { value: "measure", icon: <StraightenRoundedIcon sx={{ fontSize: "32px" }} /> }
    ]

    return <StyledToggleButtonGroup
        value={activatedTool}
        exclusive
        aria-label="text alignment"
    >
        {tools.map((tool) => (
            <NoBorderToggleButton onClick={() => handleToolChange(tool.value)} key={tool.value} sx={{ padding: 0 }} value={tool.value} aria-label={tool.value}>
                {tool.icon}
            </NoBorderToggleButton>
        ))}
        {activatedTool === "measure" && (
            <MeasureDistance />
        )}
    </StyledToggleButtonGroup>
}