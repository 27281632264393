import { ArrayField, FunctionField, SingleFieldList, TextField, useTranslate } from "react-admin"
import Typography from '@mui/material/Typography';
import { Box, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

export const ProjectList = (props: { onAdd: () => void }) => {
    const t = useTranslate();
    return <>
        <ArrayField source="projects" label={false}>
            <SingleFieldList className="flex flex-col  pb-4 w-full" linkType={false}>
                <Box className=" w-full rounded-lg border-solid border-4 border-bg first:border-success px-4 py-2">

                    <div className="flex items-center  justify-between w-full">
                        <TextField sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                        }} source="title" />
                        <Button onClick={props.onAdd} sx={{ minWidth: 'auto', padding: 1 }} color="inherit" size="small">
                            <EditIcon fontSize="small" />
                        </Button>
                    </div>

                    <div className="flex items-center  justify-between w-full">
                        <Typography >
                            {t('prospect.property.projects.floorArea')} : </Typography>
                        <FunctionField
                            source="projects"
                            render={(record: any) => record.floorArea ? `${new Intl.NumberFormat('fr-FR').format(record.floorArea)} m²` : null}
                        />
                    </div>

                    <div className="flex items-center  justify-between w-full">
                        <Typography>
                            {t('prospect.property.projects.projectedRevenue')} : </Typography>
                        <FunctionField
                            source="projects"
                            render={(record: any) => record.projectedRevenue ? `${new Intl.NumberFormat('fr-FR').format(record.projectedRevenue)} €` : null}
                        />
                    </div>

                    <div className="flex items-center  justify-between w-full">
                        <Typography > {t('prospect.property.projects.numberOfUnits')} :</Typography>
                        <TextField source="numberOfUnits" />
                    </div>
                </Box>
            </SingleFieldList>
        </ArrayField >


    </>
}
