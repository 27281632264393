import { useRecordContext } from "react-admin";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { IParcel } from "../../types/Geography";

export const PappersLink = (props: { parcel?: IParcel, coordinates?: number[] }) => {
    const record = useRecordContext();

    if (!record) return null;

    const parcel = props.parcel ?? record.parcels?.[0];
    const coordinates = props.coordinates ?? record.location.coordinates;

    return <div onClick={() => {
        window.analytics?.track('Opened Pappers', { id: record._id });
    }}>
        <a className="underline text-primary flex gap-1" href={`https://immobilier.pappers.fr/?lat=${coordinates[1]}&lon=${coordinates[0]}&z=18.00${parcel ? `&parcelle=${parcel.properties.idu}` : ''}`} target="_blank" rel="noreferrer">
            <OpenInNewRoundedIcon fontSize="small" />
            Voir sur Pappers
        </a>
    </div >
}