export const tagsMessages = {
    tags: {
        title: 'Étiquettes',
        property: {
            name: 'Nom de l\'étiquette',
            bgColor: 'Couleur',
            visibility: 'Visibilité',
        },
        actions: {
            create: 'Créer une étiquette',
            edit: 'Modifier',
            delete: 'Supprimer',
        },
        visibility: {
            user: 'Seulement pour moi',
            team: 'Pour toute l\'équipe',
            organisation: 'Pour toute l\'organisation',
        }
    }
}