import { SimpleForm, TextInput, SelectInput, Toolbar, SaveButton, useTranslate, NumberInput, BooleanInput } from 'react-admin';


const FormResultFormToolbar = (props: any) => {
    return <Toolbar {...props} >
        <SaveButton
            label={props.label}
            type="button"
        />
    </Toolbar>

}

export const FormResultForm = (props: any) => {
    const t = useTranslate();

    return <SimpleForm
        sanitizeEmptyValues={true}
        toolbar={<FormResultFormToolbar mode={props.mode} label={props.buttonLabel}
        />}>
        <TextInput fullWidth label={t("prospect.property.address")} source="address" />
        <TextInput label={t("prospect.property.parcelleNumber")} source="parcelleNumber" />
        <TextInput label={t("prospect.property.ownerComment")} source="ownerComment" />
        <NumberInput label={t("prospect.property.salePrice")} source="salePrice" />
        <SelectInput label={t("prospect.property.saleTiming")} source="saleTiming" choices={
            [
                { id: "asap", name: t(`prospect.saleTimings.asap`) },
                { id: "3months", name: t(`prospect.saleTimings.3months`) },
                { id: "6months", name: t(`prospect.saleTimings.6months`) },
                { id: "12months", name: t(`prospect.saleTimings.12months`) },
                { id: "morethan12months", name: t(`prospect.saleTimings.morethan12months`) },
            ]} />
        <NumberInput defaultValue={undefined} label={t("prospect.property.numberRooms")} source="numberRooms" />
        <NumberInput label={t("prospect.property.constructionYear")} source="constructionYear" />
        <TextInput label={'DPE'} source="dpe" />
        <SelectInput label={t("prospect.property.generalState")} source="generalState" choices={
            [
                { id: "new", name: t(`prospect.generalStates.new`) },
                { id: "good", name: t(`prospect.generalStates.good`) },
                { id: "bad", name: t(`prospect.generalStates.bad`) },
            ]} />
        <SelectInput label={t("prospect.property.residencyType")} source="residencyType" choices={
            [
                { id: "principal", name: t(`prospect.residencyTypes.principal`) },
                { id: "secondary", name: t(`prospect.residencyTypes.secondary`) },
                { id: "rental", name: t(`prospect.residencyTypes.rental`) },
                { id: "other", name: t(`prospect.residencyTypes.other`) },
            ]} />
        <BooleanInput source={"verifiedEmail"} label={t("prospect.property.verifiedEmail")} />
        <BooleanInput source={"verifiedPhone"} label={t("prospect.property.verifiedPhoneNumber")} />
    </SimpleForm>
}