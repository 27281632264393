// Libraires
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { keys, uniq } from 'lodash';
import { Source, Layer } from "react-map-gl"
import axios from 'axios';
import getCenter from '@turf/center-of-mass';
import { CommuneContext } from '../../contexts/contexts';

// Components
import GeocoderControl from './Controllers/GeoController';
import LayersController from './Controllers/LayersController';
import { MapStyleButtons } from './MapStyleButtons';
import { ParcelDrawer } from '../Parcels/ParcelDrawer';
import { PCDrawer } from '../Permits/PCDrawer';
import { ParcelsFilter } from '../Forms/ExploreFilters/ParcelsFilter';
import { ToolsController } from './Controllers/ToolsController';
import { CommuneSelectDropdown, CommuneSelectList } from '../Inputs/CommuneSelect';

// Mui components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';

// Style
import { mapboxStreetStyle } from '../../helpers/constants';

// Layers
import { SearchParcelsLayer } from './Layers/SearchParcelsLayer';
import PCSLayer from './Layers/PCSLayer';
import { CommunePluLayer } from './Layers/CommunePLULayer';
import { ArgileLayer } from './Layers/ArgileLayer';
import { EarthQuakeLayer } from './Layers/EarthquakeLayer';
import { RadonLayer } from './Layers/RadonLayer';
import { DPELayer } from './Layers/DPELayer';
import { FloodLayer } from './Layers/FloodingLayer';

// Icons
import { DPEIcon } from '../Icons/DPEIcon';
import { ArrowIcon } from '../Icons/ArrowIcon';
import theme from '../../theme';
import { Slider } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

//@ts-ignore
mapboxgl.workerClass = function () {
    return new Worker(new URL("../../mapbox.worker", import.meta.url));
};

const Parcels = (props: { setIsParcelFilterOn: any, isParcelFilterOn: boolean }) => {
    const { setIsParcelFilterOn, isParcelFilterOn } = props;
    const [formData, setFormData] = useState<any>({});
    const [displayParcels, setDisplayParcels] = useState<boolean>(false);
    const [parcelOpacity, setParcelOpacity] = useState(0.7);

    const filter = useMemo(() => ({
        ...formData
    }), [formData]);

    return <>
        <Switch
            checked={displayParcels}
            onChange={() => { setDisplayParcels(!displayParcels), setIsParcelFilterOn(!isParcelFilterOn) }}
        />
        {displayParcels === true &&
            <Slider size="small"
                value={parcelOpacity}
                min={0}
                max={1}
                step={0.1}
                onChange={(e: Event, value: number | number[]) => setParcelOpacity(value as number)}
                aria-labelledby="opacity-slider"
                valueLabelDisplay="auto"
            />}
        <ParcelsFilter
            onChange={setFormData}
        />
        {displayParcels ?
            <SearchParcelsLayer
                filter={filter}
                opacity={parcelOpacity}
            /> :
            <Source promoteId={'idu'} id="search-parcels" type="geojson" data={undefined} >
                <Layer minzoom={10} id="search-parcels" type="fill" paint={{
                    'fill-opacity': 0.1
                }} />
            </Source>
        }
    </>
}

const Risques = () => {
    const [displayArgile, setDisplayArgile] = useState<boolean>(false);
    const [displayEarthQuake, setDisplayEarthQuake] = useState<boolean>(false);
    const [displayRadon, setDisplayRadon] = useState<boolean>(false);
    const [displayFlood, setDisplayFlood] = useState<boolean>(false);
    const [argileOpacity, setArgileOpacity] = useState(0.7);
    const [radonOpacity, setRadonOpacity] = useState(0.7);
    const [floodOpacity, setFloodOpacity] = useState(0.7);
    const [earthQuakeOpacity, setEarthQuakeOpacity] = useState(0.7);


    return <>
        <div className='flex flex-col'>
            <FormControlLabel
                control={
                    <Switch
                        checked={displayArgile}
                        onChange={() => setDisplayArgile(!displayArgile)}
                        color="primary"
                    />
                }
                label="Argile"
            />
            {displayArgile === true &&
                <Slider
                    size="small"
                    value={argileOpacity}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e: Event, value: number | number[]) => setArgileOpacity(value as number)}
                    aria-labelledby="opacity-slider"
                    valueLabelDisplay="auto"
                />}
            <FormControlLabel
                control={
                    <Switch
                        checked={displayEarthQuake}
                        onChange={() => setDisplayEarthQuake(!displayEarthQuake)}
                        color="primary"
                    />
                }
                label="Sismicité"
            />
            {displayEarthQuake === true &&
                <Slider
                    size="small"
                    value={earthQuakeOpacity}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e: Event, value: number | number[]) => setEarthQuakeOpacity(value as number)}
                    aria-labelledby="opacity-slider"
                    valueLabelDisplay="auto"
                />}
            <FormControlLabel
                control={
                    <Switch
                        checked={displayRadon}
                        onChange={() => setDisplayRadon(!displayRadon)}
                        color="primary"
                    />
                }
                label="Radon"
            />
            {displayRadon === true &&
                <Slider
                    size="small"
                    value={radonOpacity}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e: Event, value: number | number[]) => setRadonOpacity(value as number)}
                    aria-labelledby="opacity-slider"
                    valueLabelDisplay="auto"
                />}
            <FormControlLabel
                control={
                    <Switch
                        checked={displayFlood}
                        onChange={() => setDisplayFlood(!displayFlood)}
                        color="primary"
                    />
                }
                label="Inondation"
            />
            {displayFlood === true &&
                <Slider
                    size="small"
                    value={floodOpacity}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e: Event, value: number | number[]) => setFloodOpacity(value as number)}
                    aria-labelledby="opacity-slider"
                    valueLabelDisplay="auto"
                />}
            {displayArgile && <ArgileLayer opacity={argileOpacity} />}
            {displayEarthQuake && <EarthQuakeLayer opacity={earthQuakeOpacity} />}
            {displayRadon && <RadonLayer opacity={radonOpacity} />}
            {displayFlood && <FloodLayer opacity={floodOpacity} />}
        </div>

    </>
}


const CommunePLU = () => {
    const selectedCommune = useContext(CommuneContext);
    const [pluOpacity, setPluOpacity] = useState(0.7);
    const [pluTypeZones, setPluTypeZones] = useState({
        U: false,
        AUc: false,
        AUs: false,
        N: false,
        A: false,
    });
    const [pluLibelleOptions, setPluLibelleOptions] = useState<string[]>([]);
    const [pluLibellesSelected, setPluLibellesSelected] = useState<string[]>([]);
    const [pluData, setPluData] = useState<any>();
    function groupByUPrefix(arr: string[]): string[] {
        const result: string[] = [];

        arr.forEach(item => {
            // Handle values starting with 'U'
            if (item.startsWith('U')) {
                // Match prefixes in the form "UA", "UB", "UC", etc.
                if (/^U[A-Z]$/.test(item)) {
                    result.push(item);
                }
                // For other "U" prefixed values like "UA1", "UB2", only add the prefix (e.g., "UA", "UB")
                else if (/^U[A-Z].*/.test(item)) {
                    const prefix = item.slice(0, 2);
                    if (!result.includes(prefix)) {
                        result.push(prefix);
                    }
                }
            }
            // Handle values not starting with 'U'
            else {
                result.push(item);
            }
        });

        return result;
    }

    const [data, setData] = useState<any>();

    const getPLUAreas = async (zones: string[]) => {
        const plu = (data.features.filter((feature: any) => zones.includes(feature.properties.typezone)) ?? null);
        setPluData(plu);
        setPluLibelleOptions(
            (uniq<string>(groupByUPrefix(plu.map((feature: any) => feature.properties.libelle))).sort(
                (a: string, b: string) => a.localeCompare(b)
            )) ?? null
        );
    }
    const fetchDataPLU = async () => {
        const response = await axios.get(`https://apicarto.ign.fr/api/gpu/zone-urba?partition=${selectedCommune?.pluPartition}`);
        setData(response.data)
    }

    useEffect(() => {
        fetchDataPLU()
    }, [selectedCommune]);


    const handleChange = async (newValues: any, selected: string[]) => {
        setPluLibellesSelected([]);
        getPLUAreas(selected);
        setPluTypeZones(newValues);
    };

    return <Box>
        <Box>Zones PLU</Box>
        <Box className="flex wrap">
            <Select
                displayEmpty
                multiple
                value={keys(pluTypeZones).filter((key) => pluTypeZones[key as keyof typeof pluTypeZones] === true)}
                onChange={(event: SelectChangeEvent<string[]>) => {
                    const selected = event.target.value as string[];
                    const newPluTypeZones = { ...pluTypeZones };
                    keys(pluTypeZones).forEach((key) => {
                        newPluTypeZones[key as keyof typeof pluTypeZones] = selected.includes(key);
                    });
                    handleChange(newPluTypeZones, selected);
                }}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>Zones PLU</em>;
                    }
                    return (selected as string[]).join(', ')
                }}
            >
                <MenuItem disabled value="">
                    <em>Zones PLU</em>
                </MenuItem>
                {keys(pluTypeZones).map((key) => (
                    <MenuItem key={key} value={key}>
                        {key}
                    </MenuItem>
                ))}
            </Select>
            <Select
                displayEmpty
                multiple
                value={pluLibellesSelected}
                onChange={(event: SelectChangeEvent<string[]>) => {
                    const value = event.target.value;
                    setPluLibellesSelected(Array.isArray(value) ? value : [value]);
                }}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>Sous zones PLU</em>;
                    }
                    return (selected as string[]).join(', ')
                }}
            >
                <MenuItem disabled value="">
                    <em>Sous zones PLU</em>
                </MenuItem>
                {pluLibelleOptions?.map((libelle: string) => (
                    <MenuItem key={libelle} value={libelle}>
                        {libelle}
                    </MenuItem>
                ))}
            </Select>
            {/* @ts-ignore */}
            <CommunePluLayer pluTypeZones={keys(pluTypeZones).filter((key: string) => pluTypeZones[key] === true)} pluLibelles={pluLibellesSelected} opacity={pluOpacity} pluData={pluData} />
        </Box>
        {keys(pluTypeZones).some((element: string) => pluTypeZones[element as keyof typeof pluTypeZones] === true) &&
            <Slider
                size="small"
                value={pluOpacity}
                min={0}
                max={1}
                step={0.1}
                onChange={(e: Event, value: number | number[]) => setPluOpacity(value as number)}
                aria-labelledby="opacity-slider"
                valueLabelDisplay="auto"
            />
        }

    </Box>
}

const DPE = () => {
    const [displayDPE, setDisplayDPE] = useState<boolean>(false);
    const [selectedDPE, setSelectedDPE] = useState<any>(null);

    return <>
        <DPEDrawer
            selectedDPE={selectedDPE}
            setSelectedDPE={setSelectedDPE}
        />
        <Box>Diagnostique de Performance Energétique</Box>
        <FormControlLabel
            control={
                <Switch
                    // @ts-ignore
                    checked={displayDPE}
                    onChange={() => setDisplayDPE(!displayDPE)}
                    name={"DPE"}
                    color="primary"
                />
            }
            label={"DPE"}
        />
        {displayDPE && <DPELayer onMarkerClick={setSelectedDPE} />}
    </>
}

const DPEDrawer = (props: { selectedDPE: any, setSelectedDPE: any }) => {
    const { selectedDPE } = props;
    return <Drawer
        open={selectedDPE !== null}
        onClose={() => props.setSelectedDPE(null)}
    >
        <div className="w-96">
            {selectedDPE &&
                <Box className="flex flex-col items-start">
                    <DPEIcon DPE={selectedDPE.dpe} />
                    <p>{selectedDPE.dpeDate}</p>
                </Box>}
        </div>
    </Drawer>
}


const ExploreMap = (props: any) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
    const [style, setStyle] = useState(mapboxStreetStyle);
    const [isCadastreOn, setIsCadastreOn] = useState<boolean>(false);
    const [isParcelFilterOn, setIsParcelFilterOn] = useState<boolean>(false);
    const [isToolOn, setIsToolOn] = useState<boolean>(false);
    const [center, setCenter] = useState(props.center ? props.center : [2.4973143200662022, 48.798628021394556]);
    const [position, setPosition] = useState<[number, number] | null>(null);
    const [selectedParcel, setSelectedParcel] = useState<any>(null);
    const [selectedPC, setSelectedPC] = useState<any>(null);
    const [selectedPLU, setSelectedPLU] = useState<any>(null);
    const [parcelPOI, setParcelPOI] = useState<{ lon: number | null, lat: number | null }>({ lon: null, lat: null });
    const [selectedCommune, setSelectedCommune] = useState<{
        code: string,
        bbox: { coordinates: [[number, number, number, number]] },
        maxBounds: [[number, number], [number, number]],
        contour: any,
        centre: { coordinates: [number, number] },
        nom: string,
        pluPartition: string,
    } | undefined>(undefined);
    let prevFeatureId: string;
    const handleClick = async (e: any) => {
        if (isToolOn) return;
        //Find feature from search-parcels layer
        const parcel = e.features.find((f: any) => f.layer.id === 'search-parcels');
        const pc = e.features.find((f: any) => f.layer.id === 'pcs');
        const plu = e.features.find((f: any) => f.layer.id === 'commune-plu');
        if (parcel) {
            setSelectedParcel({ ...parcel, geometry: parcel._geometry });
        }
        if (isCadastreOn || isParcelFilterOn) {
            const point = e.lngLat;

            const geo = {
                type: "Point",
                coordinates: [point.lng, point.lat],
            };

            try {
                const parcelle = await axios.get(
                    `https://apicarto.ign.fr/api/cadastre/parcelle?geom=${encodeURIComponent(
                        JSON.stringify(geo)
                    )}`
                );

                if (parcelle) {
                    const parcelleCenter = getCenter(parcelle.data.features[0]);

                    setSelectedParcel({
                        ...parcelle?.data?.features[0],
                        center: parcelleCenter?.geometry?.coordinates,
                    });
                }
            } catch (error) {
            }
        }

        if (pc) {
            setSelectedPC(pc);
        }
        if (plu) {
            setSelectedPLU(plu);
        }
    }

    const handleResult = (data: any) => {
        setPosition(data.result.center);
    }

    const onMouseLeave = useCallback((event: any) => {
        const featureId = event.features[0]?.id;
        if (featureId) {
            event.target.setFeatureState({
                source: 'search-parcels', id: event.features[0].id
            }, { select: false });
        }
    }, []);

    const onMouseHandler = (event: any) => {
        const featureId = event.features[0]?.id;
        if (featureId) {
            event.target.setFeatureState({
                source: 'search-parcels', id: event.features[0].id
            }, { select: true });
        }
        if (featureId && event.features[0].id !== prevFeatureId) {
            if (prevFeatureId !== undefined) {
                event.target.setFeatureState({
                    source: 'search-parcels', id: prevFeatureId
                }, { select: false });
            }
        }
        if (event?.features[0]?.id !== undefined) {
            prevFeatureId = event?.features[0]?.id;
        }
    }

    const onSelectCommune = async (code: string) => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_DATA_API_BASE_URL}/communes/${code}`,
                {
                    headers: {
                        authorization: `Bearer ${process.env.REACT_APP_DATA_API_KEY}`,
                    }
                });
            let sw: [number, number] = data.data.bbox.coordinates[0][0];
            let ne: [number, number] = data.data.bbox.coordinates[0][2];

            // Get some margings for maxBounds
            const margin = 0.01;
            const maxBounds: [[number, number], [number, number]] = [
                [sw[0] - margin, sw[1] - margin],
                [ne[0] + margin, ne[1] + margin]
            ];
            setSelectedCommune({ ...data.data, maxBounds: maxBounds, pluPartition: data.data.pluDocument.partition });
        } catch (error) {
            console.error(error);
        }
    }
    return <div className='w-full h-full relative'>
        <CommuneContext.Provider value={selectedCommune}>
            <Dialog open={!selectedCommune} >
                <CommuneSelectList onSelectCommune={onSelectCommune} />
            </Dialog>
            <div className="absolute left-2 top-16 z-50 bg-white shadow-lg rounded-sm flex items-center flex-wrap">
                <MapStyleButtons onChange={(val: string) => setStyle(val)} />
            </div>

            <PCDrawer selectedPC={selectedPC} setSelectedPC={setSelectedPC} />
            <Map
                initialViewState={{
                    longitude: selectedCommune?.centre.coordinates[0] ?? center[0],
                    latitude: selectedCommune?.centre.coordinates[1] ?? center[1],
                    zoom: 8
                }}
                maxBounds={selectedCommune?.maxBounds ? selectedCommune.maxBounds : undefined}
                mapStyle={`mapbox://styles/${style}`}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ height: "100%", width: "100%" }}
                interactiveLayerIds={['search-parcels', 'pcs', 'commune-plu']}
                onClick={(e) => { handleClick(e) }}
                onMouseMove={onMouseHandler}
                onMouseLeave={onMouseLeave}
            >
                <ParcelDrawer
                    selectedParcel={selectedParcel}
                    selectedPLU={selectedPLU}
                    setSelectedParcel={setSelectedParcel}
                    setSelectedPLU={setSelectedPLU}
                    setParcelPOI={setParcelPOI}
                />
                {
                    parcelPOI?.lon && parcelPOI?.lat &&
                    <Marker longitude={parcelPOI.lon} latitude={parcelPOI.lat} />
                }
                <div className="absolute left-2 bottom-8 z-50 bg-white shadow-lg rounded-sm flex items-center flex-wrap">
                    <ToolsController setIsToolOn={setIsToolOn} />
                </div>
                <GeocoderControl onResult={handleResult} position="top-left" />
                <div className={`absolute ${isMenuOpen ? 'right-0' : '-right-1/4'} z-50 max-w-[350px] w-1/4 h-full transition-all`}>
                    <div className="absolute -left-16 top-2 z-50">
                        <IconButton
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            color="primary"
                            size="large"
                            sx={{ backgroundColor: 'white' }}
                        >
                            <Box className={`transform ${isMenuOpen ? 'rotate-180' : 'rotate-0'} transition-all`}>
                                <ArrowIcon
                                    stroke={theme.palette.primary.main}
                                />
                            </Box>
                        </IconButton>
                    </div>
                    <div className="overflow-y-auto flex flex-col gap-2 p-2 w-full h-full bg-white rotate">
                        <CommuneSelectDropdown onSelectCommune={onSelectCommune} />
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="h6">
                                    Calques
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LayersController
                                    color="white"
                                    pluEnable={false}
                                    QPVEnable={true}
                                    isoWalkingEnable={true}
                                    isoDrivingEnable={true}
                                    threeDEnable={true}
                                    position={position!}
                                    setIsCadastreOn={setIsCadastreOn}
                                    selectedParcel={selectedParcel}
                                />
                                <Box className="flex flex-col">
                                    <CommunePLU />
                                    <DPE />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="h6">
                                    Parcelles
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Parcels setIsParcelFilterOn={setIsParcelFilterOn} isParcelFilterOn={isParcelFilterOn} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography variant="h6">
                                    Permis de construire
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PCSLayer />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography variant="h6">
                                    Risques
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Risques />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </Map >
        </CommuneContext.Provider >
    </div >
}

export default ExploreMap;