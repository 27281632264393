import { useTranslate, useNotify, ReferenceField, FunctionField, RecordContextProvider } from "react-admin"

// Components
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { dataProvider } from "../../dataProvider";
import { useMutation } from '@tanstack/react-query';
import { FileTypeIcon } from "../Icons/FileTypeIcon";
import dayjs from "dayjs";
import { IUser } from "../../types/User";
import { DownloadIcon } from "../Icons/DownloadIcon";
import theme from "../../theme";

export const FileCard = (props: { resourceId: string, file: any }) => {
    const { resourceId, file } = props;
    const t = useTranslate();
    const notify = useNotify();

    const { mutate } = useMutation({
        mutationFn: () => dataProvider.downloadFile(`prospects/${resourceId}/files/download/${file.id}`,
        ),
        onSuccess: () => {
            notify(`notifications.sent`, { type: "success" });
        },
        onError: () => {
            notify('BasicError', { type: "error" })
        }
    });

    return <RecordContextProvider value={file}>
        <Box className="rounded-lg border-solid border-4 border-bg px-4 py-2">
            <Box className="flex items-center gap-3">
                <FileTypeIcon width={'40px'} height={'48px'} type={file.fileName.split('.').pop()} />
                <Box className="flex flex-col items-start">
                    <Typography variant="h6">{file.fileName}</Typography>
                    {(file.origin === "terrafinder" && file.url) ?
                        <a href={file.url} target="_blank" rel="noreferrer">Voir</a>
                        : <Button sx={{ color: theme.palette.primary.darker }} startIcon={<DownloadIcon />} variant="text" onClick={() => mutate(file.id)}>{t('buttons.download')}</Button>
                    }
                </Box>
            </Box>
            <Box className="w-full flex justify-end">
                <Typography variant="subtitle2">{t('file.property.createdAt', { date: dayjs(file.createdAt).format('DD/MM/YYYY') })} </Typography>
                {(file.origin === "terrafinder") ?
                    <Typography variant="subtitle2">{`, depuis @TerraFinder`}</Typography>
                    : <ReferenceField source="createdBy" reference="users" link={false}>
                        <FunctionField render={(user: IUser) => {
                            if (!user) {
                                return null;
                            }
                            return <Typography variant="subtitle2">{`, par @${user.firstName} ${user.lastName}`}</Typography>
                        }} />
                    </ReferenceField>
                }
            </Box>
        </Box>
    </RecordContextProvider>
}