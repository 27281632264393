import {
    TextInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    useTranslate
} from 'react-admin';
// Mui
import Typography from '@mui/material/Typography';
import { InputAdornment } from '@mui/material';

export const ProjectForm = () => {
    const t = useTranslate();

    const numberSx = {
        "& .MuiInputBase-root legend": {
            width: "0px"
        }
    }

    return <>
        <ArrayInput source="projects" label={false}>
            <SimpleFormIterator
                disableClear
                inline
                sx={{
                    "& .RaSimpleFormIterator-line": {
                        marginBottom: "20px"
                    },

                    "& .RaSimpleFormIterator-form": {
                        marginBottom: "20px",
                    },
                }}
            >
                <Typography variant="h6">{t('prospect.property.projects.title')}</Typography>
                <TextInput fullWidth={true} source="title" label={false} helperText={false} className="w-full" />
                <Typography variant="h6">{t('prospect.property.projects.floorArea')}</Typography>
                <NumberInput
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">m²</InputAdornment>
                        ),
                    }} min={0} max={1000000} source="floorArea" label={false} helperText={false} className="w-full" sx={{ ...numberSx }} />

                <Typography variant="h6">{t('prospect.property.projects.projectedRevenue')}</Typography>
                <NumberInput
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                        ),
                    }} min={-100000000} max={100000000} source="projectedRevenue" label={false} helperText={false} className="w-full" sx={{ ...numberSx }} />

                <Typography variant="h6">{t('prospect.property.projects.numberOfUnits')}</Typography>
                <NumberInput min={0} max={1000} source="numberOfUnits" label={false} helperText={false} className="w-full" sx={{ ...numberSx }} />
            </SimpleFormIterator>
        </ArrayInput >
    </>
}