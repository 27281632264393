import { UploadFile } from "../Icons/UploadFile";

export const FileUploadButton = () => {
  return (
    <div className="flex items-center justify-center ">
      <div className="flex items-center justify-center rounded-full border-solid border p-2.5 mx-3 border-zinc-400 ">
        <UploadFile />
      </div>

      <div className="flex flex-col items-center justify-center">
        <h2 className="text-darker">Choisissez un fichier ou glissez-le ici.</h2>
        <p className="text-sm text-zinc-400">
          Formats JPEG, PNG, PDF et MP4, jusqu'à 10 Mo
        </p>
      </div>

      <button className="border-solid border-zinc-400 border rounded-md px-4 py-2 mx-3">
        Importer
      </button>
    </div>
  );
};
