export default function Dialog(theme) {
    return {
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& .MuiButton-root.ra-confirm": {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }
                },
            }
        }
    };
}
