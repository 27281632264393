
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FullArrowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M23.75 15L6.25 15M6.25 15L13.75 22.5M6.25 15L13.75 7.5" fill="none" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    )
}
