import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useState } from 'react';
import { useNotify, useRedirect, Title, useTranslate } from 'react-admin';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const RequestPasswordReset = (): any => {
    const redirect = useRedirect();
    const notify = useNotify();
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [email, setEmail] = useState("");

    const handleClick = () => {
        setIsSent(true);
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/request-password-reset`, {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        })
            .then((response) => {
                notify('auth.resetPassword.resetMailSent', { type: "success" });
                setEmail("")
            })
            .catch((e) => {
                notify('Error: comment not approved', { type: 'warning' });
                setIsSent(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return <div className='w-screen h-screen flex justify-center items-center'>
        <img src="/static/kaptcher-logo-full.png" className="absolute top-4 left-4 w-1/3 md:w-1/5"></img>
        <Card className="sm:w-1/2 md:w-1/4 h-min">
            <CardContent className="flex items-center justify-between flex-col">
                <Typography variant="h4" gutterBottom>{t('auth.resetPassword.askForgotPassword')}</Typography>
                <TextField placeholder='Email' value={email} onChange={(e) => setEmail(e.target?.value)} />
                <div className="mt-4" >
                    <Button disabled={isSent} onClick={handleClick} size="medium" variant="contained" >{t('buttons.requestResetPassword')}</Button>
                </div>
            </CardContent>
        </Card>
    </div>
}

export default RequestPasswordReset;
