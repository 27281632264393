import { lazy, Suspense, useEffect, useState } from 'react';
import {
    TextField,
    useTranslate,
    FunctionField,
    usePermissions,
    useRedirect,
    Button,
    TextInput,
    Pagination,
    useStore,
    SelectInput,
    useGetList,
    useGetIdentity,
    List,
    useRecordContext,
    CreateButton,
    Title,
    useListContext,
} from 'react-admin';
// Mui
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import ToggleButton from '@mui/material/ToggleButton';
import Fab from '@mui/material/Fab';

// Components
import { StyledList } from '../../components/layout/StyledList';
import { PageTitle } from '../../components/layout/PageTitle';
import { SplittedList } from '../../components/layout/SplittedList';
import { CustomSelectArray } from '../../components/Inputs/CustomSelectArray';
import { CustomCard } from '../../components/layout/CustomCard';
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import { isSuperAdmin } from '../../helpers/helpers';
import { allPipeStatuses, defaultPipeStatuses } from '../../helpers/constants';
import { KanbanView } from '../../components/Prospects/KanbanView';
import { ProspectPreview } from '../../components/Prospects/ProspectPreview';
import { StyledToggleButtonGroup } from '../../components/Buttons/StyledToggleButtonGroup';

// Icons
import { SearchIcon } from '../../components/Icons/SearchIcon';
import AddIcon from '@mui/icons-material/Add';

//Theme
import theme from '../../theme';

//Types
import { IProspect, ProspectPipeStatusesType } from '../../types/Prospect';
import { MobileList } from '../../components/Prospects/MobileList';
import { ProspectToolBar } from '../../components/Prospects/Toolbar';
import { ProspectsDataTable } from '../../components/Prospects/DataTable';
import { SavedViewsList } from '../../components/Prospects/CustomViews/SavedViewsList';
import { OpenFiltersButton } from '../../components/Buttons/OpenFiltersButton';
import { ProspectAggregatedValues } from '../../components/Prospects/ProspectAggreatedValues';
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';


const ListStyle = {
    "& .RaList-actions": {
        flexDirection: "column-reverse",
        gap: "8px",
        "& form": {
            flexGrow: 1,
            width: "calc(100%)",
            justifyContent: "flex-start",
            padding: "16px 20px",
            paddingRight: "78px",
            "& [data-source='pipeStatus']": {
                marginLeft: "auto"
            },
            backgroundColor: "white",
            paddingBottom: "0",
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
        }
    }
}

// Lazy loading
const ProspectsMap = lazy(() => import('../../components/Map/ProspectsMap'));

const OrganisationItem = (props: any) => {
    const record = useRecordContext();
    return <div className="flex gap-1 items-center">
        <Avatar sx={{ height: 26, width: 26 }} src={record?.url} />
        <span>
            {record?.name}
        </span>
    </div>
}

const OrganisationUsersFilter = (props: any) => {
    const { data: orgas, isLoading } = useGetList('organisations', { sort: { field: 'isActive', order: 'DESC' }, pagination: { perPage: 50, page: 1 } }, {});
    const { data: users, isLoading: isUsersLoading } = useGetList('users', { filter: { role: 'user' } }, {});
    return (isLoading || isUsersLoading) ? <></> : <>
        <SelectInput
            optionText={
                <OrganisationItem />
            }
            choices={
                orgas?.map((orga: any) => {
                    return { id: orga.id, name: orga.name, url: orga.imageUrl }
                })}
            name="organisationId"
            source="organisationId"
            alwaysOn
            className='rounded-md'
        />
        <SelectInput
            choices={
                users?.map((user: any) => {
                    return { id: user.id, name: `${user.firstName} ${user.lastName}` }
                })}
            name="userIds"
            source="userIds"
            alwaysOn
            className='rounded-md'
        />
    </>
}

const CreateProspectButton = (props: { type: "new" | "current" }) => {
    const t = useTranslate();
    const { selectedIds } = useListContext();

    if (selectedIds?.length > 0 || props.type === "new") {
        return <></>;
    }

    // @ts-ignore
    return <CreateButton label={t(`prospect.actions.create`)} alwaysOn resource="prospects" />
}

export const ProspectsList = (props: { type: "new" | "current", filter: any, containerClass?: string }) => {
    const t = useTranslate();
    const { permissions } = usePermissions();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [displayType, setDisplayType] = useStore(`displayTypeProspects${props.type}`, isDesktop ? "list" : "splitted");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [previewId, setPreviewId] = useState<string | undefined>(undefined);
    const redirect = useRedirect();
    const user = useGetIdentity();

    const pipeStatuses = isSuperAdmin(permissions) ? allPipeStatuses : defaultPipeStatuses;
    const defaultPipeStatusesFilter = user?.data?.pipeStatuses?.length > 0 ?
        user?.data?.pipeStatuses?.filter((i: { name: string, value: string }) => !["toTransfert", "transferred", "closed", "wrongContact", "notSelling", "notInterested"].includes(i.value)).map((i: { name: string, value: string }) => i.value) :
        ["toContact", "qualified", "contacted", "visited", "offerSubmitted"];

    const prospectsFilters = [
        <TextInput resettable={true} sx={{ width: "226px", "& legend": { width: "0px" } }} label={false} source="textSearch" alwaysOn className='rounded-md'
            InputProps={{ startAdornment: <InputAdornment position="start"><SearchIcon stroke={theme.palette.grey[400]} /></InputAdornment>, placeholder: t('buttons.search') }}
        />,
        //@ts-ignore
        <CreateProspectButton alwaysOn type={props.type} />,
        <CustomSelectArray
            choices={user?.data?.pipeStatuses?.length > 0 ?
                user?.data?.pipeStatuses?.map((status: { name: string, value: string, color: string }) => {
                    return { id: status.value, name: status.name, color: status.color }
                }) :
                pipeStatuses.map((status: string) => {
                    return { id: status, name: t(`prospect.statuses.${status}`) }
                })}
            label={t('prospect.property.status')}
            name="pipeStatus"
            source="pipeStatus"
            alwaysOn
            className='rounded-md'
        />,
    ];

    //Put orga filter at first position if user is superAdmin
    if (permissions?.includes('superAdmin') === true) {
        prospectsFilters.unshift(<OrganisationUsersFilter alwaysOn />);
    }

    //handle display type change
    const handleDisplayTypeChange = (event: any, newDisplayType: any) => {
        if (newDisplayType !== null) {
            setDisplayType(newDisplayType);
            window.analytics?.track('Changed Prospects Display Type', { type: newDisplayType });
        }
    }

    //Close preview drawer
    const toggleDrawer =
        (open: boolean, id?: string) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (open) window.analytics?.track('Opened Prospect Preview');
                setIsDrawerOpen(open);
                setPreviewId(id);
            };

    //Open preview drawer
    const OpenDrawer = (id: string) => {
        setIsDrawerOpen(true);
        setPreviewId(id);
    }

    return isDesktop ?
        <>
            {displayType === "list" && (
                <Box>
                    <Box className="h-full w-full">
                        <StyledList
                            title={t(`menu.prospects.${props.type}`)}
                            sx={ListStyle}
                            pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
                            perPage={25}
                            empty={
                                <div className="w-full text-center mt-8">
                                    <Typography variant="h6">{t(`prospect.empty.${props.type}`)}</Typography>
                                    {props.type === "new" && (
                                        <Button variant="contained" label={t(`menu.prospects.current`)} onClick={() => redirect('/prospects')} />
                                    )}
                                    {props.type === "current" && (
                                        <Button variant="contained" label={t(`menu.prospects.new`)} onClick={() => redirect('/newprospects')} />
                                    )}
                                </div>
                            }
                            actions={<Box className="w-full">
                                <ProspectToolBar handleDisplayTypeChange={handleDisplayTypeChange} displayType={displayType} type={props.type} />
                                {props.type === "current" && <OpenFiltersButton type={props.type} label="Filtres" />}
                            </Box>
                            }
                            sort={{ field: "createdAt", order: "DESC" }}
                            filter={props.filter}
                            filters={props.type === "new" ? prospectsFilters.slice(0, -1) : prospectsFilters}
                            filterDefaultValues={
                                {
                                    pipeStatus: defaultPipeStatusesFilter
                                }}
                        >
                            <CustomCard sx={{ overflow: "visible" }} noShadow={true} className={'px-6 py-2'} containerClass={props.containerClass}>
                                {props.type === "current" &&
                                    <SingleSplitTreatment
                                        name="agregated-values"
                                        render={<ProspectAggregatedValues />}
                                    />
                                }
                                <ProspectsDataTable
                                    type={props.type}
                                    permissions={permissions}
                                    filter={props.filter}
                                    onOpenPreview={(id: string) => OpenDrawer(id)}
                                    displayCheckList={user?.data?.checkList?.length > 0}
                                />
                            </CustomCard>
                        </StyledList>
                    </Box>
                </Box>
            )}
            {displayType === "kanban" && (
                <SplittedList
                    title={t(`menu.prospects.${props.type}`)}
                    sx={ListStyle}
                    pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
                    perPage={25}
                    className="h-full mb-2"
                    sort={{ field: "createdAt", order: "DESC" }}
                    filter={props.filter}
                    filters={prospectsFilters}
                    filterDefaultValues={{
                        pipeStatus: defaultPipeStatusesFilter
                    }}
                    actions={<Box className="w-full">
                        <ProspectToolBar handleDisplayTypeChange={handleDisplayTypeChange} displayType={displayType} type={props.type} />
                        {props.type === "current" && <OpenFiltersButton type={props.type} label="Filtres" />}
                    </Box>
                    }
                >
                    <div className="flex w-full h-full">
                        <div className="w-full h-full">
                            <CustomCard noShadow={true} className={'px-8 py-4'} containerClass='h-full mb-8' cardClass='h-full'>
                                <KanbanView
                                    statuses={pipeStatuses.filter((i: ProspectPipeStatusesType) => !["toTransfert", "transferred", "closed", "wrongContact", "notSelling", "notInterested"].includes(i))}
                                    onOpenPreview={(prospect: IProspect) => {
                                        OpenDrawer(prospect.id.toString());
                                    }
                                    }
                                />
                            </CustomCard>
                        </div>
                    </div>
                </SplittedList >
            )}
            {
                displayType === "splitted" && (
                    <SplittedList
                        title={t(`menu.prospects.${props.type}`)}
                        sx={ListStyle}
                        pagination={<Pagination rowsPerPageOptions={[50, 100, 200]} />}
                        perPage={100}
                        className="h-full mb-2"
                        sort={{ field: "createdAt", order: "DESC" }}
                        filter={props.filter}
                        filters={prospectsFilters}
                        filterDefaultValues={{
                            pipeStatus: defaultPipeStatusesFilter
                        }}
                        actions={<Box className="w-full">
                            <ProspectToolBar handleDisplayTypeChange={handleDisplayTypeChange} displayType={displayType} type={props.type} />
                            {props.type === "current" && <OpenFiltersButton type={props.type} label="Filtres" />}
                        </Box>
                        }
                    >
                        <div className="flex w-full h-full bg-white rounded-md">
                            <div className="w-full h-full overflow-hidden pt-2" >
                                <Suspense fallback={<></>}>
                                    <ProspectsMap type={props.type} />
                                </Suspense>
                            </div>
                        </div>
                    </SplittedList >
                )
            }
            <Drawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                {previewId && (
                    <ProspectPreview id={previewId} onClose={toggleDrawer(false)} />
                )}
            </Drawer>
        </> :
        <>
            <Fab onClick={() => redirect('/prospects/create')} color="primary" aria-label="create" sx={{ position: "fixed", bottom: "6em", right: "2em" }}>
                <AddIcon />
            </Fab>
            <StyledToggleButtonGroup
                color="primary"
                value={displayType}
                exclusive
                onChange={(e, val) => setDisplayType(val)}
                aria-label="Platform"
            >
                <ToggleButton value="splitted">
                    Carte
                </ToggleButton>
                <ToggleButton value="list">
                    Liste
                </ToggleButton>
            </StyledToggleButtonGroup>
            {displayType === "list" && (
                <MobileList type={props.type} filter={props.filter} prospectsFilters={[prospectsFilters[0]]} />
            )}
            {displayType === "splitted" && (
                <List
                    actions={false}
                    pagination={<Pagination rowsPerPageOptions={[100]} />}
                    perPage={100}
                    sort={{ field: "createdAt", order: "DESC" }}
                    filter={props.filter}
                    filters={[prospectsFilters[0]]}
                    sx={{
                        height: "100%",
                        "& .RaList-content": {
                            height: "calc(100%)"
                        }
                    }}>
                    <SavedViewsList />
                    <Suspense fallback={<></>}>
                        <div className='h-full w-full overflow-hidden '>
                            <ProspectsMap type={props.type} />
                        </div>
                    </Suspense>
                </List>
            )}
        </>
};