import {
    useTranslate,
    useGetOne,
    RecordContextProvider,
    ShowContextProvider,
    ShowBase,
} from 'react-admin';

// Mui
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

//Components
import theme from '../../theme';
import { InfoChip } from '../../components/Chips/InfoChip';
import { ContactPreferenceIcon } from '../../components/Icons/ContactPreferenceIcon';
import { DisponibilityField } from '../../components/Fields/DisponibilityField';
import { PhoneField } from '../../components/Fields/PhoneField';
import { CustomEmailField } from '../../components/Fields/CustomEmailField';
import { DropVoiceMailButton } from '../Buttons/DropVoiceMailButton';
import { CRMField } from '../Fields/ProspectCRMField';
import { TagManager } from './Tags/TagManager';
import dayjs from 'dayjs';

export const ProspectPreview = (props: { id: string, onClose: (event: React.KeyboardEvent | React.MouseEvent) => void }) => {
    const { data: prospect, isLoading: isLoading } = useGetOne('prospects', { id: props.id }, {});
    const t = useTranslate();

    return isLoading ? <></> :
        <RecordContextProvider value={prospect}>
            <ShowBase id={props.id} resource="prospects" >
                <div className="flex flex-col h-full max-w-[436px] md:min-w-[436px]" >
                    <Box className="text-white p-4 pt-2 bg-success">
                        <Box className="flex justify-between">
                            <Typography variant="h3" color="white">{prospect?.name}</Typography>
                            <IconButton onClick={props.onClose}>
                                <CloseIcon htmlColor='white' />
                            </IconButton>
                        </Box>
                        <Box className="text-sm">
                            {`${t("prospect.property.createdAt")} ${dayjs(prospect?.createdAt)?.format("DD/MM/YYYY")}`}
                        </Box>
                    </Box>
                    <div className="flex flex-col gap-4 p-4 overflow-auto">
                        <TagManager maxItems={5} />
                        <Box className='flex justify-between'>
                            <CustomEmailField source='email' />
                            <PhoneField />
                        </Box>
                        <DropVoiceMailButton />
                        <Box>
                            <DisponibilityField />
                            {prospect?.formResultId?.contactPreference &&
                                <div className="flex items-center justify-between">
                                    <InfoChip label={t(`prospect.contactPreferences.${prospect?.formResultId?.contactPreference}`)} icon={<ContactPreferenceIcon color="primary" type={prospect.formResultId?.contactPreference} />} />
                                </div>
                            }
                        </Box>
                        <Box>
                            <CRMField
                                variant={"compact"}
                                displayCreateButtons={true}
                                crmType="events"
                                displayButtons={true}
                                dateProperty="eventDate"
                                userProperty="authorName"
                            />
                            <CRMField
                                variant={"compact"}
                                displayCreateButtons={true}
                                crmType="notes"
                                displayButtons={true}
                                dateProperty="createdAt"
                                userProperty="authorName"
                            />
                            <CRMField
                                variant={"compact"}
                                displayCreateButtons={true}
                                crmType="tasks"
                                displayButtons={true}
                                dateProperty="dueDate"
                                userProperty="assigneeName"
                            />
                        </Box>
                    </div>
                </div>
            </ShowBase>
        </RecordContextProvider >
}
