import { useState } from 'react';
import { useDataProvider, useRecordContext, useTranslate, useRefresh, Confirm, useUpdate } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { RejectForm } from '../Inputs/RejectForm';


const RejectButton = (props: any) => {
    const { source } = props;
    const record = useRecordContext(props);
    const t = useTranslate();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const [updateOne] = useUpdate();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = (reason: string) => {
        updateOne(
            'prospects',
            { id: props.id ? props.id : record?.id, data: { status: "rejected", rejectionReason: reason }, previousData: {} },
            {
                onSuccess: (data: any) => {
                    refresh();
                    props.onReject();
                }
            }
        );
        setOpen(false);
    };

    return (
        <>
            <Button size={props.size} variant="outlined" color="primary" onClick={(e) => { e.stopPropagation(); handleClick(); }}  >
                {t("buttons.no")}
            </Button>
            <RejectForm
                isOpen={open}
                onClose={handleDialogClose}
                onConfirm={(reason: string) => handleConfirm(reason)}
            />
        </>
    )
}


export default RejectButton;