import { SelectArrayInput, useTranslate, useUpdateMany } from 'react-admin';
import { useFormContext } from 'react-hook-form';
//Mui
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
//Types
import { IProspect } from '../../types/Prospect';

export const SelectAnOwner = (props: { onBack: () => void, setAddNewOwner: (value: boolean) => void; prospect: IProspect }) => {
    const t = useTranslate();
    const [update] = useUpdateMany();
    const { setAddNewOwner, prospect, onBack } = props;


    const { getValues } = useFormContext();
    const handleClick = () => {


        const selectedParcels = getValues('selectedParcels');
        const selectedOwners = getValues('selectedOwners');

        const updatedParcels = prospect.parcels?.map(parcel => {
            if (selectedParcels.includes(parcel.id)) {
                return {
                    ...parcel,
                    owners: [...parcel.owners, ...selectedOwners.filter((ownerId: string) => !parcel.owners?.some(owner => owner.id === ownerId)).map((ownerId: string) => ({ id: ownerId, _id: ownerId }))]
                };
            }
            return parcel;
        });
        // Update prospects with new parcels data
        update(
            "prospects",
            { ids: [prospect.id], data: { parcels: updatedParcels } },
            {
                onSuccess: () => {
                    console.log("Update successful");
                    onBack()
                },
                onError: () => {
                    console.log("Update failed");
                }
            }
        );

    };

    return (
        <>

            <Typography variant="h6">{t('prospect.owners.selectAnOwner')}*</Typography>
            <SelectArrayInput
                sx={{
                    width: '100%',
                    "& .MuiInputBase-root": { height: "100%" },
                    "& .MuiSelect-select": { height: "100%" },
                    "& .MuiInputBase-input": { height: "100%" }
                }}
                label={false}
                source="selectedOwners"
                choices={prospect.owners}
                optionText={(owner) => {
                    if (!owner.lastName && !owner.firstName) return owner.fullName ?? "";
                    return `${owner.gender !== undefined ? t(`prospect.owners.gendersShort.${owner.gender}`) : ""} ${owner.lastName ?? ""} ${owner.firstName ?? ""}`;
                }
                }
                optionValue={"id"}
            />

            <Box className="flex justify-end w-full">
                <Button onClick={() => setAddNewOwner(true)} sx={{ margin: "10px" }}>+ {t('prospect.owners.addOwner')}</Button>
                <Button onClick={handleClick} sx={{ margin: "10px" }} variant='contained'>Ajouter</Button>
            </Box>
        </>
    );
};
