import { lazy, Suspense, useState } from 'react';
import {
    useGetList,
    TextField,
    List,
    useTranslate,
    FunctionField,
    SimpleList,
    usePermissions,
    ReferenceField,
    Pagination,
    TopToolbar,
    CreateButton,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import { PageTitle } from '../../components/layout/PageTitle';
import { SplittedList } from '../../components/layout/SplittedList';
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import { CustomCard } from '../../components/layout/CustomCard';
import { LightTooltip } from '../../components/layout/LightTooltip';

// Lazy loading
const TargetingsMap = lazy(() => import('./TargetingsMap'));

const TargetingList = () => {
    const t = useTranslate();
    const { permissions } = usePermissions();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [filter] = useState('');
    const [page] = useState(1);
    const perPage = 100;
    const { isLoading } = useGetList('targetings', {
        filter: { q: filter },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'ASC' }
    });
    if (isLoading) {
        <PageTitle title={t('menu.targetings')} />
    }

    const getTypeChips = (types: string[]) => {
        return <>
            {types.slice(0, 1).map((type: string) => {
                return <Chip label={t(`prospect.type.${type}`)} />
            })}
            {types?.length > 1 &&
                <LightTooltip
                    title={
                        <>
                            {types.slice(1).map((type: string) => {
                                return <Chip label={t(`prospect.type.${type}`)} />
                            })}
                        </>
                    }
                >
                    <Chip label={`+${types.length - 1}`} />
                </LightTooltip>
            }
        </>
    }

    const TargetingActions = () => (
        <TopToolbar>
            {Array.isArray(permissions) && permissions?.includes("agency") === false &&
                <CreateButton size='medium' variant="contained" />
            }
        </TopToolbar>
    );

    const targetingFilters =
        [<ReferenceInput source="organisationId" reference="organisations" alwaysOn sort={{ field: 'isActive', order: 'DESC' }}>
            <SelectInput variant="standard" optionText="name" />
        </ReferenceInput>
        ]


    return (isDesktop ?
        <CustomCard noShadow={true} className={'px-8 py-4'} containerClass='h-full mb-8' cardClass='h-full'>
            <SplittedList
                actions={<TargetingActions />}
                pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
                className="h-full"
                title={t('menu.targetings')}
                filters={permissions?.includes('superAdmin') === true ? targetingFilters : []}
                queryOptions={{ refetchOnWindowFocus: false, refetchOnReconnect: false }}
            >
                <div className="flex w-full h-full">
                    <div className="w-1/2">
                        <StyledDataGrid rowClick={"show"}>
                            <TextField source="name" label={t("prospect.property.name")} />
                            <FunctionField source="type" label={t("prospect.property.type")}
                                render={(record: any) => { return getTypeChips(record.propertyType) }}
                            />
                            {permissions?.includes('superAdmin') === true && (
                                <ReferenceField source="organisationId" reference="organisations" >
                                    <TextField source="name" />
                                </ReferenceField>
                            )}
                            {permissions?.includes('superAdmin') !== true && (
                                <FunctionField source="fieldSurface" label={t('prospect.property.fieldSurface')}
                                    render={(record: any) => { return `De ${record.fieldSurface[0]}m² à ${record.fieldSurface[1]}m²` }}
                                />
                            )}
                        </StyledDataGrid>
                    </div>
                    <div className="w-1/2 h-full rounded-r-lg overflow-hidden" >
                        <Suspense fallback={<></>}>
                            <TargetingsMap />
                        </Suspense>
                    </div>
                </div>
            </SplittedList >
        </CustomCard>
        :
        <>
            <PageTitle title={t(`menu.targetings`)} className={'pb-12 pt-2'} />
            <List actions={false}>
                <SimpleList
                    title={t('menu.targetings')}
                    linkType="show"
                    primaryText={record => { return record.name }}
                    secondaryText={record => record.type === "zipCode" ? record.zipCode : ""}
                />
            </List>
        </>
    );
};

export default TargetingList;