import { useState } from 'react';
import { useTranslate } from 'react-admin';

// Components
import { NoBorderToggleButton, StyledToggleButtonGroup } from '../Buttons/StyledToggleButtonGroup';

// Mui
import StreetviewIcon from '@mui/icons-material/Streetview';
import SatelliteIcon from '@mui/icons-material/Satellite';

// Icons
import MapIcon from '@mui/icons-material/Map';

// Helpers
import { mapboxDefaultStyle, mapboxSatelliteStyle, mapboxStreetStyle } from '../../helpers/constants';


export const MapStyleButtons = (props: {
    map: boolean,
    street: boolean,
    threeD: boolean,
    satellite: boolean,
    streetview: boolean,
    onChange: (val: string) => void
}) => {
    const t = useTranslate();
    const [style, setStyle] = useState(mapboxStreetStyle);
    const handleStyleChange = (
        event: React.MouseEvent<HTMLElement>,
        style: string | null,
    ) => {
        event.stopPropagation();
        if (style !== null) {
            setStyle(style);
            props.onChange(style);
            window.analytics?.track('Changed Prospect Map Style', { style });
        }
    };

    return <StyledToggleButtonGroup
        value={style}
        exclusive
        onChange={handleStyleChange}
        aria-label="text alignment"
    >
        {props.street && (
            <NoBorderToggleButton sx={{ padding: 0 }} value={mapboxStreetStyle} aria-label="street">
                <MapIcon sx={{ fontSize: "16px" }} />
                {t('prospect.map.street')}
            </NoBorderToggleButton>
        )}
        {props.map && (
            <NoBorderToggleButton sx={{ padding: 0 }} value={mapboxDefaultStyle} aria-label="map">
                <MapIcon sx={{ fontSize: "16px" }} />
                {t('prospect.map.monochrome')}
            </NoBorderToggleButton>
        )}
        {props.satellite && (
            <NoBorderToggleButton sx={{ padding: 0 }} value={mapboxSatelliteStyle} aria-label="satellite">
                <SatelliteIcon sx={{ fontSize: "16px" }} />
                {t('prospect.map.satellite')}
            </NoBorderToggleButton>
        )}
        {props.streetview && (
            <NoBorderToggleButton sx={{ padding: 0 }} value="streetview" aria-label="streetview">
                <StreetviewIcon sx={{ fontSize: "16px" }} />
                {t('prospect.map.streetview')}
            </NoBorderToggleButton>
        )}
    </StyledToggleButtonGroup>
}


MapStyleButtons.defaultProps = {
    map: true,
    satellite: true,
    street: true,
    threeD: true,
    streetview: true,
}