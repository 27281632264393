import { FunctionField, Datagrid, Show, SimpleShowLayout, TextField, useGetList, useRecordContext, useTranslate } from 'react-admin';
import TableHead from '@mui/material/TableHead';

import { RoleChip } from '../../components/Chips/RoleChip';

const TeamUsersField = () => {
    const record = useRecordContext();
    const { data: users } = useGetList('users', { filter: { teamIds: [record?.id] } });
    const t = useTranslate();

    return <Datagrid data={users} header={<TableHead sx={{ display: "none" }} />} bulkActionButtons={false}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <FunctionField label={t('user.property.role')} source="role" render={(record: any) => <RoleChip role={record.role} />} />
    </Datagrid>
}

const TeamShow = () => {
    const t = useTranslate();

    return (<Show title={t('menu.teams')}>
        <SimpleShowLayout>
            <TextField label={t("team.property.name")} source="name" />
            <TeamUsersField />
        </SimpleShowLayout>
    </Show>
    )
}

export default TeamShow;