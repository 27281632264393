import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
  return `linear-gradient(90deg, ${hexToRgba(color1, 1)} 0%, ${hexToRgba(color2, 1)} 100%)`;
}

function hexToRgba(hex: any, alpha: any) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const LIGHTWHITE = {
  lighter: '#FFFFFF',
  light: '#F0F0F0',
  main: '#E0E0E0',
  dark: '#C0C0C0',
  darker: '#A0A0A0',
  contrastText: '#000000',
};

// SETUP COLORS
const BLUE_GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GREY = {
  0: '#FFFFFF',
  100: '#F3F3F3',
  200: '#EFEFEF',
  300: '#E6E6E6',
  400: '#CFCFCF',
  500: '#BDBDBD',
  600: '#838383',
  700: '#656565',
  800: '#1E1E1E',
  900: '#000000',
  500_8: alpha('#BDBDBD', 0.08),
  500_12: alpha('#BDBDBD', 0.12),
  500_16: alpha('#BDBDBD', 0.16),
  500_24: alpha('#BDBDBD', 0.24),
  500_32: alpha('#BDBDBD', 0.32),
  500_48: alpha('#BDBDBD', 0.48),
  500_56: alpha('#BDBDBD', 0.56),
  500_80: alpha('#BDBDBD', 0.8),
};
// 3B54D6
const PRIMARY = {
  lighter: '#EFF6FF',
  light: '#A3AED0',
  main: '#1E40AF',
  dark: '#1B2559',
  darker: '#161434',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#ABFFCA',
  light: '#4ADE80',
  main: '#46A097',
  dark: '#15803D',
  darker: '#14532D',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFE096',
  light: '#FCCC5B',
  main: '#FCC43E',
  dark: '#D9A730',
  darker: '#14532D',
  contrastText: "#fff",
};

const ERROR = {
  lighter: '#FFDDD3',
  light: '#FFA78F',
  main: '#FC814F',
  dark: '#FD3A04',
  darker: '#C95C3F',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.main, PRIMARY.lighter),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.main, SUCCESS.light),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#6E87FF', '#556EF0', '#1E40AF', '#0821A3'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  full: ["#1E40AF", "#FB7D5B", "#FCC43E", "#61C5B3", "#1E40AF", "#1B2559", "#C95C3F", "#15803D", "#14532D", "#4ADE80", "#D9A730", "#FFA78F", "#DBEAFE", "#FFE096"]
};

const ADDITIONAL_COLORS = {
  magenta: {
    lighter: '#C86A85',
    light: '#BD4C6C',
    main: '#B34262',
    dark: '#A43D5A',
    darker: '#86324A',
    contrastText: '#fff',
  },
  paleblue: {
    lighter: '#C86A85',
    light: '#BD4C6C',
    main: '#A3C3D9',
    dark: '#A43D5A',
    darker: '#86324A',
    contrastText: '#000',
  },
  pink: {
    lighter: '#FFEBF8',
    light: '#FFD6F1',
    main: "#FFC2EB",
    dark: "#FFADE2",
    darker: "#FF99DB",
    contrastText: '#000',
  },
  darkgreen: (
    {
      lighter: '#005252',
      light: '#003D3D',
      main: '#002626',
      dark: '#001414',
      darker: '#001414',
      contrastText: '#fff',
    }
  )
}

interface ColorPalette {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
  contrastText: string;
}

interface GradientPalette {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartColors {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
  full: string[];
}

interface AdditionalColors {
  magenta: ColorPalette;
  paleblue: ColorPalette;
  pink: ColorPalette;
  darkgreen: ColorPalette;
}

export interface Palette {
  common: {
    black: string;
    white: string;
  };
  primary: ColorPalette;
  secondary: ColorPalette;
  info: ColorPalette;
  success: ColorPalette;
  warning: ColorPalette;
  error: ColorPalette;
  grey: any;
  bluegrey: any;
  gradients: GradientPalette;
  chart: ChartColors;
  additionalcolors: AdditionalColors;
  magenta: ColorPalette;
  paleblue: ColorPalette;
  pink: ColorPalette;
  darkgreen: ColorPalette;
  lightwhite: ColorPalette; // Add the new color here
  divider: string;
  text: {
    primary: string;
    secondary: string;
    disabled: string;
  };
  background: {
    paper: string;
    default: string;
    neutral: string;
  };
  action: {
    active: string;
    hover: string;
    selected: string;
    disabled: string;
    disabledBackground: string;
    focus: string;
    hoverOpacity: number;
    disabledOpacity: number;
  };
}

const palette: Palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  bluegrey: BLUE_GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  additionalcolors: ADDITIONAL_COLORS,
  magenta: { ...ADDITIONAL_COLORS?.magenta },
  paleblue: { ...ADDITIONAL_COLORS?.paleblue },
  pink: { ...ADDITIONAL_COLORS?.pink },
  darkgreen: { ...ADDITIONAL_COLORS?.darkgreen },
  lightwhite: { ...LIGHTWHITE }, // Add the new color here
  divider: GREY[300],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#F8F9FB', neutral: BLUE_GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
