import { CreateButton, useGetList, useGetOne, useTranslate } from 'react-admin';
import { IParcel } from '../../types/Geography';
import dayjs from 'dayjs';

// Mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Icons 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IDevelopmentTaxe } from '../../types/Taxes';
import { useState } from 'react';
import { developmentTaxBase, developmentTaxBaseIDF, toEuros } from '../../helpers/constants';

const TaxesCalculator = (props:
    {
        taxRates:
        {
            region: number,
            departement: number,
            commune: number
        },
        taxDeductionRates: {
            region: number
            departement: number,
            commune: number
        }
    }
) => {
    const { taxRates, taxDeductionRates } = props;
    const t = useTranslate();
    const [surface, setSurface] = useState<number>(0);
    const [taxDeduction, setTaxDeduction] = useState<boolean>(false);
    const [taxAllowance, setTaxAllowance] = useState<boolean>(false);

    const taxBase = taxRates.region !== 0 ? developmentTaxBaseIDF : developmentTaxBase;
    const taxDeducedBase = taxDeduction ? taxBase / 2 : taxBase;
    const taxValues = {
        commune: (surface * taxRates.commune * taxDeducedBase / 100),
        departement: (surface * taxRates.departement * taxDeducedBase / 100),
        region: (surface * taxRates.region * taxDeducedBase / 100)
    }

    const taxDeductionValues = {
        commune: (surface * taxRates.commune * taxDeducedBase / 100) * (taxDeductionRates.commune) / 100,
        departement: (surface * taxRates.departement * taxDeducedBase / 100) * (taxDeductionRates.departement) / 100,
        region: (surface * taxRates.region * taxDeducedBase / 100) * (taxDeductionRates.region) / 100
    }

    const taxDeducedValues = {
        commune: taxValues.commune - taxDeductionValues.commune,
        departement: taxValues.departement - taxDeductionValues.departement,
        region: taxValues.region - taxDeductionValues.region
    }

    const total = (taxValues.commune + taxValues.departement + taxValues.region) - (taxAllowance ? (taxDeductionValues.commune + taxDeductionValues.departement + taxDeductionValues.region) : 0);

    return <div>
        <Box>
            <Typography variant="h4">Calcul des taxes</Typography>
            <Box>
                <Typography variant="h6">Surface taxable: </Typography>
                <TextField type="number" onChange={(e) => setSurface(parseInt(e.target.value))} />
            </Box>
            <Box>
                <Typography variant="h6">Valeur forfaitaire: {toEuros(taxBase)}</Typography>
                <FormControlLabel control={<Checkbox value={taxDeduction} onChange={() => setTaxDeduction(!taxDeduction)} />} label="Abattement de 50%" />
                <FormControlLabel control={<Checkbox value={taxDeduction} onChange={() => setTaxAllowance(!taxAllowance)} />} label="Exonération pour locaux d'habitation" />
            </Box>
            <Box>
                {["region", "departement", "commune",].map((key) => {
                    const typedKey = key as "commune" | "departement" | "region";
                    const translatedKey = t(`taxe.applicationZones.${typedKey}`);
                    return <Box key={typedKey} className="mb-4">
                        <Box>{translatedKey}: {toEuros(taxValues[typedKey])}</Box>
                        {taxAllowance && (
                            <>
                                <Box>Déduction {translatedKey}: {toEuros(taxDeductionValues[typedKey])}</Box>
                                <Box>Total {translatedKey}: {toEuros(taxDeducedValues[typedKey])}</Box>
                            </>
                        )}
                    </Box>
                })}
            </Box>
            <Box>
                <Typography variant="h4">Total: {toEuros(total)}</Typography>
            </Box>
        </Box >
    </div >
}

export const ParcelTaxes = (props: { selectedParcel: IParcel }) => {
    const { selectedParcel } = props;
    const t = useTranslate();
    const { data: taxes } = useGetList('taxes',
        { filter: { code_dep: selectedParcel.properties?.code_dep, code_com: selectedParcel.properties?.code_com, section: selectedParcel.properties?.section, numero: selectedParcel.properties?.numero } }
    );

    let filteredTaxes: IDevelopmentTaxe[] = [];

    //If several taxes with same zone_application, we only keep the one with the most recent date_effet. It is already sorted on back end
    let taxesMap = new Map();
    if (taxes) {
        taxes.forEach((tax: any) => {
            if (!taxesMap.has(tax.zone_application) || taxesMap.get(tax.zone_application).date_effet < tax.date_effet) {
                taxesMap.set(tax.zone_application, tax);
            }
        });
        filteredTaxes = Array.from(taxesMap.values());
    }

    return <div>
        <Accordion>
            <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant="h4">{t('parcel.property.developmentTaxes')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className='flex flex-col gap-4 mb-4'>
                    {filteredTaxes?.map((tax: IDevelopmentTaxe) => {
                        const otherKeys = ['locaux_habitation', 'locaux_pret_sans_interet', 'locaux_artisanat', 'commerce_detail', 'immeuble_classe', 'maisons_sante', 'terrains_rehabilités'];
                        return <div key={tax.id}>
                            <p>{t('taxe.property.zone_application')} : {tax.zone_application}</p>
                            <p>{t('taxe.property.date_effet')} : {dayjs(tax.date_effet).format("YYYY")}</p>
                            <p>{t('taxe.property.taux')} : {tax.taux} %</p>
                            <p>Exonérations :</p>
                            {Object.keys(tax).map((key) => {
                                if (otherKeys.includes(key)) {
                                    const value = tax[key as keyof IDevelopmentTaxe];
                                    return <p key={key}>{t(`taxe.property.${key}`)} : {(value instanceof Date || key === 'date_effet') ? dayjs(value).format("YYYY") : `${value} %`}</p>;
                                }
                            })}
                        </div>
                    })}
                </div>
                <TaxesCalculator
                    taxRates={{ region: taxesMap.get('Régionale')?.taux ?? 0, departement: taxesMap.get('Départementale')?.taux ?? 0, commune: taxesMap.get('Communale')?.taux ?? 0 }}
                    taxDeductionRates={{ region: taxesMap.get('Régionale')?.locaux_habitation ?? 0, departement: taxesMap.get('Départementale')?.locaux_habitation ?? 0, commune: taxesMap.get('Communale')?.locaux_habitation ?? 0 }}
                />
            </AccordionDetails>
        </Accordion>
    </div>
}