export const targetingMessages = {
    targeting: {
        property: {
            name: "Titre du ciblage",
            type: "Type de bien",
            radius: "Rayon",
            zipCode: "Code postal",
            geotype: "Choix de la zone",
            associatedUsers: "Développeurs associés",
        },
        create: {
            title: "Nouveau ciblage",
            areaType: {
                geo: "Zone géographique",
                zipCode: "Code postal",
                commune: "Nom de la commune",
            },
            radius: "Rayon (en m)",
            errors: {
                zipCodeInvalid: "Code postal invalide",
                propertyType: {
                    required: "Le type de bien est obligatoire"
                },
                type: {
                    noCoordinates: "Vous devez sélectionner une zone géographique",
                    noCode: "Aucune commune n'a été renseignée"
                },
                contours: {
                    tooBig: "La zone géographique est trop grande",
                    tooManyPolygons: "La zone géographique est trop complexe"
                }
            },
            noUser: "Aucun développeur",
            noCommune: "Aucune commune",
            searchCommune: "Rechercher une commune",
        },
        edit: {
            title: "Modifier le ciblage",
        },
    }
}