import axios from 'axios';
import { useEffect, useState } from 'react';

export const CorportationDetails = (props: { siren: string }) => {
    const { siren } = props;
    const [information, setInformation] = useState<any>();
    let denomination;

    const getInformation = async () => {
        try {
            const { data } = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siren/${siren}`,
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_INSEE_KEY}`
                    }
                });

            const siret = `${siren}${data?.uniteLegale?.periodesUniteLegale[0]?.nicSiegeUniteLegale}`;
            denomination = data?.uniteLegale?.periodesUniteLegale[0]?.denominationUniteLegale;
            const { data: corporation } = await axios.get(`https://api.insee.fr/entreprises/sirene/V3.11/siret/${siret}`,
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_INSEE_KEY}`
                    }
                });

            setInformation({ ...data, ...corporation });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (siren) {
            getInformation();
        }
    }, [])

    const getFullAddress = (information: any): string => {
        const { numeroVoieEtablissement, typeVoieEtablissement, libelleVoieEtablissement, codePostalEtablissement, libelleCommuneEtablissement } = information.etablissement.adresseEtablissement;
        return `${numeroVoieEtablissement} ${typeVoieEtablissement} ${libelleVoieEtablissement}, ${codePostalEtablissement} ${libelleCommuneEtablissement}`;
    }

    return <div>
        {(information && information?.etablissement?.adresseEtablissement) &&
            <div>
                <h1>{information.etablissement.siren}</h1>
                <p>
                    {getFullAddress(information)}
                </p>
                <a href={`https://www.pappers.fr/entreprise/${denomination}-${siren}`} target="_blank">Voir sur Pappers</a>
            </div>
        }
    </div>
};