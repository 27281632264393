import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Confirm, useCreate, useGetList, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { ArrowIcon } from '../Icons/ArrowIcon';
import theme from '../../theme';
import { dataProvider } from '../../dataProvider';
import { IProspect } from '../../types/Prospect';

const EmptyIcon = () => <Box></Box>;

const TransferProspectButton = (props: { prospectIds: string[], onTransferred: () => void, variant: "outlined" | "text", prospects?: IProspect[] }) => {
    const notify = useNotify();
    const t = useTranslate();
    const refresh = useRefresh();
    const [selectedOrganisation, setSelectedOrganisation] = useState<string | null>(null);
    const { data: organisations } = useGetList('organisations/partners', {},
        //3 hours cache
        { staleTime: 180 * 60 * 1000, gcTime: 240 * 60 * 1000 }
    );
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [noteText, setNoteText] = useState<string | null>(null);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOrganisation(event.target.value);
    };

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const updateStatus = (previousFailedIds: string[]) => {
        const failedIds: string[] = [];

        const promises = props.prospectIds.filter((id: string) => !previousFailedIds.includes(id)).map((id: string) => {
            return new Promise((resolve, reject) => {
                return dataProvider.update(`prospects`,
                    { id, data: { pipeStatus: 'transferred' }, previousData: null })
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(() => {
                        failedIds.push(id);
                        reject({ data: { id } });
                    })
            })
        });

        Promise.allSettled(promises)
            .then((promises) => {
                if (failedIds.length > 0) {
                    notify(`Erreur pour les prospects suivants: ${failedIds.map((id) => props.prospects?.find((item: any) => item.id === id)?.name).join(", ")}`, { type: "error" });
                    notify('BasicError', { type: 'error' });
                } else {
                    refresh();
                }
            })
    }

    const handleValidateTransfert = async () => {
        window.analytics?.track('Used Bulk Action', { action: "transferredProspects" });

        const failedIds: string[] = [];

        const promises = props.prospectIds.map((id: string) => {
            return new Promise((resolve, reject) => {
                return dataProvider.create(`prospects/${id}/transfer`,
                    {
                        data:
                        {
                            organisationId: selectedOrganisation,
                            note: (noteText && noteText !== "") ? { description: noteText } : undefined
                        }
                    })
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(() => {
                        failedIds.push(id);
                        reject({ data: { id } });
                    })
            })
        });

        Promise.allSettled(promises)
            .then((promises) => {
                if (failedIds.length > 0) {
                    notify(`Erreur pour les prospects suivants: ${failedIds.map((id) => props.prospects?.find((item: any) => item.id === id)?.name).join(", ")}`, { type: "error" });
                } else {
                    setSelectedOrganisation(null);
                    handleClose();
                    setOpenPopup(false);
                    setNoteText(null);
                    notify(
                        <Alert severity="success" className='flex items-center'>
                            <Box className="flex items-center gap-2">
                                {t('prospect.notifications.prospectTransferred')}
                                <Button onClick={() => updateStatus(failedIds)}>
                                    {t('prospect.actions.updateStatus')}
                                </Button>
                            </Box>
                        </Alert>,
                        { type: 'success' }
                    );
                    refresh();
                    props.onTransferred();
                }
            })
            .catch((error) => {
                notify(`BasicError`);
            })
    }

    return (
        <>
            <Button
                sx={props.variant === "outlined" ?
                    { borderColor: "#CFCFCF", borderRadius: "8px", color: theme.palette.grey[800] }
                    : { color: theme.palette.grey[800], border: "none", ":hover": { border: "none" } }
                }
                endIcon={<ArrowIcon sx={{ transform: "rotate(-90deg)" }} stroke='black' />} variant="outlined" onClick={handleOpen}>
                {t('prospect.actions.toTransfer')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left',
                    }
                }
            >
                <Box className="flex flex-col border-solid border-2 rounded-md">
                    <FormControl>
                        {organisations && organisations.map((orga: any) => {
                            return <Box className="flex items-center justify-between gap-2 p-2 border-b-2">
                                <Box className="flex items-center gap-2">
                                    <Avatar sx={{ width: 24, height: 24 }} src={orga.imageUrl} />
                                    <Typography>{orga.name}</Typography>
                                </Box>
                                <Checkbox sx={{ padding: "2px" }} onChange={handleCheckboxChange} value={orga.id} name={orga.name} checked={selectedOrganisation === orga.id} />
                            </Box>
                        })
                        }
                    </FormControl>
                    <Box className="p-2">
                        <Button fullWidth variant="contained" onClick={() => setOpenPopup(true)} disabled={selectedOrganisation === null}>
                            {t('prospect.actions.toTransfer')}
                        </Button>
                    </Box>
                </Box>
            </Popover>
            <Confirm
                sx={{
                    "& .MuiDialogContent-root": {
                        paddingTop: "6px !important"
                    },
                    "& .MuiButton-root svg": {
                        fontSize: "2.5em"
                    },
                    "& .MuiDialog-paper": {
                        minWidth: {
                            xs: "80%",
                            md: "400px"
                        }
                    }
                }}
                isOpen={openPopup}
                title={<Box>
                    <Typography variant="h6">
                        {t('prospect.actions.transferPopupTitle', { organisationName: organisations?.find((orga: any) => orga.id === selectedOrganisation)?.name })}
                    </Typography>
                    <Box className="text-sm">
                        {t('prospect.actions.transferPopupSubtitle')}
                    </Box>
                </Box>
                }
                content={<Box>
                    {props.prospects &&
                        <Box className='p-4 mb-5 bg-bg flex flex-col gap-4'>
                            {props.prospects && props.prospects.filter((prospect: IProspect) => props.prospectIds.includes(prospect.id)).map((prospect: IProspect) => {
                                return <Box className="flex items-start gap-5 justify-between shrink">
                                    <Typography>{prospect?.address}</Typography>
                                    <Box className="shrink-0 flex gap-5">
                                        <Typography>{t(`prospect.type.${prospect?.type}`)}</Typography>
                                        <Typography>{`${prospect?.houseSurface ?? 0} m2`}</Typography>
                                    </Box>
                                </Box>
                            }
                            )}
                        </Box>
                    }
                    <OutlinedInput
                        fullWidth
                        multiline
                        rows={8}
                        label={false}
                        placeholder={`Vous pouvez laisser un commentaire ici pour donner plus de contexte à l'organisation destinataire (facultatif)`}
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        sx={{
                            "& legend": {
                                display: "none"
                            }
                        }}
                    />
                </Box>
                }
                onConfirm={handleValidateTransfert}
                onClose={() => { setOpenPopup(false); setNoteText(null); setSelectedOrganisation(null) }}
                CancelIcon={EmptyIcon}
                ConfirmIcon={EmptyIcon}
            />
        </>
    );
};

TransferProspectButton.defaultProps = {
    variant: "outlined"
}

export default TransferProspectButton;