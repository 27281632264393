import { useTranslate } from 'react-admin';
import Drawer from '@mui/material/Drawer';
import { CorportationDetails } from '../Corporations/CorporationDetails';

const PermitDetails = (props: { selectedPC: any }) => {
    const t = useTranslate();

    return <div className="flex flex-col gap-4 p-2">
        <h1>{t(`permit.property.DATE_REELLE_AUTORISATION`)} : {props.selectedPC.properties.DATE_REELLE_AUTORISATION}</h1>
        <h1>{t(`permit.property.TYPE_DAU`)} : {props.selectedPC.properties.TYPE_DAU}</h1>
        <p>{t(`permit.property.DENOM_DEM`)} : {props.selectedPC.properties.DENOM_DEM}</p>
        <CorportationDetails siren={props.selectedPC.properties.SIREN_DEM} />
        <p>{t(`permit.property.NB_LGT_TOT_CREES`)} : {props.selectedPC.properties.NB_LGT_TOT_CREES}</p>
        <p>{t(`permit.property.TYPE_PRINCIP_LOGTS_CREES`)} : {t(`permit.TYPE_PRINCIP_LOGTS_CREES.${props.selectedPC.properties.TYPE_PRINCIP_LOGTS_CREES}`)}</p>
        <p>{t(`permit.property.CAT_DEM`)} : {t(`permit.CAT_DEM.${props.selectedPC.properties.CAT_DEM}`)}</p>
    </div>
}


export const PCDrawer = (props: { selectedPC: any, setSelectedPC: any }) => {
    const { selectedPC } = props;

    return <Drawer
        open={selectedPC !== null}
        onClose={() => props.setSelectedPC(null)}
    >
        <div className="w-96">
            {selectedPC &&
                <PermitDetails selectedPC={selectedPC} />
            }
        </div>
    </Drawer>
}