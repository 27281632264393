import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SaturnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M21.2493 6.99956C23.7617 6.58719 25.6415 6.91359 26.315 8.08013C27.6958 10.4716 23.4838 15.4883 16.9073 19.2853C10.3308 23.0822 3.88006 24.2216 2.49934 21.8302C1.71145 20.4655 2.74497 18.2459 4.99957 15.8859M15 25C9.47711 25 4.99995 20.5228 4.99995 15C4.99995 9.47715 9.47711 5 15 5C20.5228 5 25 9.47715 25 15C25 20.5228 20.5228 25 15 25Z" stroke="currentColor" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    )
}

