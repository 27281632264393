import { useRecordContext } from "react-admin";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

export const DVFLink = () => {
    const record = useRecordContext();

    if (!record) return null;

    return <div onClick={() => {
        window.analytics?.track('Opened DVF', { id: record._id });
    }}>
        <a className="underline text-primary flex gap-1" href={`https://explore.data.gouv.fr/fr/immobilier?onglet=carte&filtre=tous&lat=${record?.location.coordinates[1]}&lng=${record?.location.coordinates[0]}&zoom=17.97&level=section&code`} target="_blank" rel="noreferrer">
            <OpenInNewRoundedIcon fontSize="small" />
            Voir sur le DVF
        </a>
    </div >
}