import { useState } from "react";
import { useUpdate, useRecordContext, useRefresh, useTranslate, useGetList } from 'react-admin';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import theme from "../../theme";
import { LightTooltip } from "../layout/LightTooltip";

const StyledSelect = styled(Select)(({ theme }) => ({
    padding: 0,
    border: 0,
    '& .MuiSelect-select': {
        padding: "4px 8px",
        border: 0
    },
    '& fieldset': {
        border: 0
    }
}));


export const UserSelect = (props: any) => {
    const [updateOne] = useUpdate();
    const record = useRecordContext(props);
    const { data: users, isLoading } = useGetList('users', { filter: { organisationId: record?.organisationId } });
    const [value, setValue] = useState(record?.userIds);

    const handleTypeChange = (event: SelectChangeEvent) => {
        event.stopPropagation();
        setValue(event.target.value);
        props.onChange(event.target.value as string);
        updateOne(
            'prospects',
            { id: props.id ? props.id : record?.id, data: { userIds: (event.target.value as string) }, previousData: {} }
        );
    };

    if (isLoading) return <div></div>;

    return <div className={`bg-white rounded-md cursor-pointer min-w-[140px]`} style={{ border: `1px solid ${theme.palette.grey[200]}` }}>
        <FormControl fullWidth>
            <StyledSelect
                //@ts-ignore
                onChange={handleTypeChange}
                onClick={(e) => e.stopPropagation()}
                value={value}
                multiple
                disableUnderline
                renderValue={(selected: any) => {
                    let user = users?.find((user) => user?.id === selected[0]);
                    return <div className="flex flex-wrap">
                        <Chip label={`${user?.firstName} ${user?.lastName}`} size="small" sx={{ fontSize: "11px" }} />
                        {selected?.length && selected?.length > 1 &&
                            <LightTooltip
                                title={
                                    <>
                                        {selected?.slice(1).map((sel: any) => {
                                            let user = users?.find((user) => user?.id === sel);
                                            return <Chip label={`${user?.firstName} ${user?.lastName}`} size="small" sx={{ fontSize: "11px" }} />
                                        })}
                                    </>
                                }
                            >
                                <Chip label={`+${selected.length - 1}`} />
                            </LightTooltip>
                        }
                    </div>
                }}
            >
                {users?.map((user: any) => <MenuItem value={user.id}>
                    <div>
                        {user.firstName} {user.lastName}
                    </div>
                </MenuItem>)}
            </StyledSelect>
        </FormControl>
    </div >
}