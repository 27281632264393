import React from "react";
import Box from "@mui/material/Box";

const DPEColor = (props: { dpe: string }) => {
    switch (props.dpe) {
        case "A":
            return "rgb(26, 152, 80)";
        case "B":
            return "rgb(145, 207, 96)";
        case "C":
            return "rgb(217, 239, 139)";
        case "D":
            return "rgb(255, 255, 191)";
        case "E":
            return "rgb(254, 224, 139)";
        case "F":
            return "rgb(252, 141, 89)";
        case "G":
            return "rgb(215, 48, 39)";
        default:
            return "grey";
    }
}

const marker = (color: string) => {
    return <Box className="dpe-marker" sx={{ backgroundColor: color, width: "16px", height: "16px", borderRadius: "50%" }} />
};

export const DPEMarker = (props: { dpe: string }) => {
    return <Box>
        {marker(DPEColor({ dpe: props.dpe }))}
    </Box>
}
