export const parcelMessages = {
    parcel: {
        property: {
            totalSurface: "Surface totale",
            noOwner: "Aucun propriétaire",
            surface: "Contenance",
            parcelNumber: "Numéro de parcelle",
            nearbyPOIs: "Points d'intérêt à proximité",
            corporationOwners: "Propriétaires personnes morales",
            developmentTaxes: "Taxes d'aménagement",
        },
        actions: {
            create: "Ajouter",
            edit: "Modifier",
            delete: "Supprimer",
        },
        messages: {
            delete: {
                title: "Supprimer la parcelle %{parcelNumber}",
                message: "Êtes-vous sûr de vouloir supprimer cette parcelle ?",
            },
        },
    }
}