import './App.css';

// Providers
import { dataProvider } from './dataProvider';
import { authProvider } from './authProvider';
import { i18nProvider } from './i18nProvider';

// Theme
import theme from './theme';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from "react-router-dom";
import ResetPassword from './pages/resetPassword';
import { Login } from './pages/loginPage';
import RequestPasswordReset from './pages/requestResetPassword';
import { MyLayout } from './Layout';
import AppLoading from './components/layout/AppLoading';

// Prospect
import { ProspectsList } from './pages/Prospects/ProspectsList';
import { ProspectShow } from './pages/Prospects/ProspectShow';
import { ProspectCreate } from './pages/Prospects/ProspectCreate';

// User
import { UsersList } from './pages/Users/UsersList';
import CreateUser from './pages/Users/CreateUser';
import UserShow from './pages/Users/UserShow';
import UserEdit from './pages/Users/UserEdit';

// Targeting
import TargetCreate from './pages/Targetings/TargetingCreate';
import TargetingList from './pages/Targetings/TargetingList';
import TargetingShow from './pages/Targetings/TargetingShow';
import TargetingEdit from './pages/Targetings/TargetingEdit';

// Dashboard
import { Dashboard } from './pages/Dashboards/Dashboard';
import OrganisationShow from './pages/Organisations/OrganisationShow';
import OrganisationEdit from './pages/Organisations/OrganisationEdit';
import OrganisationCreate from './pages/Organisations/OrganisationCreate';
import { OrganisationList } from './pages/Organisations/OrganisationList';

// FormResult
import { FormResultsList } from './pages/FormResults/FormResultsList';
import FormResultShow from './pages/FormResults/FormResultShow';
import { isAdmin, isSuperAdmin } from './helpers/helpers';
import FormResultEdit from './pages/FormResults/FormResultEdit';

//Team
import { TeamCreate } from './pages/Teams/TeamCreate';
import { TeamEdit } from './pages/Teams/TeamEdit';
import TeamShow from './pages/Teams/TeamShow';

// Explore
import { ExploreList } from './pages/Explore/ExploreList';

const App = () => {
  return (
    <>
      <Admin
        dataProvider={dataProvider}
        //@ts-ignore
        theme={theme}
        dashboard={Dashboard}
        authProvider={authProvider}
        loginPage={<Login backgroundImage={`/static/cities/city${Math.floor(Math.random() * 6)}.webp`} />}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        loading={AppLoading}
      >
        {permissions => (
          <>
            <Resource name="newprospects" list={<ProspectsList type="new" filter={{ status: 'pending' }} />} show={<ProspectShow type="new" />} />
            <Resource name="prospects" list={<ProspectsList type="current" filter={{ status: 'accepted' }} />} show={<ProspectShow type="current" />} create={ProspectCreate} />
            <Resource name="recommendations" show={ProspectShow} />
            <Resource name="explore" list={ExploreList} />

            {/* Agencies can only read list and detail of Targetings */}
            {Array.isArray(permissions) && permissions?.includes("agency") === false ?
              <Resource name="targetings" list={TargetingList} edit={TargetingEdit} show={TargetingShow} create={TargetCreate} recordRepresentation="name" />
              : <Resource name="targetings" list={TargetingList} show={TargetingShow} recordRepresentation="name" />
            }

            {/* Agencies can NOT access user resource */}
            {Array.isArray(permissions) && permissions?.includes("agency") === false &&
              <>
                <Resource name="users" list={UsersList} edit={UserEdit} show={UserShow} create={CreateUser} recordRepresentation={(record) => `${record.firstName} ${record.lastName}`} />
                <Resource name="teams" show={TeamShow} create={TeamCreate} edit={TeamEdit} recordRepresentation="name" />
              </>
            }

            {/* Only admins can manage Contacts and Entities*/}
            {isAdmin(permissions) &&
              <>
                <Resource name="contacts" recordRepresentation="fullName" />
                <Resource name="entities" recordRepresentation="entityName" />
              </>
            }

            {/* Only SuperAdmin can access organisations and formresults resources */}
            {isSuperAdmin(permissions) &&
              <>
                <Resource name="organisations" list={OrganisationList} show={OrganisationShow} edit={OrganisationEdit} create={OrganisationCreate} />
                <Resource name="formresults" list={FormResultsList} show={FormResultShow} edit={FormResultEdit} />
              </>
            }
            <CustomRoutes noLayout>
              <Route path="/passwordReset/:id/:token" element={<ResetPassword />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
              <Route path="/request-password-reset" element={<RequestPasswordReset />} />
            </CustomRoutes>
          </>
        )}
      </Admin >
    </>
  );
}

export default App;
