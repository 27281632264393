import { Menu, useGetList, usePermissions, useTranslate } from 'react-admin';
import { motion } from "framer-motion";
import { lazy, Suspense } from 'react';

//Mui
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

//Components
import { ToggleSidebarButton } from '../Buttons/ToggleSidebarButton';
import { LogoFull } from './LogoFull';
import { isSuperAdmin } from '../../helpers/helpers';
import theme from '../../theme';

//Icons
import StoreIcon from '@mui/icons-material/Store';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PeopleIcon from '@mui/icons-material/People';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { SearchIcon } from '../Icons/SearchIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

//Lazy imports
const MenuCalendar = lazy(() => import('../Calendar/MenuCalendar'));

const CustomMenuItem = (props: any) => {
    return <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.2, delay: props.delay }}
        className='cursor-pointer'
    >
        <Menu.Item {...props} />
    </motion.div>
}

export const MyMenu = () => {
    const t = useTranslate();
    const { permissions } = usePermissions<string[]>();
    const { data } = useGetList('prospects', {
        filter: { status: "pending" }
    }, { gcTime: 15 * 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false });
    const { data: calendarData } = useGetList('prospects/allevents', {
    }, { gcTime: 15 * 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false });


    return <Menu>
        <Box className="flex flex-col justify-between grow">
            <Box>
                <div className="flex items-center m-2 gap-x-[25px]">
                    <ToggleSidebarButton />
                    <LogoFull className={'max-w-[137px]'} />
                </div>
                <CustomMenuItem delay={0.1} className="rounded-md" to="/" primaryText={t('menu.dashboard')} leftIcon={<DashboardIcon />} />
                <CustomMenuItem delay={0.2} className="rounded-md" to="/newprospects" primaryText={t(`menu.prospects.new`)}
                    leftIcon={
                        <Badge badgeContent={data?.length} color="primary">
                            <AddHomeWorkIcon />
                        </Badge>
                    }
                />
                <CustomMenuItem delay={0.3} className="rounded-md" to="/prospects" primaryText={t(`menu.prospects.current`)} leftIcon={<MapsHomeWorkIcon />} />
                <CustomMenuItem delay={0.4} className="rounded-md" to="/targetings" primaryText={t('menu.targetings')} leftIcon={<GpsFixedIcon />} />
                <CustomMenuItem delay={0.4} className="rounded-md" to="/explore" primaryText={t('menu.explore')} leftIcon={<SearchIcon stroke={"currentColor"} />} />
                {Array.isArray(permissions) && permissions?.includes("agency") === false &&
                    <CustomMenuItem delay={0.6} className="rounded-md" to="/users" primaryText={t('menu.users')} leftIcon={<PeopleIcon />} />
                }
                {isSuperAdmin(permissions) &&
                    <>
                        <CustomMenuItem delay={0.7} className="rounded-md" to="/organisations" primaryText={t('menu.organisations')} leftIcon={<StoreIcon />} />
                        <CustomMenuItem delay={0.8} className="rounded-md" to="/formresults" primaryText={t('menu.formresults')} leftIcon={<InsertDriveFileIcon />} />
                    </>
                }
                <MenuItem sx={{
                    color: theme.palette.primary.light,
                    borderRadius: '0.325em',
                    paddingBottom: 0,
                    paddingTop: 0,
                    '& svg': {
                        color: theme.palette.primary.light,
                    },
                    '&.RaMenuItemLink-active': {
                        color: theme.palette.primary.darker,
                        '&:after': {
                            content: '""',
                            width: '4px',
                            height: '36px',
                            backgroundColor: theme.palette.primary.main,
                            position: 'absolute',
                            right: '0',
                            borderRadius: '25px'
                        }
                    },
                    '&.RaMenuItemLink-active svg': {
                        color: theme.palette.primary.main
                    },
                    fontWeight: 400
                }}
                >
                    <a className="h-full w-full py-[12px]" href="https://kaptcher.notion.site/FAQ-54d6b420c26347d78460b20fce360cd6" target="_blank" rel="noreferrer">
                        <span style={{ minWidth: '40px', display: 'inline-block' }}>
                            <HelpOutlineIcon color="primary" />
                        </span>
                        FAQ
                    </a>
                </MenuItem>
            </Box>
            <Box sx={{ transition: "all 0.4s ease-in-out" }} id="calendar-wrapper" className="grow flex flex-col mt-2 mb-12 justify-end">
                <Suspense>
                    <MenuCalendar allEvents={calendarData!} />
                </Suspense>
            </Box>
        </Box>
    </Menu >
};