import { useController } from 'react-hook-form';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StatusChip from '../Chips/StatusChip';
import { LightTooltip } from '../../components/layout/LightTooltip';
import Chip from '@mui/material/Chip';

export const CustomSelectArray = (props: any) => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useController({ name: props.name });

    const handleChange = (e: any) => {
        field.onChange(e.target.value);
    }

    return (
        <div className={props.className}>
            <FormControl sx={{
                marginTop: '0 !important',
                "& label": {
                    backgroundColor: "white",
                    "&[data-shrink=false]": {
                        top: "-8px",
                    },
                },
                "& .MuiInputBase-root": {
                    maxHeight: "40px",
                }
            }}>
                <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
                <Select
                    sx={{
                        minWidth: '120px'
                    }}
                    variant={props.variant}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    defaultValue={[]}
                    value={(field?.value && Array.isArray(field.value) && field.value.length > 0) ? field.value : []}
                    onChange={handleChange}
                    renderValue={(selected: any) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
                            <>
                                {selected.slice(0, 2).map((type: string) => {
                                    let item = props.choices.find((i: any) => i.id === type);
                                    return <StatusChip key={type} status={type} size="small" />
                                })}
                                {selected?.length > 2 &&
                                    <LightTooltip
                                        title={
                                            <>
                                                {selected.slice(2).map((type: string) => {
                                                    let item = props.choices.find((i: any) => i.id === type);
                                                    return <StatusChip key={type} status={type} size="small" />
                                                })}
                                            </>
                                        }
                                    >
                                        <Chip label={`+${selected.length - 2}`} />
                                    </LightTooltip>
                                }
                            </>
                        </Box>
                    )}
                >
                    {props.choices.map((choice: any) => (
                        <MenuItem
                            key={choice.id}
                            value={choice.id}
                        >
                            {choice.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};