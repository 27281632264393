import * as React from "react";
import { SimpleForm, TextInput, required, SelectInput, Toolbar, SaveButton, useTranslate, BooleanInput, ImageField, NumberInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import { allPipeStatuses } from "../../helpers/constants";
import { ProspectScoringPropertiesEnum } from "../../types/Prospect";

const OrganisationFormToolbar = (props: any) => {

    return <Toolbar {...props} >
        <SaveButton
            label={props.label}
            type="button"
        />
    </Toolbar>

}

export const OrganisationForm = (props: any) => {
    const t = useTranslate();

    return <SimpleForm
        defaultValues={{
            isActive: true,
            isWeeklyReportActivated: true,
            isMonthlyReportActivated: true,
            hasExclusivity: true,
        }}
        toolbar={<OrganisationFormToolbar mode={props.mode} label={props.buttonLabel}
        />}>
        <TextInput label={t("organisation.property.name")} source="name" validate={[required()]} />
        <SelectInput label={t("organisation.property.type")} source="type" validate={[required()]} choices={
            [
                { id: "promotor", name: t(`organisation.types.promotor`) },
                { id: "reseller", name: t(`organisation.types.reseller`) },
                { id: "agency", name: t(`organisation.types.agency`) },
            ]} />
        <NumberInput label={"Priority"} source="attributionPriority" />
        <ImageField source="imageUrl" title="title" />
        <TextInput label={t("organisation.property.imageUrl")} source="imageUrl" />
        <TextInput label={t("organisation.property.siteUrl")} source="siteUrl" />
        <TextInput label={t("organisation.property.description")} source="description" multiline sx={{ minWidth: "450px" }} />
        <BooleanInput label={t("organisation.property.isActive")} source="isActive" />
        <BooleanInput label={t("organisation.property.isWeeklyReportActivated")} source="isWeeklyReportActivated" />
        <BooleanInput label={t("organisation.property.isMonthlyReportActivated")} source="isMonthlyReportActivated" />
        <BooleanInput label={t("organisation.property.isNotificationReceiver")} source="isNotificationReceiver" />
        <BooleanInput label={t("organisation.property.hasExclusivity")} source="hasExclusivity" />
        <ArrayInput source="pipeStatuses">
            <SimpleFormIterator inline>
                <TextInput label={t("organisation.property.pipeStatuses.name")} source="name" />
                <SelectInput label={t("organisation.property.pipeStatuses.value")} source="value" choices={
                    allPipeStatuses.map((status: string) => {
                        return { id: status, name: status }
                    })
                } />
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="explorerCommunes">
            <SimpleFormIterator inline>
                <TextInput label={"nom"} source="nom" />
                <TextInput label={"code"} source="code_com" />
                <TextInput label={"dep_code"} source="code_dep" />
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="leadScoringCriterias">
            <SimpleFormIterator inline>
                <SelectInput label={t("organisation.property.leadScoringCriterias.name")} source="name" choices={
                    ProspectScoringPropertiesEnum.map((property: string) => {
                        return { id: property, name: t(`prospect.property.${property}`) }
                    })
                } />
                <SelectInput label={t("organisation.property.leadScoringCriterias.operator")} source="operator" choices={
                    [
                        { id: "gte", name: ">" },
                        { id: "lte", name: "<" },
                        { id: "eq", name: "=" },
                        { id: "in", name: "parmi" },
                    ]
                } />
                <FormDataConsumer>
                    {({
                        formData, // The whole form data
                        scopedFormData, // The data for this item of the ArrayInput
                        ...rest
                    }) =>
                        (scopedFormData?.operator && (scopedFormData.operator === "gte" || scopedFormData.operator === "lte")) ?
                            <NumberInput label={t("organisation.property.leadScoringCriterias.value")} source='value' />
                            :
                            <TextInput label={t("organisation.property.leadScoringCriterias.value")} source='value' />
                    }
                </FormDataConsumer>
                <NumberInput label={t("organisation.property.leadScoringCriterias.weight")} source="weight" />
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
}