import { Edit, SimpleForm, useTranslate, Toolbar, SaveButton } from "react-admin";

//Mui
import { Box } from "@mui/material";

// Components
import { ProjectForm } from "../../Forms/ProjectForm";
import { BackButton } from "../../Buttons/BackButton";
import { ProjectList } from "./ProjectList";

export const ProjectTab = (props: {
    displayType: 'list' | 'form'
    onSuccess: () => void,
    onBack: () => void,
    onAdd: () => void
}) => {
    const t = useTranslate();
    const { displayType, onSuccess, onBack, onAdd } = props;
    const CustomToolbar = () => (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
    return <>
        {displayType === 'list' &&
            <Box className="max-h-[540px] w-full overflow-auto">
                <ProjectList onAdd={onAdd} />
            </Box>
        }
        {displayType === 'form' &&
            <Box className="max-h-[540px] w-full overflow-auto">
                <Edit
                    actions={false}
                    redirect={false}
                    transform={(data: any) => {
                        return {
                            projects: data.projects
                        }
                    }}
                    mutationOptions={{
                        onSuccess: onSuccess
                    }}
                    mutationMode="pessimistic"
                    sx={{
                        "& .RaCreate-main": {
                            marginBottom: 0
                        },
                        "& .MuiToolbar-root": {
                            padding: 0
                        },
                        "& .RaEdit-main": {
                            marginTop: 0
                        }
                    }}
                    title={' '}
                >
                    <Box className="flex items-center gap-2 py-2 mb-4 border-b-2 border-neutral-100">
                        <BackButton onClick={onBack} />
                        <Box className="font-semibold text-sm">{t('prospect.property.projects.addProject')}</Box>
                    </Box>

                    <SimpleForm
                        sx={{ padding: 0 }}
                        toolbar={<CustomToolbar
                        />} >
                        <ProjectForm />
                    </SimpleForm>
                </Edit >
            </Box>
        }
    </>
}