import { useRecordContext, useTranslate, ReferenceArrayField, WithListContext, useGetIdentity, useShowContext } from "react-admin"
import { useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

// Mui
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

// Components
import { ShadowedToggleButtonGroup } from "../Buttons/ShadowedToggleGroupButton";
import { OwnerTab } from "./Owners/OwnerTab";
import { ContactTab } from "./Contacts/ContactTab";
import { FileUploader } from "../Inputs/FileUploader";
import { FileCard } from "../Files/FileCard";
import { CheckListTab } from "./CheckList/CheckListTab";
import { CheckListLinearProgress } from "./CheckList/CheckListLinearProgress";
import { ProjectTab } from "./Project/ProjectTab";

// Types
import { IProspect } from "../../types/Prospect"
import { UsersIcon } from "../Icons/UsersIcon";
import { FileIcon } from "../Icons/FileIcon";
import { UserCardIdIcon } from "../Icons/UserCardIdIcon";
import { AddToQueueIcon } from "../Icons/AddToQueueIcon";

export const ProspectDetailsTabs = (props:
    {
        onMouseEnter: (ownerId: string) => void,
        onMouseLeave: (ownerId: string) => void,
        selectedParcelIds: string[]
    }) => {
    const t = useTranslate();
    const prospect = useRecordContext<IProspect>();
    const { refetch } = useShowContext();
    const [tab, setTab] = useState<'checklist' | 'owners' | 'contacts' | 'files' | "project">('owners');
    const [ownersDisplayType, setOwnersDisplayType] = useState<'list' | 'form'>('list');
    const [contactsDisplayType, setContactsDisplayType] = useState<'list' | 'form'>('list');
    const [projectsDisplayType, setProjectsDisplayType] = useState<'list' | 'form'>('list');

    const user = useGetIdentity();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const tabs = [
        { label: 'owners', icon: <UsersIcon />, action: tab === "owners" ? <IconButton sx={{ height: '26px' }} onClick={() => setOwnersDisplayType('form')}>+</IconButton> : <></> },
        { label: 'contacts', icon: <UserCardIdIcon />, action: tab === "contacts" ? <IconButton sx={{ height: '26px' }} onClick={() => setContactsDisplayType('form')}>+</IconButton> : <></> },
        { label: 'files', icon: <FileIcon />, },
        { label: 'project', icon: <UsersIcon />, action: tab === "project" ? <IconButton sx={{ height: '26px' }} onClick={() => setProjectsDisplayType('form')}>+</IconButton> : <></> },
    ]

    if (user?.data?.checkList?.length > 0) {
        tabs.push({ label: 'checklist', icon: <AddToQueueIcon />, action: <Box className="items-center p-1 w-full absolute bottom-0 left-0 "><CheckListLinearProgress /></Box> })
    }

    if (!prospect || prospect.status !== "accepted") {
        return null;
    }

    const handleChangeTab = (
        event: React.MouseEvent<HTMLElement>,
        tab: 'checklist' | 'owners' | 'contacts' | 'files' | null,
    ) => {
        if (tab !== null) {
            setTab(tab);
        }
    };

    return <>
        <Box className="mb-4">
            <ShadowedToggleButtonGroup
                value={tab}
                exclusive
                onChange={handleChangeTab}
                aria-label="tabs"
            >
                {tabs.map((tab: { label: string, action?: JSX.Element, icon: JSX.Element }) => (
                    <ToggleButton key={tab.label} value={tab.label} aria-label={tab.label}>
                        {isDesktop ?
                            <div className="h-9 flex items-center">
                                {t(`prospect.property.${tab.label}`)}
                            </div>
                            : <div className="px-1">
                                {tab.icon}
                            </div>
                        }
                        {tab.action}
                    </ToggleButton>
                ))}
            </ShadowedToggleButtonGroup>
        </Box>
        {tab === 'owners' &&
            <OwnerTab
                displayType={ownersDisplayType}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onSuccess={() => { refetch(); setOwnersDisplayType('list') }}
                onBack={() => setOwnersDisplayType('list')}
                selectedParcelIds={props.selectedParcelIds}
            />
        }
        {tab === 'contacts' &&
            <ContactTab
                displayType={contactsDisplayType}
                onBack={() => setContactsDisplayType('list')}
                onSuccess={() => { setContactsDisplayType('list') }}
            />
        }
        {tab === 'files' &&
            <Box className="flex flex-col gap-4 max-h-[540px] mt-8 overflow-y-auto">
                <FileUploader />
                <ReferenceArrayField reference={`prospects/${prospect.id}/files`} source="fileIds">
                    <WithListContext render={({ data }) => (
                        <Box className="flex flex-col gap-4">
                            {data?.map((file: any) => {
                                return <FileCard file={file} resourceId={prospect.id} />
                            })}
                        </Box>
                    )} />
                </ReferenceArrayField>
            </Box>
        }
        {tab === 'checklist' &&
            <Box className="max-h-[540px] overflow-y-auto">
                <CheckListTab />
            </Box>
        }
        {tab === 'project' &&
            <ProjectTab
                displayType={projectsDisplayType}
                onSuccess={() => { refetch(); setProjectsDisplayType('list'); window.analytics?.track('Updated Project'); }}
                onBack={() => setProjectsDisplayType('list')}
                onAdd={() => setProjectsDisplayType('form')}
            />
        }
    </>
}