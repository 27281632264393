import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NumberInput, TextInput, useTranslate, required, ArrayInput, SelectInput, } from 'react-admin';
import { IParcel } from '../../types/Geography';
import { PropertyEnum } from '../../types/Prospect';

export const ProspectForm = (props: { selectedParcel?: IParcel, onChange?: (val: boolean) => void }) => {
    const { selectedParcel } = props;
    const t = useTranslate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            if (e.target.value === "" || e.target.value == null) {
                props.onChange(false);
            } else {
                props.onChange(true);
            }
        }
    };
    return <Box className="flex flex-col gap-2 px-5 py-2">
        {selectedParcel &&
            <Box className="ml-6 mr-6">
                <Typography variant="h6" className="mb-4">
                    {t("prospect.property.parcelleNumber")}*
                </Typography>

                <TextInput
                    defaultValue={selectedParcel?.properties?.section + selectedParcel?.properties?.numero}
                    source="parcels"
                    validate={[required()]}
                    label={false}
                    disabled
                />
            </Box>}
        <Box className="ml-6 mr-6">
            <Typography variant="h6" className="mb-4">
                {t("prospect.property.name")}*
            </Typography>
            <TextInput
                defaultValue={selectedParcel ? `${selectedParcel.properties?.nom_com ?? ""}, ${selectedParcel.address ?? ''}` : ''}
                label={false}
                fullWidth
                source="name"
                required
                onChange={handleChange}
            />
        </Box>
        <Box className="ml-6 mr-6">
            <Typography variant="h6" className="mb-4">
                {t("prospect.property.type")}
            </Typography>
            <SelectInput
                fullWidth
                source="type"
                label={false}
                choices={PropertyEnum.map((property) => ({ id: property, name: t(`prospect.type.${property}`) }))}
                defaultValue={"field"}
            />
        </Box>
        <Box className="ml-6 mr-6">
            <Typography variant="h6" className="mb-4">
                {t("prospect.eventTypes.note")}
            </Typography>
            <TextInput label={false} fullWidth source="note" />
        </Box>
    </Box>
}