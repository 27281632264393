import { styled } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        backgroundColor: 'transparent',
        padding: 0,
        '& .MuiSvgIcon-root': {
            marginRight: "2px",
        },
        '&:not(:first-of-type)': {
            marginLeft: "16px",
            justifyItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            borderRadius: 0,
        },
        '&.Mui-selected.MuiToggleButton-root:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
            border: 0,
        },
    },
    '& .MuiToggleButton-root:hover': {
        backgroundColor: 'transparent',
    }
}));

export const NoBorderToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.MuiToggleButton-root.Mui-selected.MuiToggleButtonGroup-grouped": {
        border: 0,
    }
}));

export const BackgroundToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: 0,
    borderRadius: '8px',
    '& .MuiToggleButtonGroup-grouped': {
        border: 0,
        color: theme.palette.primary.darker,
        padding: '0px 8px',
        '&:not(:first-of-type)': {
            marginLeft: "8px",
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            justifyItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            borderRadius: 0,
            backgroundColor: 'transparent',
        },
        '&.Mui-selected.MuiToggleButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    '& .MuiToggleButton-root:hover': {
        backgroundColor: 'transparent',
    }
}));