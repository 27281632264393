// Librairies
import * as React from "react";
import { useRecordContext } from 'react-admin';
import { useState } from "react";

// Mui Components
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleButton from '@mui/material/ToggleButton';

// Custom components
import { IPLUData, IProspect } from "../../../types/Prospect";
import { CRMField } from "../../../components/Fields/ProspectCRMField";
import { StyledToggleButtonGroup } from "../../../components/Buttons/StyledToggleButtonGroup";

export const ProspectCRMLayout = (props: { variant?: "compact" | "full" }) => {
    const prospect: (IProspect & { isRecommendation: boolean } & { pluData: IPLUData } | undefined) = useRecordContext();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [tab, setTab] = useState('events');

    const handleTabChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setTab(newAlignment);
        }
    };

    return <>
        {isDesktop && !prospect?.isRecommendation &&
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Card className="mt-4 p-4 rounded-2xl">
                        <CRMField
                            displayCreateButtons={true}
                            variant={props.variant}
                            crmType="events"
                            displayButtons={true}
                            dateProperty="eventDate"
                            userProperty="authorName"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="mt-4 mx-4 p-4 rounded-2xl">
                        <CRMField
                            displayCreateButtons={true}
                            variant={props.variant}
                            crmType="notes"
                            displayButtons={true}
                            dateProperty="createdAt"
                            userProperty="authorName"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="mt-4 p-4 rounded-2xl">
                        <CRMField
                            displayCreateButtons={true}
                            variant={props.variant}
                            crmType="tasks"
                            displayButtons={true}
                            dateProperty="dueDate"
                            userProperty="assigneeName"
                        />
                    </Card>
                </Grid>
            </Grid>
        }
        {
            !isDesktop &&
            <>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Card className="mt-4 p-1 md:p-4">
                            <StyledToggleButtonGroup
                                value={tab}
                                exclusive
                                onChange={handleTabChange}
                                aria-label="tab change"
                            >
                                <ToggleButton value="events" aria-label="left aligned">
                                    Échanges
                                </ToggleButton>
                                <ToggleButton value="notes" aria-label="centered">
                                    Notes
                                </ToggleButton>
                                <ToggleButton value="tasks" aria-label="right aligned">
                                    Rappels
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                            {tab === 'events' && <CRMField
                                variant={props.variant}
                                crmType="events"
                                displayButtons={true}
                                displayCreateButtons={true}
                                dateProperty="eventDate"
                                userProperty="authorName"
                            />}
                            {tab === 'notes' && <CRMField
                                variant={props.variant}
                                crmType="notes"
                                displayButtons={true}
                                displayCreateButtons={true}
                                dateProperty="createdAt"
                                userProperty="authorName"
                            />}
                            {tab === 'tasks' && <CRMField
                                variant={props.variant}
                                crmType="tasks"
                                displayButtons={true}
                                displayCreateButtons={true}
                                dateProperty="dueDate"
                                userProperty="assigneeName"
                            />}
                        </Card>
                    </Grid>
                </Grid>
            </>
        }
    </>
}