import { useState } from 'react';
import { EmailField, useRecordContext, useTranslate, useRefresh } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/AlternateEmailRounded';
import theme from '../../theme';
import { CircleCheck } from '../Icons/CircleCheck';
import { SingleSplitTreatment } from '../layout/SingleSplitTreatment';

// Icons
import { IProspect } from '../../types/Prospect';
import { EmailSender } from '../Emails/EmailSender';

export const CustomEmailField = (props: any) => {
    const record = useRecordContext<IProspect>();
    const refresh = useRefresh();
    const t = useTranslate();
    // Add state for dialog open
    const [dialogOpen, setDialogOpen] = useState(false);

    if (!record || !record.email) {
        return null;
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    return (
        <div className='flex items-center gap-x-2'>
            <SingleSplitTreatment
                name="send-notification-email"
                render={<div className='flex items-center gap-x-2'>
                    <EmailIcon color='primary' />
                    <Link sx={{ cursor: "pointer" }} variant="body2" aria-label="delete" onClick={handleDialogOpen}>
                        {record.email}
                    </Link>
                </div>
                }
                offRender={
                    <div className='flex items-center gap-x-2'>
                        <EmailIcon color='primary' />
                        <EmailField {...props} />
                    </div>
                }
            />
            {record.verifiedEmail && (
                <Tooltip placement="top" title={t('prospect.property.verifiedEmail')} >
                    <div>
                        <CircleCheck color="success" />
                    </div>
                </Tooltip>
            )}
            <EmailSender
                prospectIds={[record?.id]}
                prospectEmails={[record?.email]}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                defaultMode={'edit'}
                onSuccess={refresh}
                channel='email'
            />
        </div>
    );
};
