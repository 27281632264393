import { useState } from 'react';
import {
    List,
    useTranslate,
    SimpleList,
    useStore,
    Pagination
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StyledList } from '../../components/layout/StyledList';
import { PageTitle } from '../../components/layout/PageTitle';
import { CustomCard } from '../../components/layout/CustomCard';
import { UsersDataTable } from '../../components/Users/Datatable';
// Mui
import Box from '@mui/material/Box';
import { SplittedList } from '../../components/layout/SplittedList';
import { UsersToolbar } from '../../components/Users/Toolbar';
import { UsersColumnView } from '../../components/Users/ColumnView';

export const UsersList = () => {
    const t = useTranslate();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [displayType, setDisplayType] = useStore(`displayTypeUsers`, "users");

    return (isDesktop ?
        <>
            {displayType === "users" && (
                <Box>
                    <Box className="h-full w-full">
                        <StyledList
                            title={t('menu.users')}
                            sx={{
                                "& .RaList-actions": {
                                    flexDirection: "column-reverse",
                                    gap: "8px",
                                    "& form": {
                                        flexGrow: 1,
                                        width: "calc(100%)",
                                        justifyContent: "flex-end",
                                        padding: "16px 20px",
                                        paddingRight: "78px",
                                        "& [data-source='textSearch']": {
                                            marginRight: "auto"
                                        },
                                        backgroundColor: "white",
                                        paddingBottom: "0",
                                        borderTopRightRadius: "8px",
                                        borderTopLeftRadius: "8px",
                                    }
                                }
                            }}
                            pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
                            perPage={25}
                            actions={<Box className="w-full">
                                <UsersToolbar handleDisplayTypeChange={setDisplayType} displayType={displayType} />
                            </Box>
                            }
                        >
                            <CustomCard sx={{ overflow: "visible" }} noShadow={true} className={'px-6 py-2'}>
                                <UsersDataTable />
                            </CustomCard>
                        </StyledList>
                    </Box>
                </Box>
            )}
            {displayType === "teams" && (
                <SplittedList
                    title={t('menu.users')}
                    sx={{
                        "& .RaList-actions": {
                            flexDirection: "column-reverse",
                            gap: "8px",
                            "& form": {
                                flexGrow: 1,
                                width: "calc(100%)",
                                justifyContent: "flex-end",
                                padding: "16px 20px",
                                paddingRight: "78px",
                                "& [data-source='textSearch']": {
                                    marginRight: "auto"
                                },
                                backgroundColor: "white",
                                paddingBottom: "0",
                                borderTopRightRadius: "8px",
                                borderTopLeftRadius: "8px",
                            }
                        }
                    }}
                    pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
                    perPage={25}
                    className="h-full mb-2"
                    actions={<Box className="w-full">
                        <UsersToolbar handleDisplayTypeChange={setDisplayType} displayType={displayType} />
                    </Box>
                    }
                >
                    <div className="flex w-full h-full">
                        <div className="w-full h-full">
                            <CustomCard noShadow={true} className={'px-8 py-4'} containerClass='h-full mb-8' cardClass='h-full'>
                                <UsersColumnView />
                            </CustomCard>
                        </div>
                    </div>
                </SplittedList >
            )}</>
        :
        <>
            <PageTitle title={t(`menu.users`)} className={'pb-12 pt-2'} />
            <List actions={false} title="Kaptcher">
                <SimpleList
                    linkType="show"
                    primaryText={record => (`${record.firstName} ${record.lastName}`)}
                    secondaryText={record => record.email}
                />
            </List>
        </>
    );
};