import { styled } from '@mui/system';
import { List } from 'react-admin';
import theme from '../../theme';

export const StyledList = styled(List)({
    "& .RaDatagrid-root": {
        paddingTop: "16px"
    },
    "& .RaDatagrid-tableWrapper": {
        overflow: "hidden",
        borderRadius: theme.shape.borderRadius,
    },
    "& .RaList-main .MuiPaper-root": {
        zIndex: 1
    }
})