import { useGetIdentity, useRecordContext, useTranslate } from 'react-admin';
import Chip from '@mui/material/Chip';
import { getStatusColor } from '../../helpers/helpers';
import theme from '../../theme';

const StatusChip = (props: {
    status?: string,
    statusType: "pipeStatus",
    resourceType: 'owner' | 'prospect' | 'parcel',
    size?: 'small' | 'medium',
}) => {
    const record = useRecordContext();
    const { status, statusType, resourceType, size } = props;
    const user = useGetIdentity();

    let color: { family: keyof typeof theme.palette, variant: "main" | "light" | "dark" | "lighter" | "darker" } = getStatusColor(status ?? record?.[statusType]);
    // const status = props?.status ? props.status : props?.statusType ? record[props.statusType] : record?.status;
    const t = useTranslate();

    const pipeStatusLabel = (status: string) => {
        return user?.data?.pipeStatuses?.length > 0 && resourceType === 'prospect' ?
            user?.data?.pipeStatuses?.find((pipeStatus: any) => pipeStatus.value === status)?.name :
            (resourceType === 'owner' ? t(`prospect.owners.statuses.${status}`) : t(`prospect.statuses.${status}`))
    }

    return <Chip
        sx={{ borderRadius: "8px", fontSize: "11px", cursor: 'pointer', backgroundColor: theme.palette[color.family][color.variant], color: color.variant !== "lighter" ? 'white' : 'darker' }}
        label={pipeStatusLabel(status ?? record?.[statusType])}
        size={size}
    />
}

StatusChip.defaultProps = {
    statusType: 'pipeStatus',
    resourceType: 'prospect',
    size: 'medium',
}

export default StatusChip;