import SvgIcon from '@mui/material/SvgIcon';

export const UploadFile = () => {
    return (
<SvgIcon viewBox='0 0 17 17'> 
<path d="M5.85076 13.588H4.51967C2.48148 13.4424 1.56641 11.8756 1.56641 10.4822C1.56641 9.08872 2.48149 7.51502 4.48501 7.37637C4.76925 7.34864 5.01882 7.57048 5.03961 7.86165C5.06041 8.14589 4.84554 8.39546 4.55436 8.41626C3.20944 8.51332 2.6063 9.52547 2.6063 10.4891C2.6063 11.4527 3.20944 12.4649 4.55436 12.562H5.85076C6.135 12.562 6.37071 12.7977 6.37071 13.0819C6.37071 13.3661 6.135 13.588 5.85076 13.588Z" fill="#292D32" />
<path d="M12.2351 13.5887C12.2212 13.5887 12.2143 13.5887 12.2004 13.5887C11.9162 13.5887 11.6528 13.3529 11.6528 13.0687C11.6528 12.7706 11.8746 12.5488 12.1658 12.5488C13.0185 12.5488 13.7811 12.2507 14.3773 11.7168C15.4588 10.774 15.5281 9.41521 15.2369 8.45851C14.9457 7.50874 14.1346 6.42032 12.7204 6.247C12.4916 6.21927 12.3113 6.04596 12.2697 5.81718C11.9924 4.15335 11.0981 3.00254 9.73934 2.58658C8.33895 2.14982 6.70283 2.57965 5.68373 3.64727C4.69237 4.68023 4.4636 6.12915 5.03901 7.72366C5.13606 7.99403 4.99744 8.29213 4.72707 8.38918C4.4567 8.48624 4.15858 8.34759 4.06152 8.07722C3.36133 6.12222 3.68024 4.25041 4.93504 2.93321C6.21758 1.58828 8.27656 1.05446 10.0444 1.59521C11.6666 2.09436 12.8105 3.43236 13.2264 5.2903C14.6407 5.6092 15.7776 6.68376 16.2283 8.16734C16.7205 9.78265 16.2768 11.4465 15.0636 12.5002C14.2941 13.1935 13.2888 13.5887 12.2351 13.5887Z" fill="#292D32" />
<path d="M8.99798 15.6268C7.60452 15.6268 6.3012 14.885 5.58714 13.6857C5.51088 13.5678 5.43462 13.4292 5.37223 13.2767C5.13652 12.7844 5.01172 12.2229 5.01172 11.6406C5.01172 9.44291 6.80034 7.6543 8.99798 7.6543C11.1956 7.6543 12.9842 9.44291 12.9842 11.6406C12.9842 12.2298 12.8595 12.7844 12.6099 13.2975C12.5544 13.4292 12.4782 13.5678 12.395 13.6995C11.6948 14.885 10.3914 15.6268 8.99798 15.6268ZM8.99798 8.69419C7.37574 8.69419 6.05161 10.0183 6.05161 11.6406C6.05161 12.0704 6.14174 12.4725 6.31506 12.8399C6.37052 12.9578 6.41903 13.0548 6.47449 13.1449C7.00137 14.0392 7.96501 14.5869 8.99104 14.5869C10.0171 14.5869 10.9807 14.0392 11.5006 13.1588C11.563 13.0548 11.6185 12.9578 11.6601 12.8607C11.8473 12.4794 11.9374 12.0773 11.9374 11.6475C11.9443 10.0183 10.6202 8.69419 8.99798 8.69419Z" fill="#292D32" />
<path d="M8.60299 12.8467C8.47127 12.8467 8.33958 12.7981 8.23559 12.6942L7.54922 12.0078C7.34818 11.8068 7.34818 11.474 7.54922 11.273C7.75027 11.0719 8.08303 11.0719 8.28408 11.273L8.61687 11.6057L9.72608 10.5797C9.94099 10.3856 10.2668 10.3995 10.4609 10.6074C10.655 10.8154 10.6412 11.1482 10.4332 11.3423L8.95657 12.708C8.85258 12.7982 8.72778 12.8467 8.60299 12.8467Z" fill="#292D32" />
</SvgIcon>
)}