import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ClockIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 24 24' fill="none" {...props}>
            <path d="M12.5 7.00012V12.0001H17.5M12.5 21.0001C7.52944 21.0001 3.5 16.9707 3.5 12.0001C3.5 7.02956 7.52944 3.00012 12.5 3.00012C17.4706 3.00012 21.5 7.02956 21.5 12.0001C21.5 16.9707 17.4706 21.0001 12.5 21.0001Z" stroke="currentColor" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    )
}

