export const organisationMessages = {
    organisation: {
        property: {
            name: "Nom",
            type: "Type",
            isActive: "Actif",
            isMonthlyReportActivated: "Rapport Mensuel",
            isWeeklyReportActivated: "Rapport Hebdomadaire",
            isNotificationReceiver: "Recevoir les notifications",
            imageUrl: "Image",
            siteUrl: "Site Web",
            description: "Description",
            attributionPriority: "Ordre d'attribution",
            pipeStatuses: {
                name: "Statut affiché",
                value: "Statut",
            },
            hasExclusivity: "Exclusivité",
            leadScoringCriterias: {
                name: "Critère",
                value: "Valeur",
                operator: "Opérateur",
                weight: "Poids",
            },
            emailTemplates: {
                myTemplates: "Mes modèles",
            }
        },
        types: {
            promotor: "Promoteur",
            reseller: "Marchand de biens",
            agency: "Agence",
        }
    }
}