export const baseMessages = {
    resources: {
        targetings: {
            name: "Ciblage |||| Ciblages",
        },
        newprospects: {
            name: "Nouveau Prospect |||| Nouveaux Prospects",
        }
    },
    menu: {
        targeting: "Ciblage",
        targetings: "Ciblages",
        users: "Utilisateurs",
        user:"Utilisateur",
        prospects: {
            new: "Nouveaux Prospects",
            current: "Dossiers en cours"
        },
        dashboard: "Dashboard",
        organisations: "Organisations",
        formresults: "Résultats formulaire",
        teams: "Équipes",
        contacts: "Intervenants",
        entities: "Entités",
        explore: "Explorer",
    },
    buttons: {
        yes: "Oui",
        no: "Non",
        forgotPassword: "Mot de passe oublié",
        requestResetPassword: "Réinitialiser le mot de passe",
        validate: "Valider",
        cancel: "Annuler",
        selectRejectReason: "Motif du refus",
        copy: "Copier",
        search: "Rechercher",
        saveAndAddAnother: "Créer et ajouter un autre",
        seeMore: "Voir plus",
        seeLess: "Voir moins",
        accept: "Accepter",
        reject: "Refuser",
        filter: "Filtrer",
        reset: "Réinitialiser",
        editor: "Editeur",
        edit: "Éditer",
        next: "Suivant",
        download: "Télécharger",
    },
    property: {
        name: "Nom",
        createdAt: "Créé le"
    },
    auth: {
        resetPassword: {
            askForgotPassword: "Mot de passe oublié ?",
            resetMailSent: "Un mail vous a été envoyé",
            newPassword: "Nouveau mot de passe",
            passwordSaved: "Mot de passe enregistré"
        },
        errors: {
            invalidCredentials: "L'email ou le mot de passe est incorrect",
            invalidToken: "Le lien est invalide",
            invalidPassword: "Le mot de passe doit contenir un chiffre et une majuscule",
            expiredToken: "Le lien est expiré",
            noToken: "Le lien est invalide"
        }
    },
    layout: {
        update: "Mettre à jour",
        updateAvailable: "Une nouvelle version est disponible",
        updateNow: "Mettre à jour maintenant",
    },
    Forbidden: 'Non autorisé',
    BasicError: 'Une erreur s\'est produite',
    ValidationError: 'Le formulaire contient des erreurs',
    InvalidForm: 'Le formulaire est invalide',
    InvalidEmail: 'L\'email est incorrect',
    notifications: {
        sent: "Le message a été envoyé",
    },
    customViews: {
        removeDialogTitle: "Supprimer la vue",
        removeDialogMessage: "Êtes-vous sûr de vouloir supprimer cette vue ?",
        newDialogTitle: "Créer une nouvelle vue",
        newDialogViewName: "Entrez le nom de la nouvelle vue",
    },
    followedBy: "Suivi par",
}