import { useState } from 'react';
import { useRecordContext, useTranslate, useRefresh } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import theme from '../../theme';
import { IProspect } from '../../types/Prospect';
import Link from '@mui/material/Link';

// Icons
import { PhoneIcon } from '../Icons/PhoneIcon';
import { CircleCheck } from '../Icons/CircleCheck';

export const PhoneField = () => {
    const record: IProspect | undefined = useRecordContext<IProspect>();
    const t = useTranslate();

    if (!record || !record.phoneNumber) {
        return null;
    }

    // Improve readability of phone number. If the phone number is 10 digits long, format it as XX XX XX XX XX. Otherwise, return the phone number as is.
    const readablePhoneNumber = (phoneNumber: string) => {
        if (phoneNumber?.length === 10) {
            return phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
        }
        return phoneNumber;
    }

    return <div className='flex items-center gap-x-2'>
        <PhoneIcon stroke={theme.palette.primary.main} />
        <Link
            href={`tel:${record.phoneNumber}`}
            onClick={(e) => e.stopPropagation()}
            variant="body2"
        >
            {readablePhoneNumber(record.phoneNumber)}
        </Link>
        {record.verifiedPhone && (
            <Tooltip placement="top" title={t('prospect.property.verifiedPhoneNumber')} >
                <div>
                    <CircleCheck color="success" />
                </div>
            </Tooltip>
        )}
    </div>
};