import SchoolIcon from '@mui/icons-material/SchoolRounded';

export const POIcon = (props: { icon: string }) => {
    return (
        <>
            {
                props.icon === "school" ? <SchoolIcon /> :
                    (
                        <h1 className={`font-bold border-2 border-indigo-600 rounded-full ${props?.icon === "subway" ? "w-9 h-9" : "w-12 h-11"} flex justify-center items-center`}>
                            {props.icon === "subway" ? "M" : props.icon === "SNCF" ? "SNCF" : "RER"}
                        </h1 >
                    )
            }
        </>
    )
}