import { useState } from 'react';
import {
    TextField,
    EmailField,
    useTranslate,
    usePermissions,
    ReferenceField,
    FunctionField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
//Components
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import { RoleChip } from '../Chips/RoleChip';

export const UsersDataTable = (props: any) => {
    const { permissions } = usePermissions();
    const t = useTranslate();

    return <StyledDataGrid rowClick={"show"}
        sx={{ "& .MuiToolbar-root.MuiToolbar-regular.RaBulkActionsToolbar-toolbar": { display: "none" } }}
    >
        <TextField source="firstName" label={t("user.property.firstName")} />
        <TextField source="lastName" label={t("user.property.lastName")} />
        <EmailField source="email" label={t("user.property.email")} />
        <FunctionField source="role" label={t("user.property.role")} render={(record: any) => <RoleChip role={record.role} />} />
        <ReferenceArrayField source="teamIds" reference="teams" label={t('team.property.name')}>
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
        {
            permissions?.includes('superAdmin') === true && (
                <ReferenceField source="organisationId" reference="organisations" >
                    <TextField source="name" />
                </ReferenceField>
            )
        }
    </StyledDataGrid >
}