import { useRecordContext, useTranslate } from 'react-admin';
import { PhoneField } from "../../components/Fields/PhoneField";
import { CustomEmailField } from "../../components/Fields/CustomEmailField";
import { DropVoiceMailButton } from "../../components/Buttons/DropVoiceMailButton";
import { IProspect } from '../../types/Prospect';
import Typography from '@mui/material/Typography';

export const ContactField = () => {
    const prospect = useRecordContext<IProspect>();
    const t = useTranslate();

    if (!prospect) {
        return null;
    }

    if (!prospect.email && !prospect.phoneNumber) {
        return null;
    }

    return <div className="flex flex-col gap-2 mb-4">
        <Typography variant="h6">{'Contact'}</Typography>
        <div className="flex items-center justify-between">
            <CustomEmailField source="email" label={t("prospect.property.email")} isverified={prospect?.verifiedEmail} />
            <PhoneField />
        </div>
        <DropVoiceMailButton />
    </div>
}