// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadiusSm,
        },
        sizeLarge: {
          height: 48
        }
      }
    }
  };
}
