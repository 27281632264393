import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Visibility';
import { RichTextField } from 'react-admin';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { ShowIcon } from '../Icons/ShowIcon';
import theme from '../../theme';

export const EmailTemplateCard = (props: { title: string, body: string, onSendTemplate: () => void, onPreviewTemplate: () => void }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Box className="bg-white rounded-lg py-4 px-6 flex flex-col gap-2">
        <Box className="flex items-center gap-2">
            <Typography variant="h6">{props.title}</Typography>
        </Box>
        <div className="relative group">
            <Box className="shadow-lg p-2 rounded-md w-52 h-60 overflow-hidden">
                <Box className="w-80">
                    <RichTextField record={{ body: props.body }} source="body" />
                </Box>
            </Box>
            <div className="absolute rounded-md inset-0 bg-gray-400 bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <IconButton onClick={handleClick}>
                    <PreviewIcon color='primary' />
                </IconButton>
            </div>
        </div>
        <Button variant="contained" onClick={props.onSendTemplate}>
            Envoyer
        </Button>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)"
                },
                "& .MuiPopover-paper": {
                    width: "400px",
                    maxHeight: "400px"
                }
            }}
        >
            <Box className="p-4 flex flex-col gap-4 h-[400px] bg-bg">
                <Box className="flex items-center gap-2">
                    <ShowIcon stroke={theme.palette.primary.main} />
                    <Typography variant="h6">Aperçu : {props.title}</Typography>
                </Box>
                <div className="overflow-auto flex-grow bg-white p-4">
                    <RichTextField record={{ body: props.body }} source="body" />
                </div>
                <Button variant="contained" onClick={props.onSendTemplate}>Envoyer</Button>
            </Box>
        </Popover>
    </Box>
}