import {
    TextField,
    useTranslate,
    DateField,
    FunctionField,
    ReferenceField,
    ReferenceArrayField,
    WithListContext,
} from 'react-admin';

//Components
import StatusButtons from '../../components/Buttons/StatusButtons';
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import { UserSelect } from '../../components/Buttons/UserSelect';
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';
import { LeadScoreHeat } from './LeadScoreHeat';
import { ProspectBulkActionsButton } from '../Buttons/ProspectBulkActionsButtons';
import { CreatedByAvatar } from '../Fields/CreatedByAvatar';

// Mui
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

// Styles
import theme from '../../theme';

const DataTableSx = {
    "& .MuiToolbar-root.MuiToolbar-regular.RaBulkActionsToolbar-toolbar": {
        alignItems: "center",
        backgroundColor: "transparent",
        marginLeft: "241px",
        padding: 0,
        justifyContent: "flex-start",
        transform: "translateY(-64px)",
        width: "fit-content",
        "& .MuiTypography-root.MuiTypography-subtitle1": {
            color: theme.palette.primary.light,
        },
        "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.RaBulkActionsToolbar-icon": {
            display: "none",
        },
    }
}

export const NewProspectsDataTable = (props:
    {
        permissions: string[],
        filter: any
    }) => {
    const t = useTranslate();

    const postRowSx = () => ({
        "&:hover .preview-button-column": {
            visibility: "visible",
        },
    });

    return <StyledDataGrid
        sx={{ ...DataTableSx }}
        bulkActionButtons={
            < ProspectBulkActionsButton />
        } rowClick={"show"} rowSx={postRowSx} >
        <LeadScoreHeat label="" source="leadScore" />
        <TextField sortBy='createdAt' source="name" label={t('prospect.property.name')} />
        <FunctionField source="address" label={t('prospect.property.address')} render={(record: any) => record?.address?.toLowerCase().includes(record.cityName?.toLowerCase()) ? record?.address : `${record.address}, ${record.cityName}`} />
        <FunctionField source="type" label="Type" render={(record: any) => t(`prospect.type.${record.type}`)} />
        <FunctionField source="fieldSurface" label={t('prospect.property.fieldSurface')} render={(record: any) => `${record.fieldSurface ?? 0} m²`} />
        <DateField source="createdAt" label={t('prospect.property.createdAt')} />
        {
            props.permissions?.includes('superAdmin') === true && (
                <>
                    <ReferenceField source="organisationId" reference="organisations" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceArrayField source="userIds" reference="users">
                        <WithListContext render={({ data }) => (
                            <Box>
                                {data?.map(dev => (
                                    <Chip key={dev.id} label={`${dev.firstName} ${dev.lastName}`} />
                                ))}
                            </Box>
                        )} />
                    </ReferenceArrayField>
                </>
            )
        }
        <SingleSplitTreatment
            name="dispatch-prospects-users"
            render={<UserSelect source="userIds" onChange={(e: any) => { }} />}
        />
        {
            props.permissions?.includes("superAdmin") === false && (
                <StatusButtons size="small" label={t('prospect.property.interest')} />
            )
        }
        <CreatedByAvatar source="createdBy" label={t('followedBy')} />
    </StyledDataGrid >
}