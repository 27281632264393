import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FileTypeIcon = (props: { type: string, width: string, height: string }) => {
    const { type, width, height } = props;
    switch (type) {
        case "csv":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.25C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0H26.91C19.773 0 12.9284 2.83523 7.88176 7.88184C2.83516 12.9284 0 19.7729 0 26.9099V189.13C0.0105984 196.26 2.85043 203.094 7.89589 208.132C12.9413 213.17 19.78 216 26.91 216H137.51C141.045 216.001 144.545 215.306 147.811 213.955C151.077 212.603 154.045 210.621 156.545 208.122C159.045 205.623 161.028 202.656 162.381 199.39C163.734 196.125 164.43 192.625 164.43 189.09V49.21L139.67 49.25Z" fill="#161434" />
                </g>
                <path d="M164.43 49.25H139.67C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0L164.43 49.25Z" fill="#161434" />
                <path d="M183.94 170.61H58.8998C50.0302 170.61 42.8398 177.8 42.8398 186.67V223.95C42.8398 232.82 50.0302 240.01 58.8998 240.01H183.94C192.81 240.01 200 232.82 200 223.95V186.67C200 177.8 192.81 170.61 183.94 170.61Z" fill="#161434" />
                <path d="M80.49 213.12V220.37H73V213.12H80.49Z" fill="white" />
                <path d="M107.4 193.14C109.931 195.188 111.65 198.07 112.25 201.27H104.59C104.079 199.942 103.176 198.802 102 198C100.763 197.189 99.3088 196.774 97.8302 196.81C96.8553 196.781 95.8867 196.975 94.999 197.379C94.1114 197.783 93.3283 198.386 92.7102 199.14C91.336 200.917 90.6442 203.127 90.7602 205.37C90.6434 207.604 91.3357 209.805 92.7102 211.57C93.3308 212.32 94.1148 212.919 95.0023 213.319C95.8897 213.719 96.8571 213.911 97.8302 213.88C99.3088 213.916 100.763 213.501 102 212.69C103.175 211.905 104.085 210.782 104.61 209.47H112.27C111.657 212.66 109.94 215.531 107.42 217.58C104.716 219.659 101.368 220.721 97.9602 220.58C95.2764 220.639 92.6265 219.972 90.2902 218.65C88.1012 217.384 86.3288 215.508 85.1902 213.25C83.9576 210.798 83.3399 208.084 83.3902 205.34C83.3387 202.593 83.9564 199.875 85.1902 197.42C86.3288 195.162 88.1012 193.286 90.2902 192.02C92.6265 190.698 95.2764 190.031 97.9602 190.09C101.366 189.973 104.706 191.052 107.4 193.14Z" fill="white" />
                <path d="M136.63 216.3C135.768 217.675 134.534 218.778 133.07 219.48C131.288 220.314 129.336 220.718 127.37 220.66C124.409 220.78 121.491 219.919 119.07 218.21C118.001 217.406 117.126 216.372 116.509 215.185C115.893 213.998 115.552 212.687 115.51 211.35H123.22C123.273 212.358 123.706 213.308 124.43 214.01C125.146 214.681 126.099 215.04 127.08 215.01C127.884 215.051 128.673 214.778 129.28 214.25C129.543 213.989 129.75 213.677 129.888 213.333C130.026 212.989 130.091 212.62 130.08 212.25C130.089 211.9 130.029 211.552 129.902 211.225C129.775 210.899 129.584 210.602 129.34 210.35C128.815 209.817 128.189 209.392 127.5 209.1C126.76 208.77 125.75 208.38 124.45 207.93C122.863 207.408 121.313 206.78 119.81 206.05C118.575 205.411 117.514 204.481 116.72 203.34C115.806 201.955 115.355 200.317 115.43 198.66C115.389 197.017 115.861 195.402 116.78 194.04C117.725 192.709 119.024 191.67 120.53 191.04C122.272 190.335 124.141 189.995 126.02 190.04C128.861 189.89 131.666 190.727 133.96 192.41C134.939 193.204 135.74 194.194 136.312 195.317C136.884 196.44 137.214 197.671 137.28 198.93H129.45C129.354 198.05 128.975 197.226 128.37 196.58C128.057 196.284 127.688 196.054 127.284 195.904C126.88 195.755 126.45 195.689 126.02 195.71C125.273 195.672 124.538 195.914 123.96 196.39C123.69 196.644 123.479 196.956 123.344 197.302C123.209 197.648 123.153 198.019 123.18 198.39C123.171 198.721 123.229 199.051 123.349 199.36C123.469 199.669 123.65 199.951 123.88 200.19C124.376 200.712 124.975 201.124 125.64 201.4C126.34 201.71 127.36 202.11 128.64 202.59C130.256 203.11 131.831 203.752 133.35 204.51C134.596 205.18 135.668 206.132 136.48 207.29C137.407 208.692 137.866 210.351 137.79 212.03C137.797 213.532 137.396 215.008 136.63 216.3Z" fill="white" />
                <path d="M147.44 190.43L154.77 212.96L162.1 190.43H169.85L159.43 220.37H150.07L139.69 190.43H147.44Z" fill="white" />
                <path d="M118.89 150.8H45.5398C39.1898 150.8 34.0298 144.48 34.0298 136.7V83.51C34.0298 75.74 39.1898 69.4199 45.5398 69.4199H118.89C125.24 69.4199 130.4 75.74 130.4 83.51V136.7C130.4 144.48 125.24 150.8 118.89 150.8ZM45.5398 76.6201C42.4398 76.6201 39.9098 79.71 39.9098 83.51V136.7C39.9098 140.5 42.4398 143.59 45.5398 143.59H118.89C121.99 143.59 124.51 140.5 124.51 136.7V83.51C124.51 79.71 121.99 76.6201 118.89 76.6201H45.5398Z" fill="#161434" />
                <path d="M127.46 118.77H36.9702V125.98H127.46V118.77Z" fill="#161434" />
                <path d="M127.46 94.05H36.9702V101.26H127.46V94.05Z" fill="#161434" />
                <path d="M104.29 73.02H97.0801V147.19H104.29V73.02Z" fill="#161434" />
                <path d="M67.3501 73.02H60.1401V147.19H67.3501V73.02Z" fill="#161434" />
            </SvgIcon>

        case "doc":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.68 49.25C134.213 49.2447 128.971 47.0704 125.105 43.2046C121.239 39.3387 119.065 34.097 119.06 28.6299V1.85854e-06H26.9298C23.3951 -0.00131167 19.8947 0.693646 16.6287 2.04541C13.3626 3.39718 10.395 5.37922 7.89514 7.87818C5.39526 10.3771 3.41218 13.3441 2.0592 16.6096C0.706225 19.8751 0.00976538 23.3752 0.00976562 26.9099V189.13C0.00976538 192.665 0.706225 196.165 2.0592 199.43C3.41218 202.696 5.39526 205.663 7.89514 208.162C10.395 210.661 13.3626 212.643 16.6287 213.994C19.8947 215.346 23.3951 216.041 26.9298 216.04H137.53C141.064 216.04 144.563 215.344 147.828 213.992C151.093 212.639 154.059 210.657 156.558 208.158C159.057 205.659 161.039 202.693 162.391 199.428C163.744 196.163 164.44 192.664 164.44 189.13V49.25H139.68Z" fill="#161434" />
                </g>
                <path d="M164.44 49.25H139.68C134.212 49.2447 128.971 47.0704 125.105 43.2046C121.239 39.3387 119.065 34.097 119.06 28.6299V0L164.44 49.25Z" fill="#161434" />
                <path d="M130.61 83.73H27.3699C26.378 83.73 25.4267 83.3359 24.7254 82.6345C24.024 81.9331 23.6299 80.9819 23.6299 79.99C23.6286 79.498 23.7244 79.0106 23.9118 78.5557C24.0991 78.1008 24.3744 77.6872 24.7218 77.3389C25.0692 76.9905 25.482 76.7142 25.9364 76.5256C26.3908 76.3371 26.8779 76.24 27.3699 76.24H130.61C131.605 76.24 132.558 76.6351 133.262 77.3384C133.965 78.0416 134.36 78.9954 134.36 79.99C134.357 80.9828 133.961 81.9342 133.258 82.6353C132.555 83.3364 131.603 83.73 130.61 83.73Z" fill="#161434" />
                <path d="M130.61 106.1H27.3699C26.378 106.1 25.4267 105.706 24.7254 105.005C24.024 104.303 23.6299 103.352 23.6299 102.36C23.6286 101.868 23.7244 101.381 23.9118 100.926C24.0991 100.471 24.3744 100.057 24.7218 99.709C25.0692 99.3606 25.482 99.0843 25.9364 98.8958C26.3908 98.7072 26.8779 98.6101 27.3699 98.6101H130.61C131.605 98.6101 132.558 99.005 133.262 99.7083C133.965 100.412 134.36 101.366 134.36 102.36C134.357 103.353 133.961 104.304 133.258 105.005C132.555 105.706 131.603 106.1 130.61 106.1Z" fill="#161434" />
                <path d="M130.61 128.47H27.3699C26.8779 128.47 26.3908 128.373 25.9364 128.184C25.482 127.996 25.0692 127.719 24.7218 127.371C24.3744 127.023 24.0991 126.609 23.9118 126.154C23.7244 125.699 23.6286 125.212 23.6299 124.72C23.6299 123.728 24.024 122.777 24.7254 122.075C25.4267 121.374 26.378 120.98 27.3699 120.98H130.61C131.603 120.98 132.555 121.374 133.258 122.075C133.961 122.776 134.357 123.727 134.36 124.72C134.36 125.715 133.965 126.668 133.262 127.372C132.558 128.075 131.605 128.47 130.61 128.47Z" fill="#161434" />
                <path d="M89.8899 150.84H27.3699C26.8779 150.84 26.3908 150.743 25.9364 150.554C25.482 150.366 25.0692 150.089 24.7218 149.741C24.3744 149.393 24.0991 148.979 23.9118 148.524C23.7244 148.07 23.6286 147.582 23.6299 147.09C23.6299 146.098 24.024 145.147 24.7254 144.445C25.4267 143.744 26.378 143.35 27.3699 143.35H89.8899C90.8818 143.35 91.8332 143.744 92.5346 144.445C93.2359 145.147 93.6299 146.098 93.6299 147.09C93.6312 147.582 93.5355 148.07 93.3482 148.524C93.1608 148.979 92.8855 149.393 92.5381 149.741C92.1907 150.089 91.7779 150.366 91.3235 150.554C90.8691 150.743 90.3819 150.84 89.8899 150.84Z" fill="#161434" />
                <path d="M183.96 170.61H58.9198C50.0501 170.61 42.8599 177.8 42.8599 186.67V223.95C42.8599 232.82 50.0501 240.01 58.9198 240.01H183.96C192.83 240.01 200.02 232.82 200.02 223.95V186.67C200.02 177.8 192.83 170.61 183.96 170.61Z" fill="#161434" />
                <path d="M76.2297 213.38V220.65H68.6997V213.38H76.2297Z" fill="white" />
                <path d="M105.17 213.4C103.918 215.674 102.026 217.53 99.7299 218.74C97.1453 220.062 94.2719 220.718 91.3698 220.65H80.0098V190.58H91.3698C94.274 190.508 97.1515 191.15 99.7498 192.45C102.037 193.638 103.923 195.474 105.17 197.73C106.463 200.13 107.114 202.824 107.06 205.55C107.111 208.285 106.461 210.988 105.17 213.4ZM97.3499 211.89C98.1628 211.056 98.7925 210.061 99.1989 208.969C99.6052 207.877 99.7793 206.713 99.7098 205.55C99.7798 204.389 99.6059 203.225 99.1995 202.135C98.793 201.045 98.163 200.052 97.3499 199.22C95.5279 197.623 93.1483 196.81 90.7299 196.96H87.2998V214.14H90.7397C93.1528 214.286 95.5267 213.478 97.3499 211.89Z" fill="white" />
                <path d="M133.2 192.09C135.488 193.389 137.379 195.287 138.67 197.58C140.022 200.001 140.712 202.737 140.67 205.51C140.715 208.29 140.025 211.033 138.67 213.46C137.374 215.761 135.476 217.666 133.18 218.97C130.842 220.305 128.191 220.995 125.5 220.97C122.808 220.994 120.158 220.304 117.82 218.97C115.523 217.669 113.628 215.763 112.34 213.46C110.979 211.034 110.289 208.29 110.34 205.51C110.288 202.736 110.978 199.998 112.34 197.58C113.635 195.287 115.529 193.389 117.82 192.09C120.158 190.756 122.808 190.066 125.5 190.09C128.198 190.065 130.855 190.755 133.2 192.09ZM119.8 199.19C118.324 200.955 117.574 203.213 117.7 205.51C117.574 207.797 118.325 210.047 119.8 211.8C120.518 212.581 121.399 213.195 122.38 213.599C123.362 214.004 124.419 214.188 125.48 214.14C126.537 214.185 127.59 213.999 128.568 213.595C129.546 213.191 130.423 212.578 131.14 211.8C132.622 210.05 133.38 207.8 133.26 205.51C133.38 203.223 132.629 200.976 131.16 199.22C130.441 198.438 129.561 197.824 128.579 197.42C127.598 197.015 126.54 196.831 125.48 196.88C124.422 196.831 123.367 197.013 122.386 197.412C121.405 197.81 120.523 198.417 119.8 199.19Z" fill="white" />
                <path d="M167.99 193.3C170.526 195.364 172.251 198.257 172.86 201.47H165.16C164.64 200.139 163.731 198.995 162.55 198.19C161.305 197.38 159.845 196.965 158.36 197C157.38 196.969 156.405 197.163 155.512 197.569C154.619 197.975 153.831 198.581 153.21 199.34C151.818 201.121 151.108 203.342 151.21 205.6C151.108 207.849 151.818 210.06 153.21 211.83C153.836 212.581 154.626 213.18 155.518 213.58C156.41 213.981 157.383 214.172 158.36 214.14C159.845 214.18 161.307 213.764 162.55 212.95C163.724 212.159 164.634 211.034 165.16 209.72H172.86C172.241 212.923 170.518 215.808 167.99 217.87C165.27 219.947 161.91 221.008 158.49 220.87C155.796 220.927 153.136 220.257 150.79 218.93C148.595 217.654 146.814 215.772 145.66 213.51C144.42 211.029 143.803 208.283 143.86 205.51C143.804 202.737 144.421 199.992 145.66 197.51C146.814 195.248 148.595 193.366 150.79 192.09C153.136 190.763 155.796 190.093 158.49 190.15C161.927 190.059 165.288 191.173 167.99 193.3Z" fill="white" />
            </SvgIcon>

        case "docx":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.25C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V1.85854e-06H26.92C23.3853 -0.00131167 19.8849 0.693646 16.6189 2.04541C13.3529 3.39718 10.3851 5.37922 7.88525 7.87818C5.38537 10.3771 3.4023 13.3441 2.04932 16.6096C0.696337 19.8751 -2.4406e-07 23.3752 0 26.9099V189.13C-2.4406e-07 192.665 0.696337 196.165 2.04932 199.43C3.4023 202.696 5.38537 205.663 7.88525 208.162C10.3851 210.661 13.3529 212.643 16.6189 213.994C19.8849 215.346 23.3853 216.041 26.92 216.04H137.52C144.657 216.04 151.502 213.205 156.548 208.158C161.595 203.112 164.43 196.267 164.43 189.13V49.25H139.67Z" fill="#161434" />
                </g>
                <path d="M164.43 49.25H139.67C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0L164.43 49.25Z" fill="#161434" />
                <path d="M130.6 83.73H27.3601C26.3682 83.73 25.417 83.3359 24.7156 82.6345C24.0142 81.9331 23.6201 80.9819 23.6201 79.99C23.6188 79.498 23.7146 79.0106 23.902 78.5557C24.0894 78.1008 24.3646 77.6872 24.712 77.3389C25.0595 76.9905 25.4721 76.7142 25.9265 76.5256C26.3809 76.3371 26.8681 76.24 27.3601 76.24H130.6C131.595 76.24 132.548 76.6351 133.252 77.3384C133.955 78.0416 134.35 78.9954 134.35 79.99C134.347 80.9828 133.951 81.9342 133.248 82.6353C132.545 83.3364 131.593 83.73 130.6 83.73Z" fill="#161434" />
                <path d="M130.6 106.1H27.3601C26.3682 106.1 25.417 105.706 24.7156 105.005C24.0142 104.303 23.6201 103.352 23.6201 102.36C23.6188 101.868 23.7146 101.381 23.902 100.926C24.0894 100.471 24.3646 100.057 24.712 99.709C25.0595 99.3606 25.4721 99.0843 25.9265 98.8958C26.3809 98.7072 26.8681 98.6101 27.3601 98.6101H130.6C131.595 98.6101 132.548 99.005 133.252 99.7083C133.955 100.412 134.35 101.366 134.35 102.36C134.347 103.353 133.951 104.304 133.248 105.005C132.545 105.706 131.593 106.1 130.6 106.1Z" fill="#161434" />
                <path d="M130.6 128.47H27.3601C26.8681 128.47 26.3809 128.373 25.9265 128.184C25.4721 127.996 25.0595 127.719 24.712 127.371C24.3646 127.023 24.0894 126.609 23.902 126.154C23.7146 125.699 23.6188 125.212 23.6201 124.72C23.6201 123.728 24.0142 122.777 24.7156 122.075C25.417 121.374 26.3682 120.98 27.3601 120.98H130.6C131.593 120.98 132.545 121.374 133.248 122.075C133.951 122.776 134.347 123.727 134.35 124.72C134.35 125.715 133.955 126.668 133.252 127.372C132.548 128.075 131.595 128.47 130.6 128.47Z" fill="#161434" />
                <path d="M89.8601 150.84H27.3601C26.8681 150.84 26.3809 150.743 25.9265 150.554C25.4721 150.366 25.0595 150.089 24.712 149.741C24.3646 149.393 24.0894 148.979 23.902 148.524C23.7146 148.07 23.6188 147.582 23.6201 147.09C23.6201 146.098 24.0142 145.147 24.7156 144.445C25.417 143.744 26.3682 143.35 27.3601 143.35H89.8601C90.852 143.35 91.8033 143.744 92.5047 144.445C93.206 145.147 93.6001 146.098 93.6001 147.09C93.6014 147.582 93.5056 148.07 93.3183 148.524C93.1309 148.979 92.8556 149.393 92.5082 149.741C92.1608 150.089 91.7481 150.366 91.2937 150.554C90.8393 150.743 90.3521 150.84 89.8601 150.84Z" fill="#161434" />
                <path d="M183.95 170.61H58.9101C50.0404 170.61 42.8501 177.8 42.8501 186.67V223.95C42.8501 232.82 50.0404 240.01 58.9101 240.01H183.95C192.82 240.01 200.01 232.82 200.01 223.95V186.67C200.01 177.8 192.82 170.61 183.95 170.61Z" fill="#161434" />
                <path d="M61.1798 213.38V220.65H53.6499V213.38H61.1798Z" fill="white" />
                <path d="M90.12 213.4C88.8651 215.671 86.9744 217.527 84.6799 218.74C82.0954 220.062 79.2221 220.718 76.3199 220.65H64.96V190.58H76.3199C79.2241 190.509 82.1013 191.151 84.7 192.45C86.9874 193.637 88.873 195.474 90.12 197.73C91.4129 200.13 92.064 202.824 92.01 205.55C92.0615 208.285 91.4107 210.988 90.12 213.4ZM82.2999 211.89C83.1128 211.055 83.7427 210.061 84.149 208.969C84.5554 207.877 84.7295 206.713 84.66 205.55C84.7299 204.389 84.5561 203.225 84.1497 202.135C83.7432 201.045 83.1131 200.052 82.2999 199.22C80.478 197.622 78.0984 196.81 75.6799 196.96H72.24V214.14H75.6799C78.0964 214.288 80.4743 213.48 82.2999 211.89Z" fill="white" />
                <path d="M118.15 192.09C120.438 193.389 122.329 195.287 123.62 197.58C124.973 200.001 125.663 202.737 125.62 205.51C125.671 208.29 124.98 211.034 123.62 213.46C122.332 215.763 120.436 217.669 118.14 218.97C115.802 220.305 113.152 220.995 110.46 220.97C107.768 220.995 105.118 220.305 102.78 218.97C100.484 217.669 98.5885 215.763 97.3001 213.46C95.9398 211.034 95.2495 208.29 95.3001 205.51C95.2482 202.736 95.9387 199.998 97.3001 197.58C98.5951 195.287 100.489 193.389 102.78 192.09C105.118 190.755 107.768 190.065 110.46 190.09C113.155 190.066 115.808 190.756 118.15 192.09ZM104.75 199.19C103.275 200.955 102.524 203.213 102.65 205.51C102.524 207.797 103.275 210.047 104.75 211.8C105.469 212.581 106.35 213.195 107.331 213.599C108.312 214.004 109.37 214.188 110.43 214.14C111.487 214.184 112.54 213.997 113.518 213.593C114.495 213.189 115.373 212.577 116.09 211.8C117.572 210.05 118.331 207.8 118.21 205.51C118.33 203.223 117.58 200.976 116.11 199.22C115.392 198.438 114.511 197.824 113.53 197.42C112.548 197.015 111.49 196.831 110.43 196.88C109.373 196.831 108.317 197.013 107.337 197.412C106.356 197.81 105.474 198.417 104.75 199.19Z" fill="white" />
                <path d="M152.94 193.3C155.476 195.364 157.201 198.257 157.81 201.47H150.11C149.591 200.139 148.681 198.995 147.5 198.19C146.256 197.38 144.795 196.965 143.31 197C142.33 196.969 141.355 197.163 140.462 197.569C139.569 197.975 138.781 198.581 138.16 199.34C136.768 201.122 136.059 203.342 136.16 205.6C136.058 207.849 136.768 210.06 138.16 211.83C138.786 212.581 139.576 213.18 140.468 213.58C141.361 213.981 142.333 214.172 143.31 214.14C144.795 214.18 146.257 213.764 147.5 212.95C148.674 212.159 149.584 211.034 150.11 209.72H157.81C157.191 212.923 155.468 215.808 152.94 217.87C150.22 219.947 146.86 221.008 143.44 220.87C140.746 220.927 138.086 220.257 135.74 218.93C133.545 217.654 131.764 215.772 130.61 213.51C129.367 211.029 128.746 208.284 128.8 205.51C128.747 202.736 129.368 199.991 130.61 197.51C131.764 195.248 133.545 193.366 135.74 192.09C138.086 190.763 140.746 190.093 143.44 190.15C146.877 190.059 150.238 191.173 152.94 193.3Z" fill="white" />
                <path d="M180.14 220.65L173.8 211.29L168.36 220.65H160.02L169.64 205.17L159.73 190.58H168.36L174.53 199.64L179.84 190.58H188.14L178.65 205.72L188.77 220.65H180.14Z" fill="white" />
            </SvgIcon>

        case "pdf":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.25C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0H26.91C19.773 0 12.9284 2.83523 7.88177 7.88184C2.83517 12.9284 0 19.7729 0 26.9099V189.13C0.0105984 196.26 2.85042 203.094 7.89587 208.132C12.9413 213.17 19.7799 216 26.91 216H137.52C144.65 216 151.489 213.17 156.534 208.132C161.58 203.094 164.419 196.26 164.43 189.13V49.25H139.67Z" fill="#161434" />
                </g>
                <path d="M164.43 49.25H139.67C134.203 49.2447 128.961 47.0704 125.095 43.2046C121.229 39.3387 119.055 34.097 119.05 28.6299V0L164.43 49.25Z" fill="#161434" />
                <path d="M130.6 150.84H27.3599C26.3653 150.84 25.4115 150.445 24.7082 149.742C24.0049 149.038 23.6099 148.085 23.6099 147.09C23.6125 146.097 24.0088 145.146 24.7117 144.445C25.4147 143.744 26.367 143.35 27.3599 143.35H130.6C131.592 143.35 132.543 143.744 133.244 144.445C133.946 145.147 134.34 146.098 134.34 147.09C134.341 147.582 134.245 148.07 134.058 148.524C133.871 148.979 133.595 149.393 133.248 149.741C132.901 150.089 132.488 150.366 132.033 150.554C131.579 150.743 131.092 150.84 130.6 150.84Z" fill="#161434" />
                <path d="M130.6 108.6H27.3599C26.367 108.6 25.4147 108.206 24.7117 107.505C24.0088 106.804 23.6125 105.853 23.6099 104.86C23.6099 103.866 24.0049 102.912 24.7082 102.208C25.4115 101.505 26.3653 101.11 27.3599 101.11H130.6C131.092 101.11 131.579 101.207 132.033 101.396C132.488 101.584 132.901 101.861 133.248 102.209C133.595 102.557 133.871 102.971 134.058 103.426C134.245 103.881 134.341 104.368 134.34 104.86C134.34 105.852 133.946 106.803 133.244 107.505C132.543 108.206 131.592 108.6 130.6 108.6Z" fill="#161434" />
                <path d="M130.6 129.72H27.3599C26.3653 129.72 25.4115 129.325 24.7082 128.622C24.0049 127.918 23.6099 126.965 23.6099 125.97C23.6125 124.977 24.0088 124.026 24.7117 123.325C25.4147 122.624 26.367 122.23 27.3599 122.23H130.6C131.592 122.23 132.543 122.624 133.244 123.325C133.946 124.027 134.34 124.978 134.34 125.97C134.341 126.462 134.245 126.949 134.058 127.404C133.871 127.859 133.595 128.273 133.248 128.621C132.901 128.969 132.488 129.246 132.033 129.434C131.579 129.623 131.092 129.72 130.6 129.72Z" fill="#161434" />
                <path d="M89.8799 87.48H27.3599C26.367 87.48 25.4147 87.0864 24.7117 86.3853C24.0088 85.6842 23.6125 84.7328 23.6099 83.74C23.6099 82.7454 24.0049 81.7916 24.7082 81.0884C25.4115 80.3851 26.3653 79.99 27.3599 79.99H89.8799C90.8727 79.9926 91.824 80.3888 92.5251 81.0918C93.2262 81.7948 93.6199 82.7472 93.6199 83.74C93.6172 84.7311 93.2224 85.6808 92.5215 86.3816C91.8207 87.0824 90.871 87.4773 89.8799 87.48Z" fill="#161434" />
                <path d="M89.8799 66.3601H27.3599C26.367 66.3601 25.4147 65.9662 24.7117 65.2651C24.0088 64.564 23.6125 63.6129 23.6099 62.6201C23.6099 61.6256 24.0049 60.6715 24.7082 59.9683C25.4115 59.265 26.3653 58.8701 27.3599 58.8701H89.8799C90.8727 58.8728 91.824 59.2689 92.5251 59.9719C93.2262 60.6749 93.6199 61.6273 93.6199 62.6201C93.6172 63.6112 93.2224 64.5609 92.5215 65.2617C91.8207 65.9625 90.871 66.3575 89.8799 66.3601Z" fill="#161434" />
                <path d="M183.94 170.61H58.8998C50.0301 170.61 42.8398 177.8 42.8398 186.67V223.95C42.8398 232.82 50.0301 240.01 58.8998 240.01H183.94C192.81 240.01 200 232.82 200 223.95V186.67C200 177.8 192.81 170.61 183.94 170.61Z" fill="#161434" />
                <path d="M83.8599 213.38V220.65H76.3398V213.38H83.8599Z" fill="white" />
                <path d="M94.9199 210.1V220.65H87.6499V190.58H99.4299C103 190.58 105.73 191.47 107.6 193.24C108.546 194.17 109.285 195.289 109.767 196.525C110.25 197.761 110.466 199.085 110.4 200.41C110.436 202.158 110.005 203.884 109.15 205.41C108.277 206.901 106.983 208.102 105.43 208.86C103.557 209.745 101.501 210.174 99.4299 210.11L94.9199 210.1ZM103 200.41C103 197.743 101.54 196.41 98.6199 196.41H94.9199V204.24H98.6199C101.54 204.267 103 202.99 103 200.41Z" fill="white" />
                <path d="M139.13 213.4C137.878 215.674 135.987 217.53 133.69 218.74C131.106 220.063 128.232 220.719 125.33 220.65H113.98V190.58H125.33C128.234 190.507 131.112 191.149 133.71 192.45C136 193.634 137.887 195.471 139.13 197.73C140.431 200.128 141.086 202.823 141.03 205.55C141.084 208.287 140.43 210.991 139.13 213.4ZM131.31 211.89C132.123 211.056 132.753 210.061 133.159 208.969C133.565 207.877 133.739 206.713 133.67 205.55C133.74 204.389 133.566 203.225 133.16 202.135C132.753 201.045 132.123 200.052 131.31 199.22C129.491 197.624 127.115 196.812 124.7 196.96H121.25V214.14H124.7C127.113 214.287 129.487 213.478 131.31 211.89Z" fill="white" />
                <path d="M164.76 190.58V196.37H152.43V202.96H161.95V208.49H152.43V220.65H145.15V190.58H164.76Z" fill="white" />
                <path d="M128.77 58.8701H111.09C108.008 58.8701 105.51 61.3684 105.51 64.4501V82.1301C105.51 85.2119 108.008 87.7101 111.09 87.7101H128.77C131.852 87.7101 134.35 85.2119 134.35 82.1301V64.4501C134.35 61.3684 131.852 58.8701 128.77 58.8701Z" fill="#161434" />
            </SvgIcon>

        case "pptx":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.26C134.203 49.2547 128.961 47.0805 125.095 43.2146C121.229 39.3487 119.055 34.107 119.05 28.6399V0.0100098H26.9099C19.7729 0.0100098 12.9284 2.84524 7.88184 7.89185C2.83523 12.9384 0 19.7829 0 26.9199V189.14C0 196.277 2.83523 203.122 7.88184 208.168C12.9284 213.215 19.7729 216.05 26.9099 216.05H137.51C141.045 216.051 144.545 215.356 147.811 214.004C151.077 212.653 154.045 210.671 156.545 208.172C159.045 205.673 161.028 202.706 162.381 199.44C163.734 196.175 164.43 192.675 164.43 189.14V49.26H139.67Z" fill="#161434" />
                </g>
                <path d="M164.43 49.26H139.67C134.203 49.2547 128.961 47.0805 125.095 43.2146C121.229 39.3487 119.055 34.107 119.05 28.6399V0.0100098L164.43 49.26Z" fill="#161434" />
                <path d="M183.94 170.62H58.8997C50.03 170.62 42.8398 177.81 42.8398 186.68V223.96C42.8398 232.83 50.03 240.02 58.8997 240.02H183.94C192.809 240.02 200 232.83 200 223.96V186.68C200 177.81 192.809 170.62 183.94 170.62Z" fill="#161434" />
                <path d="M86.8 213.39V220.66H79.27V213.39H86.8Z" fill="white" />
                <path d="M97.8599 210.11V220.66H90.5898V190.59H102.37C105.943 190.59 108.663 191.477 110.53 193.25C111.477 194.179 112.218 195.299 112.702 196.534C113.186 197.77 113.404 199.094 113.34 200.42C113.371 202.168 112.94 203.893 112.09 205.42C111.216 206.914 109.917 208.115 108.36 208.87C106.488 209.759 104.431 210.188 102.36 210.12L97.8599 210.11ZM105.94 200.42C105.94 197.753 104.48 196.42 101.56 196.42H97.8599V204.25H101.56C104.48 204.277 105.94 203 105.94 200.42Z" fill="white" />
                <path d="M124.19 210.11V220.66H116.91V190.59H128.69C132.27 190.59 134.994 191.477 136.86 193.25C137.808 194.179 138.548 195.299 139.032 196.534C139.517 197.77 139.734 199.094 139.67 200.42C139.703 202.169 139.268 203.895 138.41 205.42C137.542 206.916 136.247 208.117 134.69 208.87C132.818 209.759 130.761 210.188 128.69 210.12L124.19 210.11ZM132.27 200.42C132.27 197.753 130.81 196.42 127.89 196.42H124.19V204.25H127.89C130.81 204.277 132.27 203 132.27 200.42Z" fill="white" />
                <path d="M164.85 190.59V196.38H156.68V220.66H149.37V196.38H141.28V190.59H164.85Z" fill="white" />
                <path d="M74.6899 83.74H31C30.0072 83.74 29.0548 83.3464 28.3518 82.6453C27.6488 81.9442 27.2526 80.9928 27.25 80C27.25 79.0054 27.6451 78.0517 28.3484 77.3484C29.0517 76.6451 30.0054 76.25 31 76.25H74.6499C75.6445 76.25 76.5985 76.6451 77.3018 77.3484C78.005 78.0517 78.3999 79.0054 78.3999 80C78.3973 80.9859 78.0066 81.9311 77.3123 82.6311C76.6179 83.3311 75.6758 83.7295 74.6899 83.74Z" fill="#161434" />
                <path d="M74.6899 106.11H31C30.0072 106.11 29.0548 105.716 28.3518 105.015C27.6488 104.314 27.2526 103.363 27.25 102.37C27.25 101.376 27.6451 100.422 28.3484 99.7183C29.0517 99.015 30.0054 98.6201 31 98.6201H74.6499C75.6445 98.6201 76.5985 99.015 77.3018 99.7183C78.005 100.422 78.3999 101.376 78.3999 102.37C78.3973 103.356 78.0066 104.301 77.3123 105.001C76.6179 105.701 75.6758 106.1 74.6899 106.11Z" fill="#161434" />
                <path d="M74.6899 128.48H31C30.0054 128.48 29.0517 128.085 28.3484 127.382C27.6451 126.678 27.25 125.725 27.25 124.73C27.2579 123.741 27.6565 122.794 28.3589 122.098C29.0613 121.401 30.0106 121.01 31 121.01H74.6499C75.6427 121.01 76.5951 121.404 77.2981 122.105C78.0011 122.806 78.3973 123.757 78.3999 124.75C78.3947 125.734 78.0029 126.677 77.3088 127.375C76.6148 128.073 75.6741 128.469 74.6899 128.48Z" fill="#161434" />
                <path d="M74.6899 150.85H31C30.0054 150.85 29.0517 150.455 28.3484 149.752C27.6451 149.048 27.25 148.095 27.25 147.1C27.2526 146.107 27.6488 145.156 28.3518 144.455C29.0548 143.754 30.0072 143.36 31 143.36H74.6499C75.6427 143.36 76.5951 143.754 77.2981 144.455C78.0011 145.156 78.3973 146.107 78.3999 147.1C78.4 148.088 78.0106 149.035 77.3159 149.738C76.6213 150.44 75.6776 150.84 74.6899 150.85Z" fill="#161434" />
                <path d="M117.25 118.01V84.77C109.152 85.1976 101.527 88.7153 95.9451 94.5981C90.3636 100.481 87.2515 108.281 87.25 116.39C87.2487 120.548 88.0666 124.666 89.6572 128.507C91.2478 132.349 93.5799 135.84 96.52 138.78C99.4602 141.72 102.951 144.052 106.793 145.643C110.634 147.233 114.752 148.051 118.91 148.05C127.019 148.051 134.819 144.939 140.701 139.357C146.582 133.775 150.097 126.148 150.52 118.05L117.25 118.01Z" fill="#161434" />
                <path d="M123.31 82.24V112.24H153.31C152.909 104.413 149.62 97.0133 144.078 91.4717C138.537 85.9301 131.137 82.6407 123.31 82.24Z" fill="#161434" />
            </SvgIcon>

        case "xls":
        case "xlsx":
        case "xsl":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.69 49.25C134.223 49.2447 128.981 47.0704 125.115 43.2046C121.249 39.3387 119.075 34.097 119.07 28.6299V0H26.9297C19.7927 0 12.948 2.83523 7.90137 7.88184C2.85476 12.9284 0.0195312 19.7729 0.0195312 26.9099V189.13C0.0195312 196.267 2.85476 203.112 7.90137 208.158C12.948 213.205 19.7927 216.04 26.9297 216.04H137.53C144.667 216.04 151.511 213.205 156.558 208.158C161.604 203.112 164.44 196.267 164.44 189.13V49.25H139.69Z" fill="#161434" />
                </g>
                <path d="M164.439 49.25H139.689C134.222 49.2447 128.981 47.0704 125.115 43.2046C121.249 39.3387 119.075 34.097 119.069 28.6299V0L164.439 49.25Z" fill="#161434" />
                <path d="M183.959 170.61H58.9194C50.0497 170.61 42.8594 177.8 42.8594 186.67V223.95C42.8594 232.82 50.0497 240.01 58.9194 240.01H183.959C192.829 240.01 200.019 232.82 200.019 223.95V186.67C200.019 177.8 192.829 170.61 183.959 170.61Z" fill="#161434" />
                <path d="M87.45 213.38V220.65H79.9199V213.38H87.45Z" fill="white" />
                <path d="M109.479 220.65L103.149 211.29L97.6995 220.65H89.3694L98.9792 205.17L89.0693 190.58H97.6995L103.869 199.64L109.189 190.58H117.479L107.999 205.72L118.119 220.65H109.479Z" fill="white" />
                <path d="M141.32 216.57C140.457 217.951 139.219 219.057 137.75 219.76C135.962 220.597 134.003 221.005 132.03 220.95C129.053 221.075 126.119 220.206 123.69 218.48C122.616 217.673 121.737 216.635 121.119 215.443C120.501 214.25 120.159 212.933 120.12 211.59H127.86C127.915 212.603 128.347 213.559 129.07 214.27C129.79 214.941 130.746 215.301 131.73 215.27C132.131 215.293 132.533 215.238 132.912 215.105C133.291 214.973 133.641 214.767 133.94 214.5C134.207 214.241 134.417 213.93 134.556 213.585C134.696 213.241 134.762 212.871 134.75 212.5C134.76 212.147 134.699 211.797 134.57 211.468C134.441 211.14 134.247 210.842 134 210.59C133.471 210.054 132.843 209.626 132.15 209.33C131.424 209.01 130.403 208.62 129.09 208.16C127.496 207.634 125.94 207.003 124.43 206.27C123.19 205.631 122.125 204.697 121.33 203.55C120.408 202.162 119.952 200.515 120.03 198.85C119.994 197.2 120.469 195.579 121.39 194.21C122.336 192.872 123.644 191.831 125.16 191.21C126.908 190.499 128.784 190.159 130.67 190.21C133.534 190.052 136.363 190.897 138.67 192.6C139.652 193.399 140.457 194.394 141.032 195.522C141.607 196.65 141.94 197.886 142.01 199.15H134.14C134.052 198.265 133.672 197.435 133.06 196.79C132.747 196.49 132.377 196.258 131.971 196.106C131.565 195.955 131.133 195.888 130.7 195.91C129.95 195.876 129.214 196.118 128.63 196.59C128.361 196.845 128.151 197.157 128.016 197.503C127.882 197.848 127.825 198.22 127.85 198.59C127.835 199.262 128.086 199.913 128.55 200.4C129.048 200.919 129.647 201.332 130.31 201.61C131.02 201.92 132.04 202.32 133.37 202.8C134.993 203.329 136.574 203.977 138.1 204.74C139.347 205.412 140.421 206.364 141.24 207.52C142.173 208.93 142.635 210.6 142.56 212.29C142.547 213.803 142.118 215.284 141.32 216.57Z" fill="white" />
                <path d="M153.85 215.08H163.59V220.65H146.59V190.58H153.87L153.85 215.08Z" fill="white" />
                <path d="M118.909 150.84H45.5894C39.2394 150.84 34.0693 144.52 34.0693 136.74V83.55C34.0693 75.78 39.2394 69.46 45.5894 69.46H118.939C125.279 69.46 130.449 75.78 130.449 83.55V136.74C130.419 144.52 125.249 150.84 118.909 150.84ZM45.5894 76.6599C42.4794 76.6599 39.9595 79.75 39.9595 83.55V136.74C39.9595 140.54 42.4794 143.63 45.5894 143.63H118.939C122.039 143.63 124.559 140.54 124.559 136.74V83.55C124.559 79.75 122.039 76.6599 118.939 76.6599H45.5894Z" fill="#161434" />
                <path d="M127.479 118.81H36.9893V126.02H127.479V118.81Z" fill="#161434" />
                <path d="M127.479 94.0901H36.9893V101.3H127.479V94.0901Z" fill="#161434" />
                <path d="M104.3 73.0601H97.0898V147.23H104.3V73.0601Z" fill="#161434" />
                <path d="M67.3701 73.0601H60.1602V147.23H67.3701V73.0601Z" fill="#161434" />
            </SvgIcon>

        case "png":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.27C134.203 49.2647 128.961 47.0906 125.095 43.2247C121.229 39.3589 119.055 34.1172 119.05 28.65V0.0300293H26.91C19.773 0.0300293 12.9284 2.8652 7.88177 7.9118C2.83517 12.9584 0 19.803 0 26.94V189.16C0.0105984 196.29 2.85042 203.124 7.89587 208.162C12.9413 213.2 19.7799 216.03 26.91 216.03H137.52C144.652 216.03 151.492 213.199 156.538 208.159C161.583 203.119 164.422 196.282 164.43 189.15V49.27H139.67Z" fill="#161434" />
                </g>
                <path d="M164.43 49.27H139.67C134.203 49.2647 128.961 47.0906 125.095 43.2247C121.229 39.3589 119.055 34.1172 119.05 28.65V0.0300293L164.43 49.27Z" fill="#161434" />
                <path d="M183.94 170.63H58.8998C50.0301 170.63 42.8398 177.82 42.8398 186.69V223.97C42.8398 232.84 50.0301 240.03 58.8998 240.03H183.94C192.81 240.03 200 232.84 200 223.97V186.69C200 177.82 192.81 170.63 183.94 170.63Z" fill="#161434" />
                <path d="M79.0598 213.4V220.67H71.5298V213.4H79.0598Z" fill="white" />
                <path d="M90.1199 210.13V220.67H82.8398V190.6H94.6199C98.1999 190.6 100.923 191.487 102.79 193.26C103.737 194.189 104.478 195.309 104.962 196.544C105.446 197.78 105.664 199.104 105.6 200.43C105.631 202.179 105.196 203.905 104.34 205.43C103.471 206.923 102.176 208.121 100.62 208.87C98.7489 209.762 96.6917 210.194 94.6199 210.13H90.1199ZM98.1999 200.43C98.1999 197.763 96.7399 196.43 93.8199 196.43H90.1199V204.26H93.8199C96.7399 204.287 98.1999 203.01 98.1999 200.43Z" fill="white" />
                <path d="M135.88 220.67H128.61L116.43 202.21V220.67H109.16V190.6H116.43L128.6 209.23V190.6H135.87L135.88 220.67Z" fill="white" />
                <path d="M164.29 193.03C166.716 194.846 168.347 197.53 168.84 200.52H161.14C160.635 199.449 159.824 198.551 158.81 197.94C157.664 197.261 156.352 196.914 155.02 196.94C153.985 196.897 152.954 197.082 151.998 197.481C151.043 197.88 150.187 198.484 149.49 199.25C148.029 201.006 147.286 203.249 147.41 205.53C147.41 208.39 148.14 210.587 149.6 212.12C150.421 212.917 151.399 213.533 152.472 213.929C153.545 214.325 154.689 214.492 155.83 214.42C157.461 214.448 159.058 213.946 160.38 212.99C161.727 211.982 162.698 210.553 163.14 208.93H153.96V203.7H169.23V211.03C168.639 212.819 167.712 214.478 166.5 215.92C165.191 217.472 163.56 218.721 161.72 219.58C159.634 220.543 157.357 221.022 155.06 220.98C152.269 221.051 149.509 220.381 147.06 219.04C144.819 217.784 142.994 215.9 141.81 213.62C140.542 211.165 139.907 208.433 139.96 205.67C139.9 202.891 140.536 200.141 141.81 197.67C142.993 195.396 144.809 193.513 147.04 192.25C149.453 190.921 152.176 190.255 154.93 190.32C158.265 190.157 161.558 191.11 164.29 193.03Z" fill="white" />
                <path d="M54.7002 66.75H40.9502V80.5H54.7002V66.75Z" fill="#161434" />
                <path d="M68.46 80.5H54.71V94.25H68.46V80.5Z" fill="#161434" />
                <path d="M82.21 66.75H68.46V80.5H82.21V66.75Z" fill="#161434" />
                <path d="M95.96 80.5H82.21V94.25H95.96V80.5Z" fill="#161434" />
                <path d="M109.73 66.75H95.9805V80.5H109.73V66.75Z" fill="#161434" />
                <path d="M123.48 80.5H109.73V94.25H123.48V80.5Z" fill="#161434" />
                <path d="M54.7002 94.23H40.9502V107.98H54.7002V94.23Z" fill="#161434" />
                <path d="M68.46 107.98H54.71V121.73H68.46V107.98Z" fill="#161434" />
                <path d="M82.21 94.23H68.46V107.98H82.21V94.23Z" fill="#161434" />
                <path d="M95.96 107.98H82.21V121.73H95.96V107.98Z" fill="#161434" />
                <path d="M109.73 94.23H95.9805V107.98H109.73V94.23Z" fill="#161434" />
                <path d="M123.48 107.98H109.73V121.73H123.48V107.98Z" fill="#161434" />
                <path d="M54.7002 121.84H40.9502V135.59H54.7002V121.84Z" fill="#161434" />
                <path d="M68.46 135.59H54.71V149.34H68.46V135.59Z" fill="#161434" />
                <path d="M82.21 121.84H68.46V135.59H82.21V121.84Z" fill="#161434" />
                <path d="M95.96 135.59H82.21V149.34H95.96V135.59Z" fill="#161434" />
                <path d="M109.73 121.84H95.9805V135.59H109.73V121.84Z" fill="#161434" />
                <path d="M123.48 135.59H109.73V149.34H123.48V135.59Z" fill="#161434" />
            </SvgIcon>

        case "jpg":
        case "jpeg":
            return <SvgIcon sx={{ width, height }} viewBox='0 0 200 240' fill="none" {...props}>
                <g opacity="0.3">
                    <path opacity="0.3" d="M139.67 49.27C134.202 49.2674 128.959 47.0941 125.093 43.2277C121.226 39.3612 119.053 34.118 119.05 28.65V0.0300293H26.9102C23.3754 0.0300291 19.8754 0.726366 16.6099 2.07935C13.3443 3.43233 10.3771 5.4154 7.87818 7.91528C5.37922 10.4152 3.39742 13.3829 2.04566 16.6489C0.69389 19.915 -0.00131167 23.4154 1.85789e-06 26.9501V189.16C-0.00131167 192.695 0.69389 196.195 2.04566 199.461C3.39742 202.727 5.37922 205.695 7.87818 208.195C10.3771 210.695 13.3443 212.678 16.6099 214.031C19.8754 215.384 23.3754 216.08 26.9102 216.08H137.51C141.045 216.08 144.545 215.384 147.81 214.031C151.076 212.678 154.043 210.695 156.542 208.195C159.041 205.695 161.023 202.727 162.375 199.461C163.726 196.195 164.421 192.695 164.42 189.16V49.28L139.67 49.27Z" fill="#161434" />
                </g>
                <path d="M164.42 49.27H139.67C134.202 49.2674 128.959 47.0941 125.092 43.2277C121.226 39.3612 119.052 34.118 119.05 28.65V0.0300293L164.42 49.27Z" fill="#161434" />
                <path d="M183.94 170.63H58.8999C50.0302 170.63 42.8398 177.82 42.8398 186.69V223.97C42.8398 232.84 50.0302 240.03 58.8999 240.03H183.94C192.81 240.03 200 232.84 200 223.97V186.69C200 177.82 192.81 170.63 183.94 170.63Z" fill="#161434" />
                <path d="M84.2202 213.4V220.68H76.6904V213.4H84.2202Z" fill="white" />
                <path d="M106.57 190.61V211.15C106.57 214.303 105.714 216.727 104 218.42C103.044 219.302 101.92 219.982 100.695 220.42C99.4699 220.858 98.1691 221.045 96.8704 220.97C95.4479 221.046 94.0244 220.84 92.6821 220.363C91.3399 219.886 90.1054 219.147 89.0503 218.19C87.1236 216.33 86.1571 213.663 86.1504 210.19H93.3403C93.3403 213 94.4103 214.4 96.5303 214.4C98.3803 214.4 99.3003 213.32 99.3003 211.17V190.63L106.57 190.61Z" fill="white" />
                <path d="M118.63 210.13V220.68H111.36V190.61H123.14C126.707 190.61 129.427 191.493 131.3 193.26C132.248 194.189 132.989 195.308 133.473 196.544C133.957 197.78 134.175 199.104 134.11 200.43C134.138 202.177 133.707 203.901 132.86 205.43C131.99 206.921 130.695 208.118 129.14 208.87C127.268 209.759 125.212 210.191 123.14 210.13H118.63ZM126.71 200.43C126.71 197.763 125.25 196.43 122.33 196.43H118.63V204.26H122.33C125.25 204.287 126.71 203.01 126.71 200.43Z" fill="white" />
                <path d="M161.08 193.03C163.501 194.851 165.13 197.532 165.63 200.52H157.93C157.409 199.474 156.587 198.607 155.57 198.03C154.425 197.35 153.112 197.003 151.78 197.03C150.746 196.988 149.716 197.174 148.762 197.575C147.808 197.976 146.954 198.582 146.26 199.35C144.794 201.099 144.047 203.341 144.17 205.62C144.17 208.48 144.9 210.677 146.36 212.21C147.181 213.006 148.16 213.622 149.232 214.018C150.305 214.414 151.449 214.582 152.59 214.51C154.222 214.54 155.819 214.038 157.14 213.08C158.49 212.073 159.464 210.644 159.91 209.02H150.72V203.79H165.99V210.98C165.407 212.77 164.483 214.43 163.27 215.87C161.957 217.422 160.322 218.67 158.48 219.53C156.398 220.492 154.124 220.971 151.83 220.93C149.04 221.003 146.28 220.337 143.83 219C141.589 217.74 139.765 215.852 138.58 213.57C137.308 211.099 136.672 208.349 136.73 205.57C136.68 202.808 137.316 200.076 138.58 197.62C139.763 195.343 141.579 193.457 143.81 192.19C146.224 190.863 148.946 190.197 151.7 190.26C155.048 190.114 158.349 191.089 161.08 193.03Z" fill="white" />
                <path d="M104.3 146.44H60.1304C55.8063 146.435 51.6608 144.715 48.6033 141.657C45.5457 138.6 43.8256 134.454 43.8203 130.13V85.9601C43.8256 81.636 45.5457 77.4906 48.6033 74.433C51.6608 71.3754 55.8063 69.6553 60.1304 69.65H104.3C108.623 69.6553 112.768 71.3757 115.824 74.4336C118.88 77.4915 120.598 81.6369 120.6 85.9601V130.13C120.598 134.453 118.88 138.599 115.824 141.656C112.768 144.714 108.623 146.435 104.3 146.44ZM60.1304 76.86C57.7177 76.8626 55.4045 77.8223 53.6985 79.5283C51.9925 81.2343 51.0329 83.5474 51.0303 85.9601V130.13C51.0329 132.543 51.9925 134.856 53.6985 136.562C55.4045 138.268 57.7177 139.227 60.1304 139.23H104.3C106.712 139.227 109.024 138.267 110.729 136.561C112.433 134.855 113.39 132.542 113.39 130.13V85.9601C113.39 83.5483 112.433 81.2352 110.729 79.5289C109.024 77.8226 106.712 76.8626 104.3 76.86H60.1304Z" fill="#161434" />
                <path d="M117 121.73V130.13C116.997 133.497 115.658 136.726 113.277 139.107C110.896 141.488 107.667 142.827 104.3 142.83H60.1301C56.7619 142.83 53.5316 141.492 51.1499 139.11C48.7682 136.729 47.4302 133.498 47.4302 130.13V116.03C54.5002 114.51 65.2701 114.03 78.3401 119.25L86.4402 111.53L91.96 125.53C91.96 125.53 93.44 120.38 98.22 121.11C103 121.84 110.74 124.43 114.05 122.22C114.942 121.706 115.99 121.531 117 121.73Z" fill="#161434" />
                <path d="M101.53 94.98C104.242 94.98 106.44 92.7818 106.44 90.0701C106.44 87.3584 104.242 85.16 101.53 85.16C98.8183 85.16 96.6201 87.3584 96.6201 90.0701C96.6201 92.7818 98.8183 94.98 101.53 94.98Z" fill="#161434" />
            </SvgIcon>

        default:
            return <></>
    }
    return <></>
}