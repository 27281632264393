import {
    TextField,
    useTranslate,
    SimpleList,
    ArrayField,
    SingleFieldList,
    DateField,
    useRecordContext,
    useGetList,
    TextInput,
    BooleanInput,
    useUpdate,
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StyledList } from '../../components/layout/StyledList';
import { PageTitle } from '../../components/layout/PageTitle';
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import BooleanField from '../../components/Fields/BooleanField';
import some from 'lodash/some';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import StarRounded from '@mui/icons-material/StarRounded';
import { ProspectOrganisationField } from '../../components/Fields/ProspectOrganisationField';
import dayjs from 'dayjs';
import { OriginField } from '../../components/Fields/OriginField';

const IsAttributedField = (props: { label: string }) => {
    const record = useRecordContext();
    let bool = some(record?.prospects, (prospect: any) => prospect?.status === 'accepted' || prospect?.status === 'pending');

    return <BooleanField source={""} forceValue={bool} label={props.label} />;
}

const FavoriteToggleButton = (props: any) => {
    const [updateOne] = useUpdate();
    const record = useRecordContext(props);
    const [isFav, setIsFav] = useState(record?.isFavorite);

    if (!record) return null;

    return <IconButton onClick={(e) => {
        e.stopPropagation();
        setIsFav(!isFav);
        updateOne(
            'formresults',
            { id: record.id, data: { isFavorite: !isFav }, previousData: record }
        );
    }
    }>
        {isFav ? <StarRounded color="primary" /> : <StarRounded />}
    </IconButton>
};

export const FormResultsList = () => {
    const { data: organisations } = useGetList('organisations', { pagination: { page: 1, perPage: 100 }, sort: { field: "isActive", order: "DESC" } });
    const t = useTranslate();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const fmFilters = [
        <TextInput resettable={true} sx={{ "& legend": { width: "0px" } }} label={false} source="textSearch" alwaysOn className='rounded-md bg-white'
            InputProps={{ placeholder: t('buttons.search') }}
        />,
        <TextInput sx={{ width: "100px", "& legend": { width: "0px" } }} label={"dpt"} source="regionCode" alwaysOn className='rounded-md bg-white' />,
        <BooleanInput label={"Favoris seulement"} source="isFavorite" alwaysOn />
    ]

    return (isDesktop ?
        <>
            <PageTitle title={t('menu.formresults')} />
            <StyledList sort={{ field: "createdAt", order: "DESC" }} filters={fmFilters}
                sx={{
                    ".RaDatagrid-tableWrapper": { overflow: "auto" }
                }}
            >
                <StyledDataGrid rowClick={"show"} bulkActionButtons={false}>
                    <TextField source="name" label={t("prospect.property.name")} />
                    <TextField source="address" label={t("prospect.property.address")} />
                    <TextField source="zipCode" label={t("prospect.property.zipCode")} />
                    <TextField source="type" label={t("prospect.property.type")} />
                    <TextField source="fieldSurface" label={t("prospect.property.fieldSurface")} />
                    <IsAttributedField label={t("prospect.property.isAttributed")} />
                    <BooleanField source="isTreated" label={t("prospect.property.isTreated")} />
                    {organisations &&
                        (
                            <ArrayField source="prospects" label={t("menu.organisations")} >
                                <SingleFieldList sx={{ gap: "4px", margin: "0px" }}>
                                    <div className={'flex'}>
                                        <ProspectOrganisationField organisations={organisations} />
                                    </div>
                                </SingleFieldList>
                            </ArrayField>
                        )}
                    <OriginField source="origin" />
                    <OriginField source="source" />
                    <DateField showTime source="createdAt" label={t("prospect.property.createdAt")} />
                    <FavoriteToggleButton />
                </StyledDataGrid>
            </StyledList >
        </>
        :
        <>
            <PageTitle title={t(`menu.formresults`)} className={'pb-12 pt-2'} />
            <StyledList actions={false} title={t(`menu.formresults`)} sort={{ field: "createdAt", order: "DESC" }} exporter={false}>
                <SimpleList
                    hasBulkActions={false}
                    title={t(`menu.formresults`)}
                    linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => `${record.zipCode}, ${record.address}`}
                    tertiaryText={record => { return dayjs(record.createdAt).format('DD/MM/YYYY') }}
                />
            </StyledList>
        </>
    );
};