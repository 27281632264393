import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RainbowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M3.75 21.25V18.75C3.75 12.5368 8.7868 7.5 15 7.5C21.2132 7.5 26.25 12.5368 26.25 18.75V21.25M7.5 21.25V18.75C7.5 14.6079 10.8579 11.25 15 11.25C19.1421 11.25 22.5 14.6079 22.5 18.75V21.25M11.25 21.25V18.75C11.25 16.6789 12.9289 15 15 15C17.0711 15 18.75 16.6789 18.75 18.75V21.25" stroke="currentColor" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    )
}

