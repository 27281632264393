// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20
        },
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
            alignItems: "start",
            gap: "0.2em",
            "& .MuiAutocomplete-tag": {
              margin: 0
            },
            "& legend": {
              width: "0",
            },
            "& input": {
              padding: "0px 4px !important",
            }
          },
        },
      }
    }
  };
}
