import { StyledList } from '../../components/layout/StyledList';
import {
    SimpleList,
    useTranslate,
} from 'react-admin';

//Components
import { PageTitle } from '../../components/layout/PageTitle';
import StatusChip from '../Chips/StatusChip';
import { SavedViewsList } from './CustomViews/SavedViewsList';

export const MobileList = (props: { type: string, filter: any, prospectsFilters: JSX.Element[] }) => {
    const t = useTranslate();

    return (<>
        <PageTitle title={t(`menu.prospects.${props.type}`)} className={'pb-2 pt-2'} />
        <StyledList perPage={100} actions={false} title={t(`menu.prospects.${props.type}`)} filter={props.filter} filters={props.prospectsFilters} sort={{ field: "createdAt", order: "DESC" }} exporter={false}>
            <SavedViewsList />
            <SimpleList
                hasBulkActions={false}
                title={t(`menu.prospects.${props.type}`)}
                linkType="show"
                primaryText={record => record.name}
                secondaryText={record => `${record.address}`}
                tertiaryText={record => {
                    return <div className="flex flex-col gap-2 items-end">
                        <StatusChip status={record.pipeStatus} size="small" />
                        {t(`prospect.type.${record.type}`)}
                    </div>
                }}
            />
        </StyledList>
    </>)
}