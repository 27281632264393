import { Show, SimpleShowLayout, TextField, EmailField, useTranslate, FunctionField, ReferenceField, usePermissions, ReferenceArrayField, SingleFieldList } from 'react-admin';
import BooleanField from '../../components/Fields/BooleanField';
import { RoleChip } from '../../components/Chips/RoleChip';
import { BackButton } from '../../components/Buttons/BackButton';
import { PageTitle } from '../../components/layout/PageTitle';
import Box from '@mui/material/Box';

const UserShow = (props: any) => {
    const t = useTranslate();
    const { permissions } = usePermissions();

    return (
        <Show title={t('menu.users')}>
            <SimpleShowLayout>
                <Box className="flex gap-2">
                    <BackButton />
                    <FunctionField label={false} source="id" render={(record: any) => { return <PageTitle title={`${record.firstName} ${record.lastName}`} /> }} />
                </Box>
                <TextField label={t('user.property.firstName')} source="firstName" />
                <TextField label={t('user.property.lastName')} source="lastName" />
                <EmailField label={t('user.property.email')} source="email" />
                <TextField label={t('user.property.phoneNumber')} source="phoneNumber" />
                <TextField label={t("user.property.jobTitle")} source="jobTitle" />
                <FunctionField label={t('user.property.role')} source="role" render={(record: any) => <RoleChip role={record.role} />} />
                <BooleanField label={t('user.property.receiveNewProspects')} source="receiveNewProspects" />
                <BooleanField label={t('user.property.receiveWeeklyReports')} source="receiveWeeklyReports" />
                {permissions?.includes('superAdmin') === true && (
                    <ReferenceField source="organisationId" reference="organisations" >
                        <TextField source="name" />
                    </ReferenceField>
                )}
                <ReferenceArrayField source="teamIds" reference="teams" label={t('team.property.name')} />
            </SimpleShowLayout>
        </Show>
    )
};

export default UserShow;