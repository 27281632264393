import { useState } from 'react';
import {
    useTranslate,
    useListContext,
} from 'react-admin';

// Mui
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MUIButton from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import theme from '../../theme';
import { ProspectsFiltersForm } from '../Forms/FiltersForm';


export const OpenFiltersButton = (props: { type: 'new' | 'current', label: string }) => {
    const t = useTranslate();
    const {
        filterValues,
    } = useListContext();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <Box className="absolute right-4 bottom-0 mb-1">
        <Badge badgeContent={Object.keys(filterValues)?.length} color="primary">
            <MUIButton
                sx={{
                    borderRadius: "8px",
                    borderColor: theme.palette.grey[400],
                }}
                aria-describedby={id} onClick={handleClick} variant="outlined" color="primary">
                {props.label}
            </MUIButton>
        </Badge>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorReference='anchorEl'
            anchorOrigin={{
                vertical: 50,
                horizontal: 'right',
            }}
            onClose={handleClose}
            sx={{
                ".MuiPopover-paper": {
                    border: "1px solid",
                    borderColor: theme.palette.grey[400],
                }
            }}
        >
            <ProspectsFiltersForm type={props.type} />
        </Popover>
    </Box >
}