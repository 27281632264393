import * as React from 'react';
import { ReactElement, ChangeEvent, FormEvent, useState } from 'react';
import { useListContext, useTranslate } from 'ra-core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useSavedQueries, extractValidSavedQueries, useUpdate, useGetIdentity } from 'react-admin';
import isEqual from 'lodash/isEqual';

export const AddViewDialog = ({
    open,
    onClose,
}: AddSavedQueryDialogProps): ReactElement => {
    const translate = useTranslate();
    const {
        resource,
        filterValues,
        displayedFilters,
        sort,
        perPage,
    } = useListContext();

    const [savedQueries, setSavedQueries] = useSavedQueries(resource);

    const validSavedQueries = extractValidSavedQueries(savedQueries);
    const hasSavedCurrentFilterValue = validSavedQueries.some(savedQuery =>
        isEqual(savedQuery.value, {
            filter: filterValues,
            sort,
            perPage,
            displayedFilters,
        })
    );
    const hasFilterValues = !isEqual(filterValues, {});

    // input state
    const [queryName, setQueryName] = useState('');
    const [update] = useUpdate();
    const user = useGetIdentity();

    const handleQueryNameChange = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        setQueryName(event.target.value);
    };

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        addQuery();
    };

    const saveToServer = (newSavedQueries: any[]): void => {
        update("users", { id: user?.data?.id?.toString(), data: { customViews: newSavedQueries } });
    }

    const addQuery = (): void => {
        const newSavedQuery = {
            label: queryName,
            value: {
                filter: filterValues,
                sort,
                perPage,
                displayedFilters,
            },
        };
        const newSavedQueries = extractValidSavedQueries(savedQueries);
        setSavedQueries(newSavedQueries.concat(newSavedQuery));
        saveToServer(newSavedQueries.concat(newSavedQuery));
        setQueryName('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            sx={{ ".MuiDialog-paper": { minWidth: "400px" } }}
        >
            <DialogTitle id="form-dialog-title">
                {translate('customViews.newDialogTitle', {
                    _: 'Save current query as',
                })}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleFormSubmit}>
                    <TextField
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        margin="dense"
                        id="name"
                        label={translate('customViews.newDialogViewName', {
                            _: 'Query name',
                        })}
                        fullWidth
                        value={queryName}
                        onChange={handleQueryNameChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {translate('ra.action.cancel')}
                </Button>
                <Button onClick={addQuery} color="primary" variant='contained'>
                    {translate('ra.action.save')}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export interface AddSavedQueryDialogProps {
    open: boolean;
    onClose: () => void;
}
