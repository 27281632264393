import CircularProgress from '@mui/material/CircularProgress';
import { LogoFull } from './LogoFull';
import { Loader } from './Loader';

const AppLoading = () => {
    return (
        <div className="app-loading w-full h-[100vh] flex items-center justify-center flex-col gap-8">
            <div>
                <Loader />
            </div>
        </div>
    )
}

export default AppLoading;