import { useGeolocated } from "react-geolocated";
import Map, { Marker, useMap } from 'react-map-gl';
// Mui
import IconButton from '@mui/material/IconButton';

//Icons
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import theme from "../../../theme";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

const GPSControl = (props: { prospectCreate: boolean; coords: GeolocationCoordinates | undefined, isGeolocationAvailable: boolean, isGeolocationEnabled: boolean }) => {
    const { current: map } = useMap();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const initialLocate = props.prospectCreate;
    //On clcik, center on point
    const handleClick = () => {
        if (props.coords?.latitude && props.coords?.longitude && props.isGeolocationAvailable && props.isGeolocationEnabled) {
            window.analytics?.track('Clicked Locate Control');
            map?.flyTo({ center: [props.coords?.longitude, props.coords?.latitude], zoom: 15 });
        }
    }
    const initialLocated = () => {
        if (props.coords?.latitude && props.coords?.longitude && props.isGeolocationAvailable && props.isGeolocationEnabled) {
            map?.jumpTo({ center: [props.coords?.longitude, props.coords?.latitude], zoom: 15 });
        }
    }
    useEffect(() => {
        if (!isDesktop || initialLocate) { initialLocated(); }
    }, [props.coords])

    return <IconButton sx={{
        backgroundColor: theme.palette.primary.main,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    }} onClick={handleClick}>
        <GpsFixedIcon />
    </IconButton>
}

export const LocateControl = (props: { initialLocated: boolean }) => {
    const prospectCreate = props.initialLocated
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
        });

    return <>
        {coords?.latitude && coords?.longitude && isGeolocationAvailable && isGeolocationEnabled && (
            <>
                <Marker longitude={coords?.longitude} latitude={coords?.latitude}>
                    <GpsFixedIcon color="success" />
                </Marker>
                <div className="absolute bottom-10 left-2">
                    <GPSControl prospectCreate={prospectCreate} coords={coords} isGeolocationAvailable={isGeolocationAvailable} isGeolocationEnabled={isGeolocationEnabled} />
                </div>
            </>
        )}
    </>
}
LocateControl.defaultProps = {
    initialLocated: false
}