import FormControl from '@mui/material/FormControl';
import TextInput from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

export const CustomTextInput = (props: any) => {
    return (<FormControl fullWidth>
        <Typography variant="h6">{props.title} {props.required ? " *" : ""}</Typography>
        <TextInput {...props} value={props.value} onChange={(e) => props.onChange(e.target.value)}
            sx={!props.multiline ? {
                '& .MuiInputBase-root': {
                    height: '48px'
                }
            } : {}}
        />
    </FormControl>
    )
}