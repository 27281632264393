import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import SDK functions
import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react';
import * as Sentry from "@sentry/react";
import { ISplitFactoryChildProps } from '@splitsoftware/splitio-react/types/types';

// Define your config object
const CONFIG = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_KEY as string,
    key: 'anonymous'
  }
};

Sentry.init({
  dsn: "https://f37371c850f141d2bfd638282770997e@o4505239530307584.ingest.sentry.io/4505239532142592",
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SplitFactory config={CONFIG} updateOnSdkTimedout={true} >
    {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => {
      return <React.StrictMode>
        <App />
      </React.StrictMode>
    }}
  </SplitFactory>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
