import { useDataProvider, useRecordContext, useTranslate, useRefresh, useUpdate, useRedirect } from 'react-admin';
import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';

const AcceptButton = (props: any) => {
    const record = useRecordContext(props);
    const t = useTranslate();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [updateOne] = useUpdate();
    const redirect = useRedirect();

    const { mutate } = useMutation({
        mutationKey: ['prospects', 'update', { id: props.id ? props.id : record?.id }], // Optional mutation key for caching
        mutationFn: () =>
            dataProvider.update('prospects', {
                id: props.id ? props.id : record?.id,
                data: { status: "accepted" },
                previousData: {}
            }),
        onSuccess: (data) => {
            refresh();
        }
    });

    const AcceptRecommendation = () => {
        updateOne(`recommendations/`, { id: record?.id, data: { status: "accepted" }, previousData: record }, {
            mutationMode: 'pessimistic',
            onSuccess: (data: any) => {
                redirect('show', 'prospects', data?.id);
            }
        });
    }

    //@ts-ignore
    return <Button size={props.size} disableElevation={true} variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); record?.isRecommendation || props?.isRecommendation ? AcceptRecommendation() : mutate(); }}>
        {t("buttons.yes")}
    </Button>

}

export default AcceptButton;