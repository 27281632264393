import { useRecordContext } from "react-admin";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";

export const ProspectOrganisationField = (props: any) => {
    const record = useRecordContext();

    const getStatusColor = () => {
        switch (record?.status) {
            case 'accepted':
                return 'success';
            case 'pending':
                return 'warning';
            case 'rejected':
                return 'error';
            default:
                return 'default';
        }
    }

    return <Chip color={getStatusColor()} avatar={<Avatar sx={{ height: 56, width: 56 }} src={props.organisations.find((item: any) => item.id == record?.organisationId)?.imageUrl} />} label={props.organisations.find((item: any) => item.id == record?.organisationId)?.name}></Chip>
}