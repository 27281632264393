import { AutocompleteArrayInput, Form, useRecordContext, useTranslate, useUpdate, useDelete, useGetList, useCreateSuggestionContext, Create, useCreateController, SimpleForm, useCreate, SaveButton } from "react-admin"
import { useEffect, useRef } from "react";

//mui
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

//components
import { CreateTagForm } from "../../Forms/CreateTagForm";
//types
import { IProspect, IProspectTag } from "../../../types/Prospect";
import { LightTooltip } from "../../layout/LightTooltip";

const CreateTag = () => {
    const { onCancel, onCreate } = useCreateSuggestionContext();
    const [addOne, { isPending }] = useCreate();
    const t = useTranslate();

    return (
        <Dialog
            open
            onClose={onCancel}
            sx={{
                zIndex: 1400,
            }}>
            <DialogContent>
                <Form
                    record={{}}
                    resource="prospectTags"
                    onSubmit={(data: any) => {
                        console.log(data);
                        addOne('prospectTags', { data }, {
                            onSuccess: (data) => {
                                onCreate(data);
                            }
                        });
                    }}
                >
                    <Box className="flex flex-col mb-4">
                        <CreateTagForm />
                    </Box>
                    <SaveButton />
                </Form>
            </DialogContent>
        </Dialog >
    );
};

export const TagForm = () => {
    const { data: prospectTags, refetch } = useGetList('prospectTags');
    const prospect = useRecordContext<IProspect>();
    const [deleteOne] = useDelete();
    const [update] = useUpdate();
    const t = useTranslate();
    const autocompleteRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (autocompleteRef.current) {
            const input = autocompleteRef.current.querySelector('input');
            if (input) {
                //Focus after 100ms to avoid change of anchor
                setTimeout(() => {
                    input.focus();
                }, 150);
            }
        }
    }, []);

    const handleChanges = (event: any) => {
        update('prospects', { id: prospect?.id, data: { tags: event } });
    };

    const removeTag = (id: string) => {
        window.analytics?.track('Deleted a tag');

        deleteOne(`prospectTags`, { id }, {
            onSuccess: () => {
                refetch();
            }
        });
    }
    return <div ref={autocompleteRef}>
        <Form>
            <AutocompleteArrayInput
                source="tags"
                optionValue="id"
                choices={prospectTags}
                create={<CreateTag />}
                onChange={handleChanges}
                onFocus={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                label={false}
                helperText={false}
                inputText={(choice: { id: string, name: string, bgColor: string }) => choice.name}
                matchSuggestion={(filterValue: string, choice: { id: string, name: string, bgColor: string }) => choice.name.toLowerCase().includes(filterValue.toLowerCase())}
                optionText={(choice: { id: string, name: string, bgColor: string }) => {
                    return <Box className="flex items-center justify-between w-full" sx={{ color: choice.bgColor }}>
                        {choice.name}
                        <LightTooltip
                            title={
                                <Box className="flex gap-1 items-center">
                                    <IconButton size="small" onClick={(e: React.MouseEvent) => { e.stopPropagation(); removeTag(choice.id) }}>
                                        <DeleteOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    {t('ra.action.delete')}
                                </Box>
                            }
                        >
                            <MoreHorizIcon className="delete-tag" />
                        </LightTooltip>
                    </Box>
                }}
                ListboxProps={{
                    sx: {
                        padding: "0",
                        "& .MuiAutocomplete-option": {
                            padding: "0 8px",
                            borderBottom: "1px solid #f0f0f0",
                        },
                    }
                }}
                sx={{
                    "& .MuiAutocomplete-tag .delete-tag": {
                        display: 'none',
                    },
                }}
            />
        </Form >
    </div>
}
