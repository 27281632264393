import { FileInput, FileField, Form, SaveButton, SimpleForm, useCreate, useNotify, useRecordContext, useRefresh, TextInput } from "react-admin"
import { IProspect } from "../../types/Prospect";
import { FileUploadButton } from "./FileUploadButton";

export const FileUploader = () => {
    const [addOne, { isLoading: isCreateLoading }] = useCreate();
    const prospect = useRecordContext<IProspect>();
    const notify = useNotify();
    const refresh = useRefresh();

    const uploadFile = (fileData: any) => {
        addOne(`prospects/${prospect?.id}/files`, {
            data: {
                file: { rawFile: fileData },
            }
        },
            {
                onSuccess: (data) => {
                    notify(`notifications.sent`, { type: "success" });
                    refresh();
                },
                onError: () => {
                    notify('BasicError', { type: "warning" })
                }
            });
    }

    return <Form>
        <FileInput
            accept={{
                'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                'text/csv': ['.csv'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }}
            maxSize={10000000}
            source="file"
            multiple={false}
            onChange={(e) => uploadFile(e)}
            label={false}
            placeholder={<FileUploadButton />}
            sx={{ ['& .RaFileInput-dropZone']: { background: 'transparent' } }}
        >
        </FileInput>
    </Form>
}
