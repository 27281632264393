import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EuroIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 24 24' fill="none" {...props}>
            <path d="M15 18.5001C13.8112 18.5012 12.6451 18.1752 11.6291 17.5579C10.6132 16.9406 9.78669 16.0557 9.24 15.0001H15L16 13.0001H8.58C8.53 12.6701 8.5 12.3401 8.5 12.0001C8.5 11.6601 8.53 11.3301 8.58 11.0001H15L16 9.00013H9.24C9.78766 7.94531 10.6144 7.0611 11.6301 6.44392C12.6458 5.82674 13.8115 5.50028 15 5.50013C16.61 5.50013 18.09 6.09013 19.23 7.07013L21 5.30013C19.3534 3.81759 17.2157 2.99813 15 3.00013C11.08 3.00013 7.76 5.51013 6.52 9.00013H3L2 11.0001H6.06C5.97901 11.6643 5.97901 12.3359 6.06 13.0001H3L2 15.0001H6.52C7.76 18.4901 11.08 21.0001 15 21.0001C17.31 21.0001 19.41 20.1301 21 18.7001L19.22 16.9301C18.09 17.9101 16.62 18.5001 15 18.5001Z" fill="currentColor" />
        </SvgIcon>
    )
}

