import jwt_decode from "jwt-decode";
import axios from "axios";
import { UserIdentity, addRefreshAuthToAuthProvider } from "react-admin";
import { checkRefresh } from "./refreshAuthTokens";

const login = (params: any) => {
    const email = params["username"];
    const password = params["password"];

    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/token`,
        JSON.stringify({ email, password }),
        { headers: { 'Content-Type': 'application/json' } }
    )
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response;
        })
        .then((response) => {
            const decodedToken: any = jwt_decode(response.data?.access_token);
            localStorage.setItem("access_token", JSON.stringify(response.data?.access_token));
            localStorage.setItem("refresh_token", JSON.stringify(response.data?.refresh_token));
            localStorage.setItem('permissions', decodedToken.permissions);
            localStorage.setItem('canCheckPermissions', response.data?.isSuperAdmin);
            localStorage.setItem('user', JSON.stringify(response.data));
            //Check if the user has saved queries and are array
            if (response.data?.customViews && Array.isArray(response.data?.customViews)) {
                response.data?.customViews.map((view: any) => {
                    delete view._id;
                    view.value.displayedFilters = {};
                    return view;
                })
                localStorage.setItem('RaStore.prospects.savedQueries', JSON.stringify(response.data?.customViews));
            }

            //Check if user has saved prospect datagrid columns, if so, save them to local storage
            if (response.data?.prospectDataGridColumns && Array.isArray(response.data?.prospectDataGridColumns) && response.data?.prospectDataGridColumns.length > 0) {
                localStorage.setItem('RaStore.preferences.prospects.datagrid.columns', JSON.stringify(response.data?.prospectDataGridColumns));
            }

            window.analytics?.identify(response.data._id);
        })
        .catch((e) => {
            console.log(e);
            throw new Error(e.response.data);
        });
};

const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("canCheckPermissions");
    localStorage.removeItem("user");
    return Promise.resolve();
    // return Promise.resolve('/my-custom-login');
};

const checkError = (error: any) => {
    const status = error?.status;
    if (status === 401) {
        localStorage.removeItem("auth");
        //return Promise.reject({ redirectTo: '/unauthorized', logoutUser: false });
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
};

const checkAuth = () => {
    return localStorage.getItem("access_token")
        ? Promise.resolve()
        : Promise.reject();
};

const getPermissions = async (params: any): Promise<string[]> => {
    let canCheckPermissions = localStorage.getItem("canCheckPermissions")
    let access_token = localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token") ?? "")
        : null;

    if (canCheckPermissions === "true" && access_token != null) {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "/auth/role", {
            headers: { "Authorization": `Bearer ${access_token}` },
        });
        if (response.status < 200 || response.status >= 300) {
            Promise.reject();
        }
        return Promise.resolve(response.data);
    }
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");

    return Promise.resolve([user?.role ?? "user", user?.organisationType ?? "agency"]);
};

const getIdentity = async (): Promise<UserIdentity> => {
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");

    return Promise.resolve({
        id: user?._id ?? "",
        phoneNumber: user?.phoneNumber,
        fullName: `${user?.firstName} ${user?.lastName}`,
        firstName: user?.firstName,
        lastName: user?.lastName,
        avatar: user?.avatar,
        email: user?.email,
        organisationId: user?.organisationId,
        organisationType: user?.organisationType,
        organisationName: user?.organisationName,
        pipeStatuses: user?.pipeStatuses,
        checkList: user?.checkList,
    })
}

const getCourierToken = async (): Promise<{ token: string }> => {
    const access_token = localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token") ?? "")
        : null;
    if (access_token != null) {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "/auth/courier/token", {
            headers: { "Authorization": `Bearer ${access_token}` },
        });
        if (response.status < 200 || response.status >= 300) {
            Promise.reject();
        }
        return Promise.resolve(response.data);
    }
    return Promise.resolve({ token: "" });
}

const myAuthProvider = {
    // authentication
    login: (params: any) => login(params),
    logout: () => logout(),
    checkAuth: () => checkAuth(),
    checkError: (error: any) => checkError(error),
    getIdentity: () => getIdentity(),
    // authorization
    getPermissions: (params: any) => getPermissions(params),
    getCourierToken: () => getCourierToken()
};

export const authProvider = addRefreshAuthToAuthProvider(myAuthProvider, checkRefresh);
