// Librairies
import { lazy, Suspense, useState } from 'react';
import { SimpleForm, TextInput, required, Toolbar, SaveButton, useTranslate, useNotify, minLength, useEditContext, DeleteButton, SelectArrayInput, ReferenceArrayInput, AutocompleteArrayInput, usePermissions } from 'react-admin';
import axios from 'axios';

// Components
import { RangeSlider } from '../../components/Inputs/RangeInput';
import { RATextInput } from '../../components/Inputs/RATextInput';

// Mui components
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BackButton } from '../../components/Buttons/BackButton';
import { PageTitle } from '../../components/layout/PageTitle';
import { useFormContext } from 'react-hook-form';
import { CommuneSearchInput } from '../../components/Inputs/CommuneSearchInput';

// Lazy loading
const BaseMap = lazy(() => import('../../components/Map/BaseMap'));

const NoLabelArraySelect = styled(SelectArrayInput)({
    "& label": {
        display: "none"
    }
})
const EditargetingToobar = (props: any) => {
    const notify = useNotify();
    const { setValue } = useFormContext();

    return <Toolbar {...props} className="justify-between">
        <div className="flex gap-2">
            <SaveButton
                label={props.label}
                transform={data => ({ ...data, ...props.addedData, houseSurface: data.fieldSurface })}
                type="button"
                alwaysEnable
            />
            {props.mode !== "edit" && (
                <SaveButton
                    alwaysEnable
                    transform={data => ({ ...data, ...props.addedData, houseSurface: data.fieldSurface })}
                    type="button"
                    label="buttons.saveAndAddAnother"
                    mutationOptions={{
                        onSuccess: () => {
                            setValue("name", "");
                            setValue("zipCode", "");
                            props.onResetForm();
                            window.scrollTo(0, 0);
                            notify('ra.notification.created', {
                                type: 'success',
                                messageArgs: { smart_count: 1 },
                            });
                        },
                    }}
                />
            )}
        </div>
        {props.mode === "edit" && (
            <DeleteButton size="medium" />
        )}
    </Toolbar>

}

export const TargetingForm = (props: { mode: string, buttonLabel: string, record?: any }) => {
    const { record } = props;
    const t = useTranslate();
    const { permissions } = usePermissions();
    const [radius, setRadius] = useState(record?.radius ? record?.radius : 1000);
    const [coordinates, setCoordinates] = useState(record?.location?.coordinates);
    const [contours, setContours] = useState(record?.type === "polygon" ? record?.contours?.coordinates : undefined);
    const [type, setType] = useState(record?.type ?? "commune");
    const [communeCode, setCommuneCode] = useState<string | undefined>(record?.type === "commune" ? record?.communeInfo?.code : undefined);

    // Handle change of geocoder search
    const handleGeocoderResult = (e: any) => {
        setCoordinates(e.result?.center);
    };

    // handle change of creating, updating or deleting a polygon
    const handlePolygonResult = (e: any) => {

        let multipoly = JSON.parse(JSON.stringify(Object.values(e)));

        if (multipoly.length > 0) {
            // only keep objects with geometry property
            multipoly = multipoly.filter((item: any) => item?.geometry?.coordinates && item?.geometry?.coordinates[0]?.length > 0 && item?.geometry?.coordinates[0][0] != null);
            let result: { type: string, geometry: { type: string, coordinates: any[] } } = { type: "Feature", geometry: { type: "MultiPolygon", coordinates: [] } };
            multipoly.map((item: any): void => {
                if (item.type === "Feature") {
                    result.geometry.coordinates.push(item.geometry.coordinates);
                }
            });
            setContours(result.geometry.coordinates);
            return;
        }
        setContours([]);
    }

    const handleSliderChange = (e: any) => {
        setRadius(e.target.value);
    }

    const handleCommuneSearch = (e: any) => {
        setCommuneCode(e?.code);
    }

    const validateForm = async (values: any) => {
        let errors: any = {};
        if (type === "zipCode") {
            const zipInfo = await axios.get(`https://geo.api.gouv.fr/communes?codePostal=${values.zipCode}&fields=nom,centre&format=json&geometry=centre`);
            if (!zipInfo?.data?.length || zipInfo?.data?.length === 0) {
                errors.zipCode = t('targeting.create.errors.zipCodeInvalid')
            }
        }
        if (!values.propertyType || values.propertyType.length < 1) {
            errors.propertyType = t('targeting.create.errors.propertyType.required')
        }
        if ((type === "circle" && coordinates?.length !== 2) || (type === "polygon" && contours?.length < 1)) {
            errors.type = t('targeting.create.errors.type.noCoordinates')
        }
        console.log(errors)
        return errors;
    }

    return (
        <SimpleForm
            validate={validateForm}
            defaultValues={{
                houseSurface: [0, 1000],
                fieldSurface: [0, 1000]
            }}
            toolbar={<EditargetingToobar
                mode={props.mode}
                label={props.buttonLabel}
                onResetForm={() => setType("commune")}
                addedData={{ type, location: { coordinates, type: "Point" }, radius, contours: { coordinates: contours, type: "MultiPolygon" }, communeCode }}
            />}
        >
            <div className='flex items-center mb-8'>
                <BackButton />
                <PageTitle title={t(`targeting.${props.mode}.title`)}></PageTitle>
            </div>
            <div className="pr-4">
                <RATextInput className="w-full mb-4" source="name" label="T" title={t('targeting.property.name')} placeholder="Entrez votre titre" />
            </div>
            {permissions?.includes("user") === false && (
                <>
                    <Typography variant="h6">{t('targeting.property.associatedUsers')}</Typography>
                    <ReferenceArrayInput source="userIds" reference="users" filter={{ role: "user" }}>
                        <AutocompleteArrayInput debounce={500} emptyText={t('targeting.create.noUser')} label="" optionText={(choice) => `${choice?.firstName} ${choice?.lastName}`} />
                    </ReferenceArrayInput>
                </>
            )}
            <Typography variant="h6">{t('targeting.property.type')} *</Typography>
            <NoLabelArraySelect placeholder='Type de bien' source="propertyType" label=""
                choices={[
                    { id: "house", name: t('prospect.type.house') },
                    { id: "field", name: t('prospect.type.field') },
                    { id: "building", name: t('prospect.type.building') },
                    { id: "appartment", name: t('prospect.type.appartment') },
                    { id: "warehouse", name: t('prospect.type.warehouse') },
                    { id: "garage", name: t('prospect.type.garage') },
                    { id: "other", name: t('prospect.type.other') },
                ]}
                validate={[minLength(1), required()]}
            />
            <div className={"pr-4 pl-2 py-2 w-full md:w-1/2"}>
                <RangeSlider label={t('prospect.property.fieldSurface')} name="fieldSurface" source="fieldSurface" min={0} max={5000} step={100}
                    getAriaLabel={(value: any) => { return value + ' m²' }}
                />
            </div>
            <FormControl >
                <Typography variant="h6">{t('targeting.property.geotype')} *</Typography>
                <RadioGroup
                    className="mb-2"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={type === "polygon" || type === "circle" ? "geo" : type}
                    onChange={(e) => setType(e.target.value === "geo" ? "polygon" : e.target.value)}
                >
                    <FormControlLabel value="commune" control={<Radio />} label={t("targeting.create.areaType.commune")} />
                    <FormControlLabel value="zipCode" control={<Radio />} label={t("targeting.create.areaType.zipCode")} />
                    <FormControlLabel value="geo" control={<Radio />} label={t("targeting.create.areaType.geo")} />
                </RadioGroup>
            </FormControl>

            {type === "commune" &&
                <CommuneSearchInput onSelected={(e: any) => handleCommuneSearch(e)} />
            }
            {type === "zipCode" &&
                <TextInput source="zipCode" label={t('targeting.property.zipCode')}
                    validate={(value) => {
                        if (/^\d{5,6}$/.test(value)) return undefined;
                        return t("targeting.create.errors.zipCodeInvalid")
                    }}
                />
            }
            {(type === "polygon" || type === "circle") &&
                <>
                    <div className="w-full relative min-h-[500px]">
                        <Suspense fallback={<></>}>
                            <BaseMap
                                features={record?.contours}
                                type={record?.type}
                                contours={record?.contours}
                                center={record?.location?.coordinates}
                                radius={radius}
                                onGeocoderResult={(e: any) => handleGeocoderResult(e)}
                                onSliderChange={handleSliderChange}
                                onPolygonChange={(features: any) => handlePolygonResult(features)}
                                onInit={(features: any) => handlePolygonResult(features)}
                                onGeoTypeChange={(type: string) => setType(type)}
                            />
                        </Suspense>
                    </div>
                </>
            }
        </SimpleForm>
    );
}