import { useRedirect } from "react-admin"
import IconButton from '@mui/material/IconButton';
import theme from "../../theme";
import { FullArrowIcon } from "../Icons/FullArrowicon";
import { useNavigate } from "react-router";

export const BackButton = (props: any) => {
    const navigate = useNavigate();
    const redirect = useRedirect();

    return <IconButton onClick={() => props?.resource ? redirect('list', props.resource) : navigate(-1)} {...props} sx={{ padding: "3px" }}>
        <FullArrowIcon stroke={theme.palette.grey[800]} />
    </IconButton >
}