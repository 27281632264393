import { useEffect, useState } from "react";
import { useGetList } from "react-admin"
import FormControl from '@mui/material/FormControl';
import TextInput from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { IUser } from "../../types/User";

export const TextInputTagging = (props: {
    title: string,
    placeholder: string;
    description: string;
    taggedUserIds: string[];
    onChange: (val: { description: string, taggedUserIds: string[] }) => void
}) => {
    const { title, description, placeholder, taggedUserIds, onChange } = props;
    const { data: users, isLoading } = useGetList<IUser>('users', { sort: { field: 'firstName', order: 'ASC' } })
    const [inputValue, setInputValue] = useState(description);
    const [isTagging, setIsTagging] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
    const [tagStart, setTagStart] = useState(0);
    // Initialize the taggedUsers state as an empty array initially
    const [taggedUsers, setTaggedUsers] = useState<{ email: string; userId: string }[]>([]);

    // UseEffect to update taggedUsers when both taggedUserIds and users are available
    useEffect(() => {
        if (users && taggedUserIds) {
            const updatedTaggedUsers = taggedUserIds.map((userId) => {
                // Find the user from the fetched users by their ID
                const user = users.find((user) => user.id === userId);
                return {
                    email: user?.email ?? "", // Set the email or fallback to an empty string
                    userId: userId
                };
            });

            setTaggedUsers(updatedTaggedUsers); // Update the state with the user data
        }
    }, [isLoading, taggedUserIds]);

    // Handle input changes
    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setInputValue(value);

        onChange({ description: value, taggedUserIds: taggedUsers.map(user => user.userId) });

        // Detect if there's an "@" before the cursor
        const cursorPosition = e.target.selectionStart;
        const lastAt = value.lastIndexOf('@', cursorPosition - 1);

        if (lastAt !== -1 && (lastAt === 0 || /\s/.test(value[lastAt - 1]))) {
            // Extract the text after the "@" up to the cursor
            const searchTerm = value.slice(lastAt + 1, cursorPosition);
            const filtered = users?.filter(user =>
                user.email.toLowerCase().startsWith(searchTerm.toLowerCase())
            ) ?? [];

            // Show the dropdown with matching users
            setFilteredUsers(filtered);
            setIsTagging(true);
            setTagStart(lastAt);  // Save the position of "@"
        } else {
            setIsTagging(false);  // Hide the dropdown if not tagging
        }

        // Detect deleted tagged usernames
        detectDeletedTags(value);
    };

    // Insert the selected tag into the input field
    const insertTag = (user: IUser) => {
        // Replace the "@..." with the selected username
        const beforeTag = inputValue.slice(0, tagStart);
        const afterTag = inputValue.slice(tagStart + 1); // +1 to skip the "@" symbol
        const newText = `${beforeTag}@${user.email} ${afterTag}`;

        // Update input value
        setInputValue(newText);
        setIsTagging(false);

        // Add the user's ID and firsName to the list of tagged user IDs
        setTaggedUsers((prev) => [...prev, { email: user.email, userId: user.id }]);

        // Update the parent component
        onChange({ description: newText, taggedUserIds: [...taggedUserIds, user.id] });
    };

    // Detect if any tagged username has been deleted
    const detectDeletedTags = (newValue: string) => {
        const currentText = newValue;
        const currentEmails = taggedUsers.map((tag) => tag.email);

        // Find if any tagged username is missing from the current input
        const missingTags = currentEmails.filter(email => !currentText.includes(`@${email}`));

        if (missingTags.length > 0) {
            // Remove the corresponding tagged user IDs
            const updatedTaggedUsers = taggedUsers.filter(tag => !missingTags.includes(tag.email));
            setTaggedUsers(updatedTaggedUsers);

            // Update the parent component
            onChange({ description: newValue, taggedUserIds: updatedTaggedUsers.map(user => user.userId) });
        }
    }

    return (<FormControl fullWidth>
        <Typography variant="h6">{title}</Typography>
        <TextInput
            value={inputValue}
            onChange={handleInputChange}
            error={inputValue?.length > 1000}
            multiline
            rows={8}
            placeholder={placeholder}
        />
        {isTagging && filteredUsers.length > 0 && (
            <div className="rounded-md shadow-md p-2 absolute top-16 bg-white z-50 max-h-60 overflow-y-auto overflow-x-hidden">
                <ul>
                    {filteredUsers.map((user: IUser) => (
                        <li key={user.id} onClick={() => insertTag(user)}>
                            {user.email}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </FormControl>
    )

};