import React, { useContext } from 'react';
import { useGetOne } from 'react-admin';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CommuneContext } from '../../contexts/contexts';

export const CommuneSelectList = (props: {
    onSelectCommune: (code: string) => void
}) => {
    const { onSelectCommune } = props;
    const { data: myOrganisation, isLoading } = useGetOne('organisations', { id: 'my-organisation' });

    if (isLoading) {
        return <div className="px-8 py-4">Chargement...</div>
    }

    if (!myOrganisation || !myOrganisation.explorerCommunes || myOrganisation.explorerCommunes.length === 0) {
        return <div className="px-8 py-4">Aucune commune disponible</div>
    }

    return <div className="px-8 py-4 flex flex-col gap-2">
        {myOrganisation.explorerCommunes.map((commune: { nom: string, code_com: string, code_dep: string }) => (
            <button onClick={() => onSelectCommune(commune.code_com)}>{commune.nom} ({commune.code_dep})</button>
        ))}
    </div>
}

export const CommuneSelectDropdown = (props: {
    onSelectCommune: (code: string) => void
}) => {
    const { onSelectCommune } = props;
    const selectedCommune = useContext(CommuneContext);
    const { data: myOrganisation } = useGetOne('organisations', { id: 'my-organisation' });

    if (!myOrganisation || !myOrganisation.explorerCommunes || myOrganisation.explorerCommunes.length === 0) {
        return <div className="px-8 py-4">Aucune commune disponible</div>
    }

    return <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCommune?.code}
        label="Age"
        onChange={(e) => onSelectCommune(e.target.value)}
        sx={{
            "& fieldset legend": { width: 0 },
        }}
    >
        {myOrganisation.explorerCommunes.map((commune: { nom: string, code_com: string, code_dep: string }) => (
            <MenuItem value={commune.code_com}>{commune.nom} ({commune.code_dep})</MenuItem>
        ))}
    </Select>
}