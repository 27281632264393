import { ReferenceField, useRecordContext, FunctionField, useTranslate } from "react-admin";
import { IProspect } from "../../types/Prospect";
import Avatar from '@mui/material/Avatar';
import { IUser } from "../../types/User";
import theme from "../../theme";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[600],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.grey[300],
    },
}));

export const CreatedByAvatar = (props: { source?: string, label?: string }) => {
    const t = useTranslate();
    const prospect = useRecordContext<IProspect>();

    if (!prospect || !prospect.createdBy) {
        return null;
    }

    let color: string;
    switch (prospect?.origin) {
        case 'creation':
            color = theme.palette.primary.main;
            break;
        case 'kaptcher':
            color = theme.palette.warning.main;
            break;
        case 'terrafinder':
            color = theme.palette.success.darker;
            break;
        default:
            color = 'default';
    }

    return <ReferenceField source="createdBy" reference="users" link={false}>
        <FunctionField render={(user: IUser) => {
            return <CustomTooltip
                placement="top"
                arrow
                title={
                    <div>
                        <div className="text-xs">
                            {t('followedBy')} :
                        </div>
                        <div className="text-sm">
                            {user?.firstName} {user?.lastName}
                        </div>
                    </div>
                }>
                <Avatar sx={{ bgcolor: color, width: 28, height: 28, fontSize: '11px' }}>
                    {`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`}
                </Avatar>
            </CustomTooltip>
        }} />
    </ReferenceField >
}