// Librairies
import { useRecordContext, useTranslate } from 'react-admin';
import { motion } from "framer-motion";

// Mui Components
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Types
import { IPLUData, IProspect } from "../../../types/Prospect";

// Custom components
import { InfoChip } from "../../../components/Chips/InfoChip";
import { ContactPreferenceIcon } from "../../../components/Icons/ContactPreferenceIcon";
import { DisponibilityField } from "../../../components/Fields/DisponibilityField";

// Fields
import { ContactField } from "../../../components/Fields/ContactField";

// Icons
import { CommentIcon } from "../../../components/Icons/CommentIcons";
import { EuroIcon } from "../../../components/Icons/EuroIcon";
import { ClockIcon } from "../../../components/Icons/ClockIcon";
import theme from "../../../theme";

const CustomField = (props: any) => {
    const prospect = useRecordContext(props);
    return prospect ? <div className={"flex items-center gap-x-2"}>
        {props.icon}
        <Typography variant={props.variant}>{props.render ? props.render(prospect) : prospect[props.source]}</Typography>
    </div> : null
}

export const KaptcherOwnerInformation = (props: {}) => {
    const prospect: (IProspect & { isRecommendation: boolean } & { pluData: IPLUData } | undefined) = useRecordContext();
    const t = useTranslate();

    return <div className="flex flex-col gap-4">
        <div>
            {prospect?.status === "accepted" &&
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <ContactField />
                </motion.div>
            }
            {prospect?.ownerComment &&
                <div>
                    <CustomField variant="comment" render={(prospect: any) => prospect?.ownerComment} icon={<CommentIcon color="primary" className="self-start" />} />
                    <Divider sx={{ margin: '12px 0px' }} />
                </div>
            }
            <DisponibilityField />
            {prospect?.formResultId?.contactPreference && (
                <div className="flex items-center justify-between grow">
                    <Typography variant="h6">{t(`prospect.property.contactPreference`)}</Typography>
                    <InfoChip label={t(`prospect.contactPreferences.${prospect?.formResultId?.contactPreference}`)} icon={<ContactPreferenceIcon color="primary" type={prospect.formResultId.contactPreference} />} />
                </div>
            )}
        </div>
        {
            (prospect?.formResultId?.saleTiming !== undefined || prospect?.formResultId?.salePrice !== undefined) &&
            <Card>
                <div className="flex flex-col gap-4">
                    {prospect?.formResultId?.saleTiming !== undefined && (
                        <div className="flex items-center justify-between grow">
                            <Typography variant="h6">{t(`prospect.property.saleTiming`)}</Typography>
                            <InfoChip label={t(`prospect.saleTimings.${prospect.formResultId?.saleTiming}`)} icon={<ClockIcon sx={{ color: theme.palette.primary.main }} />} />
                        </div>
                    )}
                    {prospect?.formResultId?.salePrice !== undefined && (
                        <div className="flex items-center justify-between grow">
                            <Typography variant="h6">{t(`prospect.property.salePrice`)}</Typography>
                            <InfoChip label={`${prospect.formResultId?.salePrice} €`} icon={<EuroIcon sx={{ color: theme.palette.primary.main }} />} />
                        </div>
                    )}
                    {prospect?.formResultId?.residencyType !== undefined && (
                        <div className="flex items-center justify-between grow">
                            <Typography variant="h6">{t(`prospect.property.residencyType`)}</Typography>
                            <InfoChip label={t(`prospect.residencyTypes.${prospect.formResultId?.residencyType}`)} />
                        </div>
                    )}
                </div>
            </Card>
        }
    </div>
}