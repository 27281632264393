import * as React from 'react';
import { useState } from 'react';
import { useControl, Marker, useMap } from 'react-map-gl';
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import LocationOnIcon from '@mui/icons-material/LocationOn';

/* eslint-disable complexity,max-statements */
export default function GeocoderControl(props: any) {
    const [marker, setMarker] = useState(null);
    const { current: map } = useMap(); // Get the map instance

    const geocoder = useControl(() => {
        const ctrl = new MapboxGeocoder({
            types: 'place,address,postcode,poi',
            ...props,
            marker: false,
            accessToken: process.env.REACT_APP_MAPBOX_TOKEN
        });
        ctrl.on('loading', props.onLoading);
        ctrl.on('results', props.onResults);
        ctrl.on('result', (evt: any) => {
            props.onResult(evt);

            const { result } = evt;
            const location =
                result &&
                (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));
            if (location && props.marker) {
                //@ts-ignore
                setMarker(<Marker {...props.marker} longitude={location[0]} latitude={location[1]} >
                    <LocationOnIcon fontSize='large' color='primary' />
                </Marker>);

                // Custom flyTo options
                map?.jumpTo({
                    center: location,
                    zoom: 15
                });
            } else {
                setMarker(null);
            }
        });
        ctrl.on('error', props.onError);
        return ctrl;
    },
        {
            position: props.position
        }
    );
    return marker;
}

const noop = () => { };

GeocoderControl.defaultProps = {
    marker: true,
    onLoading: noop,
    onResults: noop,
    //   onResult: noop,
    onError: noop
};