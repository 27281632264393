// Components
import { useRecordContext, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';
import { HistoryItem } from '../layout/HistoryItem';
import { EventActionButton } from "../Buttons/EventActionButton";
import { VoiceUploader } from '../Inputs/VoiceUploader';

export const CRMField = (props:
    {
        crmType: "events" | "notes" | "tasks",
        dateProperty: string,
        userProperty: string,
        variant?: "compact" | "full",
        filter?: (event: { id: string, associatedOwners: string[] }) => void,
        displayButtons?: boolean,
        displayCreateButtons?: boolean
        displayAssociatedOwners?: boolean
    }) => {
    const record = useRecordContext();
    const t = useTranslate();
    const { crmType, dateProperty, userProperty, variant, filter, displayButtons, displayCreateButtons, displayAssociatedOwners } = props;

    if (!record) return null;

    let events = record && record[crmType] ? record[crmType] : [];
    if (filter) {
        events = events.filter(filter);
    }

    events = events.sort((a: any, b: any) => new Date(b[dateProperty]).getTime() - new Date(a[dateProperty]).getTime());

    const getColor = (type: string) => {
        if (type) {
            switch (type) {
                case 'email':
                    return 'warning';
                case 'call':
                    return 'error';
                case 'meeting':
                    return 'success';
                case 'postMail':
                    return 'primary';
                default:
                    return 'primary';
            }
        } else {
            return 'primary'
        }
    }

    return (<>
        <div className='opacity-0'>
            <div className='bg-primary'></div>
            <div className='bg-success'></div>
            <div className='bg-warning'></div>
            <div className='bg-error'></div>
        </div>
        {displayCreateButtons &&
            <div className="flex items-center justify-between h-15 ">
                <div className="flex items-center">
                    <Typography variant={variant == "compact" ? "h6" : "h4"}>{t(`prospect.property.${crmType}`)}</Typography>
                    <EventActionButton action="create" id={record.id.toString()} crmType={crmType} event={null} />
                </div >
                {crmType === "notes" &&
                    <VoiceUploader />
                }
            </div >
        }
        <div className="flex flex-col mr-[-16px] overflow-hidden w-full">
            <div>
                {events?.map((event: any) => {
                    return <HistoryItem
                        key={event._id}
                        id={event._id}
                        crmType={crmType}
                        title={event.title ?? ""}
                        description={event.description}
                        file={event.files?.[0]}
                        date={event[dateProperty] ?? event.createdAt}
                        displayButtons={displayButtons}
                        displayAssociatedOwners={displayAssociatedOwners}
                        color={getColor(event.type)}
                        user={event[userProperty]}
                        icon={event.type}
                        item={event}
                    />
                })}
            </div>
        </div>
    </>
    )

}
