import theme from "../theme";
import simplify from '@turf/simplify';
import { polygon } from '@turf/helpers';
import { multiPolygon } from '@turf/helpers';

export const randPassword = (letters: number, numbers: number, either: number): string => {
    var chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", // letters
        "0123456789", // numbers
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" // either
    ];

    return [letters, numbers, either].map(function (len, i) {
        return Array(len).fill(chars[i]).map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
        }).join('');
    }).concat().join('').split('').sort(function () {
        return 0.5 - Math.random();
    }).join('')
}

export const createGeoJSONCircle = (data: any): { type: string, data: GeoJSON.FeatureCollection } => {
    let points = 100;

    var features: GeoJSON.Feature[] = [];

    for (let k = 0; k < data?.length; k++) {
        if (data[k].type === 'circle') {

            var coords = {
                latitude: data[k].location.coordinates[1],
                longitude: data[k].location.coordinates[0]
            };

            var km = data[k].radius / 1000;

            var ret = [];
            var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
            var distanceY = km / 110.574;

            var theta, x, y;
            for (var i = 0; i < points; i++) {
                theta = (i / points) * (2 * Math.PI);
                x = distanceX * Math.cos(theta);
                y = distanceY * Math.sin(theta);

                ret.push([coords.longitude + x, coords.latitude + y]);
            }
            ret.push(ret[0]);

            features.push({
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ret]
                },
                "properties": {
                    id: data[k].id,
                    name: data[k].name,
                },
            })
        } else if ((data[k].type === "polygon" || data[k].type === "zipCode") && data[k]?.contours?.coordinates) {
            features.push({
                "type": "Feature",
                "geometry": data[k].contours,
                "properties": {
                    id: data[k].id,
                    name: data[k].name,
                },
            })

            //If commune, use turf to simplify the polygon
        } else if (data[k].type === "commune") {
            let turfpolygon = data[k].contours.type === "Polygon" ? polygon(data[k].contours.coordinates) : multiPolygon(data[k].contours.coordinates);
            let simplified = simplify(turfpolygon, { tolerance: 0.0005, highQuality: true, mutate: true });
            features.push({
                "type": "Feature",
                "geometry": simplified.geometry,
                "properties": {
                    id: data[k].id,
                    name: data[k].name,
                },
            })
        }
    }
    return {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": features
        }
    };
};

interface layerStyleI {
    id: string,
    paint: {
        'fill-color': any,
        'fill-opacity': number,
    },
}

export const layerStyle: layerStyleI = {
    id: 'my-data',
    paint: {
        'fill-color': ['case', ['boolean', ['feature-state', 'select'], false], theme.palette.success.main, theme.palette.primary.main],
        'fill-opacity': 0.5,
    },
};

export const parcelStyle: layerStyleI = {
    id: 'parcels-layer',
    paint: {
        //@ts-ignore
        'fill-opacity': ['case', ['boolean', ['feature-state', 'select'], false], 0.7, 0.2],
        'fill-color': ['case', ['boolean', ['feature-state', 'hasOwner'], false], theme.palette.success.main, theme.palette.warning.main,],
    },
};

export const isSuperAdmin = (permissions: any) => {
    return Array.isArray(permissions) && permissions?.includes("superAdmin")
}

export const isAdmin = (permissions: any) => {
    return Array.isArray(permissions) && (permissions?.includes("admin") || permissions?.includes("superAdmin"))
}

export const isTeamManager = (permissions: any) => {
    return Array.isArray(permissions) && (permissions?.includes("teamManager") || permissions?.includes("superAdmin"))
}

export const getStatusColor = (status: string) => {
    let color: {
        family: keyof typeof theme.palette, variant: "main" | "light" | "dark" | "lighter" | "darker"
    };
    switch (status) {
        case "followUp":
            color = { family: "warning", variant: "dark" };
            break;
        case "pending":
        case "toContact":
            color = { family: "warning", variant: "main" };
            break;
        case "standBy":
            color = { family: "primary", variant: "light" };
            break;
        case "accepted":
        case "contacted":
            color = { family: "success", variant: "main" };
            break;
        case "rejected":
            color = { family: "error", variant: "main" };
            break;
        case "notInterested":
            color = { family: "error", variant: "darker" };
            break;
        case "wrongContact":
            color = { family: "error", variant: "main" };
            break;
        case "notSelling":
            color = { family: "error", variant: "main" };
            break;
        case "toTransfert":
            color = { family: "primary", variant: "main" };
            break;
        case "feasabilityStudy":
            color = { family: "info", variant: "lighter" };
            break;
        case "closed":
            color = { family: "info", variant: "light" }
            break;
        case "qualified":
            color = { family: "magenta", variant: "main" }
            break;
        case "visited":
            color = { family: "success", variant: "darker" }
            break;
        case "offerSubmitted":
            color = { family: "primary", variant: "darker" }
            break;
        case "mandateSigned":
            color = { family: "success", variant: "lighter" }
            break;
        case "signed":
            color = { family: "success", variant: "dark" }
            break;
        case "transferred":
            color = { family: "primary", variant: "light" }
            break;
        default:
            color = { family: "warning", variant: "main" }
    }
    return color;
}

export const setLayerLang = (map: mapboxgl.Map) => {
    map?.getStyle().layers.forEach(function (layer: any) {
        if (layer.id.indexOf('-label') > 0) {
            if (!['road-label', 'settlement-label'].includes(layer.id)) {
                map?.setLayoutProperty(layer.id, 'text-field', [
                    'get',
                    'name_fr'
                ])
            }
        }
    });
}

export const getEventColor = (type: string) => {
    switch (type) {
        case "task":
        case "tasks":
            return theme.palette.primary.main;
        case "call":
            return theme.palette.error.main;
        case "email":
            return theme.palette.warning.main;
        case "meeting":
            return theme.palette.success.main;
        default:
            return theme.palette.primary.main;
    }
}