export const permitMessages = {
    permit: {
        property: {
            "REG": "Code de la région du lieu des travaux",
            "DEP": "Code du département du lieu des travaux",
            "COMM": "Code de la commune du lieu des travaux",
            "TYPE_DAU": "Type de DAU",
            "Num_DAU": "Numéro d'enregistrement de la DAU",
            "Etat_DAU": "Etat d'avancement du projet",
            "DATE_REELLE_AUTORISATION": "Date réelle d'autorisation (PC) ou de non-opposition (DP) initiale",
            "DATE_REELLE_DOC": "Date réelle d'ouverture de chantier",
            "DATE_REELLE_DAACT": "Date réelle d'achèvement des travaux",
            "AN_DEPOT": "Année de dépôt de la DAU",
            "DPC_PREM": "Date (mois) de prise en compte (DPC) du premier évènement reçu dans Sitadel (dépôt de la demande ou autorisation)",
            "DPC_AUT": "Date (mois) de prise en compte (DPC) de l'autorisation initiale",
            "DPC_DOC": "Date (mois) de prise en compte (DPC) de l'ouverture du chantier",
            "DPC_DERN": "Date (mois) (DPC) de dernière mise à jour des données",
            "CAT_DEM": "Catégorie du demandeur (maître d'ouvrage) selon Sitadel",
            "APE_DEM": "Code d'activité principale de l'établissement d'un demandeur avéré en tant que personne morale",
            "CJ_DEM": "Catégorie juridique d'un demandeur avéré en tant que personne morale",
            "DENOM_DEM": "Dénomination d'un demandeur avéré en tant que personne morale",
            "SIREN_DEM": "Numéro SIREN d'un demandeur avéré en tant que personne morale",
            "SIRET_DEM": "Numéro SIRET d'un demandeur avéré en tant que personne morale",
            "CODPOST_DEM": "Code postal du demandeur",
            "LOCALITE_DEM": "Localité du demandeur",
            "REC_ARCHI": "Indicateur de recours à un architecte",
            "ADR_NUM_TER": "Numéro de voie du terrain",
            "ADR_TYPEVOIE_TER": "Type de voie du terrain",
            "ADR_LIBVOIE_TER": "Libellé de la voie du terrain",
            "ADR_LIEUDIT_TER": "Lieu-dit du terrain",
            "ADR_LOCALITE_TER": "Localité du terrain",
            "ADR_CODPOST_TER": "Code postal du terrain",
            "SEC_CADASTRE1": "Section cadastrale 1",
            "NUM_CADASTRE1": "Numéro parcelle cadastrale 1",
            "SEC_CADASTRE2": "Section cadastrale 2",
            "NUM_CADASTRE2": "Numéro parcelle cadastrale 2",
            "SEC_CADASTRE3": "Section cadastrale 3",
            "NUM_CADASTRE3": "Numéro parcelle cadastrale 3",
            "SUPERFICIE_TERRAIN": "Superficie du terrain",
            "ZONE_OP": "Code 'zone opératoire'",
            "NATURE_PROJET_DECLAREE": "Nature du projet déclarée par le demandeur",
            "NATURE_PROJET_COMPLETEE": "Nature de projet détaillée",
            "DESTINATION_PRINCIPALE": "Destination principale",
            "TYPE_PRINCIP_LOGTS_CREES": "Type principal des logements créés",
            "TYPE_TRANSFO_PRINCIPAL": "Type principal de transformation",
            "TYPE_PRINCIP_LOCAUX_TRANSFORMES": "Type principal des locaux d'origine transformés",
            "I_EXTENSION": "Indicateur d'extension",
            "I_SURELEVATION": "Indicateur de surélévation",
            "I_NIVSUPP": "Indicateur de création de niveau(x) supplémentaire(s)",
            "NB_NIV_MAX": "Nombre de niveaux du bâtiment le plus élevé",
            "UTILISATION": "Utilisation visée des locaux à construire",
            "RES_PRINCIP_OU_SECOND": "Type d'habitation principale ou secondaire",
            "TYP_ANNEXE": "Type d'annexe",
            "I_PISCINE": "Présence d'une piscine dans le projet",
            "I_GARAGE": "Présence d'un garage dans le projet",
            "I_VERANDA": "Présence d'une véranda dans le projet",
            "I_ABRI_JARDIN": "Présence d'un abri de jardin dans le projet",
            "I_AUTRE_ANNEXE": "Présence d'une autre annexe dans le projet",
            "RESIDENCE": "Type de résidence",
            "RES_PERS_AGEES": "Présence d'une résidence pour personnes âgées",
            "RES_ETUDIANTS": "Présence d'une résidence étudiante",
            "RES_TOURISME": "Présence d'une résidence de tourisme",
            "RES_HOTEL_SOCIALE": "Présence d'une résidence hôtelière à vocation sociale",
            "RES_SOCIALE": "Présence d'une résidence sociale",
            "RES_HANDICAPES": "Présence d'une résidence pour personnes handicapées",
            "RES_AUTRE": "Présence d'un autre type de résidence",
            "NB_LGT_TOT_CREES": "Nombre total de logements créés",
            "NB_LGT_IND_CREES": "Nombre de logements individuels créés",
            "NB_LGT_COL_CREES": "Nombre de logements collectifs créés",
            "NB_LGT_INDIV_PURS": "Nombre de logements individuels purs créés",
            "NB_LGT_INDIV_GROUPES": "Nombre de logements individuels groupés créés (hors résidence)",
            "NB_LGT_RES": "Nombre de logements en résidence créés",
            "NB_LGT_COL_HORS_RES": "Nombre de logements collectifs créés hors résidence",
            "NB_LGT_DEMOLIS": "Nombre de logements démolis",
            "NB_LGT_1P": "Nombre de logements de 1 pièce créés",
            "NB_LGT_2P": "Nombre de logements de 2 pièces créés",
            "NB_LGT_3P": "Nombre de logements de 3 pièces créés",
            "NB_LGT_4P": "Nombre de logements de 4 pièces créés",
            "NB_LGT_5P": "Nombre de logements de 5 pièces créés",
            "NB_LGT_6P_PLUS": "Nombre de logements de 6 pièces et plus créés",
            "NB_LGT_PRET_LOC_SOCIAL": "Nb de logements locatifs sociaux",
            "NB_LGT_ACC_SOC_HORS_PTZ": "Nb de logements aidé (hors ptz)",
            "NB_LGT_PTZ": "Nb de logements ptz",
            "SURF_HAB_AVANT": "Surface de plancher de la destination ' Habitation ' existante avant travaux",
            "SURF_HAB_CREEE": "Surface de plancher de la destination ' Habitation ' nouvelle construite",
            "SURF_HAB_ISSUE_TRANSFO": "Surface de plancher de la destination ' Habitation ' issue d'une transformation (changement de destination, réhabilitation, scission, regroupement …)",
            "SURF_HAB_DEMOLIE": "Surface de plancher de la destination ' Habitation ' supprimée (démolie)",
            "SURF_HAB_TRANSFORMEE": "Surface de plancher de la destination ' Habitation ' transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_LOC_AVANT": "Surface de plancher de locaux non résidentiels (i.e. autres que d'habitation) existante avant travaux",
            "SURF_LOC_CREEE": "Surface de plancher de locaux non résidentiels (i.e. autres que d'habitation) nouvelle construite",
            "SURF_LOC_ISSUE_TRANSFO": "Surface de plancher de locaux non résidentiels (i.e. autres que d'habitation) issue d'une transformation (changement de destination, réhabilitation, scission, regroupement …)",
            "SURF_LOC_DEMOLIE": "Surface de plancher de locaux non résidentiels (i.e. autres que d'habitation) démolie",
            "SURF_LOC_TRANSFORMEE": "Surface de plancher de locaux non résidentiels (i.e. autres que d'habitation) transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_HEB_TRANSFORMEE": "Surface d'hébergement hôtelier transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_BUR_TRANSFORMEE": "Surface de bureaux transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_COM_TRANSFORMEE": "Surface de commerce transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_ART_TRANSFORMEE": "Surface d'artisanat transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_IND_TRANSFORMEE": "Surface industrielle transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_AGR_TRANSFORMEE": "Surface agricole transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_ENT_TRANSFORMEE": "Surface d'entrepôt transformée (changement de destination, réhabilitation, scission, regroupement ...)",
            "SURF_PUB_TRANSFORMEE": "Surface de service public ou d'intérêt collectif transformée (changement de destination, réhabilitation, scission, regroupement ...)"
        },
        Etat_DAU: {
            2: "Autorisé",
            4: "Annulé",
            5: "Commencé",
            6: "Terminé",
        },
        CAT_DEM: {
            "10": "Particuliers sans autre indication (SAI)",
            "11": "Particuliers purs",
            "12": "SCI de particuliers",
            "20": "Bailleurs sociaux SAI",
            "21": "Organismes HLM",
            "22": "EPL (ex SEM)",
            "23": "Autres organismes",
            "30": "Promoteurs (SAI)",
            "31": "Promoteurs reconnus comme tels",
            "32": "SCI ou autres supports de programmes de construction",
            "33": "Autres professionnels de la construction",
            "40": "Administrations publiques (SAI)",
            "41": "État, ODAC (Organismes Divers d'Administration Centrale) et organismes rattachés divers",
            "42": "Départements et régions",
            "43": "Communes, EPIC, syndicats de communes",
            "50": "Autres sociétés (de fait, intervenant pour compte propre)",
            "80": "Sans objet (car locaux purs)",
            "90": "Non déterminé (valeur par défaut à la création du permis)"
        },
        TYPE_PRINCIP_LOGTS_CREES: {
            1: "un logement individuel",
            2: "plusieurs logements individuels",
            3: "collectif hors résidence",
            4: "résidence"
        }
    }
}