import {
    TextField,
    useTranslate,
    FunctionField,
    ReferenceField,
    ReferenceArrayField,
    WithListContext,
    useRecordContext,
} from 'react-admin';
import dayjs from 'dayjs';

//Components
import { PipeStatusDropdown } from '../../components/Buttons/PipeStatusDropdown';
import { StyledConfigurableDataGrid } from '../../components/layout/StyledDataGrid';
import { UserSelect } from '../../components/Buttons/UserSelect';
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';
import { LeadScoreHeat } from './LeadScoreHeat';
import { ProspectBulkActionsButton } from '../Buttons/ProspectBulkActionsButtons';
import { StyledTimestamp } from '../Fields/StyledTimestamp';
import { HoverPopover } from '../Popover/HoverPopover';
import { TagManager } from './Tags/TagManager';
import { CreatedByAvatar } from '../Fields/CreatedByAvatar';
import { CheckListCircularProgressBar } from './CheckList/CheckListCircularProgress';

// Mui
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

//Icons
import { ShowIcon } from '../Icons/ShowIcon';
import { EventsTypeIcon } from '../Icons/EventsTypeIcon';

// Types
import { IProspect } from '../../types/Prospect';

// Styles
import theme from '../../theme';

const DataTableSx = {
    "& .MuiToolbar-root.MuiToolbar-regular.RaBulkActionsToolbar-toolbar": {
        alignItems: "center",
        backgroundColor: "transparent",
        marginLeft: "241px",
        padding: 0,
        justifyContent: "flex-start",
        transform: "translateY(-64px)",
        width: "fit-content",
        "& .MuiTypography-root.MuiTypography-subtitle1": {
            color: theme.palette.primary.light,
        },
        "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.RaBulkActionsToolbar-icon": {
            display: "none",
        },
    },
    '& .column-leadScore': {
        padding: '0 0 0 4px',
    },
    '& .column-preview': {
        padding: '0',
    },
    '& .column-tags': {
        padding: '0',
    }
}

export const CurrentProspectsDataTable = (props:
    {
        permissions: string[],
        filter: any,
        onOpenPreview: (id: string) => void,
        displayCheckList?: boolean
    }) => {
    const t = useTranslate();

    const ContactField = (props: any) => {
        const record = useRecordContext(props);

        return (<div className="flex justify-start items-center group relative gap-2">
            <div className="flex flex-col">
                <TextField sortBy='createdAt' source="name" label={t('prospect.property.name')} />
                {record?.status === "accepted" &&
                    <div className="preview-button-column invisible">
                        <StyledTimestamp source="createdAt" label={false} />
                    </div>
                }
            </div>
        </div >)
    }

    const Preview = (props: { label: string, source: string, onOpenPreview: (id: string) => void }) => {
        const record = useRecordContext();
        return record ?
            <div className="preview-button-column invisible">
                <IconButton color="primary" onClick={(e) => { e.stopPropagation(); props.onOpenPreview((record.id) as string) }}><ShowIcon stroke={theme.palette.primary.main} /></IconButton >
            </div>
            : <></>
    }

    const Tags = (props: { label: string, source: string }) => {
        const record = useRecordContext();
        return (record) ?
            <div className="column-tags">
                <TagManager maxItems={1} />
            </div>
            : <></>
    }

    const postRowSx = () => ({
        "&:hover .preview-button-column": {
            visibility: "visible",
        },
    });

    return <StyledConfigurableDataGrid
        omit={['projects.0.floorArea', 'projects.0.projectedRevenue', 'projects.0.numberOfUnits']}
        sx={{ ...DataTableSx }}
        bulkActionButtons={
            <ProspectBulkActionsButton />
        }
        rowClick={"show"}
        rowSx={postRowSx}
    >
        <Preview label="" source="preview" onOpenPreview={props.onOpenPreview} />
        <LeadScoreHeat label="" source="leadScore" />
        <ContactField sortBy="createdAt" source="name" label={t('prospect.property.name')} onOpenPreview={props.onOpenPreview} />
        <Tags label="" source="tags" />
        <FunctionField source="address" label={t('prospect.property.address')} render={(record: any) => record?.address?.toLowerCase().includes(record.cityName?.toLowerCase()) ? record?.address : `${record.address}, ${record.cityName}`} />
        <FunctionField source="type" label="Type" render={(record: any) => t(`prospect.type.${record.type}`)} />
        <FunctionField source="fieldSurface" label={t('prospect.property.fieldSurface')} render={(record: any) => `${record.fieldSurface ?? 0} m²`} />
        <FunctionField
            label={t('prospect.property.projects.floorArea')}
            source="projects.0.floorArea"
            render={(record: any) => record.projects[0]?.floorArea ? `${new Intl.NumberFormat('fr-FR').format(record.projects[0]?.floorArea)} m²` : null}
        />
        <FunctionField
            label={t('prospect.property.projects.projectedRevenue')}
            source="projects.0.projectedRevenue"
            render={(record: any) => record.projects[0]?.projectedRevenue ? `${new Intl.NumberFormat('fr-FR').format(record.projects[0]?.projectedRevenue)} €` : null}
        />
        <TextField
            label={t('prospect.property.projects.numberOfUnits')}
            source="projects.0.numberOfUnits"
        />
        <FunctionField source="events.0.eventDate" sortBy="events.0.eventDate" label={t('prospect.property.lastContactedAt')} render={(record: IProspect) =>
            record.events && record?.events?.length > 0 ?
                <HoverPopover
                    hoverContent={<Box className="text-sm">{dayjs(record?.events[0]?.eventDate).format('DD/MM/YYYY')}</Box>}
                    popoverContent={
                        <Box className="bg-darker flex gap-2 text-sm p-2 items-center">
                            <EventsTypeIcon type={(record?.events[0]?.type) as "call" | "email" | "meeting" | "postMail"} />
                            <Box className="text-white">{record?.events[0]?.title}</Box>
                        </Box>
                    }
                />
                : <Box className="bg-error w-7 h-[3px]">
                </Box>
        } />
        {
            props.permissions?.includes('superAdmin') === true && (
                <>
                    <ReferenceField source="organisationId" reference="organisations" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceArrayField source="userIds" reference="users">
                        <WithListContext render={({ data }) => (
                            <Box>
                                {data?.map(dev => (
                                    <Chip key={dev.id} label={`${dev.firstName} ${dev.lastName}`} />
                                ))}
                            </Box>
                        )} />
                    </ReferenceArrayField>
                </>
            )
        }
        <SingleSplitTreatment
            name="dispatch-prospects-users"
            render={<UserSelect source="userIds" onChange={(e: any) => { }} />}
        />
        <FunctionField source="pipeStatus" label={t('prospect.property.status')} render={(prospect: IProspect) => {
            return <PipeStatusDropdown variant={"normal"} pipeStatus={prospect.pipeStatus} onChange={(e: any) => { }} />
        }}
        />
        <CreatedByAvatar source="createdBy" label={t('followedBy')} />
        {(props.displayCheckList) && (
            <FunctionField source="checkListItems" label={''} render={(record: IProspect) => (
                <Box className="relative w-6 h-6">
                    <CheckListCircularProgressBar size="small" />
                </Box>
            )} />
        )}
    </StyledConfigurableDataGrid>
}

CurrentProspectsDataTable.defaultProps = {
    displayCheckList: false
}