import { useState } from "react";
import { useController } from 'react-hook-form';

//Mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

export const LeadScoreInput = (props: { name: string }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedScore, setSelectedScore] = useState<number | null>(null);
    const potentials = [4, 6, 8];
    const open = Boolean(anchorEl);

    const getColor = (score: number) => {
        return score >= 8 ? "#FC814F" : score >= 6 ? "#FCC43E" : "#FFE096";
    }

    const { field } = useController(props);

    const handleScoreClick = (score: number | null) => {
        setSelectedScore(score);
        field.onChange(score === null ? 0 : score);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <svg
                    className="transition-all"
                    width="25"
                    fill={selectedScore !== null ? getColor(selectedScore) : "none"}
                    viewBox="-2 -3 24 29"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        stroke={selectedScore !== null ? getColor(selectedScore) : "#FC814F"}
                        strokeWidth={selectedScore !== null ? 0 : 2}
                        clipRule="evenodd"
                        d="M17.8048 8.61018C16.5685 9.84644 15.8704 10.3846 15.565 13.1334C15.2013 16.5659 9.9218 14.0206 12.9324 11.3736C16.1322 8.56655 14.1105 4.49416 11.0562 2.55978C12.2343 4.81413 12.2343 6.60308 11.5944 7.60663C10.6054 9.16286 8.9037 7.89751 9.31094 6.23947C9.84907 4.13055 11.289 1.04718 6.54754 0C9.02005 3.92694 2.2279 7.83934 4.56952 14.806C1.7334 14.1224 4.80223 10.4428 2.21336 8.02841C1.90793 11.839 -0.899106 14.2097 0.29352 18.8493C2.27153 25.0306 13.1942 25.976 18.0956 20.4928C21.0336 17.2204 17.1212 12.6244 17.8048 8.62472V8.61018Z"
                    />
                </svg>
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -60,
                }}
            >
                <Box className="flex gap-4">
                    <IconButton onClick={() => handleScoreClick(null)}>
                        <svg width="20" height="25" viewBox="-2 -3 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                stroke="#FC814F"
                                strokeWidth={2}
                                d="M17.8048 8.61018C16.5685 9.84644 15.8704 10.3846 15.565 13.1334C15.2013 16.5659 9.9218 14.0206 12.9324 11.3736C16.1322 8.56655 14.1105 4.49416 11.0562 2.55978C12.2343 4.81413 12.2343 6.60308 11.5944 7.60663C10.6054 9.16286 8.9037 7.89751 9.31094 6.23947C9.84907 4.13055 11.289 1.04718 6.54754 0C9.02005 3.92694 2.2279 7.83934 4.56952 14.806C1.7334 14.1224 4.80223 10.4428 2.21336 8.02841C1.90793 11.839 -0.899106 14.2097 0.29352 18.8493C2.27153 25.0306 13.1942 25.976 18.0956 20.4928C21.0336 17.2204 17.1212 12.6244 17.8048 8.62472V8.61018Z"
                            />
                        </svg>
                    </IconButton>
                    {potentials.map((score: number) => (
                        <>
                            <IconButton key={score} onClick={() => handleScoreClick(score)}>
                                <svg
                                    width="20"
                                    height="25"
                                    viewBox="0 0 20 25"
                                    fill={getColor(score)}
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M17.8048 8.61018C16.5685 9.84644 15.8704 10.3846 15.565 13.1334C15.2013 16.5659 9.9218 14.0206 12.9324 11.3736C16.1322 8.56655 14.1105 4.49416 11.0562 2.55978C12.2343 4.81413 12.2343 6.60308 11.5944 7.60663C10.6054 9.16286 8.9037 7.89751 9.31094 6.23947C9.84907 4.13055 11.289 1.04718 6.54754 0C9.02005 3.92694 2.2279 7.83934 4.56952 14.806C1.7334 14.1224 4.80223 10.4428 2.21336 8.02841C1.90793 11.839 -0.899106 14.2097 0.29352 18.8493C2.27153 25.0306 13.1942 25.976 18.0956 20.4928C21.0336 17.2204 17.1212 12.6244 17.8048 8.62472V8.61018Z"
                                    />
                                </svg>
                            </IconButton>
                        </>
                    ))}
                </Box>
            </Popover>
        </>
    );
};
