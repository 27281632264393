// In src/prospects/PostListContent.tsx
import { Box } from "@mui/material";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useListContext, useUpdate } from "react-admin";
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";

import type { IProspect, ProspectPipeStatusesType } from "../../types/Prospect";
import { ProspectsByStatus, getProspectsByStatus, updateProspectStatusLocal } from "../../helpers/prospects";
import { allPipeStatuses } from "../../helpers/constants";
import { ProspectKanbanColumn } from "./KanbanColumn";

export const KanbanView = (props: { statuses: ProspectPipeStatusesType[], onOpenPreview: (prospect: IProspect) => void }) => {
    const { data: unorderedProspects, isLoading, filterValues } = useListContext<IProspect>();
    const [updateOne] = useUpdate();

    const [prospectsByStatus, setProspectsByStatus] = useState<ProspectsByStatus>(
        getProspectsByStatus([])
    );

    useEffect(() => {
        if (unorderedProspects) {
            const newProspectsByStatus = getProspectsByStatus(unorderedProspects);
            if (!isEqual(newProspectsByStatus, prospectsByStatus)) {
                setProspectsByStatus(newProspectsByStatus);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unorderedProspects]);

    if (isLoading) return null;

    const handleTypeChange = (prospect: IProspect, pipeStatus: ProspectPipeStatusesType) => {
        updateOne(
            'prospects',
            { id: prospect?.id, data: { pipeStatus: (pipeStatus as string) }, previousData: {} },
            {
                onSuccess: (data: any) => {
                    // refresh();
                }
            }
        );
    };

    const onDragEnd: OnDragEndResponder = (result) => {

        window.analytics?.track('Used Kanban to update status');

        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const sourceStatus = source.droppableId as IProspect["pipeStatus"];
        const destinationStatus = destination.droppableId as IProspect["pipeStatus"];
        const sourcePost = prospectsByStatus[sourceStatus][source.index]!;

        //Destination index is calculated by getting index of sourePost in an ordered array of posts in the destination status, sorted by createdAt
        const destinationIndex = [...prospectsByStatus[destinationStatus], sourcePost].sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }).findIndex((post) => post.id === sourcePost.id);

        const destinationPost = prospectsByStatus[destinationStatus][
            destination.index
        ] ?? {
            status: destinationStatus,
            index: undefined, // undefined if dropped after the last item 
        };

        // compute local state change synchronously
        setProspectsByStatus(
            updateProspectStatusLocal(
                sourcePost,
                { status: sourceStatus, index: source.index },
                { status: destinationStatus, index: destinationIndex },
                prospectsByStatus
            )
        );

        // trigger the mutation to persist the changes
        handleTypeChange(sourcePost, destinationStatus);
    };


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex" className="pt-8 gap-4 overflow-x-scroll h-full">
                {filterValues?.pipeStatus
                    ?.sort((a: ProspectPipeStatusesType, b: ProspectPipeStatusesType) => allPipeStatuses.indexOf(a) - allPipeStatuses.indexOf(b))
                    ?.map((status: ProspectPipeStatusesType) => (
                        <ProspectKanbanColumn
                            status={status}
                            prospects={prospectsByStatus[status]}
                            key={status}
                            onOpenPreview={props.onOpenPreview}
                        />
                    ))}
            </Box>
        </DragDropContext>
    );
};