import React, { useState } from 'react';
import { useGetOne, useNotify, useTranslate, useUpdateMany } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { dataProvider } from '../../dataProvider';
import { EmailTemplatesManager } from './EmailTemplatesManager';
import { EmailEditor } from './EmailEditor';

export const EmailSender = (props: {
    defaultMode: "edit" | "template",
    channel: "email" | "postMail",
    dialogOpen: boolean,
    setDialogOpen: any,
    prospectIds: string[],
    prospectEmails: string[],
    onSuccess: () => void
}) => {

    const { dialogOpen, setDialogOpen, channel, defaultMode, prospectIds } = props;
    const t = useTranslate();
    const { data: myOrganisation } = useGetOne('organisations', { id: 'my-organisation' },
        { staleTime: 180 * 60 * 1000, gcTime: 240 * 60 * 1000 }
    );
    const [mode, setMode] = useState<"edit" | "template">(defaultMode);

    const [update] = useUpdateMany();
    const notify = useNotify();

    const updatePipeStatus = (failedIds: string[]) => {
        let successIds = prospectIds?.filter((id: string) => !failedIds.includes(id));
        update(
            "prospects",
            { ids: successIds, data: { pipeStatus: "contacted" } },
            {
                onSuccess: () => {
                },
                onError: () => {
                    notify('BasicError', { type: 'error' });
                }
            }
        )
    }

    const handleClick = (data: { title: string, body: string, templateId?: string }) => {
        if (channel === "email") return sendEmail(data);
        if (channel === "postMail") return generatePDF(data);
    }

    const generatePDF = async (data: { title: string, body: string, templateId?: string }) => {
        try {
            await dataProvider.generatePDF(`prospects/generate-mail-pdf`, { prospectIds, message: data.body, title: data.title, templateId: data.templateId });
        } catch (error) {
            notify(`Erreur lors de la génération du courrier`, { type: "error" });
        }
    }

    const sendEmail = (data: { title: string, body: string }) => {
        if (prospectIds) {

            if (prospectIds.length > 0) {
                window.analytics?.track('Used Bulk Action', { action: "sendEmail" });
            }

            const failedIds: string[] = [];

            const promises = prospectIds.map((id: string) => {
                return new Promise((resolve, reject) => {
                    return dataProvider.create(`prospects/${id}/send-notification`, { data: { mode, channel: "email", message: data?.body, title: t(`prospect.actions.sendNotification.${channel}.success`, { title: data?.title ? `"${data?.title}"` : '' }) } })
                        .then((data) => {
                            resolve(data);
                        })
                        .catch(() => {
                            failedIds.push(id);
                            reject({ data: { id } });
                        })
                })
            });

            Promise.allSettled(promises)
                .then((promises) => {
                    if (failedIds.length > 0) {
                    } else {
                        setDialogOpen(false);
                        props.onSuccess();
                        notify(
                            <Alert severity="success" className='flex items-center'>
                                <Box className="flex items-center gap-2">
                                    {t(`prospect.actions.sendNotification.${channel}.success`, { title: data?.title ?? '' })}
                                    <Button onClick={() => updatePipeStatus(failedIds)}>
                                        {t('prospect.actions.updateStatus')}
                                    </Button>
                                </Box>
                            </Alert >,
                            { type: 'success', autoHideDuration: 10000 }
                        );
                    }
                })
                .catch((error) => {
                    notify(`BasicError`);
                })
        }
    }


    return (
        <Dialog fullWidth maxWidth={'md'} open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Box className="flex justify-between items-start">
                    <Box>
                        {t(`prospect.actions.sendNotification.${channel}.title`)}
                        {props.prospectEmails?.length === 1 ?
                            <span className="text-primary ml-1">
                                {props.prospectEmails[0]}
                            </span>
                            :
                            <Box className="flex gap-2 text-sm items-center">
                                {props.prospectEmails?.slice(0, 3).map((email, index) => {
                                    return <Box className="bg-neutral-100 px-2 rounded-sm" key={index}>
                                        {email}
                                    </Box>
                                })}
                                {props.prospectEmails?.length > 3 &&
                                    <Box className="bg-neutral-100 px-2 rounded-full w-8 h-8 flex items-center justify-center">+{props.prospectEmails.length - 3}</Box>
                                }
                            </Box>
                        }
                    </Box>
                    {myOrganisation?.emailTemplates?.length > 0 && mode === "edit" &&
                        <Button variant="contained" onClick={() => setMode("template")}>{t('organisation.property.emailTemplates.myTemplates')}</Button>
                    }
                    {mode === "template" &&
                        <Button variant="contained" onClick={() => setMode("edit")}>{t('buttons.editor')}</Button>
                    }
                </Box>
            </DialogTitle>
            {mode === "edit" &&
                <>
                    <DialogContent>
                        <EmailEditor channel={channel} prospectIds={prospectIds} onSubmit={(data: { title: string, body: string }) => handleClick(data)} />
                    </DialogContent>
                    <DialogActions sx={{ paddingRight: '24px', paddingBottom: '24px' }}>
                        <Button onClick={() => setDialogOpen(false)} color="primary" variant="outlined">
                            {t('ra.action.cancel')}
                        </Button>
                        <Button type="submit" form="rich-form" color="primary" variant="contained">
                            {t('ra.action.confirm')}
                        </Button>
                    </DialogActions>
                </>
            }
            {mode === "template" &&
                <DialogContent>
                    <EmailTemplatesManager channel={channel} templates={myOrganisation?.emailTemplates ?? []} onSendTemplate={(data: { title: string, body: string, templateId: string }) => handleClick(data)} />
                </DialogContent>
            }
        </Dialog >
    );
}