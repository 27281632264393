import { RadioButtonGroupInput, SimpleForm, TextInput, useTranslate } from "react-admin"

//Mui
import Box from "@mui/material/Box";

//components
import { ColorPickerInput } from "../Inputs/ColorPickerInput";
import theme from "../../theme";

export const CreateTagForm = () => {
    const t = useTranslate();
    let randomColor = [theme.palette.primary.main, theme.palette.info.main, theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main][Math.floor(Math.random() * 5)];

    return <>
        <Box className="flex gap-4">
            <TextInput
                label={t('tags.property.name')}
                source="name"
                fullWidth
            />
            <ColorPickerInput source={"bgColor"} randomColor={randomColor} />
        </Box>
        <RadioButtonGroupInput label={t('tags.property.visibility')} source="scope" defaultValue="user" choices={[
            { id: 'user', name: t('tags.visibility.user') },
            { id: 'team', name: t('tags.visibility.team') },
            { id: 'organisation', name: t('tags.visibility.organisation') },
        ]} />
    </>
}

