import { styled } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const ShadowedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    border: 0,
    backgroundColor: theme.palette.background.default,
    borderRadius: '12px',
    padding: '4px',
    '& .MuiToggleButtonGroup-grouped': {
        border: 0,
        padding: '8px 8px',
        '& .MuiSvgIcon-root': {
            marginRight: "2px",
        },
        '&:not(:first-of-type)': {
            marginLeft: "4px",
            justifyItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '&.Mui-selected': {
            backgroundColor: 'white',
            color: theme.palette.grey[800],
            borderRadius: '12px',
            boxShadow: '0px 5px 28px rgba(0, 0, 0, 0.12)',
        },
        '&.Mui-selected.MuiToggleButton-root:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
            border: 0,
        },
    },
    '& .MuiToggleButton-root:hover': {
        backgroundColor: 'transparent',
    }
}));

export const NoBorderToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.MuiToggleButton-root.Mui-selected.MuiToggleButtonGroup-grouped": {
        border: 0,
    }
}));