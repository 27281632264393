import { useState, useEffect, useRef } from 'react';
import { useRecordContext, useTranslate, useRefresh, useCreate, useNotify } from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import theme from '../../theme';
import { SingleSplitTreatment } from '../layout/SingleSplitTreatment';
import { IProspect } from '../../types/Prospect';
import Link from '@mui/material/Link';

// Icons
import { CircleCheck } from '../Icons/CircleCheck';
import { VoiceMailIcon } from '../Icons/VoiceMailIcon';

export const DropVoiceMailButton = () => {
    const record: IProspect | undefined = useRecordContext<IProspect>();
    const t = useTranslate();
    const isSendingRef = useRef(false); // Add state for sending status
    const [, forceUpdate] = useState({}); // Use state to force a re-render
    const [addOne, { isLoading }] = useCreate();
    const notify = useNotify();
    const [isSuccess, setIsSuccess] = useState(false);
    const refresh = useRefresh();

    if (!record || !record.phoneNumber) {
        return null;
    }

    const sendVoiceMail = () => {
        addOne(`prospects/${record.id}/send-notification`, { data: { channel: "voicemail", title: t('prospect.actions.sendNotification.voicemail.success') } },
            {
                onSuccess: (data) => {
                    notify(data?.body?.name ?? `notifications.sent`, { type: "success" });
                    isSendingRef.current = false; // Set the sending status to false when done
                    setIsSuccess(true);
                    refresh();
                },
                onError: (data: any) => {
                    notify(data?.body?.name ?? 'BasicError', { type: "error" });
                    isSendingRef.current = false; // Set the sending status to false when done
                    forceUpdate({}); // Force a re-render
                }
            });
    }

    const handleClick = () => {
        isSendingRef.current = true; // Set the sending status to false when done
        forceUpdate({}); // Force a re-render
        setTimeout(() => {
            if (isSendingRef.current) {
                sendVoiceMail(); // Call sendVoiceMail method after 2 seconds
            }
        }, 4000);
    };

    const handleCancel = () => {
        isSendingRef.current = false; // Set the sending status to false when done
        forceUpdate({}); // Force a re-render
    };

    return (
        <SingleSplitTreatment
            name="send-notification-phone"
            render={
                <div className='flex items-center gap-x-2'>
                    <>
                        {isSuccess ?
                            <div className='flex items-center gap-x-2 text-success'>
                                <VoiceMailIcon fill={theme.palette.success.main} />
                                <Typography variant="body2">{t('prospect.actions.sendNotification.voicemail.success')}</Typography >
                            </div>
                            :
                            <>
                                {isSendingRef.current === false ?
                                    <>
                                        <VoiceMailIcon stroke="none" fill={theme.palette.primary.main} />
                                        <Link
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => { e.stopPropagation(); handleClick() }}
                                            variant="body2"
                                        >
                                            {t('prospect.actions.sendNotification.voicemail.title')}
                                        </Link>
                                        {record.verifiedPhone && (
                                            <Tooltip placement="top" title={t('prospect.property.verifiedPhoneNumber')} >
                                                <div>
                                                    <CircleCheck color="success" />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </>
                                    :
                                    <>
                                        <VoiceMailIcon stroke="none" />
                                        <Typography variant="body2">{t('prospect.actions.sendNotification.sending')}</Typography>
                                        <button className='text-error underline' onClick={handleCancel}>
                                            <Typography fontWeight={500} variant="body2">
                                                {t('ra.action.cancel')}
                                            </Typography>
                                        </button>
                                    </>
                                }
                            </>
                        }
                    </>
                </div >
            }
            offRender={
                <></>
            }
        />
    );
};