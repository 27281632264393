import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface Props {
    emoji: string
}

export const EmojiIcon = (props: SvgIconProps & Props) => {
    switch (props.emoji) {
        case "new":
            return <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C21.9037 2.5 27.5 8.09625 27.5 15C27.5 21.9037 21.9037 27.5 15 27.5ZM20 16.25C20 17.5761 19.4732 18.8479 18.5355 19.7855C17.5979 20.7232 16.3261 21.25 15 21.25C13.6739 21.25 12.4021 20.7232 11.4645 19.7855C10.5268 18.8479 10 17.5761 10 16.25H20ZM15 25C17.6522 25 20.1957 23.9464 22.0711 22.0711C23.9464 20.1957 25 17.6522 25 15C25 12.3478 23.9464 9.8043 22.0711 7.92893C20.1957 6.05357 17.6522 5 15 5C12.3478 5 9.8043 6.05357 7.92893 7.92893C6.05357 9.8043 5 12.3478 5 15C5 17.6522 6.05357 20.1957 7.92893 22.0711C9.8043 23.9464 12.3478 25 15 25ZM11.875 13.75C12.3723 13.75 12.8492 13.5525 13.2008 13.2008C13.5525 12.8492 13.75 12.3723 13.75 11.875C13.75 11.3777 13.5525 10.9008 13.2008 10.5492C12.8492 10.1975 12.3723 10 11.875 10C11.3777 10 10.9008 10.1975 10.5492 10.5492C10.1975 10.9008 10 11.3777 10 11.875C10 12.3723 10.1975 12.8492 10.5492 13.2008C10.9008 13.5525 11.3777 13.75 11.875 13.75ZM18.125 13.75C18.6223 13.75 19.0992 13.5525 19.4508 13.2008C19.8025 12.8492 20 12.3723 20 11.875C20 11.3777 19.8025 10.9008 19.4508 10.5492C19.0992 10.1975 18.6223 10 18.125 10C17.6277 10 17.1508 10.1975 16.7992 10.5492C16.4475 10.9008 16.25 11.3777 16.25 11.875C16.25 12.3723 16.4475 12.8492 16.7992 13.2008C17.1508 13.5525 17.6277 13.75 18.125 13.75Z" fill="currentColor" />
            </SvgIcon>
        case "good":
            return <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
                <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                <path d="M10 17.5C10 17.5 11.875 20 15 20C18.125 20 20 17.5 20 17.5M11.25 11.25H11.2625M18.75 11.25H18.7625" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            </SvgIcon>
        case "bad":
            return <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
                <path d="M11.25 13.75C11.5815 13.75 11.8995 13.6183 12.1339 13.3839C12.3683 13.1495 12.5 12.8315 12.5 12.5C12.5 12.1685 12.3683 11.8505 12.1339 11.6161C11.8995 11.3817 11.5815 11.25 11.25 11.25C10.9185 11.25 10.6005 11.3817 10.3661 11.6161C10.1317 11.8505 10 12.1685 10 12.5C10 12.8315 10.1317 13.1495 10.3661 13.3839C10.6005 13.6183 10.9185 13.75 11.25 13.75ZM17.5 21.25C17.5 20.587 17.2366 19.9511 16.7678 19.4822C16.2989 19.0134 15.663 18.75 15 18.75C14.337 18.75 13.7011 19.0134 13.2322 19.4822C12.7634 19.9511 12.5 20.587 12.5 21.25H10C10 19.9239 10.5268 18.6521 11.4645 17.7145C12.4021 16.7768 13.6739 16.25 15 16.25C16.3261 16.25 17.5979 16.7768 18.5355 17.7145C19.4732 18.6521 20 19.9239 20 21.25H17.5ZM20 12.5C20 12.8315 19.8683 13.1495 19.6339 13.3839C19.3995 13.6183 19.0815 13.75 18.75 13.75C18.4185 13.75 18.1005 13.6183 17.8661 13.3839C17.6317 13.1495 17.5 12.8315 17.5 12.5C17.5 12.1685 17.6317 11.8505 17.8661 11.6161C18.1005 11.3817 18.4185 11.25 18.75 11.25C19.0815 11.25 19.3995 11.3817 19.6339 11.6161C19.8683 11.8505 20 12.1685 20 12.5Z" fill="currentColor" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5 15C27.5 21.9037 21.9037 27.5 15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C21.9037 2.5 27.5 8.09625 27.5 15ZM25 15C25 17.6522 23.9464 20.1957 22.0711 22.0711C20.1957 23.9464 17.6522 25 15 25C12.3478 25 9.8043 23.9464 7.92893 22.0711C6.05357 20.1957 5 17.6522 5 15C5 12.3478 6.05357 9.8043 7.92893 7.92893C9.8043 6.05357 12.3478 5 15 5C17.6522 5 20.1957 6.05357 22.0711 7.92893C23.9464 9.8043 25 12.3478 25 15Z" fill="currentColor" />
            </SvgIcon>
        default:
            return <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C21.9037 2.5 27.5 8.09625 27.5 15C27.5 21.9037 21.9037 27.5 15 27.5ZM20 16.25C20 17.5761 19.4732 18.8479 18.5355 19.7855C17.5979 20.7232 16.3261 21.25 15 21.25C13.6739 21.25 12.4021 20.7232 11.4645 19.7855C10.5268 18.8479 10 17.5761 10 16.25H20ZM15 25C17.6522 25 20.1957 23.9464 22.0711 22.0711C23.9464 20.1957 25 17.6522 25 15C25 12.3478 23.9464 9.8043 22.0711 7.92893C20.1957 6.05357 17.6522 5 15 5C12.3478 5 9.8043 6.05357 7.92893 7.92893C6.05357 9.8043 5 12.3478 5 15C5 17.6522 6.05357 20.1957 7.92893 22.0711C9.8043 23.9464 12.3478 25 15 25ZM11.875 13.75C12.3723 13.75 12.8492 13.5525 13.2008 13.2008C13.5525 12.8492 13.75 12.3723 13.75 11.875C13.75 11.3777 13.5525 10.9008 13.2008 10.5492C12.8492 10.1975 12.3723 10 11.875 10C11.3777 10 10.9008 10.1975 10.5492 10.5492C10.1975 10.9008 10 11.3777 10 11.875C10 12.3723 10.1975 12.8492 10.5492 13.2008C10.9008 13.5525 11.3777 13.75 11.875 13.75ZM18.125 13.75C18.6223 13.75 19.0992 13.5525 19.4508 13.2008C19.8025 12.8492 20 12.3723 20 11.875C20 11.3777 19.8025 10.9008 19.4508 10.5492C19.0992 10.1975 18.6223 10 18.125 10C17.6277 10 17.1508 10.1975 16.7992 10.5492C16.4475 10.9008 16.25 11.3777 16.25 11.875C16.25 12.3723 16.4475 12.8492 16.7992 13.2008C17.1508 13.5525 17.6277 13.75 18.125 13.75Z" fill="#1E1E1E" />
            </SvgIcon>
    }
}