import { SplitTreatments } from "@splitsoftware/splitio-react";

export const SingleSplitTreatment = (props: { name: string, render: JSX.Element, offRender?: JSX.Element }) => {
    return <SplitTreatments names={[props.name]} >
        {({ isReady, treatments }) => {
            if (!isReady) return null;
            if (treatments[props.name]?.treatment !== 'on') return props.offRender ?? null;
            return props.render;
        }}
    </SplitTreatments>
}