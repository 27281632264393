export function Accordion(theme) {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    border: 'solid 4px #F8F9FB',
                    "&::before": {
                        display: 'none',
                    },
                    "&.Mui-expanded": {
                        margin: '0px',
                    },
                },
            },
        }
    };
}

export function AccordionSummary(theme) {
    return {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    paddingRight: '30px',
                    "&.Mui-expanded": {
                        minHeight: '48px',
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "12px 0"
                    },
                    "& .MuiAccordionSummary-expandIconWrapper": {
                        marginTop: '12px'
                    }
                },
                content: {
                    flexDirection: 'column',
                    "&.Mui-expanded": {
                        margin: '0px',
                    },
                },
            },
        }
    };
}