import React, { useContext, useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Layer, Source } from "react-map-gl"
import { PCsFilter } from '../../Forms/ExploreFilters/PCsFilter';
import { CommuneContext } from '../../../contexts/contexts';

interface PCS {
    id: string;
    latitude: number;
    longitude: number;
}

const PCSLayer: React.FC = () => {
    const [destination, setDestination] = useState<number>(1);
    const [statuses, setStatus] = useState<string[]>([]);
    const [year, setYear] = useState<number | undefined>(undefined);
    const [nbUnits, setNbUnits] = useState<number | undefined>(undefined);
    const selectedCommune = useContext(CommuneContext);
    const { data: pcs, isLoading, error } = useGetList<PCS>('permits', {
        filter: {
            COMM: selectedCommune?.code,
            DESTINATION_PRINCIPALE: destination,
            ETAT_DAU: { $in: statuses.map(Number) },
            AN_DEPOT: year ?? { $exists: true },
            NB_LGT_TOT_CREES: nbUnits ? { $gte: nbUnits } : undefined
        }
    });
    const [geoJsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection<GeoJSON.Point>>({
        type: 'FeatureCollection',
        features: [],
    });

    useEffect(() => {
        if (pcs) {
            const features = pcs.map((pc: any) => ({
                type: 'Feature',
                geometry: pc.location,
                properties: { ...pc },
            }));
            setGeoJsonData({
                type: 'FeatureCollection',
                // @ts-ignore
                features,
            });
        }
    }, [pcs]);

    //Return Markers
    return (<div className="">
        <PCsFilter
            onPCStateChagne={(statuses: string[]) => { setStatus(statuses) }}
            onYearChange={(year: number | undefined) => { setYear(year) }}
            onNbUnitsChange={(nbUnits: number | undefined) => { setNbUnits(nbUnits) }}
        />
        <Source promoteId={"id"} id="pcs" type="geojson" data={geoJsonData}>
            <Layer
                id="pcs"
                type="circle"
                //Paint as a white circle. Borer color depend on a property ETAT_DAU
                paint={{
                    'circle-radius': 8,
                    'circle-color': 'white',
                    'circle-stroke-color': [
                        'case',
                        ['==', ['get', 'ETAT_DAU'], 2], 'green',
                        ['==', ['get', 'ETAT_DAU'], 4], 'red',
                        ['==', ['get', 'ETAT_DAU'], 5], 'yellow',
                        ['==', ['get', 'ETAT_DAU'], 6], 'blue',
                        'black'
                    ],
                    'circle-stroke-width': 2
                }}
            />
        </Source>
    </div>
    );
};

export default PCSLayer;