import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DoubleArrowIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 12 28' fill="none" {...props}>
            <path d="M6.53387 0.466134C6.23902 0.171289 5.76098 0.171289 5.46613 0.466134L0.661344 5.27093C0.366498 5.56577 0.366498 6.04381 0.661344 6.33866C0.95619 6.6335 1.43423 6.6335 1.72908 6.33866L6 2.06773L10.2709 6.33866C10.5658 6.6335 11.0438 6.6335 11.3387 6.33866C11.6335 6.04381 11.6335 5.56577 11.3387 5.27092L6.53387 0.466134ZM5.46614 27.5339C5.76098 27.8287 6.23902 27.8287 6.53387 27.5339L11.3387 22.7291C11.6335 22.4342 11.6335 21.9562 11.3387 21.6613C11.0438 21.3665 10.5658 21.3665 10.2709 21.6613L6 25.9323L1.72908 21.6613C1.43423 21.3665 0.956191 21.3665 0.661345 21.6613C0.366499 21.9562 0.366499 22.4342 0.661345 22.7291L5.46614 27.5339ZM5.245 1L5.245 27L6.755 27L6.755 1L5.245 1Z" fill="currentColor" />
        </SvgIcon>
    )
}

