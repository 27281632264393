export const contactMessages = {
    contact: {
        actions: {
            addContact: "Ajouter un intervenant",
        },
        roles: {
            architect: "Architecte",
            builder: "Constructeur",
            developer: "Promoteur",
            notary: "Notaire",
            owner: "Propriétaire",
            propertyFinder: "Apporteur d'affaire",
        }
    }
}
