import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ConstructionIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 28 19' fill="none" {...props}>
            <g id="Layer_1" clip-path="url(#clip0_2057_7246)">
                <path id="Vector" d="M15.4275 6.03394C15.7606 4.79972 16.5785 4.06018 17.6119 4.06018C18.2633 4.06018 18.9147 4.36873 19.4975 4.94666L20.1979 5.64702C20.2273 5.32378 20.3644 5.02992 20.5946 4.79972L22.3088 3.08554C22.3186 3.01697 22.3333 2.9484 22.348 2.87494H14.5264V7.32203H15.0798L15.4324 6.02414L15.4275 6.03394Z" fill="currentColor" />
                <path id="Vector_2" d="M13.3511 2.88474H4.7998V7.33182H13.3511V2.88474Z" fill="currentColor" />
                <path id="Vector_3" d="M9.72656 8.51215V12.9641H13.5516L14.7565 8.51215H9.72656Z" fill="currentColor" />
                <path id="Vector_4" d="M13.2581 14.1396H4.7998V18.5867H13.3511V15.4473C13.2189 15.0604 13.1846 14.6147 13.263 14.1396H13.2581Z" fill="currentColor" />
                <path id="Vector_5" d="M8.55134 8.51215H0V12.9592H8.55134V8.51215Z" fill="currentColor" />
                <path id="Vector_6" d="M23.4743 8.51215L23.2686 8.71785L25.1003 10.5496C25.8349 11.2842 26.1337 12.156 25.9231 12.9494C25.9231 12.9543 25.9231 12.9592 25.9182 12.9641H28.0046V8.51704H23.4792L23.4743 8.51215Z" fill="currentColor" />
                <path id="Vector_7" d="M15.4079 16.8333C15.0896 16.8333 14.7908 16.7745 14.5264 16.6717V18.5867H23.0777V14.8693L16.3091 16.7011C16.0006 16.7843 15.6969 16.8284 15.4079 16.8284V16.8333Z" fill="currentColor" />
                <path id="Vector_8" d="M22.5489 9.11456L20.6584 11.0051C20.5458 11.1177 20.394 11.1765 20.2421 11.1765C20.0903 11.1765 19.9434 11.1177 19.8258 11.0051C19.7524 10.9316 19.7132 10.8434 19.6838 10.7553C19.7377 10.77 19.7915 10.7896 19.8454 10.7896C19.9972 10.7896 20.1442 10.7308 20.2617 10.6181L23.3277 7.55221C23.5578 7.32201 23.5578 6.94979 23.3277 6.7196L22.3873 5.77925L23.9154 4.25117C23.9595 4.25607 24.0035 4.26587 24.0525 4.26587C24.3023 4.26587 24.5521 4.16791 24.7431 3.9769L27.045 1.675C27.427 1.29298 27.427 0.670974 27.045 0.288955C26.663 -0.0930632 26.041 -0.0930632 25.6589 0.288955L23.357 2.59086C23.1317 2.81615 23.0436 3.12471 23.0828 3.41857L21.1384 5.36294C20.9082 5.59314 20.9082 5.96536 21.1384 6.19555L22.0788 7.1359L21.3196 7.89504L18.9344 5.50988C17.8227 4.3981 16.5885 4.72625 16.1771 6.24453L14.0906 13.9486C13.6792 15.4668 14.5755 16.3631 16.0938 15.9517L23.7978 13.8653C25.3161 13.4539 25.6443 12.2197 24.5325 11.1079L22.5391 9.11456H22.5489Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_2057_7246">
                    <rect width="28" height="18.5867" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

