// Librairies
import * as React from "react";
import { useGetIdentity, useRecordContext, useTranslate } from 'react-admin';
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Mui Components
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

// Custom components
import { PipeStatusDropdown } from "../../../components/Buttons/PipeStatusDropdown";
import { TagManager } from "../../../components/Prospects/Tags/TagManager";
import { PrevNextButtons } from "../../../components/Buttons/PrevNextButtons";
import { CreatedByAvatar } from "../../../components/Fields/CreatedByAvatar";
import { CustomTextField } from "../../Fields/CustomTextField";
import { LeadScoreButton } from "../../Buttons/LeadScoreButton";
import { SingleSplitTreatment } from "../SingleSplitTreatment";

// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PropertySurfaceIcon } from "../../../components/Icons/PropertySurfaceIcon";
import { ProspectTypeIcon } from "../../../components/Icons/ProspectTypeIcon";
import { SplitClient } from "@splitsoftware/splitio-react";


export const ProspectShowHeader = () => {
    const t = useTranslate();
    const user = useGetIdentity();
    const prospect = useRecordContext();

    if (!prospect) return null;

    return <div>
        <div className="mb-4">
            <div className="flex justify-between items-center grow ">
                <div className="flex gap-1 items-center grow">
                    <PrevNextButtons filter={{ status: prospect?.status }} resource={"prospects"} linkType="show">
                        <div className="flex gap-2 items-center">
                            <CreatedByAvatar />
                            <Typography variant="h6" >{prospect?.name}</Typography>
                        </div>
                    </PrevNextButtons>
                </div>
                {prospect?.status === "accepted" &&
                    <div className="flex items-center gap-2">
                        <SplitClient splitKey={user?.data?.id as string ?? "anonymous"}>
                            <SingleSplitTreatment
                                name="update-lead-score"
                                render={<LeadScoreButton />}
                            />
                        </SplitClient>
                        <PipeStatusDropdown variant={"normal"} pipeStatus={prospect?.pipeStatus} onChange={(e: any) => { }} />
                    </div>
                }
            </div>
            <Box className="mt-2">
                <TagManager maxItems={5} />
            </Box>
        </div>
        <div className="flex gap-x-8 justify-between mb-2">
            <CustomTextField icon={<ProspectTypeIcon type={prospect?.type} />} source="type" variant="h6" render={(prospect: any) => t(`prospect.type.${prospect?.type}`)} />
            {prospect?.houseSurface && <CustomTextField icon={<PropertySurfaceIcon type={"houseSurface"} />} variant="h6" source="houseSurface" render={(prospect: any) => `${prospect?.houseSurface} m²`} />}
            {prospect?.fieldSurface && <CustomTextField icon={<PropertySurfaceIcon type={"fieldSurface"} />} variant="h6" source="fieldSurface" render={(prospect: any) => `${prospect?.fieldSurface} m²`} />}
        </div>
        <div className="flex justify-between items-start">
            <CustomTextField source="address" variant="subtitle1" render={(prospect: any) => {
                let address = prospect?.address;
                if (prospect?.zipCode && !address.includes(prospect?.zipCode)) {
                    address += `, ${prospect?.zipCode}`;
                }
                if (prospect?.cityName && !address.includes(prospect?.cityName)) {
                    address += ` ${prospect?.cityName}`;
                }
                return address;
            }} />
            <CopyToClipboard text={prospect?.address}>
                <IconButton color="primary" size="small">
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </CopyToClipboard>
        </div>
    </div>

}