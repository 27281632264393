import { Create, useTranslate } from 'react-admin';
import { OrganisationForm } from "./OrganisationForm";

const OrganisationCreate = () => {
    const t = useTranslate();

    return <Create redirect="show" >
        <OrganisationForm buttonLabel={t("ra.action.create")} mode="create" />
    </Create>
};

export default OrganisationCreate;


