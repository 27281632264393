import { useState } from "react";
import Grid from "@mui/material/Grid";
import { CustomTextInput } from '../Inputs/CustomTextInput';
import { useRecordContext, useTranslate } from "react-admin";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { TextInputTagging } from "../Inputs/TexInputTagging";

export const NoteForm = (props: {
    event: {
        title: string, description: string, associatedOwners: string[], taggedUserIds: string[]
    } | null,
    onChange: (val: { title: string, description: string, associatedOwners: string[], taggedUserIds: string[] }) => void
}) => {
    const [data, setData] = useState(props.event ?? { title: "", description: "", associatedOwners: [], taggedUserIds: [] });
    const t = useTranslate();
    const prospect = useRecordContext();

    return <Grid container className="gap-4">
        <CustomTextInput
            title={t('prospect.property.note.title')}
            error={data?.title?.length > 60}
            placeholder="Entrez votre titre..."
            value={data?.title}
            onChange={(val: string) => { setData({ ...data, title: val }); props.onChange({ ...data, title: val }) }} />
        <TextInputTagging
            title={t('prospect.property.note.description')}
            placeholder="Entrez votre description..."
            description={data.description}
            taggedUserIds={data.taggedUserIds}
            onChange={(val: { description: string, taggedUserIds: string[] }) => {
                setData({ ...data, description: val.description, taggedUserIds: val.taggedUserIds });
                props.onChange({ ...data, description: val.description, taggedUserIds: val.taggedUserIds });
            }}
        />
        <FormControl fullWidth>
            <Typography variant="h6">Associer à</Typography>
            <Select
                multiple={true}
                value={data.associatedOwners}
                onChange={(event: any) => {
                    setData({ ...data, associatedOwners: event.target.value });
                    props.onChange({ ...data, associatedOwners: event.target.value });
                }}
            >
                {prospect?.owners?.map((owner: any) => <MenuItem key={owner.id} value={owner.id}>{owner.fullName}</MenuItem>)}
            </Select>
        </FormControl>
    </Grid>
}