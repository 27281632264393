import { AppBar, RefreshIconButton, TitlePortal, UserMenu, useGetIdentity, useGetList, usePermissions, useStore } from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Inbox, CourierProvider } from "@trycourier/react-inbox";
import { authProvider } from '../../authProvider';
import { useEffect, useState } from 'react';
import { isSuperAdmin } from '../../helpers/helpers';
import { customShadows } from '../../theme/shadows';

const Simple = (props: any) => {
    const { children, className } = props;
    return (
        <div
            className={className}
        >
            {children}
        </div>
    );
};


export const MyAppBar = (props: any) => {
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const user = useGetIdentity();
    const [token, setToken] = useState<string | undefined>(undefined);
    const { permissions } = usePermissions();
    const [isSidebarOpen, setSidebarOpen] = useStore(`sidebar.open`, "true");
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { data: settings } = useGetList('settings', {},
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    //Get courier token for signature
    useEffect(() => {
        const getToken = async () => {
            try {
                const token = await authProvider.getCourierToken();
                setToken(token?.token);
            }
            catch (e) {
                console.log(e);
            }
        }
        if (user) {
            getToken();
        }
    }, [user?.identity?.id]);

    //@ts-ignore
    return <>
        <AppBar
            {...props}
            color="default"
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
                '& .RaAppBar-toolbar': {
                    transition: 'all ease-in-out 0.2s',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexGrow: 1,
                    borderRadius: '0.5rem',
                    backgroundColor: 'white',
                    paddingLeft: isDesktop ? '2rem' : '8px',
                    paddingRight: '0.5rem',
                    marginRight: '0.5rem',
                    marginTop: '0.5rem',
                    boxShadow: scrolled ? customShadows.z12 : 'none',
                    marginLeft: isDesktop ? isSidebarOpen ? '256px' : '70px' : '8px',
                },
                '& .RaAppBar-menuButton': {
                    display: isDesktop ? 'none' : 'block',
                }
            }}
            container={Simple}
            userMenu={false}
            toolbar={<></>}
        >
            <div className="flex grow">
                {isDesktop &&
                    <TitlePortal variant="h6" sx={{ fontSize: '20px !important' }} />
                }
                <div className="flex justify-end grow items-center">
                    <span className="grow" />
                    {token && user?.identity?.id &&
                        <CourierProvider userSignature={token} clientKey={process.env.REACT_APP_COURIER_CLIENT_KEY} userId={user?.data?.id.toString()}>
                            <Inbox />
                        </CourierProvider>
                    }
                    <RefreshIconButton />
                    <UserMenu />
                </div>
                {settings?.[0]?.displayBanner ?
                    <div className="bg-success shadow-lg text-center font-bold text-white flex w-full shrink-0 justify-center items-center p-2">
                        {isDesktop ?
                            <>
                                {settings[0]?.bannerText}
                                {settings[0]?.bannerLink &&
                                    <a target='_blank' href={settings[0]?.bannerLink} className="underline ml-2">
                                        <div className="rounded-xl px-2 bg-white font-normal text-black shadow-lg hover:shadow-none">
                                            {"En savoir plus"}
                                        </div>
                                    </a>
                                }
                            </>
                            :
                            <>
                                <a target='_blank' href={settings[0]?.bannerLink} className="font-normal text-sm">
                                    <div>
                                        {settings[0]?.bannerText}
                                    </div>
                                </a>
                            </>
                        }
                    </div>
                    : <></>
                }
            </div>
        </AppBar >
    </>
};