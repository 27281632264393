import axios from "axios";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import { center as turfCenter } from '@turf/turf';
import { POI } from "../types/Geography";

export const checkParcel = async (codeInsee: string, parcelSection: string, parcelNumber: string): Promise<boolean> => {
    try {
        const res = await axios.get(`https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=${codeInsee.toString()}&section=${parcelSection.toString().padStart(2, '0')}&numero=${parcelNumber.toString().padStart(4, '0')}`);
        return res.data?.features?.[0]?.properties?.idu ? true : false;
    } catch (err) {
        throw ('geo err')
    }
}

export const getAddressDetails = async (
    lng: number,
    lat: number
): Promise<any> => {
    try {
        const response = await axios.get(
            `https://api-adresse.data.gouv.fr/reverse/?lon=${lng}&lat=${lat}`
        );
        const { city, street } = response.data.features[0].properties;
        if (!street) {
            return { city, street: null };
        } else {
            return { city, street };
        }
    } catch (err) {
        return { city: null, street: null };
    }
};

// node["amenity"="school"](around:1000,${lat},${lon});
// node["amenity"="college"](around:1000,${lat},${lon});
// node["amenity"="university"](around:1000,${lat},${lon});
// node["amenity"="kindergarten"](around:1000,${lat},${lon});
// node["public_transport"="station"](around:1000,${lat},${lon});

export const getNearbyPOIs = async (lat: number, lon: number) => {
    try {
        const query = `
      [out:json];
      (
        nwr["amenity"="school"](around:1000,${lat},${lon});
        nwr["railway"="station"](around:1000,${lat},${lon});
      );
      out body;
      >;
      out skel qt;
    `;
        const res = await axios.get('https://overpass-api.de/api/interpreter', {
            params: {
                data: query,
            },
        });

        const elements: any[] | undefined = res.data.elements;

        if (!elements) return [];

        const poiNodes = elements.filter((element: any) => element.type === 'node' && element?.tags);
        const polygons = elements.filter((element: any) => element.type === 'way')
            .map((way: any) => {
                const coordinates = way.nodes.map((nodeId: string) => {
                    let node = elements.find((element: any) => element.id === nodeId)
                    return [node.lon, node.lat];
                });
                const center = turfCenter({ type: 'Feature', properties: {}, geometry: { type: 'Polygon', coordinates: [coordinates] } });
                return {
                    ...way,
                    id: way.id,
                    coordinates,
                    lat: center.geometry.coordinates[1],
                    lon: center.geometry.coordinates[0],
                };
            });

        // Calculate distance for each POI and sort by distance
        const poisWithDistance: POI[] = [...poiNodes, ...polygons].map((poi: POI) => ({
            ...poi,
            distance: poi.lon !== undefined && poi.lat !== undefined ? distance(point([lon, lat]), point([poi.lon, poi.lat]), { units: 'meters' }) : undefined,
        }));

        // Sort POIs by distance (ascending)
        const sortedPois = poisWithDistance.sort((a: any, b: any) => a.distance - b.distance);

        return sortedPois;
    } catch (error) {
        console.error('Error fetching POIs:', error);
    }
};
