import { CoffeeIcon } from "./CoffeeIcon";
import { MoonIcon } from "./MoonIcon";
import { RainbowIcon } from "./RainbowIcon";
import { SaturnIcon } from "./SaturnIcon";
import { SunIcon } from "./SunIcon";

export const DaytimeIcon = (props: { type: string }) => {
    switch (props.type) {
        case "morning":
            return <CoffeeIcon />;
        case "lunch":
            return <SunIcon />;
        case "afternoon":
            return <MoonIcon />;
        case "evening":
            return <SaturnIcon />;
        case "all":
            return <RainbowIcon />;
        default:
            return <RainbowIcon />;
    }
}