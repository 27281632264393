

import { useState } from "react";
import Grid from "@mui/material/Grid";
import { CustomTextInput } from '../Inputs/CustomTextInput';
import { useRecordContext, useTranslate } from "react-admin";
// Components
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { CustomDateTimePicker } from '../Inputs/CustomDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Icons
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/AlternateEmailRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

export const EventForm = (props: {
    event:
    { title: string, description: string, eventDate: Dayjs | null, type: string, associatedOwners: string[] } | null,
    onChange: (val: { title: string, description: string, eventDate: Dayjs | null, type: string, associatedOwners: string[] }) => void
}) => {
    const [data, setData] = useState(props.event ?? { title: "", description: "", eventDate: dayjs(), type: "call", associatedOwners: [] });
    const t = useTranslate();
    const prospect = useRecordContext();

    return <Grid container className="gap-4">
        <CustomTextInput
            className="mb-2"
            title={t('prospect.property.note.title')}
            error={data.title?.length > 60}
            placeholder="Entrez votre titre..."
            value={data.title}
            onChange={(val: string) => { setData({ ...data, title: val }); props.onChange({ ...data, title: val }) }} />
        <CustomTextInput
            className="mb-2"
            title={t('prospect.property.note.description')}
            error={data.description?.length > 280}
            multiline rows={4}
            placeholder="Entrez votre description..."
            value={data.description}
            onChange={(val: string) => { setData({ ...data, description: val }); props.onChange({ ...data, description: val }) }} />
        <Box className="flex gap-4 items-center w-full">
            <FormControl fullWidth>
                <CustomDateTimePicker
                    title={t('prospect.property.event.eventDate')}
                    value={dayjs(data.eventDate)}
                    onChange={(newValue: any) => {
                        setData({ ...data, eventDate: newValue });
                        props.onChange({ ...data, eventDate: newValue });
                    }}
                    ampm={false}
                />
            </FormControl>
            <FormControl fullWidth>
                <Typography variant="h6">Type</Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.type}
                    onChange={(event: SelectChangeEvent) => {
                        setData({ ...data, type: event.target.value as string });
                        props.onChange({ ...data, type: event.target.value as string });
                    }}
                >
                    <MenuItem key="call" value="call"><CallIcon color="error" /> <span className="ml-2"> {t('prospect.eventTypes.call')}</span></MenuItem>
                    <MenuItem key="email" value="email"><EmailIcon color="warning" /> <span className="ml-2"> {t('prospect.eventTypes.email')}</span></MenuItem>
                    <MenuItem key="meeting" value="meeting"><PeopleAltOutlinedIcon color="success" /> <span className="ml-2"> {t('prospect.eventTypes.meeting')}</span></MenuItem>
                    <MenuItem key="postMail" value="postMail"><DescriptionRoundedIcon color="primary" /> <span className="ml-2"> {t('prospect.eventTypes.postMail')}</span></MenuItem>
                </Select>
            </FormControl>
        </Box>
        <FormControl fullWidth>
            <Typography variant="h6">Associer à</Typography>
            <Select
                multiple={true}
                value={data.associatedOwners}
                onChange={(event: any) => {
                    setData({ ...data, associatedOwners: event.target.value });
                    props.onChange({ ...data, associatedOwners: event.target.value });
                }}
            >
                {prospect?.owners?.map((owner: any) => <MenuItem key={owner.id} value={owner.id}>{owner.fullName}</MenuItem>)}
            </Select>
        </FormControl>
    </Grid >
}
