import { useState } from "react";
import Grid from "@mui/material/Grid";
import { CustomTextInput } from '../Inputs/CustomTextInput';
import { useRecordContext, useTranslate } from "react-admin";
// Components
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { CustomDateTimePicker } from '../Inputs/CustomDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const TaskForm = (props: {
    event:
    { title: string, description: string, dueDate: Dayjs | null, sendNotification: boolean, associatedOwners: string[] } | null,
    onChange: (val: { title: string, description: string, dueDate: Dayjs | null, sendNotification: boolean, associatedOwners: string[] }) => void
}) => {
    const [data, setData] = useState(props.event ?? { title: "", description: "", dueDate: dayjs().add(1, 'day'), sendNotification: true, associatedOwners: [] });
    const t = useTranslate();
    const prospect = useRecordContext();


    return <Grid container className="gap-4">
        <CustomTextInput
            title={t('prospect.property.note.title')}
            error={data.title?.length > 60}
            placeholder="Entrez votre titre..."
            value={data.title}
            onChange={(val: string) => { setData({ ...data, title: val }); props.onChange({ ...data, title: val }) }} />
        <CustomTextInput
            title={t('prospect.property.note.description')}
            error={data.description?.length > 280}
            multiline rows={4}
            placeholder="Entrez votre description..."
            value={data.description}
            onChange={(val: string) => { setData({ ...data, description: val }); props.onChange({ ...data, description: val }) }} />
        <Box className="flex w-full gap-4">
            <CustomDateTimePicker
                title={t('prospect.property.task.dueDate')}
                className="grow"
                ampm={false}
                minDateTime={dayjs()}
                value={dayjs(data.dueDate)}
                onChange={(newValue: any) => {
                    setData({ ...data, dueDate: newValue });
                    props.onChange({ ...data, dueDate: newValue });
                }} />
            <div className="flex flex-col justify-start mb-4 grow">
                <Typography variant="h6">Rappel par mail</Typography>
                <Switch
                    checked={data.sendNotification}
                    onChange={(ev: any) => {
                        setData({ ...data, sendNotification: ev.target.checked });
                        props.onChange({ ...data, sendNotification: ev.target.checked });
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
        </Box>
        <FormControl fullWidth>
            <Typography variant="h6">Associer à</Typography>
            <Select
                multiple={true}
                value={data.associatedOwners}
                onChange={(event: any) => {
                    setData({ ...data, associatedOwners: event.target.value });
                    props.onChange({ ...data, associatedOwners: event.target.value });
                }}
            >
                {prospect?.owners?.map((owner: any) => <MenuItem key={owner.id} value={owner.id}>{owner.fullName}</MenuItem>)}
            </Select>
        </FormControl>
    </Grid>
}