import { useGetList, useListContext, useTranslate } from "react-admin";
import Skeleton from '@mui/material/Skeleton';

const AggregatedCard = (props: { title: string, value: string, variant: 'compact' | 'normal' }) => {
    const { title, value, variant } = props;

    // return <div className={`flex flex-col items-center rounded-lg border-solid border-4 border-bg  px-4 py-2`}>
    return <div className={`flex flex-col items-center rounded-lg border-solid border-4 border-bg  ${variant === "compact" ? "px-12 py-6" : "px-4 py-2"} `}>
        <div className={`font-bold ${variant === "compact" ? "text-base" : "text-sm"}`}>{value}</div>
        <div className={`${variant === "compact" ? "text-base" : "text-sm"}`}>{title}</div>
    </div>
}

export const ProspectAggregatedValues = (props: { dashboardFilters?: { dateRange: { start: { $d: Date }, end: { $d: Date } }, organisationId: string }, variant: 'compact' | 'normal' }) => {
    const { filter, filterValues } = useListContext();
    const t = useTranslate();
    const filters = { minCreatedAt: props?.dashboardFilters?.dateRange?.start?.$d, maxCreatedAt: props?.dashboardFilters?.dateRange?.end?.$d };
    const { data: aggregatedValues, isLoading } = useGetList('prospects/agregatedvalues', { filter: { ...filters, ...filter, ...filterValues } });
    if (isLoading) {
        return <Skeleton variant="rounded" width={150} height={60} />

    }

    if (!aggregatedValues || !aggregatedValues[0]) {
        return null;
    }

    const values = [
        {
            name: "floorArea",
            format: (value: number) => `${new Intl.NumberFormat('fr-FR').format(value)} m²`
        },
        {
            name: "projectedRevenue",
            format: (value: number) => `${new Intl.NumberFormat('fr-FR').format(value)} €`
        },
        {
            name: "numberOfUnits",
            format: (value: number) => `${value}`
        }
    ]

    return <div className={`flex gap-8 flex-wrap ${props.variant === "compact" ? "justify-center" : ""}`}>
        {
            values.map((value) => {
                return <AggregatedCard key={value.name} variant={props.variant} title={t(`prospect.property.projects.${value.name}`)} value={value.format(aggregatedValues[0][value.name])} />
            })
        }
    </div>
}

ProspectAggregatedValues.defaultProps = {
    variant: 'normal',
}
