import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Droppable } from "@hello-pangea/dnd";
import type { IProspect } from "../../types/Prospect";
import StatusChip from "../Chips/StatusChip";
import { useGetIdentity, useTranslate } from "react-admin";
import theme from "../../theme";
import { KanbanCard } from "./KanbanCard";
import { getStatusColor } from "../../helpers/helpers";
import { KanbanTitle } from "../Kanban/KanbanTitle";
import { KanbanColumn } from "../Kanban/KanbanColumn";

export const ProspectKanbanColumn = ({
    status,
    prospects,
    onOpenPreview
}: {
    status: IProspect["pipeStatus"];
    prospects: IProspect[];
    onOpenPreview: (prospect: IProspect) => void;
}) => {
    const user = useGetIdentity();
    const color: {
        family: keyof typeof theme.palette;
        variant: "main" | "light" | "dark" | "lighter" | "darker";
    } = getStatusColor(status);
    const t = useTranslate();

    return <KanbanColumn>
        <KanbanTitle
            count={prospects.length}
            title={user?.data?.pipeStatuses?.length > 0 ?
                <StatusChip status={user?.data?.pipeStatuses?.find((pipeStatus: any) => pipeStatus.value === status)?.value} />
                :
                <Typography variant="h5">
                    {t(`prospect.statuses.${status}`)}
                </Typography>
            }
            color={theme.palette[color.family][color.variant]}
        />
        <Droppable droppableId={status}>
            {(droppableProvided, snapshot) => (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "auto",
                        height: "100%",
                    }}
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={snapshot.isDraggingOver ? " isDraggingOver" : ""}
                >
                    {prospects.map((prospect, i) => (
                        <KanbanCard prospect={prospect} position={i} onOpenPreview={onOpenPreview} />
                    ))}
                    {droppableProvided.placeholder}
                </Box>
            )}
        </Droppable>
    </KanbanColumn>
}
