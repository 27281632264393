import { useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";

export const CustomTextField = (props: {
    source: string,
    variant: "h6" | "subtitle1" | "body1" | "body2" | "caption",
    icon?: JSX.Element,
    render?: (record: any) => string | JSX.Element
}) => {
    const prospect = useRecordContext(props);

    return prospect ? <div className={"flex items-center gap-x-2"}>
        {props.icon}
        <Typography variant={props.variant}>{props.render ? props.render(prospect) : prospect[props.source]}</Typography>
    </div> : null
}