import { useState } from 'react';
import { Create, useTranslate, } from 'react-admin';
import { TargetingForm } from './TargetingForm';


const TargetCreate = () => {
    const t = useTranslate();

    return <Create redirect="list" title={t('menu.targetings')}>
        <TargetingForm mode="create" buttonLabel={t("ra.action.create")} />
    </Create>
};

export default TargetCreate;
