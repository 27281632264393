import { OwnerPipeStatusesType, ProspectPipeStatusesType } from "../types/Prospect";

export const defaultPipeStatuses: ProspectPipeStatusesType[] = ["toContact", "contacted", "qualified", "visited", "offerSubmitted", "signed", "toTransfert", "transferred", "closed", "wrongContact"];
export const allPipeStatuses: ProspectPipeStatusesType[] = ["standBy", "toContact", "contacted", "qualified", "visited", "offerSubmitted", "mandateSigned", "signed", "followUp", "feasabilityStudy", "toTransfert", "transferred", "closed", "wrongContact", "notInterested"];
export const ownerPipeStatuses: OwnerPipeStatusesType[] = ["toContact", "standBy", "contacted", "qualified", "offerSubmitted", "mandateSigned", "signed", "wrongContact", "notSelling"];

export const mapboxDefaultStyle = "kaptcher/cltehxwyr01dd01pi60fj0jbw";
export const mapboxSatelliteStyle = "kaptcher/cltell1nn01dm01piblgud9d2";
export const mapboxStreetStyle = "kaptcher/cm1umwib0015k01qr8tra14o9";

// Tax constants
export const developmentTaxBase: number = 914;
export const developmentTaxBaseIDF: number = 1036;
export const developmentTaxSwimmingPool: number = 258;
export const developmentTaxParking: number = 3000;

export const toEuros = (amount: number) => {
    return `${new Intl.NumberFormat('fr-FR').format(amount)} €`;
}