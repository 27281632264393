import { Create, useTranslate } from 'react-admin';
import { UserForm } from "./UserForm";

const CreateUser = () => {
    const t = useTranslate();

    return <Create redirect="list">
        <UserForm buttonLabel={t("ra.action.create")} mode="create" />
    </Create>
};

export default CreateUser;


