import Box from '@mui/material/Box';
import theme from '../../theme';
import { useTranslate } from 'react-admin';

export const RoleChip = (props: { role: string, label?: JSX.Element }) => {
    const { role, label } = props;
    const t = useTranslate();
    let color;
    switch (role) {
        case 'superAdmin':
            color = 'red';
            break;
        case 'admin':
            color = theme.palette.error.lighter;
            break;
        case 'user':
            color = theme.palette.primary.lighter;
            break;
        case 'teamManager':
            color = theme.palette.warning.lighter;
            break;
        default:
            color = theme.palette.grey[500];
            break;
    }
    return <Box sx={{ backgroundColor: color }} className="rounded-sm text-neutral-600 inline-block p-1">
        {label ? label : t(`user.roles.${role}`)}
    </Box>
}