import { dataProvider } from "../../dataProvider";
import { useMutation } from '@tanstack/react-query';
import { useState, useRef, useEffect } from "react";
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from "@mui/material/IconButton";
import { set } from "lodash";
const AudioFileField = (props: { file: any, prospect: any }) => {
    const { file, prospect } = props;
    const [url, setUrl] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { mutate } = useMutation({
        mutationFn: () => dataProvider.getBlob(`prospects/${prospect}/files/download/${file._id}?typeFile=audioFile`),
        onSuccess: (data: { blob: Blob }) => {
            const audioUrl = window.URL.createObjectURL(data.blob);
            setUrl(audioUrl);
        },
        onError: () => {
            console.error("Error loading audio file");
        },
    });

    const handlePlay = async () => {
        mutate();
    };


    return (
        <div key={file._id}>
            <audio key={file._id} autoPlay controls={isLoaded} onLoadedData={(e) => setIsLoaded(true)
            } >
                {url && <source src={url} type="audio/webm" />}
            </audio >
            {!isLoaded &&
                <IconButton onClick={handlePlay} type="button" size="small" color="inherit" >
                    <PlayArrowIcon />
                </IconButton >
            }
        </div>
    );
};

export default AudioFileField;
