import * as React from 'react';
import { ReactElement, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useTranslate } from 'ra-core';
import { AddViewDialog } from './AddViewDialog';
import { ProspectsFiltersForm } from '../../Forms/FiltersForm';
import theme from '../../../theme';


export const AddViewButton = (
    props: ButtonProps
): ReactElement => {
    const [openSave, setOpenSave] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const t = useTranslate();

    const handleOpenSave = (): void => {
        setOpenSave(true);
    };
    const handleCloseSave = (): void => {
        setOpenSave(false);
    };

    const handleOpenForm = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                aria-label={t('ra.saved_queries.new_label', {
                    _: 'Save current query...',
                })}
                size="small"
                onClick={handleOpenForm}
                {...props}
                variant="outlined"
            >
                + Vue
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorReference='anchorEl'
                anchorOrigin={{
                    vertical: -50,
                    horizontal: 'right',
                }}
                onClose={handleClose}
                sx={{
                    ".MuiPopover-paper": {
                        border: "1px solid",
                        borderColor: theme.palette.grey[400],
                    }
                }}
            >
                <ProspectsFiltersForm type="current" onSaveFilters={handleOpenSave} />
            </Popover>
            <AddViewDialog open={openSave} onClose={() => { handleCloseSave(); handleClose() }} />
        </>
    );
};
