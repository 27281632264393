import { styled } from '@mui/system';
import { memo, FunctionComponent } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';
import { useTranslate, useRecordContext } from 'ra-core';

import { sanitizeFieldRestProps } from 'react-admin';

const BooleanField: FunctionComponent<BooleanFieldProps> = memo(
    props => {
        const {
            className,
            emptyText,
            source,
            valueLabelTrue,
            valueLabelFalse,
            TrueIcon = DoneIcon,
            FalseIcon = ClearIcon,
            looseValue = false,
            ...rest
        } = props;
        const record = useRecordContext(props);
        const translate = useTranslate();

        const value = rest.forceValue !== undefined ? rest.forceValue : get(record, source);
        const isTruthyValue = value === true || (looseValue && value);
        let ariaLabel = value ? valueLabelTrue : valueLabelFalse;

        if (!ariaLabel) {
            ariaLabel = isTruthyValue ? 'ra.boolean.true' : 'ra.boolean.false';
        }

        if (looseValue || value === false || value === true) {
            return (
                <StyledTypography
                    component="span"
                    variant="body2"
                    className={className}
                    {...sanitizeFieldRestProps(rest)}
                >
                    <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
                        {isTruthyValue ? (
                            <TrueIcon data-testid="true" fontSize="small" />
                        ) : (
                            <FalseIcon data-testid="false" fontSize="small" />
                        )}
                    </Tooltip>
                </StyledTypography>
            );
        }

        return (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText && translate(emptyText, { _: emptyText })}
            </Typography>
        );
    }
);

BooleanField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    // ...fieldPropTypes,
    valueLabelFalse: PropTypes.string,
    valueLabelTrue: PropTypes.string,
    TrueIcon: PropTypes.elementType,
    FalseIcon: PropTypes.elementType,
    looseValue: PropTypes.bool,
};

BooleanField.displayName = 'BooleanField';

export interface BooleanFieldProps {
    valueLabelTrue?: string;
    valueLabelFalse?: string;
    TrueIcon?: SvgIconComponent;
    FalseIcon?: SvgIconComponent;
    looseValue?: boolean;
    source: string;
    emptyText?: string;
    forceValue?: boolean;
    className?: string;
    label?: string;
}

const PREFIX = 'RaBooleanField';

const StyledTypography = styled(Typography, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})({
    display: 'inline-flex',
    verticalAlign: 'middle',
    lineHeight: 0,
});


const StyledBooleanField = styled(BooleanField)(({ theme }) => ({
    color: "white",
    "& svg": {
        borderRadius: "100%",
        padding: theme.spacing(0.5),
        width: "30px",
        height: "30px",
    },
    "& [data-testid=false]": {
        backgroundColor: theme.palette.error.main
    },
    "& [data-testid=true]": {
        backgroundColor: theme.palette.success.main
    }
}));

export default StyledBooleanField;