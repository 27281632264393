import { Edit, useTranslate } from 'react-admin';
import { OrganisationForm } from './OrganisationForm';

const OrganisationEdit = () => {
    const t = useTranslate();

    return <Edit redirect="show" >
        <OrganisationForm buttonLabel={t("ra.action.edit")} mode="edit" />
    </Edit>
};

export default OrganisationEdit;


