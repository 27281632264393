import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MoonIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M11.25 7.50003C11.25 13.7132 16.2868 18.75 22.5 18.75C23.6366 18.75 24.7338 18.5815 25.768 18.268C24.3679 22.8874 20.0766 26.25 15 26.25C8.7868 26.25 3.75 21.2133 3.75 15C3.75 9.92342 7.11259 5.63216 11.732 4.23206C11.4185 5.26627 11.25 6.36346 11.25 7.50003Z" stroke="currentColor" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon>
    )
}

