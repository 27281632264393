import { Show, SimpleShowLayout, TextField, useTranslate, FunctionField, useShowContext, ReferenceArrayField, SingleFieldList, ChipField, useRecordContext, usePermissions } from 'react-admin';

// Mui Components
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { BackButton } from '../../components/Buttons/BackButton';

// Lazy loading
import { lazy, Suspense } from 'react';
const MapField = lazy(() => import('../../components/Map/TargetingShowMap'));

const UserChip = () => {
    const record = useRecordContext();
    return <Chip sx={{ cursor: 'pointer' }} label={`${record?.firstName} ${record?.lastName}`} />
}

const TargetingShowLayout = (props: any) => {
    const t = useTranslate();
    const { record } = useShowContext();
    const { permissions } = usePermissions();

    return (<Grid container>
        <Grid item md={6} xs={12}>
            <BackButton />
            {record ?
                <SimpleShowLayout>
                    <TextField label={t('prospect.property.name')} source="name" />
                    <TextField label={t('prospect.property.zipCode')} source={record.type === "commune" ? "communeInfo.zipCodes.0" : "zipCode"} />
                    <FunctionField label="Type"
                        render={(record: any) => { return record?.propertyType.map((pType: string) => <p>{t(`prospect.type.${pType}`)}</p>) }}
                    />
                    {/* <FunctionField label={t('prospect.property.houseSurface')}
                    render={(record: any) => { return `${record.houseSurface[0]} - ${record.houseSurface[1]}` }}
                /> */}
                    <FunctionField label={t('prospect.property.fieldSurface')}
                        render={(record: any) => { return `De ${record.fieldSurface[0]}m² à ${record.fieldSurface[1]}m²` }}
                    />
                    {permissions?.includes("user") === false && (
                        <ReferenceArrayField label={t('prospect.property.associatedUsers')} reference="users" source="userIds" >
                            <SingleFieldList sx={{ margin: "0", gap: "0.5em" }} linkType="show">
                                <UserChip />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    )}
                </SimpleShowLayout>
                : <></>
            }
        </Grid>
        <Grid item md={6} xs={12} className={"min-h-[400px]"}>
            <Suspense fallback={<></>}>
                <MapField />
            </Suspense>
        </Grid>
    </Grid>
    )
};

const TargetingShow = () => {
    const t = useTranslate();

    return <Show title={t('menu.targetings')}>
        <TargetingShowLayout />
    </Show>
};

export default TargetingShow;
