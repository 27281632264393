import { lazy, Suspense, useState } from 'react';
import { useGetList, useTranslate, usePermissions, useNotify, useList, ListContextProvider, FunctionField, DateField, Pagination, useRedirect, LinearProgress, SimpleList, List } from "react-admin"
import dayjs from "dayjs";
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion, Variants } from "framer-motion";

import theme from "../../theme";

// Components
import { PageTitle } from "../../components/layout/PageTitle";
import { CustomCard } from "../../components/layout/CustomCard";
import { RecommendationList } from "../../components/Prospects/RecommendationsList";
import { ProspectAggregatedValues } from '../../components/Prospects/ProspectAggreatedValues';
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';

//Mui
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';

// Icons
import { UsersIcon } from '../../components/Icons/UsersIcon';
import { UserCheckIcon } from "../../components/Icons/UserCheck";
import { UserCloseIcon } from "../../components/Icons/UserClose";
import { UserVoiceIcon } from "../../components/Icons/UserVoice";
import { StyledDataGrid } from "../../components/layout/StyledDataGrid";
import StatusButtons from "../../components/Buttons/StatusButtons";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SquareCheckIcon } from "../../components/Icons/SquareCheckIcon";
import { allPipeStatuses } from '../../helpers/constants';
import { getStatusColor, isAdmin, isTeamManager } from '../../helpers/helpers';
import { uniq } from 'lodash';

// Lazy loading
const CustomLineChart = lazy(() => import("../../components/Charts/LineChart"));
const CustomPieChart = lazy(() => import("../../components/Charts/PieChart"));
const StackedBarChart = lazy(() => import("../../components/Charts/StackedBarChart"));

const getCount = (data: any, group: string, value: string): number => {
    if (data) {
        const index = data[0][group].findIndex((item: any) => item._id == value);
        if (index > -1) return data[0][group][index].count
    }
    return 0
}

const StatusCard = (props: any) => {
    return <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: props.delay }}
        className="w-1/2 md:w-1/4 p-4 md:p-0"
    >
        <div style={{ padding: "12px 0px 12px 12px" }} className="w-full text-white m-0 rounded-md flex flex-col md:flex-row justify-start items-center shadow-md bg-white">
            <div className="flex items-center">
                <div style={{ backgroundColor: props.color, borderRadius: '100%', width: "32px", height: "32px" }} className="p-2 mr-[6px] flex justify-center items-center">
                    {props.icon}
                </div>
                <div className="mr-1">
                    <Typography variant="h4" sx={{ color: theme.palette.primary.darker }}>{props.value}</Typography>
                </div>
            </div>
            <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{props.title}</Typography>
        </div>
    </motion.div>
}

const StyledSelect = styled(Select)(({ theme }) => ({
    padding: 0,
    border: 0,
    '& .MuiSelect-select': {
        padding: "4px 8px",
        border: 0
    },
    '& fieldset': {
        border: 0
    }
}));

const OrgaFilter = (props: any) => {
    const { data: orgas } = useGetList('organisations', {});

    return <div className={`bg-white  rounded-md cursor-pointer min-w-[140px] h-[36px]`} style={{ border: `1px solid ${theme.palette.grey[200]}` }}>
        <FormControl fullWidth>
            <StyledSelect
                onClick={(e) => e.stopPropagation()}
                disableUnderline
                labelId="orga-select-label"
                id="orga-select"
                value={props.value}
                //@ts-ignore
                onChange={props.onChange}
                sx={{ border: 0, padding: 0 }}
                //@ts-ignore
                MenuProps={{ className: "mt-2" }}
            >
                <MenuItem value="" key="all">Tous</MenuItem>
                {orgas && orgas.map((orga: any) => <MenuItem key={orga.id} value={orga.id}>{orga.name}</MenuItem>)
                }
            </StyledSelect>
        </FormControl>
    </div>
}

export const Dashboard = () => {
    const t = useTranslate();
    const notify = useNotify();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const redirect = useRedirect();
    const [page, setPage] = useState(1);
    const { permissions } = usePermissions();
    const [dateRange, setDateRange] = useState("month");
    const [filters, setFilters] = useState<{ dateRange: any, organisationId: string }>({ dateRange: { start: dayjs().subtract(1, 'month'), end: dayjs() }, organisationId: "" });
    const { data, isLoading } = useGetList('dashboards',
        {
            filter: filters,
        }
    );
    const { data: newProspects, total, isPending } = useGetList(
        'prospects',
        {
            filter: { status: "pending" },
            pagination: { page, perPage: 5 },
            sort: { field: "createdAt", order: "DESC" }
        })
    const listContext = useList({ data: newProspects, isPending });

    const pending = getCount(data, "byStatus", "pending")
    const rejected = getCount(data, "byStatus", "rejected")
    const accepted = getCount(data, "byStatus", "accepted")

    const handleOrgaChange = (e: SelectChangeEvent) => {
        setFilters({ ...filters, organisationId: e.target.value })
    };

    const handlePeriodChange = (e: SelectChangeEvent) => {
        let start;
        switch (e.target.value) {
            case "week":
                start = dayjs().subtract(7, 'day');
                break;
            case "month":
                start = dayjs().subtract(1, 'month');
                break;
            case "threeMonths":
                start = dayjs().subtract(3, 'month');
                break;
            case "sixMonths":
                start = dayjs().subtract(6, 'month');
                break;
            default:
                start = dayjs().subtract(1, 'month');
                break;
        }
        setDateRange(e.target.value);
        setFilters({ ...filters, dateRange: { start, end: dayjs() } });
    }

    let usersData = data?.[0]?.byUser ? data?.[0].byUser.map((user: any) => {
        let toObject = user.pipeStatusCounts.reduce((acc: any, curr: any) => {
            acc[curr.pipeStatus] = curr.count;
            return acc;
        }, {});
        return toObject;
    }) : [];

    //Extract all the statuses from the data
    let allStatuses = usersData.map((user: any) => Object.keys(user)).flat();
    //remove duplicate
    allStatuses = uniq(allStatuses);
    //Sort by filterging allPipeStatuses list
    allStatuses = allPipeStatuses.filter((status) => allStatuses.includes(status));

    return <div className={`${isDesktop ? 'absolute' : 'flex-col'} flex gap-4 h-full`} style={{ width: "calc(100% - 32px)" }}>
        <div className={`${isDesktop ? 'w-9/12 h-full overflow-auto' : 'w-full'} grow `}>
            <CustomCard noShadow={true} className={'pl-8 pr-4 py-4'} containerClass='mb-8'>
                {(total !== undefined && total > 0 && !isPending) ?
                    <CustomCard containerClass='rounded-md px-6 py-4 mt-5'>
                        <div>
                            <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.charts.titles.byDate')}</Typography>
                            {isDesktop ?
                                <>
                                    <ListContextProvider value={listContext}>
                                        <StyledDataGrid resource="newprospects" bulkActionButtons={false} rowClick={"show"} total={total}>
                                            <FunctionField source="address" label={t('prospect.property.address')} render={(record: any) => `${record?.address}, ${record?.zipCode}`} />
                                            <FunctionField source="type" label="Type" render={(record: any) => t(`prospect.type.${record.type}`)} />
                                            <FunctionField source="fieldSurface" label={t('prospect.property.fieldSurface')} render={(record: any) => `${record.fieldSurface ?? 0} m²`} />
                                            <StatusButtons alignment="right" size="small" label={t('prospect.property.interest')} />
                                        </StyledDataGrid>
                                        <Pagination rowsPerPageOptions={[]} />
                                    </ListContextProvider>
                                </>
                                :
                                <SimpleList
                                    hasBulkActions={false}
                                    title={t(`menu.prospects.new`)}
                                    linkType="show"
                                    primaryText={record => record.name}
                                    secondaryText={record => `${record.zipCode}, ${record.address}`}
                                    tertiaryText={record => { return `${t(`prospect.type.${record.type}`)}, ${record.fieldSurface ? `${record.fieldSurface} m²` : ""}` }}
                                />
                            }
                        </div>
                    </CustomCard>
                    : <div className="flex w-full gap-4 mt-6">
                        {!isPending &&
                            <>
                                <CustomCard containerClass='p-4 grow rounded-md w-1/2'>
                                    <div className="flex gap-2 items-center">
                                        <SquareCheckIcon stroke={theme.palette.primary.main} />
                                        <Typography variant="subtitle3" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.buttons.noNewProspects')}</Typography>
                                    </div>
                                </CustomCard>
                                <div className='p-4 grow w-1/2 rounded-md bg-primary cursor-pointer hover:shadow-lg' onClick={(e: any) => redirect('list', 'prospects')}>
                                    <div className="flex gap-2 text-white items-center">
                                        <OpenInNewIcon color="inherit" />
                                        <Typography variant="subtitle3" sx={{ color: "white" }}>{t('dashboard.buttons.accessCurrentProspects')}</Typography>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                <div className="flex w-full h-full flex-col mt-6">
                    <div className="flex justify-between items-end pr-4">
                        <PageTitle title="Activité" />
                        <div className="flex">
                            {permissions?.includes('superAdmin') === true && (
                                <OrgaFilter value={filters.organisationId} onChange={handleOrgaChange} />
                            )}
                            <div className={`bg-white rounded-md cursor-pointer min-w-[140px] h-[36px]`} style={{ border: `1px solid ${theme.palette.grey[200]} ` }}>
                                <FormControl fullWidth>
                                    <StyledSelect
                                        onClick={(e) => e.stopPropagation()}
                                        disableUnderline
                                        labelId="range-select-label"
                                        id="range-select"
                                        value={dateRange}
                                        //@ts-ignore
                                        onChange={handlePeriodChange}
                                        sx={{ border: 0, padding: 0 }}
                                        //@ts-ignore
                                        MenuProps={{ className: "mt-2" }}
                                    >
                                        <MenuItem key="week" value="week">{t('dashboard.dateRanges.last7Days')} </MenuItem>
                                        <MenuItem key="month" value="month">{t('dashboard.dateRanges.lastMonth')} </MenuItem>
                                        <MenuItem key="threeMonths" value="threeMonths">{t('dashboard.dateRanges.last3Months')}</MenuItem>
                                        <MenuItem key="sixMonths" value="sixMonths">{t('dashboard.dateRanges.last6Months')}</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-between flex-wrap md:flex-nowrap mt-4 md:gap-x-4">
                        <StatusCard delay={0} icon={<UsersIcon fill="none" stroke="white" sx={{ width: "20px", height: "20px" }} />} title={'Total'} value={data ? data[0].total : 0} color={theme.palette.primary.main} />
                        <StatusCard delay={0.1} icon={<UserCheckIcon fill="none" stroke="white" sx={{ width: "20px", height: "20px" }} />} title={t("prospect.statuses.accepted")} value={accepted} color={theme.palette.success.main} />
                        <StatusCard delay={0.2} icon={<UserVoiceIcon fill="none" stroke="white" sx={{ width: "20px", height: "20px" }} />} title={t("prospect.statuses.pending")} value={pending} color={theme.palette.warning.main} />
                        <StatusCard delay={0.3} icon={<UserCloseIcon fill="none" stroke="white" sx={{ width: "20px", height: "20px" }} />} title={t("prospect.statuses.rejected")} value={rejected} color={theme.palette.error.main} />
                    </div>
                    <motion.div
                        initial={{ opacity: 0, x: -200 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.5 }}
                        className="w-full mt-6">
                        <CustomCard containerClass="h-[400px] rounded-md">
                            <div className="absolute p-4">
                                <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.charts.titles.byDate')}</Typography>
                            </div>
                            <Suspense fallback={<></>}>
                                <CustomLineChart
                                    series={[{
                                        name: 'Nouveaux prospects',
                                        type: 'line',
                                        data: data ? data[0].byDate.map((item: any) => [item._id, item.count]) : [],
                                        backgroundColor: theme.palette.chart.blue,
                                        smooth: true,
                                        symbol: 'circle',
                                        symbolSize: 4,
                                        itemStyle: {
                                            borderWidth: 6,
                                            borderColor: theme.palette.success.main,
                                            color: theme.palette.success.main
                                        },
                                        lineStyle: {
                                            color: theme.palette.primary.dark,
                                            width: 4
                                        },
                                    }]} />
                            </Suspense>
                        </CustomCard>
                    </motion.div>
                    <div className="flex w-full justify-around flex-col md:flex-row flex-wrap md:flex-nowrap mt-6 gap-x-4">

                        <SingleSplitTreatment
                            name="agregated-values"
                            render={
                                <div className="flex  w-full justify-around flex-col md:flex-row flex-wrap md:flex-nowrap mt-6 gap-x-4">
                                    <CustomCard containerClass="w-full rounded-md">
                                        <Typography variant="h6" sx={{ color: theme.palette.primary.darker, padding: "1rem" }}>{t('dashboard.charts.titles.byPoject')}</Typography>
                                        <div className='pb-4' >
                                            <ListContextProvider value={listContext} >
                                                <List title="Dashboard" resource='prospects/agregatedvalues' actions={false} pagination={false}>
                                                    < ProspectAggregatedValues variant="compact" dashboardFilters={filters} />
                                                </List>
                                            </ListContextProvider>
                                        </div>
                                    </CustomCard>
                                </div>
                            }
                        />
                    </div>
                    <div className="flex w-full justify-around flex-col md:flex-row flex-wrap md:flex-nowrap mt-6 gap-x-4">
                        <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="w-full md:w-1/2">
                            <CustomCard containerClass="h-[400px] rounded-md">
                                <div className="absolute p-4">
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.charts.titles.byType')}</Typography>
                                </div>
                                <Suspense fallback={<></>}>
                                    <CustomPieChart
                                        series={[{
                                            type: 'pie',
                                            radius: ['55%', '70%'],
                                            avoidLabelOverlap: false,
                                            itemStyle: {
                                                borderRadius: 15,
                                                borderColor: '#fff',
                                                borderWidth: 3
                                            },
                                            minShow: 1,
                                            minShowLabelAngle: 1,
                                            top: 10,
                                            name: 'Type de bien',
                                            labelLine: {
                                                length: 5,
                                                length2: 5
                                            },
                                            data: data ? data[0]?.byType.map((type: any) => ({ value: type.count, name: t(`prospect.type.${type?._id}`) })) : []
                                        }]} />
                                </Suspense>
                            </CustomCard>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="w-full md:w-1/2 mt-6 md:mt-0">
                            <CustomCard containerClass="h-[400px] rounded-md">
                                <div className="absolute p-4">
                                    <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.charts.titles.byCity')}</Typography>
                                </div>
                                <Suspense fallback={<></>}>
                                    <CustomPieChart
                                        series={[{
                                            type: 'pie',
                                            radius: ['55%', '70%'],
                                            avoidLabelOverlap: false,
                                            itemStyle: {
                                                borderRadius: 15,
                                                borderColor: '#fff',
                                                borderWidth: 3
                                            },
                                            top: 10,
                                            minShow: 10,
                                            minShowLabelAngle: 10,
                                            name: 'Ville',
                                            label: {
                                                position: "outside",
                                                distanceToLabelLine: 0,
                                                bleedMargin: 0,
                                                margin: 0,
                                                alignTo: 'none',
                                                overflow: 'break'
                                            },
                                            labelLine: {
                                                show: false,
                                                length: 1,
                                                length2: 0
                                            },
                                            data: data ? data[0].byZipCode.map((item: any) => ({ name: item._id ? item.cityName : "Autres", value: item.count })) : [],
                                        }]} />
                                </Suspense>
                            </CustomCard>
                        </motion.div>
                    </div>
                    {(isAdmin(permissions) || isTeamManager(permissions)) &&
                        <CustomCard containerClass="h-[400px] rounded-md mt-6">
                            <div className="absolute p-4">
                                <Typography variant="h6" sx={{ color: theme.palette.primary.darker }}>{t('dashboard.charts.titles.byUser')}</Typography>
                            </div>
                            <Suspense fallback={<></>}>
                                <StackedBarChart
                                    categories={(data && data[0]?.byUser) ? data[0]?.byUser.map((user: any) => `${user.firstName} ${user.lastName}`) : []}
                                    series={
                                        allStatuses.map((status: string) => {
                                            let color = getStatusColor(status);
                                            return {
                                                name: t(`prospect.statuses.${status}`),
                                                type: 'bar',
                                                stack: 'pipeStatus',
                                                color: theme.palette[color.family][color.variant],
                                                data: (data && data[0]?.byUser) ? data[0]?.byUser.map((user: any) => {
                                                    let toObject = user.pipeStatusCounts.reduce((acc: any, curr: any) => {
                                                        acc[curr.pipeStatus] = curr.count;
                                                        return acc;
                                                    }, {});
                                                    return toObject[status] ?? 0;
                                                }) : []
                                            }
                                        })
                                    } />
                            </Suspense>
                        </CustomCard>
                    }
                </div>
            </CustomCard >
        </div >
        <div className={`${isDesktop ? 'max-w-[25%] h-full overflow-auto' : 'w-full'} `}>
            <RecommendationList direction='col' />
        </div>
    </div >
}