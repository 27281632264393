export const userMessages = {
    user: {
        property: {
            firstName: "Prénom",
            lastName: "Nom",
            email: "Email",
            role: "Rôle",
            receiveNewProspects: "Recevoir les alertes de nouveaux prospects",
            receiveWeeklyReports: "Recevoir les rapports hebdomadaires",
            receiveNewProspectsShort: "Alertes",
            receiveWeeklyReportsShort: "Rapports",
            phoneNumber: "Numéro de téléphone",
            jobTitle: "Poste",
        },
        roles: {
            user: "Développeur",
            admin: "Administrateur",
            teamManager: "Responsable d'équipe",
        },
        displayTypes: {
            users: "Utilisateurs",
            teams: "Équipes",
        },
        actions: {
            create: "Ajouter un utilisateur",
            edit: "Modifier",
        }
    }
}