import { forwardRef } from 'react';
import { Layout, useGetIdentity, CheckForApplicationUpdate, LayoutProps, useTranslate, useGetList } from 'react-admin';
import { MyAppBar } from './components/layout/appBar';
import { MyMenu } from './components/layout/MyMenu';
import { SplitClient } from '@splitsoftware/splitio-react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { MyError } from './components/layout/ErrorPage';

const CustomAppUpdatedNotification = forwardRef((props, ref) => {
    const t = useTranslate();

    return <Alert
        sx={{
            zIndex: 9999,
            boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(106, 106, 106, 0.1) 0px 5px 36px 0px'
        }}
        ref={ref as React.RefObject<HTMLDivElement>}
        severity="info"
        action={
            <Button
                color="inherit"
                size="small"
                onClick={() => window.location.reload()}
            >
                {t('layout.update')}
            </Button>
        }
    >
        {t('layout.updateAvailable')}
    </Alert>
});

export const MyLayout = ({ children, ...props }: LayoutProps) => {
    const { data } = useGetIdentity();
    const { data: settings } = useGetList('settings', {},
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    return <SplitClient splitKey={(data && data?.id as string && data?.id !== "") ? (data?.id as string) : "anonymous"}>
        <Layout
            {...props}
            appBar={MyAppBar}
            menu={MyMenu}
            error={(error) => <MyError error={error} resetErrorBoundary={() => { }} />}
            sx={settings?.[0]?.displayBanner ?
                {
                    "& .RaLayout-appFrame": { marginTop: "96px" },
                    "& .RaSidebar-docked": { height: "calc(100vh - 96px)" },
                }
                : {}}
        >
            {children}
            <CheckForApplicationUpdate interval={300000} disabled={false} notification={<CustomAppUpdatedNotification />} />
        </Layout>
    </SplitClient>
}