import React, { useState, useEffect } from 'react';
import { Source, Layer, useMap, LayerProps } from 'react-map-gl';
import length from '@turf/length';
import distance from '@turf/distance';
import midpoint from '@turf/midpoint';
import { Point } from '@turf/helpers';
import theme from '../../../theme';

export const MeasureDistance = () => {
    const [points, setPoints] = useState<number[][]>([]); // Array of point coordinates [lng, lat]
    const [distances, setDistances] = useState<{ midpoint: number[]; dist: number }[]>([]);
    const [distanceTotal, setDistanceTotal] = useState<number | null>(null);
    const { current: map } = useMap();

    const pointLayer: LayerProps = {
        id: 'point',
        type: 'circle',
        paint: {
            'circle-radius': 4,
            'circle-color': theme.palette.primary.main,
        }
    };

    const lineLayer: LayerProps = {
        id: 'line',
        type: 'line',
        paint: {
            'line-color': theme.palette.primary.main,
            'line-width': 2
        }
    };

    const labelLayer: LayerProps = {
        id: 'label',
        type: 'symbol',
        layout: {
            'text-field': ['get', 'label'],
            'text-size': 14,
            'text-offset': [0, 1.5],
        },
        paint: {
            'text-color': '#000',
            'text-halo-color': '#fff', // White halo (border)
            'text-halo-width': 2, // Width of the halo
        },
    };

    const handleMove = (e: any) => {
        if (!map) return;

        const features = map.queryRenderedFeatures(e.point, {
            layers: ['point']
        });
        // Change the cursor to a pointer when hovering over a point on the map.
        // Otherwise cursor is a crosshair.
        map.getCanvas().style.cursor = features.length
            ? 'pointer'
            : 'crosshair';
    }

    useEffect(() => {
        if (!map) return;

        const handleClick = (e: any) => {
            setPoints((prevPoints) => {
                const clickedPoint = [e.lngLat.lng, e.lngLat.lat];

                // Check if the point was clicked (to remove)
                const features = map.queryRenderedFeatures(e.point, { layers: ['point'] });
                if (features.length > 0) {
                    const clickedFeature = features.find((f) => f.properties?.id !== 'line');

                    return prevPoints.filter((point) => {
                        const id = clickedFeature?.properties?.id;
                        return id !== String(prevPoints.indexOf(point));
                    });
                }

                // If not removing, add the point
                return [...prevPoints, clickedPoint];
            });
        };

        // Attach click event listener to the map
        map.on("click", handleClick);
        map.on("mousemove", handleMove);

        // Clean up the event listener when the component unmounts
        return () => {
            map.off("click", handleClick);
        };
    }, [map]);

    // Create geojson from points
    const geojson: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        //@ts-ignore
        features: [
            ...points.map((point, index) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: point
                },
                properties: {
                    id: String(index)
                }
            })),
            points.length > 1 && {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: points
                },
                properties: {
                    id: "line"
                }
            }
        ].filter(Boolean) // Filter out falsy values
    };

    // Create GeoJSON for labels
    const labelGeojson: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: distances.map((d, index) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: d.midpoint
            },
            properties: {
                id: `label-${index}`,
                label: `${(d.dist * 1000).toFixed(2)} m`
            }
        }))
    };

    // Calculate distances and midpoints
    useEffect(() => {
        if (points.length > 1) {
            const distances = [];
            let totalDistance = 0;

            for (let i = 0; i < points.length - 1; i++) {
                const pointA: Point = {
                    type: "Point",
                    coordinates: points[i]

                };
                const pointB: Point = {
                    type: "Point",
                    coordinates: points[i + 1]
                };

                // Calculate the distance between two points
                const dist = distance(pointA, pointB, { units: 'kilometers' });
                totalDistance += dist;

                // Calculate the midpoint
                const mid = midpoint(pointA, pointB);

                // Store distance and midpoint
                distances.push({
                    midpoint: mid.geometry.coordinates,
                    dist
                });
            }

            setDistances(distances);
            setDistanceTotal(totalDistance);
        } else {
            setDistances([]);
            setDistanceTotal(null);
        }
    }, [points]);

    return (
        <>
            <Source id="measure-source" type="geojson" data={geojson}>
                {/* Layer for Points */}
                <Layer {...pointLayer} />
                {/* Layer for Lines */}
                <Layer {...lineLayer} />
            </Source>
            {/* Labels for distances */}
            <Source id="label-source" type="geojson" data={labelGeojson}>
                <Layer {...labelLayer} />
            </Source>
            {distanceTotal !== null &&
                <div className=" bg-white p-2 rounded-md shadow-md">
                    Distance totale: {(distanceTotal * 1000).toFixed(2)} m
                </div>
            }
        </>
    );
};