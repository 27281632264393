import restProvider from 'ra-data-simple-rest'
import { checkRefresh } from './refreshAuthTokens';
import {
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    addRefreshAuthToDataProvider,
    combineDataProviders
} from "react-admin";

const httpDataClient = (async (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${process.env.REACT_APP_DATA_API_KEY}`);
    return fetchUtils.fetchJson(url, options);
});

const kaptcherDataProvider = restProvider(process.env.REACT_APP_DATA_API_BASE_URL ?? "", httpDataClient);

type PostParams = {
    id: string;
    title: string;
    content: string;
    file: {
        rawFile: File;
        fileName: string;
        src?: string;
        title?: string;
        type?: any;
    };
};

const createPostFormData = (
    params: CreateParams<PostParams> | UpdateParams<PostParams>
) => {
    const formData = new FormData();
    params.data.file?.rawFile && formData.append("file", params.data.file.rawFile);
    params.data.file?.type && formData.append("typeFile", params.data.file.type);
    params.data.file?.fileName && formData.append("fileName", params.data.file.fileName);
    formData.append("resource", "prospects");

    return formData;
};

const httpClient = (async (url: string, options: any = {}) => {

    const access_token = JSON.parse(localStorage.getItem('access_token') ?? "");

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${access_token}`);
    return fetchUtils.fetchJson(url, options);
});

const myDataProvider = restProvider(process.env.REACT_APP_API_BASE_URL ?? "", httpClient);
//@ts-ignore
export const dataProvider: DataProvider = combineDataProviders((resource) => {
    if (resource === 'permits') return kaptcherDataProvider;
    if (resource === 'parcels') return kaptcherDataProvider;
    if (resource === 'taxes') return kaptcherDataProvider;
    return {
        //@ts-ignore
        ...addRefreshAuthToDataProvider(myDataProvider, checkRefresh),

        getBlob: async (path: string, fileType: string) => {
            const access_token = JSON.parse(localStorage.getItem('access_token') ?? "");
            let options = { headers: new Headers({ Accept: 'application/json' }) };
            options.headers.set('Authorization', `Bearer ${access_token}`);
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/${path}`,
                options
            )
            if (!response.status || response.status !== 200) {
                throw new Error('Failed to download file');
            }

            const blob = await response.blob();
            const contentDisposition = response.headers.get('Content-Disposition');
            return { blob, contentDisposition };
        },

        downloadFile: async (path: string) => {
            const getBlob = await dataProvider.getBlob(path)
            const blob = getBlob.blob;
            const contentDisposition = getBlob.contentDisposition;

            let filename = 'downloaded-file';

            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '');
            }

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        },


        generatePDF: async (path: string, data: any) => {
            const access_token = JSON.parse(localStorage.getItem('access_token') ?? "");
            let options = {
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                method: 'POST',
                body: JSON.stringify(data),
            };
            options.headers.set('Authorization', `Bearer ${access_token}`);

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${path}`,
                options
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Convert the response into a Blob
            const blob = await response.blob();

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Open the URL in a new browser tab
            window.open(url, '_blank');
        },

        create: (resource, params) => {
            if (resource.includes("/files")) {
                const formData = createPostFormData(params);
                const access_token = JSON.parse(localStorage.getItem('access_token') ?? "");

                let options = { headers: new Headers({ Accept: 'application/json' }) };
                options.headers.set('Authorization', `Bearer ${access_token}`);

                return fetchUtils
                    .fetchJson(`${process.env.REACT_APP_API_BASE_URL}/${resource}`, {
                        method: "POST",
                        body: formData,
                        headers: options.headers,
                    })
                    .then(({ json }) => ({ data: json }));
            }

            //@ts-ignore
            return addRefreshAuthToDataProvider(myDataProvider, checkRefresh).create(resource, params);
        },
    }
});
