import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import theme from "../../theme";

interface Props {
    type: string
}

export const PropertySurfaceIcon = (props: SvgIconProps & Props) => {
    return (
        <div className="relative mt-1">
            <div className="absolute top-[-0.25em] left-1">
                <SvgIcon viewBox='0 0 24 24' fill="none" {...props} stroke={props.type === "houseSurface" ? theme.palette.primary.main : "#CFCFCF"}>
                    <path d="M0.438477 17.1702H16.1577M4.36827 7.88149H7.51211M9.86998 17.1702H14.5857V9.5607C14.5857 8.67431 14.5857 8.23109 14.466 7.88149C14.3065 7.41535 14.0005 7.04502 13.6154 6.85194C13.3265 6.70713 12.9605 6.70713 12.228 6.70713C11.4956 6.70713 11.1294 6.70713 10.8405 6.85194C10.4554 7.04502 10.1492 7.41535 9.98964 7.88149C9.86998 8.23109 9.86998 8.6743 9.86998 9.5607V17.1702ZM9.86998 17.1702V4.04376C9.86998 2.97833 9.87015 2.44568 9.69882 2.03874C9.54812 1.68078 9.30732 1.38973 9.01155 1.20735C8.67529 1 8.23542 1 7.35507 1H4.52562C3.64526 1 3.20475 1 2.8685 1.20735C2.57273 1.38973 2.33243 1.68078 2.18172 2.03874C2.01039 2.44568 2.01039 2.97833 2.01039 4.04376V17.1702H9.86998ZM4.36827 4.80476L7.51211 4.80476M4.36827 10.7021H7.51211M4.36827 13.7459H7.51211" fill="none" stroke-width="1.63" />
                </SvgIcon>
            </div>
            <SvgIcon viewBox='0 0 28 16' fill="none" {...props}>
                <path d="M26 1.41702H6.43836L2 15H21.911L23.9555 8.20851L26 1.41702Z" fill={props.type === "houseSurface" ? "#E6E6E6" : "none"} stroke={props.type === "houseSurface" ? "#CFCFCF" : theme.palette.primary.main} stroke-width="1.63" />
            </SvgIcon>
        </div>
    )
}

