import { TypeAppartmentIcon } from "./TypeAppartmentIcon";
import { TypeFieldIcon } from "./TypeField";
import { TypeHangarIcon } from "./TypeHangarIcon";
import { TypeGarageIcon } from "./TypeGarageIcon";
import { TypeBuildingIcon } from "./TypeBuilding";
import { TypeHouseIcon } from "./TypeHouseIcon";
import theme from "../../theme";


export const ProspectTypeIcon = (props: { type: string }) => {
    switch (props.type) {
        case "house":
            return <TypeHouseIcon stroke={theme.palette.primary.main} />;
        case "building":
            return <TypeBuildingIcon stroke={theme.palette.primary.main} />;
        case "appartment":
            return <TypeAppartmentIcon stroke={theme.palette.primary.main} />;
        case "garage":
            return <TypeGarageIcon stroke={theme.palette.primary.main} />;
        case "warehouse":
            return <TypeHangarIcon stroke={theme.palette.primary.main} />;
        case "field":
            return <TypeFieldIcon stroke={theme.palette.primary.main} />;
        default:
            return <TypeHouseIcon stroke={theme.palette.primary.main} />;
    }
}