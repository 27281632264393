import Box from '@mui/material/Box';

export const DPEIcon = (props: { DPE: string }) => {

    const DPEOptions = [
        {
            value: 'A',
            color: '#458D46',
        },
        {
            value: 'B',
            color: '#7DAA61',
        },
        {
            value: 'C',
            color: '#B1C55D',
        },
        {
            value: 'D',
            color: '#F7C353',
        },
        {
            value: 'E',
            color: '#FEA144',
        },
        {
            value: 'F',
            color: '#F45927',
        },
        {
            value: 'G',
            color: '#CE161E',
        }
    ]

    return <Box bgcolor={DPEOptions.find((option) => option.value === props.DPE)?.color} className="rounded-lg px-4 py-2">
        <Box color='white' fontSize={18}>{props.DPE}</Box>
    </Box>

}