import Typography from '@mui/material/Typography';
import { ProspectTypeIcon } from '../../components/Icons/ProspectTypeIcon';
import { SurfaceIcon } from '../../components/Icons/SurfaceIcon';
import { PluStatus } from '../../components/Chips/PluStatus';
import theme from '../../theme';
import { useTranslate } from 'react-admin';

export const PopupPropertyInfo = (props: any) => {
    const t = useTranslate();

    return <>
        <div className="flex items-center gap-x-1">
            <ProspectTypeIcon type={props?.data?.type} />
            <div>
                {t(`prospect.type.${props?.data?.type}`)}
            </div>
        </div>
        <div className="flex items-center gap-x-4">
            {props?.data?.fieldSurface &&
                <div className="flex items-center gap-x-1">
                    <SurfaceIcon stroke={theme.palette.primary.main} />
                    <div>
                        {`${props?.data?.fieldSurface} m²`}
                    </div>
                </div>
            }
            {props?.data?.pluInfo?.libelle &&
                <div className="flex items-center gap-x-1">
                    <PluStatus plu={props?.data?.pluInfo?.libelle} />
                    {`${props?.data?.pluInfo?.libelle}`}
                </div>
            }
        </div>
    </>
}

export const PopupProspectInfo = (props: any) => {
    return <div className="mr-[64px]">
        <Typography variant="h6">
            {props?.data?.name}
        </Typography>
    </div>
}

export const PopupAdressInfo = (props: any) => {
    return <div>
        {props?.data?.address}, {props?.data?.zipCode}
    </div>
}