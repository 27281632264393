import { IParcel } from "./Geography";

export type PropertyType = "house" | "appartment" | "field" | "garage" | "building" | "other" | "warehouse" | "industrialWasteland";
export const PropertyEnum = ["house", "appartment", "field", "garage", "building", "other", "warehouse", "industrialWasteland"];

export type OriginType = 'JVMI' | 'kaptcher' | 'creation' | 'terrafinder';

export interface pointType {
    type: string,
    coordinates: [number, number]
}

export interface pluInfoType {
    libelle: string,
    datevalid?: Date,
    libelong?: string,
    partitionId?: string,
    nomfic?: string,
    urlfic?: string,
}
export interface IProspectTag {
    title?: string,
    organisationId?: string,
    bgColor?: string,
    description?: string,
    scope?: "organisation" | "user" | "team",
    visibilityRef?: string,
}
export interface IProspectNote {
    title: string,
    description?: string,
    authorName: string;
}

export interface IProspectEvent {
    title?: string,
    description?: string,
    authorName: string;
    type: string;
    eventDate?: Date;
}


export interface IProspectTask {
    title?: string,
    description?: string,
    sendNotification: boolean;
    dueDate: Date;
    assigneeName?: string;
    assigneeId?: string;
    gcpTaskName?: string;
}

export type SaleTimingType = 'asap' | '3months' | '6months' | '12months' | 'morethan12months';

export type ProspectStatusesType = "pending" | "accepted" | "rejected";
export const ProspectStatusesEnum = ["pending", "accepted", "rejected"];

export type ProspectPipeStatusesType = "standBy" | "toBeTreated" | "toContact" | "contacted" | "toTransfert" | "transferred" | "closed" | "qualified" | "visited" | "offerSubmitted" | "mandateSigned" | "signed" | "wrongContact" | "notSelling" | "notInterested" | "followUp" | "feasabilityStudy";
export const ProspectPipeStatusesEnum = ["standBy", "toBeTreated", "toContact", "contacted", "toTransfert", "transferred", "closed", "qualified", "visited", "offerSubmitted", "mandateSigned", "signed", "wrongContact", "notSelling", "notInterested", "followUp", "feasabilityStudy"];

export type OwnerPipeStatusesType = "toContact" | "standBy" | "contacted" | "qualified" | "visited" | "offerSubmitted" | "mandateSigned" | "signed" | "wrongContact" | "notSelling";
export const OwnerPipeStatusesEnum = ["toContact", "standBy", "contacted", "qualified", "offerSubmitted", "mandateSigned", "signed", "wrongContact", "notSelling"];

export type ProspectScoringPropertiesType = "type" | "houseSurface" | "fieldSurface" | "zipCode" | "cityName" | "regionCode" | "country" | "dpe" | "contactPreference" | "parcelleNumber" | "generalState" | "constructionYear" | "numberRooms" | "ownerType" | "residencyType" | "saleTiming" | "salePrice";
export const ProspectScoringPropertiesEnum = ["type", "houseSurface", "fieldSurface", "zipCode", "cityName", "regionCode", "country", "dpe", "contactPreference", "parcelleNumber", "generalState", "constructionYear", "numberRooms", "ownerType", "residencyType", "saleTiming", "salePrice"];

export type contactRoleType = 'architect' | 'notary' | 'surveyor' | 'developer' | 'builder' | 'other';

export interface IPerson {
    fullName: string;
    createdBy: any;
    gender?: 'man' | 'woman';
    firstName?: string;
    lastName?: string;
    address?: string;
    zipCode?: string;
    cityName?: string;
    phoneNumber?: string;
    email?: string;
    externalId?: string;
    externalSource?: string;
    personType?: 'individual' | 'professional';
    job?: string;
    entityId?: any;
}

export interface IOwner extends IPerson {
    id: string;
    pipeStatus: OwnerPipeStatusesType;
    meetings?: Date[];
}

export interface IContact extends IPerson {
    id: string;
    organisationId: any;
    role: contactRoleType;
}
export interface IComment {
    text: string;
    authorName: string;
}
export interface IProspectProject {
    title?: string;
    floorArea?: number;
    projectedRevenue?: number;
    numberOfUnits?: number;
}

// 1. Create an interface representing a document in MongoDB.
export interface IProspect {
    id: string;
    name: string;
    type: PropertyType;
    address: string;
    email: string;
    phoneNumber: string;
    attributedAt?: Date;
    treatedAt?: Date;
    status: ProspectStatusesType;
    pipeStatus: ProspectPipeStatusesType;
    formResultId: any;
    organisationId: any;
    location: pointType;
    houseSurface?: number;
    fieldSurface?: number;
    zipCode?: string;
    cityName?: string;
    regionCode?: string;
    fileIds?: string[];
    country: string;
    rejectionReason?: string;
    notes?: IProspectNote[];
    events?: IProspectEvent[];
    tasks?: IProspectTask[];
    pluInfo?: pluInfoType;
    userIds?: any[];
    verifiedEmail?: boolean;
    verifiedPhone?: boolean;
    origin?: OriginType;
    ownerComment?: string;
    createdAt: Date;
    createdBy?: string;
    leadScore?: number;
    tags?: string[];
    parcels?: IParcel[];
    owners?: IOwner[];
    contactIds?: any[];
    checkListItems?: {
        itemId: string,
        status: 'pending' | 'accepted' | 'rejected',
        fileIds: string[],
        comments?: IComment[],
        updatedAt: Date,
        updatedBy: string
    }[];
    projects?: IProspectProject[];
}

// Define the interface for Source
export interface Source {
    type: string;
    text: string;
    fileCitation: string;
}

// Define the interface for PLUDatas
export interface IPLUData {
    cityName: string;
    zipCode: string;
    libelle: string;
    maxHeight: number;
    maxSurface: number;
    numberFloors: number;
    fullText: string;
    sources: Source[];
}