import { useState } from "react";
import { EmailTemplateCard } from "./EmailTemplateCard"
import Box from '@mui/material/Box';
import { RichTextField } from "react-admin";
import { BackButton } from "../Buttons/BackButton";

export const EmailTemplatesManager = (props: {
    channel: "email" | "postMail",
    templates: { title: string, body: string, type: 'email' | 'postMail', _id: string }[],
    onSendTemplate: (template: { body: string, title: string, templateId: string }) => void
}) => {
    const { channel } = props;
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewTemplate, setPreviewTemplate] = useState("");

    return isPreviewOpen ?
        <Box>
            <BackButton onClick={() => setIsPreviewOpen(false)} />
            <RichTextField record={{ body: previewTemplate }} source="body" />
        </Box>
        :
        <Box className="flex gap-4 bg-bg p-2 overflow-auto">
            {props.templates.filter((template) => template.type === channel).map((template, index) => {
                return <EmailTemplateCard
                    key={index}
                    title={template.title}
                    body={template.body}
                    onSendTemplate={() => { props.onSendTemplate({ body: template.body, title: template.title, templateId: template._id }) }}
                    onPreviewTemplate={() => {
                        setIsPreviewOpen(true);
                        setPreviewTemplate(template.body);
                    }}
                />
            })
            }
        </Box>
}