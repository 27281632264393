import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';
import ComponentsOverrides from './overrides';

//@ts-ignore
const theme = {
  palette,
  shape: { borderRadius: 8, borderRadiusSm: 4 },
  typography,
  shadows,
  customShadows,
  components: {
    RaMenu: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
          minHeight: '100%',
          "&.RaMenu-closed #calendar-wrapper": {
            opacity: 0
          }
        }
      }
    },
    RaCreateButton: {
      styleOverrides: {
        root: {
          backgroundColor: `${palette.primary.main}10`,
          color: palette.primary.darker,
          border: `1px solid ${palette.primary.main}`,
          '&:hover': {
            backgroundColor: `${palette.primary.main}40`,
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // invisible border when not active, to avoid position flashs
          color: palette.primary.light,
          paddingTop: '12px',
          paddingBottom: '12px',
          borderRadius: '0.325em',
          '& svg': {
            color: palette.primary.light,
          },
          '&.RaMenuItemLink-active': {
            color: palette.primary.darker,
            fontWeight: 500,
            '&:after': {
              content: '""',
              width: '4px',
              height: '36px',
              backgroundColor: palette.primary.main,
              position: 'absolute',
              right: '0',
              borderRadius: '25px'
            }
          },
          '&.RaMenuItemLink-active svg': {
            color: palette.primary.main
          },
          fontWeight: 400
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }
      }
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          "&.RaSidebar-appBarCollapsed": {
            margin: "0px"
          },
          "& .RaSidebar-fixed": {
            backgroundColor: 'white',
            height: '100vh ',
            top: '0px',
            zIndex: 5000
          },
        }
      }
    },
    RaCreate: {
      styleOverrides: {
        root: {
          "& .RaCreate-main": {
            marginBottom: "2em",
            "& form > .MuiToolbar-root": {
              backgroundColor: "white"
            }
          }
        }
      }
    },
    RaEdit: {
      styleOverrides: {
        root: {
          "& .RaEdit-main": {
            marginBottom: "2em",
            "& form > .MuiToolbar-root": {
              backgroundColor: "white"
            }
          }
        }
      }
    },
    RaList: {
      styleOverrides: {
        root: {
          "& .RaList-main": {
            width: '100%',
          }
        }
      }
    },
    RaShow: {
      styleOverrides: {
        root: {
          "& .RaShow-main": {
            marginBottom: "2em"
          },
          "& .RaShow-main.RaShow-noActions": {
            marginTop: 0
          }
        }
      }
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "minWidth": 'auto',
          "& .RaLayout-content": {
            overflow: 'hidden',
            position: "relative",
            paddingLeft: '16px',
            paddingTop: '4px',
            marginTop: '16px',
          },
          "& .RaLayout-appFrame": {
            marginTop: "44px"
          }
        }
      }
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          "& .filter-field .MuiFormControl-root": {
            marginBottom: "0px !important"
          }
        }
      }
    },
  }
}

//@ts-ignore
theme.components = { ...theme.components, ...ComponentsOverrides(theme) };

export default theme;