import { Link, useGetList, useListContext } from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import { KanbanColumn } from "../Kanban/KanbanColumn";
import { KanbanTitle } from "../Kanban/KanbanTitle";
import { RoleChip } from "../Chips/RoleChip";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

export const UsersColumnView = () => {
    const { data: teams } = useGetList('teams', { pagination: { page: 1, perPage: 1000 } });
    const { data: users } = useListContext();

    const colors = [
        theme.palette.primary.main,
        theme.palette.warning.main,
        theme.palette.info.main,
        theme.palette.success.main,
        theme.palette.error.main
    ]

    return <Box display="flex" className="gap-4 overflow-x-scroll h-full">
        {teams?.map((team: any, i: number) => {
            let teamUsers = users?.filter(user => user.teamIds?.includes(team.id));
            return <KanbanColumn key={team.id}>
                <KanbanTitle
                    title={
                        <Typography variant="h6">{team.name}</Typography>
                    }
                    color={colors[i % colors.length]}
                    count={teamUsers?.length ?? 0}
                />
                <Box className="flex flex-col gap-4 h-full overflow-auto">
                    {teamUsers?.map((user: any) => {
                        return <Link key={user.id} to={`/users/${user.id}/show`} className="bg-white p-4 rounded-lg mb-4 w-full overflow-hidden min-h-[79px]">
                            <Box key={user.id} className="text-sm">
                                <RoleChip role={user.role}
                                    label={
                                        <div className="flex items-center">
                                            {user.role === 'teamManager' && <StarBorderRoundedIcon />}
                                            {user.firstName} {user.lastName}
                                        </div>
                                    }
                                />
                                <div>{user.email}</div>
                            </Box>
                        </Link>
                    })}
                </Box>
            </KanbanColumn>
        })}
    </Box >
}