import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Report';
import History from '@mui/icons-material/History';
import Home from '@mui/icons-material/Home';
import { Title, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

interface MyErrorProps {
    error: any;
    resetErrorBoundary: () => void;
}

export const MyError = ({
    error,
    resetErrorBoundary,
    ...rest
}: MyErrorProps) => {
    const { pathname } = useLocation();
    const originalPathname = useRef(pathname);

    // Effect that resets the error state whenever the location changes
    useEffect(() => {
        if (pathname !== originalPathname.current) {
            resetErrorBoundary();
        }
    }, [pathname, resetErrorBoundary]);

    const t = useTranslate();

    return (
        <div className="flex flex-col items-center justify-center text-center pt-12">
            <Title title="Oups" />
            <h1 className="text-2xl font-bold">Oups, quelque chose s'est mal passé apparemment</h1>
            {process.env.NODE_ENV !== 'production' && (
                <details className="mb-4">
                    <h2>{t(error.toString())}</h2>
                    {/* {errorInfo.componentStack} */}
                </details>
            )}
            <div className="mb-4">Un simple rafraichissement devrait suffir</div>
            <div className="space-x-4">
                <Button
                    variant="contained"
                    startIcon={<History />}
                    onClick={() => window.location.reload()}
                >
                    Rafraîchir
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<Home />}
                    onClick={() => window.location.assign('/')}
                >
                    Accueil
                </Button>
            </div>
        </div>
    );
};