import * as React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useRecordContext, useTranslate } from 'react-admin';

import { InfoChip } from "../../components/Chips/InfoChip";
import { CalendarIcon } from "../../components/Icons/CalendarIcon";
import { DaytimeIcon } from "../../components/Icons/DayTimeIcon";

import theme from "../../theme";

export const DisponibilityField = (props: any) => {
    const record = useRecordContext(props);
    const t = useTranslate();

    const getDayTimes: string[] = record?.formResultId?.dayTimes ?
        Object.keys(record?.formResultId?.dayTimes).filter((key) => record?.formResultId?.dayTimes[key] === true).sort((a, b) => {
            const order = ["morning", "lunch", "afternoon", "evening"];
            return order.indexOf(a) - order.indexOf(b);
        })
        : [];

    if (getDayTimes?.length === 0 && record?.formResultId?.weekDays === undefined) {
        return <></>
    }

    return <div className="flex flex-col gap-2 mb-4">
        <Typography variant="h6">{t(`prospect.disponibility`)}</Typography>
        {record?.formResultId?.weekDays !== undefined && (
            <InfoChip label={t(`prospect.weekDays.${record.formResultId?.weekDays}`)} icon={<CalendarIcon />} />
        )}
        {getDayTimes?.length > 0 && (
            <Box sx={{ backgroundColor: theme?.palette?.bluegrey[100] }} className="flex flex-wrap items-center p-2 rounded-md w-fit">
                {getDayTimes.map((key, i, a) => (
                    <div className="flex gap-x-2 items-center text-primary cursor-default">
                        <DaytimeIcon type={key} />
                        <Typography color={theme?.palette.primary?.darker} variant="subtitle1">{t(`prospect.dayTimes.${key}`)}</Typography>
                        {i !== a.length - 1 && (
                            <Box sx={{ width: "1px", height: "24px", backgroundColor: theme.palette.grey[300], marginRight: "8px" }}></Box>
                        )}
                    </div>
                ))}
            </Box>
        )}
    </div>

}