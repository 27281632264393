// Libraires
import { useEffect, useState } from "react";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Map, { Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { useFormContext } from "react-hook-form";
import axios from "axios";
import {
    SimpleForm,
    Form,
    Create,
    TextInput,
    required,
    SimpleList,
    useTranslate,
    useInput,
    SaveButton,
    useRecordContext,
} from "react-admin";
import { center, centerOfMass } from "@turf/turf";
import { useWatch } from "react-hook-form";

// Mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

// Components
import { mapboxDefaultStyle, mapboxStreetStyle } from "../../helpers/constants";
import { layerStyle } from "../../helpers/helpers";
import { CadastreLayer } from "../../components/Map/Layers/CadastreLayer";
import GeocoderControl from "../../components/Map/Controllers/GeoController";
import { LocateControl } from "../../components/Map/Controllers/LocateControl";
import { BackButton } from "../../components/Buttons/BackButton";
import LayersController from "../../components/Map/Controllers/LayersController";
import { MapStyleButtons } from "../../components/Map/MapStyleButtons";
import { getAddressDetails } from "../../helpers/geo";
import { ProspectForm } from "../../components/Forms/ProspectForm";

//@ts-ignore
mapboxgl.workerClass = function () {
    return new Worker(new URL("../../mapbox.worker", import.meta.url));
};

const notEmptyParcels = (value: any[], allValues: any) => {
    if (!value || value.length === 0) {
        return "Required";
    }
    return undefined;
};
const validateParcels = [required(), notEmptyParcels];

const ParcelsInput = (props: {
    source: string;
    label: string;
    validate: any[];
    isManual: boolean;
}) => {
    const { isManual } = props;
    const { setValue, getValues } = useFormContext();
    const t = useTranslate();
    const [style, setStyle] = useState(mapboxStreetStyle);

    const [parcels, setParcels] = useState<any[]>(getValues("parcels") ?? []);
    const initCenter = getValues('parcels')?.[0]?.geometry?.coordinates[0][0][0];

    const getParcelsAddress = async (parcels: any) => {
        if (parcels?.length > 0) {
            const coords = center(parcels[0]?.geometry).geometry.coordinates;
            if (coords.length > 0) {
                const infos = await getAddressDetails(coords[0], coords[1]);
                return infos;
            }
        } else {
            return null;
        }
    };

    const setAddress = async (parcels: any) => {
        if (!isManual) {
            const address: any = await getParcelsAddress(parcels);
            let addressText = "";
            if (address !== null) {
                addressText = `${parcels[0]?.properties?.nom_com}`
                if (address.street !== null) {
                    addressText = `${address.city}, ${address.street}`
                }
            }
            setValue('name', addressText);
        }
    };

    const { id, field, fieldState, formState } = useInput({
        source: props.source,
        validate: props.validate,
    });

    const addParcelle = async (point: mapboxgl.LngLat) => {
        const geo = {
            type: "Point",
            coordinates: [point.lng, point.lat],
        };
        const newParcel = await axios.get(
            `https://apicarto.ign.fr/api/cadastre/parcelle?geom=${encodeURIComponent(
                JSON.stringify(geo)
            )}`
        );

        if (!newParcel.data.features[0]) return;

        //If not in parcels, add it
        if (!parcels.find((parcel) => parcel.id === newParcel.data.features[0])) {
            let newParcels = [...parcels, newParcel.data.features[0]];
            setParcels(newParcels);
            field.onChange(newParcels);
            setAddress(newParcels);
        }
    };

    const handleClick = async (event: any) => {
        //Check if parcel already exists with feature
        const feature = event.features && event.features[0];

        //If present, remove it by id
        if (feature) {
            let newParcels = parcels.filter(
                (parcel) => parcel.properties.idu !== feature.properties.idu);
            setParcels(newParcels);
            field.onChange(newParcels);
            setAddress(newParcels);
        } else {
            addParcelle(event.lngLat);
        }
    };

    return (
        <Box className="w-full h-full">
            {fieldState.error && (
                <p className="text-error">{t("prospect.form.selectParcels")}*</p>
            )}
            <Map
                fadeDuration={0}
                initialViewState={{
                    longitude: initCenter?.[0] ?? 2.3522,
                    latitude: initCenter?.[1] ?? 48.8566,
                    zoom: initCenter ? 16 : 10,
                }}
                mapStyle={`mapbox://styles/${style}`}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                style={{ height: "100%", width: "100%" }}
                onClick={handleClick}
                interactiveLayerIds={["my-data"]}
            >
                <div className="absolute top-20 md:top-16 w-full z-50 flex items-center px-2 justify-between ">
                    <div className=" bg-white shadow-lg rounded-sm flex items-center flex-wrap">
                        <MapStyleButtons
                            streetview={false}
                            onChange={(val: string) => setStyle(val)}
                        />
                    </div>
                    <LayersController
                        cadastreEnable={false}
                    />
                </div>
                <CadastreLayer />
                <GeocoderControl
                    onResult={() => { }}
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    position="top-left"
                />
                <LocateControl initialLocated={!initCenter} />
                <Source
                    generateId={true}
                    id="parcels"
                    type="geojson"
                    data={{
                        type: "FeatureCollection",
                        features: parcels?.map((parcel: any) => {
                            return {
                                type: "Feature",
                                geometry: parcel.geometry,
                                properties: parcel.properties,
                            };
                        }),
                    }}
                >
                    <Layer type="fill" {...layerStyle} />
                </Source>
            </Map>
        </Box>
    );
};

const NextButton = (props: { onClick: () => void }) => {
    const t = useTranslate();
    const parcels = useWatch({ name: "parcels" });
    return (
        <Button
            disabled={!parcels || parcels?.length < 1}
            variant="contained"
            onClick={props.onClick}
        >
            {t("buttons.next")}
        </Button>
    );
};


const MobileForm = () => {
    const t = useTranslate();
    const [tab, setTab] = useState(0);
    const [isManual, setIsManual] = useState<boolean>(false);
    return (
        <Form className="grow flex flex-col">
            <Box className="flex flex-col w-full h-full">
                <ParcelsInput
                    isManual={isManual}
                    source="parcels"
                    label="Parcels"
                    validate={validateParcels}
                />
            </Box>
            <Box className="p-2">
                {tab === 0 && (
                    <Box className="flex flex-col gap-4">
                        <Typography variant="h6" className="mb-4">
                            {t("prospect.form.selectParcels")}*
                        </Typography>
                        <NextButton onClick={() => setTab(1)} />
                    </Box>
                )}
                {tab === 1 && (
                    <>
                        <ProspectForm onChange={(val: boolean) => setIsManual(val)} />
                        <SaveButton />
                    </>
                )}
            </Box>
        </Form>
    );
};

const DesktopForm = () => {
    const t = useTranslate();
    const [isManual, setIsManual] = useState<boolean>(false);
    const record = useRecordContext();
    return (
        <SimpleForm className="grow" sx={{ height: "100%" }} id="create-form">
            <Grid container className="h-full" columnSpacing={4}>
                <Grid item md={4}>
                    <ProspectForm onChange={setIsManual} />
                </Grid>
                <Grid item md={8}>
                    <Box className="flex flex-col w-full h-full">
                        <Typography variant="h6" className="mb-4">
                            {t("prospect.form.selectParcels")}*
                        </Typography>
                        <ParcelsInput
                            isManual={isManual}
                            source="parcels"
                            label="Parcels"
                            validate={validateParcels}
                        />
                    </Box>
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export const ProspectCreate = () => {
    const t = useTranslate();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

    return (
        <Create
            redirect="show"
            title={t(`menu.prospects.current`)}
            transform={(data: any) => {
                return {
                    ...data,
                    type: data.type ?? "field",
                    location: {
                        type: "Point",
                        coordinates: centerOfMass(data.parcels[0]?.geometry).geometry
                            .coordinates,
                    },
                    notes: data.note && [
                        {
                            title: data.note && "Commentaire",
                            description: data.note ?? undefined
                        }

                    ]
                };
            }}
            sx={{
                height: "100%",
                "& .RaCreate-main": {
                    height: "calc(100% - 3em)",
                },
                "& #create-form": {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Box className="flex flex-col h-full">
                <Box className="flex gap-2">
                    <BackButton />
                    <Typography variant="h4">{t("prospect.actions.create")}</Typography>
                </Box>
                {isDesktop ? <DesktopForm /> : <MobileForm />}
            </Box>
        </Create>
    );
};
