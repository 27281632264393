import { useTranslate, Edit, useEditContext } from 'react-admin';
import { TargetingForm } from './TargetingForm';

const Form = () => {
    const { record } = useEditContext();
    return <TargetingForm mode="edit" buttonLabel="Edit" record={record} />
}

const TargetingEdit = () => {
    const t = useTranslate();

    return <Edit redirect="show" mutationMode='pessimistic' title={t('menu.targetings')}>
        <Form />
    </Edit>
};

export default TargetingEdit;
