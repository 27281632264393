import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExploreMap from "../../components/Map/ExploreMap";
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';

export const ExploreList = () => {
    return <SingleSplitTreatment
        name='kaptcher-explorer'
        render={
            <Box className="w-full h-full">
                <ExploreMap />
            </Box>
        }
        offRender={
            <>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.5), white)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1
                    }}
                >
                    <Button variant="contained" size="large" onClick={
                        () => {
                            window.analytics?.track('Discovered Kaptcher Explorer');
                        }
                    }>
                        <a href="https://www.loom.com/share/f2e99087cf0048dba5f1b06657b9a98f?sid=67ccdb57-5d51-4729-a355-3e38c90e6543" target="_blank" rel="noopener noreferrer">
                            Découvrir Kaptcher Explorer
                        </a>
                    </Button>
                </Box>
                <Box className="w-full h-full absolute" sx={{ zIndex: 0 }}>
                    <img src='/static/demos/explorer.png' className="w-full h-full" style={{ objectFit: 'cover' }} />
                </Box>
            </>
        }
    />
}