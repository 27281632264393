import {
    TextField,
    List,
    useTranslate,
    SimpleList,
    FunctionField,
    BooleanInput
} from 'react-admin';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { StyledList } from '../../components/layout/StyledList';
import { PageTitle } from '../../components/layout/PageTitle';
import { StyledDataGrid } from '../../components/layout/StyledDataGrid';
import BooleanField from '../../components/Fields/BooleanField';

export const OrganisationList = () => {
    const t = useTranslate();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const orgaFilters = [
        <BooleanInput label={"Actifs seulement"} source="onlyActive" alwaysOn />
    ]

    return (isDesktop ?
        <>
            <PageTitle title={t('menu.organisations')} />
            <StyledList title={<Typography variant="h4">Users</Typography>} filters={orgaFilters}>
                <StyledDataGrid rowClick={"show"}>
                    <TextField source="name" label={t("organisation.property.name")} />
                    <FunctionField source="type"
                        render={(record: any) => t(`organisation.types.${record.type}`)}
                    />
                    <BooleanField label={t("organisation.property.isActive")} source="isActive" />

                    {/* If attribution priority is set, return it, else if type is promotor, return 10, if type is reseller, return 20, else return 30 */}
                    <FunctionField label={t("organisation.property.attributionPriority")} render={(record: any) => record?.attributionPriority ?? (record?.type === 'promotor' ? 10 : record?.type === 'reseller' ? 20 : 30)} />
                    <BooleanField label={t("organisation.property.isWeeklyReportActivated")} source="isWeeklyReportActivated" />
                    <BooleanField label={t("organisation.property.isNotificationReceiver")} source="isNotificationReceiver" />
                    <BooleanField label={t("organisation.property.hasExclusivity")} source="hasExclusivity" />
                </StyledDataGrid>
            </StyledList >
        </>
        :
        <List title="Kaptcher">
            <SimpleList
                linkType="show"
                primaryText={record => { return record.name }}
                secondaryText={record => t(`organisation.types.${record.type}`)}
            />
        </List>
    );
};