// Icons
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/AlternateEmailRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

export const EventsTypeIcon = (props: { type: "email" | "call" | "meeting" | "postMail" }) => {
    switch (props.type) {
        case "email":
            return <EmailIcon color="warning" />;
        case "call":
            return <CallIcon color="error" />;
        case "meeting":
            return <PeopleAltOutlinedIcon color="success" />;
        case "postMail":
            return <DescriptionRoundedIcon color="primary" />;
        default:
            return <EmailIcon color="warning" />;
    }
}