import { useController } from 'react-hook-form';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-valueLabel': {
        'borderWidth': '3px',
        'borderColor': theme.palette.primary.main,
        'backgroundColor': 'white',
        'padding': '1px',
        'color': theme.palette.grey[800],
        'borderRadius': '4px',
        '&:before': {
            'transform': 'translate(-50%, 80%) rotate(45deg)',
            'backgroundColor': 'white',
            'bottom': '0',
            'left': '50 %',
            'border': 'none',
            'borderRight': 'solid #1E40AF 1px',
            'borderBottom': 'solid #1E40AF 1px',
            'borderWidth': '2.5px',
        }
    }
}));

export const RangeSlider = (props: any) => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useController({ name: props.name, defaultValue: props.defaultValue });

    const getLabel = (val: number) => {
        return val.toString() + " m²"
    }

    const handleChange = (e: any, idx: number) => {
        let newVal = field.value;
        newVal[idx] = e.target.value;
        field.onChange(newVal);
    }

    return (
        <span className='w-full'>
            <Typography id={props.label ?? "input-slider"} gutterBottom variant="h6">
                {props.label}
            </Typography>
            <div className="flex items-center">
                <TextField className="max-w-[60px]" variant="standard" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={field.value[0]} onChange={(e) => handleChange(e, 0)} />
                <StyledSlider
                    {...field}
                    min={props.min}
                    max={props.max}
                    defaultValue={props.defaultValue}
                    disableSwap
                    valueLabelDisplay="on"
                    aria-labelledby={props.label ?? "id-slider"}
                    step={props.step}
                    getAriaValueText={getLabel}
                    valueLabelFormat={getLabel}
                    className="mx-8 mt-8"
                />
                <TextField className="max-w-[60px]" variant="standard" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={field.value[1]} onChange={(e) => handleChange(e, 1)} />
            </div>
        </span>
    );
};