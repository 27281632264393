import { useCallback, useEffect, useState } from "react";
import Map, { Source, Layer, useMap, Popup, useControl, Marker } from 'react-map-gl';
import { DPEMarker } from "../../Icons/DPEMarker";
//@ts-ignore
import debounce from 'lodash.debounce';

export const DPELayer = (props: {
    onMarkerClick?: (dpe: any) => void
}) => {
    const { current: map } = useMap();
    const [dpeMarkers, setDpeMarkers] = useState<any[]>([]);

    function getDPEInfo() {
        if (!map) return;
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        //Get info from ademe API with bounds
        fetch(`https://data.ademe.fr/data-fair/api/v1/datasets/dpe-v2-logements-existants/lines?size=300&bbox=${sw.lng}%2C+${sw.lat}%2C+${ne.lng}%2C+${ne.lat}&select=Date_%C3%A9tablissement_DPE%2CEtiquette_GES%2CEtiquette_DPE%2C_geopoint`)
            .then((response) => response.json())
            .then((data) => {
                setDpeMarkers(data?.results
                    .filter((dpe: any) => dpe?.classe_consommation_energie !== "N" && dpe['_geopoint'])
                    .map((dpe: any) => {
                        let coordinates = dpe['_geopoint'].split(',');
                        dpe.latitude = coordinates[0];
                        dpe.longitude = coordinates[1];
                        return (
                            {
                                longitude: dpe.longitude,
                                latitude: dpe.latitude,
                                dpe: dpe.Etiquette_DPE,
                                dpeDate: dpe.Date_établissement_DPE
                            })
                    }));
            })
            .catch((error) => console.error('Error:', error));
    }

    const debouncedGetDPEInfo = useCallback(
        debounce(() => {
            getDPEInfo(); // Call your actual function here
        }, 300),
        []
    );

    useEffect(() => {
        if (map) {
            map.on('moveend', debouncedGetDPEInfo);
        }

        // Clean up the event listener
        return () => {
            if (map) {
                map.off('moveend', debouncedGetDPEInfo);
            }
        };
    }, [map, debouncedGetDPEInfo]); // Include dependencies

    return <>
        {dpeMarkers.map((marker: any, index: number) =>
            <Marker key={index} longitude={marker.longitude} latitude={marker.latitude} onClick={() => props.onMarkerClick?.(marker)}>
                <DPEMarker dpe={marker.dpe} />
            </Marker >
        )}
    </>
}