
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TagIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props}>
            <path d="M8.12498 26.25H25.5882C25.9537 26.25 26.2501 25.9537 26.2501 25.5882L26.25 18.1618M9.97557 25.8391L25.8026 18.4589C26.1339 18.3044 26.2771 17.9106 26.1227 17.5793L22.984 10.8487C22.8295 10.5174 22.4357 10.3742 22.1045 10.5286L15.0378 13.8238M12.3523 23.0061C11.7269 25.34 9.32791 26.7251 6.994 26.0997C4.66009 25.4743 3.27477 23.0753 3.90014 20.7414L8.41995 3.87329C8.51454 3.52026 8.87724 3.31068 9.23027 3.40528L16.4037 5.32741C16.7568 5.422 16.9663 5.78491 16.8717 6.13794L12.3523 23.0061ZM8.12498 22H8.12748L8.12742 22.0025L8.12498 22.0024V22Z" stroke="currentColor" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </SvgIcon >
    )
}
