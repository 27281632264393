import TextField from '@mui/material/TextField';
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import axios from 'axios';
import { useTranslate } from 'react-admin';

interface CommuneInfo {
    nom: string,
    code: string,
    codesPostaux: string[],
}

export const CommuneSearchInput = (props: any) => {
    const t = useTranslate();
    const [value, setValue] = React.useState<CommuneInfo | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<CommuneInfo[]>([]);

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        axios.get(`https://geo.api.gouv.fr/communes?nom=${inputValue}&fields=nom,code,codesPostaux&boost=population&limit=5`)
            .then((res) => {
                let results = res?.data;
                if (active) {
                    let newOptions: CommuneInfo[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
                return () => {
                    active = false;
                };
            })
            .catch((err) => {
                console.log(err)
            })
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="google-map-demo"
            sx={{ width: 300 }}
            getOptionLabel={(option: CommuneInfo) => option.nom}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={t("targeting.create.noCommune")}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                props.onSelected(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={t("targeting.create.searchCommune")} fullWidth
                    sx={{
                        '& label': {
                            top: "-6px",
                            backgroundColor: 'white',
                            '&.Mui-focused': {
                                top: "0px",
                            },
                            '&[data-shrink=true]': {
                                top: "0px",
                            }
                        }
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <div>{option.nom}</div>
                                <Typography variant="body2" color="text.secondary">
                                    {option?.codesPostaux[0]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
