import { DateField, ReferenceField, TextField, useGetIdentity, useRecordContext, useTranslate, useUpdate, Form, SelectInput, ReferenceArrayInput, RecordContextProvider, useNotify } from "react-admin";
import { useMutation } from '@tanstack/react-query';
import { dataProvider } from "../../../dataProvider";
// Mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

// Icons
import { CloseIcon } from "../../Icons/CloseIcon";
import DoneIcon from '@mui/icons-material/Done';
import Edit from "@mui/icons-material/Edit";

//Types 
import { IProspect } from "../../../types/Prospect";

// Styles
import theme from "../../../theme";
import { DownloadIcon } from "../../Icons/DownloadIcon";
import { useState } from "react";
import { CustomTextInput } from "../../Inputs/CustomTextInput";

export const CheckListItem = (props:
    {
        item: { _id: string, name: string, bgColor: string }
    }) => {
    const { item } = props;
    const prospect = useRecordContext<IProspect>();
    const t = useTranslate();
    const notify = useNotify();
    const [updateStatus] = useUpdate();
    const user = useGetIdentity();

    const prospectCheckItem = prospect?.checkListItems?.find((checkListItem) => checkListItem.itemId === item._id)
    const status = prospectCheckItem ? prospectCheckItem.status : 'pending';
    const [data, setData] = useState<string>(prospectCheckItem?.comments && prospectCheckItem.comments.length > 0 ? prospectCheckItem.comments[0].text : "");
    const [edit, setEdit] = useState<boolean>(prospectCheckItem?.comments?.[0] ? false : true);

    const { mutate } = useMutation({

        mutationFn: () => prospectCheckItem?.fileIds[0] && dataProvider.downloadFile(`prospects/${prospect?.id}/files/download/${prospectCheckItem?.fileIds[0]}`,
        ),
        onSuccess: () => {
            notify(`notifications.sent`, { type: "success" });
        },
        onError: () => {
            notify('BasicError', { type: "error" })
        }
    })

    const handleStatusChange = (status: string) => {
        updateStatus('prospects', {
            id: prospect?.id,
            data: {
                checkListItems: [
                    ...prospect?.checkListItems?.filter((checkListItem) => checkListItem.itemId !== item._id) ?? [],
                    { itemId: item._id, status, updatedAt: new Date(), updatedBy: user?.data?.id, fileIds: prospectCheckItem?.fileIds ?? [], comments: prospectCheckItem?.comments ?? [] }
                ]
            }
        })
    }
    const handleAddFile = (value: any) => {
        updateStatus('prospects', {
            id: prospect?.id,
            data: {
                checkListItems: [
                    ...prospect?.checkListItems?.filter((checkListItem) => checkListItem.itemId !== item._id) ?? [],
                    { itemId: item._id, status, updatedAt: new Date(), updatedBy: user?.data?.id, fileIds: value.fileIds !== "" ? value.fileIds : [], comments: prospectCheckItem?.comments ?? [] }
                ]
            }
        })
    }

    const handleSubmit = () => {
        updateStatus('prospects', {
            id: prospect?.id,
            data: {
                checkListItems: [
                    ...prospect?.checkListItems?.filter((checkListItem) => checkListItem.itemId !== item._id) ?? [],
                    {
                        ...prospectCheckItem, itemId: item._id, status,
                        comments: data !== "" ? [{ text: data, authorName: `${user?.data?.firstName} ${user?.data?.lastName}` }] : []
                    }
                ]
            }
        })
    };

    if (!prospect) return null;

    return <Box className="rounded-lg border-solid border-4 border-bg px-4 py-2" sx={{ borderLeftWidth: '12px', borderLeftColor: item.bgColor }
    } >
        <Box className="flex justify-between items-center "
        >
            <Box className="flex flex-col">
                <Typography variant="h6">
                    {item.name}
                </Typography>
                {prospectCheckItem && status !== "pending" &&
                    <Box className="flex gap-1 items-center">
                        {t(`prospect.checkListItem.statuses.${status}`)} par
                        <ReferenceField reference="users" source="updatedBy" link={false} record={prospectCheckItem}>
                            <TextField source="firstName" />
                            <TextField source="lastName" />
                        </ReferenceField>, le
                        <DateField source="updatedAt" record={prospectCheckItem} />
                    </Box>
                }
            </Box>
            <Box>
                <Box className="flex flex-col gap-1">
                    <Button size="small" variant={status === "accepted" ? 'contained' : 'outlined'} color="success"
                        onClick={() => handleStatusChange('accepted')}
                        sx={{ minWidth: '24px', width: '24px', height: '24px', padding: 0, "& .MuiSvgIcon-root": { marginRight: 0 } }}
                    >
                        <CheckRoundedIcon stroke={status === "accepted" ? 'white' : theme.palette.success.main} />
                    </Button>
                    <Button size="small" variant={status === "rejected" ? 'contained' : 'outlined'} color="error"
                        onClick={() => handleStatusChange('rejected')}
                        sx={{ minWidth: '24px', width: '24px', height: '24px', padding: 0, "& .MuiSvgIcon-root": { marginRight: 0 } }}
                    >
                        <CloseIcon stroke={status === "rejected" ? 'white' : theme.palette.error.main} />
                    </Button>
                </Box>
            </Box >
        </Box>
        <Box className="flex items-center m-0  w-full">
            <RecordContextProvider value={prospectCheckItem}>
                <Form className="flex flex-col items-center m-0 w-full " onSubmit={(e: any) => { handleSubmit(); setEdit(!edit); }}>
                    <div className="flex items-center w-full justify-between">
                        <ReferenceArrayInput sx={{ marginY: 0, marginBottom: 0 }} source="fileIds" reference={`prospects/${prospect.id}/files`} >
                            <SelectInput
                                fullWidth={false}
                                variant="standard"
                                optionText="fileName"
                                onChange={(value) => handleAddFile({ itemId: value.target.name, fileIds: value.target.value })}
                                label="Ajouter un fichier"
                                sx={{ maxWidth: '300px' }}
                            />
                        </ReferenceArrayInput>
                        {prospectCheckItem?.fileIds && prospectCheckItem?.fileIds[0] !== undefined &&
                            <Button onClick={() => { mutate() }} sx={{
                                color: theme.palette.primary.darker
                            }} startIcon={<DownloadIcon />} >{t('buttons.download')}</Button>
                        }
                    </div>
                    <div className="flex items-center w-full justify-between  ">

                        {edit ?
                            <>
                                <CustomTextInput
                                    value={data}
                                    onChange={(value: string) => { setData(value) }}
                                    size="small" multiline id="outlined-basic"
                                    label={false}
                                />
                                <Button startIcon={<DoneIcon />} type="submit"></Button>
                            </>
                            :
                            <div className="flex justify-between w-full">
                                <TextField
                                    className="flex items-center"
                                    source={`comments.${0}.text`}
                                    record={prospectCheckItem}
                                />
                                <Button startIcon={<Edit />} onClick={() => setEdit(!edit)}>{t("buttons.edit")}</Button>
                            </div>
                        }
                    </div>
                </Form>
            </RecordContextProvider>
        </Box >

    </Box >
}