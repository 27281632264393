import * as React from "react";
import { SimpleForm, TextInput, required, SelectInput, Toolbar, SaveButton, useTranslate, ReferenceInput, usePermissions, BooleanInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { randPassword } from "../../helpers/helpers";
import { PageTitle } from '../../components/layout/PageTitle';
import { BackButton } from '../../components/Buttons/BackButton';
import Box from '@mui/material/Box';

const UserFormToolbar = (props: any) => {
    const t = useTranslate();

    return <Toolbar {...props}>
        <SaveButton
            label={props.label}
            transform={data => {
                if (props.mode === "create") return ({ ...data, password: randPassword(5, 5, 5) })
                return data
            }}
            type="button"
        />
    </Toolbar>

}

export const UserForm = (props: any) => {
    const t = useTranslate();
    const { permissions } = usePermissions();

    return <SimpleForm defaultValues={{ receiveNewProspects: true, receiveWeeklyReports: true }} toolbar={<UserFormToolbar mode={props.mode} label={props.buttonLabel} />}>
        <Box className="flex gap-2 mb-4">
            <BackButton />
            <PageTitle title={t(`user.actions.${props.mode}`)} />
        </Box>
        <TextInput label={t("user.property.firstName")} source="firstName" validate={[required()]} />
        <TextInput label={t("user.property.lastName")} source="lastName" validate={[required()]} />
        {props.mode === "create" && (
            <TextInput label={t("user.property.email")} source="email" validate={[required()]} />
        )}
        <TextInput source="jobTitle" label={t("user.property.jobTitle")} />
        <SelectInput label={t("user.property.role")} source="role" validate={[required()]} choices={[
            { id: "user", name: t("user.roles.user") },
            { id: "admin", name: t("user.roles.admin") },
            { id: "teamManager", name: t("user.roles.teamManager") },
        ]} />
        <BooleanInput source="receiveNewProspects" label={t("user.property.receiveNewProspects")} />
        <BooleanInput source="receiveWeeklyReports" label={t("user.property.receiveWeeklyReports")} />
        <TextInput source="phoneNumber" label={t("user.property.phoneNumber")} />
        {permissions?.includes('superAdmin') === true && props.mode == "create" && (
            <ReferenceInput source="organisationId" reference="organisations" sort={{ field: "isActive", order: "DESC" }} perPage={50}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        )}
        <ReferenceArrayInput label={t('team.property.name')} source="teamIds" reference="teams" sort={{ field: "name", order: "ASC" }} perPage={50} />
    </SimpleForm>
}