import React from 'react';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Edit, SimpleForm, TextInput, required, Title, useRedirect, useNotify, useTranslate } from 'react-admin';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';


const ResetPassword = (): any => {
    let { id, token } = useParams();
    const redirect = useRedirect();
    const notify = useNotify();
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");

    const handleClick = async () => {
        setLoading(true);
        try {
            let result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/password-reset`,
                JSON.stringify({ token, userId: id, password }),
                { headers: { 'Content-Type': 'application/json' } }
            )
            if (result.status == 201) {
                notify('auth.resetPassword.passwordSaved', { type: 'success' });
                redirect('/login');
            } else {
                notify("Une erreur s'est produite", { type: 'warning' })
            }
        } catch (err: any) {
            console.log(err.message)
            notify(err.response.data, { type: 'warning' })
        }
    };

    return <div className='w-screen h-screen flex justify-center items-center'>
        <img src="/static/kaptcher-logo-full.png" className="absolute top-4 left-4 w-1/3 md:w-1/5"></img>
        <Card>
            <Title />
            <CardContent className="flex items-center justify-between flex-col">
                <Typography variant="h4" gutterBottom>{t('auth.resetPassword.newPassword')}</Typography>
                <TextField type="password" placeholder={t('auth.resetPassword.newPassword')} onChange={(e) => setPassword(e.target?.value)} />
                <div className="mt-4" >
                    <Button size="medium" variant="contained" onClick={handleClick} >{t(("buttons.validate"))}</Button>
                </div>
            </CardContent>
        </Card>
    </div>
}

export default ResetPassword;
