import { WithListContext, ReferenceArrayField, useCreate, useGetList, useRecordContext, useGetOne } from "react-admin";
import { IProspect } from "../../../types/Prospect";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, SendButton, InputToolbox, ConversationHeader } from '@chatscope/chat-ui-kit-react';
import { useState } from "react";
import '../../../styles/Chat.css';
import { IMessage } from "../../../types/Message";

export const ProspectChat = () => {
    const prospect = useRecordContext<IProspect>();
    const messages = useGetList<IMessage>(`prospects/${prospect?.id}/messages`);
    const propertyFinder = useGetList('contacts', { filter: { origin: 'terrafinder', role: 'propertyFinder', id: prospect?.contactIds } });

    const [message, setMessage] = useState<string>('');
    const [sendMessage, { isLoading: isSending }] = useCreate();

    if (!messages.data || !prospect) {
        return null;
    }

    const handleSendMessage = async (resource: string, data: any) => {
        sendMessage(resource, data, {
            onSuccess: () => {
                setMessage('');
            },
            onError: () => {
                console.log('Failed to send message');
            }
        });
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSendMessage(`prospects/${prospect.id}/messages`, { data: { message } });
        }
    }

    return <div>
        <MainContainer>
            <ChatContainer>
                <ConversationHeader>
                    <ConversationHeader.Content
                        info="Apporteur d'affaire sur TerraFinder"
                        userName={propertyFinder?.data?.[0]?.fullName}
                    />
                </ConversationHeader>
                <MessageList>
                    {messages.data.map((message: any) => {
                        return <Message model={{
                            message: message.message,
                            sentTime: message.createdAt,
                            direction: message.source === 'kaptcher' ? "outgoing" : "incoming",
                            position: "single",
                        }} />
                    })}
                </MessageList>
                <MessageInput placeholder="Message" attachButton={false} sendButton={false} value={message} onChange={setMessage} onKeyDown={handleKeyDown} />
                <InputToolbox>
                    <SendButton onClick={() => handleSendMessage(`prospects/${prospect.id}/messages`, { data: { message } })} disabled={isSending || message === ''} />
                </InputToolbox>
            </ChatContainer>
        </MainContainer>
    </div>
}


