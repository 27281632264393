//Create a react component that will display a popup when a button is clicked. Use MUI

import React, { ReactElement, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { IPLUData, IProspect, Source } from "../../types/Prospect";
import { useRecordContext, useTranslate } from "react-admin";

import OpenIcon from "@mui/icons-material/OpenInNew";
import { DoubleArrowIcon } from "../Icons/DoubleArrowIcon";

//component is named PLUPopupInfos
export const PLUPopupInfos = () => {
  const t = useTranslate();
  //in detail page, record is IProspect joined with PLUData object
  const prospect: (IProspect & { pluData: IPLUData } | undefined) = useRecordContext();
  //state to manage the open/close state of the popup
  const [open, setOpen] = useState(false);

  //function to open the popup
  const handleClickOpen = () => {
    window.analytics?.track("Opened PLU data Popup");
    setOpen(true);
  };

  //function to close the popup
  const handleClose = () => {
    setOpen(false);
  };

  const FormattedText = ({
    text,
    sources,
  }: {
    text: string;
    sources: Source[];
  }): ReactElement => {
    const renderText = () => {
      return text?.split("\n").map((line, index) => {
        const parts = line.split(/\*\*(.*?)\*\*/g); // Split by '**' and capture the enclosed text

        return (
          <div key={index}>
            {parts.map((part, partIndex) => {
              const matchSource = part.match(/【(\d+)†source】/);
              if (matchSource) {
                // Return path text, but replace the substrnig that matches with a Tooltip
                const citation = sources.find(
                  (source) => source.text === matchSource[0]
                );
                return (
                  <React.Fragment key={partIndex}>
                    {part.replace(matchSource[0], "")}
                    <Tooltip
                      key={`tooltip-${partIndex}`}
                      title={citation?.fileCitation ?? ""}
                    >
                      <strong>{"[Source]"}</strong>
                    </Tooltip>
                  </React.Fragment>
                );
              } else if (partIndex % 2 === 0) {
                return part; // Non-bold text
              } else {
                return <strong key={partIndex}>{part}</strong>; // Bold text
              }
            })}
          </div>
        );
      });
    };

    return <div>{renderText()}</div>;
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} color="primary">
        <OpenIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <div className="flex items-center gap-4">
          <DialogTitle id="alert-dialog-title">
            {"Obtenir les infos du PLU"}
          </DialogTitle>
          <a target="_blank" href={prospect?.pluInfo?.urlfic}>
            <Button
              variant="text"
              color="primary"
              size="small"
              startIcon={<OpenIcon />}
            >
              {t("prospect.actions.goToPLUDocument")}
            </Button>
          </a>
        </div>
        <Box className="px-6">
          <Typography variant="subtitle1" gutterBottom>
            {`Pour la zone ${prospect?.pluInfo?.libelle}, voici les informations de constructibilité trouvées :`}
          </Typography>
        </Box>
        <DialogContent>
          <Box className="flex row gap-8">
            <Box className="flex flex-col gap-6 w-full">
              <Box className="flex gap-4 items-center">
                <Box className="bg-green-600 w-10 h-10 rounded-sm">
                  <img
                    src="/static/imgs/groundtexture.png"
                    alt="terrain"
                    className="w-full h-full"
                  />
                </Box>
                <Typography variant="h6">{`Surface du terrain : ${prospect?.fieldSurface ?? 0
                  } m²`}</Typography>
              </Box>
              <Box className="flex gap-4 items-center">
                <Box className="bg-orange-400 w-10 h-10 rounded-sm"></Box>
                <Typography variant="h6">
                  {`${t("prospect.pluData.maxSurface")} : ${prospect?.pluData?.maxSurface
                    ? prospect?.pluData?.maxSurface * 100 + " %"
                    : "Non renseigné"
                    }`}
                </Typography>
              </Box>
              <Box className="flex gap-4 items-center">
                <Box className="w-10 h-10">
                  <img
                    src="/static/imgs/floorsnumber.png"
                    alt="terrain"
                    className="w-full h-full"
                  />
                </Box>
                <Typography variant="h6">
                  {`${t("prospect.pluData.maxNumberFloors")} : ${prospect?.pluData?.numberFloors ?? "Non renseigné"
                    }`}
                </Typography>
              </Box>
              <Box className="flex gap-4 items-center">
                <Box className="">
                  <DoubleArrowIcon />
                </Box>
                <Typography variant="h6">
                  {`${t("prospect.pluData.maxHeight")} : ${prospect?.pluData?.maxHeight
                    ? prospect?.pluData?.maxHeight + " m"
                    : "Non renseigné"
                    }`}
                </Typography>
              </Box>
              <DialogContentText id="dialog-plu-fulltext">
                <FormattedText
                  text={prospect?.pluData?.fullText ?? ""}
                  sources={prospect?.pluData?.sources ?? []}
                />
              </DialogContentText>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("ra.action.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
