import { useUpdate, useRecordContext, useGetIdentity, usePermissions, useTranslate } from 'react-admin';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import StatusChip from "../Chips/StatusChip";
import { styled } from '@mui/system';
import theme from "../../theme";
import { isSuperAdmin } from "../../helpers/helpers";
import { allPipeStatuses, defaultPipeStatuses, ownerPipeStatuses } from "../../helpers/constants";
import { getStatusColor } from '../../helpers/helpers';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
const StyledSelect = styled(Select)(({ theme }) => ({

    padding: 0,
    border: 0,
    '& .MuiSelect-select': {
        padding: "4px 8px",
        border: 0
    },
    '& fieldset': {
        border: 0
    }
}));

export const PipeStatusDropdown = (props: { pipeStatus: string, id?: string, onChange?: (value: string) => void, resourceType: 'prospect' | 'owner' | 'parcel', resource?: string, variant: 'normal' | 'compact' }) => {
    const [updateOne] = useUpdate();
    const t = useTranslate();
    const record = useRecordContext(props);
    const user = useGetIdentity();
    const { permissions } = usePermissions();
    const variant = props.variant;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    let color: { family: keyof typeof theme.palette, variant: "main" | "light" | "dark" | "lighter" | "darker" } = getStatusColor(props.pipeStatus);

    const handleTypeChange = (event: SelectChangeEvent<unknown | string>) => {
        event.stopPropagation();
        updateOne(
            `${props.resource}`,
            {
                id: props.id ? props.id : record?.id, data: { pipeStatus: (event.target.value as string) },
                previousData: {},
            },
            {
                onSuccess: () => {
                    props.onChange && props.onChange(event.target.value as string);
                }
            });
    };
    const pipeStatuses = props.resourceType === 'owner' ?
        ownerPipeStatuses : isSuperAdmin(permissions) ? allPipeStatuses : defaultPipeStatuses;
    const styleSX = (variant: "compact" | "normal") => {
        return (
            variant === "compact" ? {
                border: 0,
                padding: 0,
                borderRadius: '50%',
                height: '28px',
                width: '28px',
                backgroundColor: theme.palette[color.family][color.variant],
                '& .MuiChip-label': {
                    visibility: props.variant === "compact" ? 'hidden' : 'visible'
                }
            } : {
                border: 0,
                padding: 0,
            }
        )
    }
    const statuses =
        (user?.data?.pipeStatuses?.length > 0 && props.resourceType === 'prospect') ?
            user?.data?.pipeStatuses : pipeStatuses.map(status => ({ name: status, value: status }));

    const label = (status: string) => {
        return user?.data?.pipeStatuses?.length > 0 && props.resourceType === 'prospect' ? status : (props.resourceType === 'owner' ? t(`prospect.owners.statuses.${status}`) : t(`prospect.statuses.${status}`))
    }
    const menuItems = () => {
        return statuses.map((status: { name: string, value: string, _id: string }) => (
            <MenuItem key={status.name} value={status.value}>
                <StatusChip status={status.value} size="small" resourceType={props.resourceType} />
            </MenuItem>
        ));
    };

    const selectContent = (
        <StyledSelect
            onClick={(e) => e.stopPropagation()}
            onMouseDown={() => { setIsOpen(false) }}
            onMouseEnter={() => { setIsOpen(true) }}
            onMouseLeave={() => { setIsOpen(false) }}
            disableUnderline
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.pipeStatus}
            onChange={(e: SelectChangeEvent<unknown | string>) => handleTypeChange(e)}
            sx={() => styleSX(props.variant)}
            IconComponent={props.variant === "compact" ? () => null : undefined}
            MenuProps={{
                className: "mt-2",
            }}
        >
            {
                menuItems()
            }
        </StyledSelect >
    );

    return <div
        className={` ${variant === "normal" && 'rounded-md bg-white'
            } cursor-pointer`}
        style={{
            border: variant === "normal" ? `1px solid ${theme.palette.grey[200]} ` : 'none'
        }}>
        <FormControl fullWidth>
            {props.variant === "compact" ?
                <Tooltip
                    title={label(props.pipeStatus)}
                    arrow
                    open={isOpen}
                >
                    {selectContent}
                </Tooltip>
                :
                selectContent
            }

        </FormControl >
    </div >
}

//Set default props
PipeStatusDropdown.defaultProps = {
    resource: 'prospects',
    resourceType: 'prospect',
    variant: 'normal'
}