import { Layer, Source } from 'react-map-gl';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslate } from 'react-admin';
import { motion } from 'framer-motion';

// Components
import { NegativeStyledSwitch } from '../../Buttons/StyledSwitch';
import { PluLayer } from '../Layers/PluLayer';
import { CadastreLayer } from '../Layers/CadastreLayer';
import { ThreeDLayer } from '../Layers/ThreeDLayer';

// Mui
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { QPVLayer } from '../Layers/QPVLayer';
import { SelectedLayer } from '../Layers/SelectedLayer';
import { Slider } from "@mui/material";

const legendVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const ToggleButton = (props: {
    label: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    checked: boolean,
    color: "blue" | "white",
    disabled?: boolean
}) => {
    return <FormControlLabel
        control={props.color === "blue" ?
            <NegativeStyledSwitch
                onChange={props.onChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={props.disabled}
            />
            : <Switch
                onChange={props.onChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={props.disabled}
            />
        }
        sx={{ margin: 0, gap: "12px", fontSize: "11px", fontWeight: 600 }}
        label={props.label}
        labelPlacement="start"
        componentsProps={{ typography: { fontSize: "14px", fontWeight: 600 } }}
    />
}

const LayersController = (props: {
    color: "blue" | "white",
    cadastreEnable: boolean,
    pluEnable: boolean,
    QPVEnable: boolean,
    isoWalkingEnable: boolean,
    isoDrivingEnable: boolean,
    threeDEnable: boolean,
    record?: any,
    position?: [number, number],
    disabled?: boolean,
    setIsCadastreOn?: (val: boolean) => void
    selectedParcel?: any
}) => {
    const { color, cadastreEnable, pluEnable, QPVEnable, isoWalkingEnable, isoDrivingEnable, threeDEnable, record, position, disabled } = props;
    const t = useTranslate();

    const [pluLayer, setPluLayer] = useState(false);
    const [isoWalkingLayer, setIsoWalkingLayer] = useState(false);
    const [isoDrivingLayer, setIsoDrivingLayer] = useState(false);
    const [qPVLayer, setQPVLayer] = useState(false);
    const [cadastreLayer, setCadastreLayer] = useState(false);
    const [threeDLayer, setThreeDLayer] = useState(false);

    const [cadastreOpacity, setCadastreOpacity] = useState(0.7);
    const [pluOpacity, setPLUOpacity] = useState(0.7);
    const [QPVOpacity, setQPVOpacity] = useState(0.2);
    const [isoWalkingOpacity, setIsoWalkingOpacity] = useState(0.2);
    const [isoDrivingOpacity, setIsoDrivingOpacity] = useState(0.2);

    const { isLoading, error, data: isowalkingData } = useQuery({
        queryKey: ['isoWalking'],
        queryFn: () => {
            let location = position ?? record?.location?.coordinates;
            return fetch(`https://api.mapbox.com/isochrone/v1/mapbox/walking/${location[0]},${location[1]}?contours_minutes=10,20,30&polygons=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`).then((res) =>
                res.json(),
            )
        },
        enabled: isoWalkingLayer
    });
    const { data: isodrivingData } = useQuery({
        queryKey: ['isoDriving'],
        queryFn: () => {
            let location = position ?? record?.location?.coordinates;
            return fetch(`https://api.mapbox.com/isochrone/v1/mapbox/driving/${location[0]},${location[1]}?contours_minutes=10,20,30&polygons=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`).then((res) =>
                res.json(),
            )
        },
        enabled: isoDrivingLayer
    });

    return (<>
        <div className={`z-10 pr-2 flex flex-col px-2 py-1 gap-2 rounded-sm ${color === "blue" ? 'bg-primary text-white' : 'text-black'}`}>
            {cadastreEnable && (
                <>
                    < ToggleButton
                        color={color}
                        label={t('prospect.map.cadastre')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            window.analytics?.track('Triggered Cadastre Layer');
                            setCadastreLayer(event.target.checked);
                            if (props.setIsCadastreOn) {
                                props.setIsCadastreOn(event.target.checked);
                            }
                        }}
                        checked={cadastreLayer}
                        disabled={disabled}
                    />
                    {cadastreLayer === true && (
                        <Slider
                            size="small"
                            value={cadastreOpacity}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(e: Event, value: number | number[]) => setCadastreOpacity(value as number)}
                            aria-labelledby="opacity-slider"
                            valueLabelDisplay="auto"
                            color={color === "blue" ? "lightwhite" : "primary"}
                        />
                    )}
                </>
            )}
            {pluEnable && (
                <>
                    <ToggleButton
                        color={color}
                        label={t('prospect.map.plu')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            window.analytics?.track('Triggered PLU Layer');
                            setPluLayer(event.target.checked);
                        }}
                        checked={pluLayer}
                        disabled={disabled}
                    />
                    {pluLayer === true && (
                        <Slider
                            size="small"
                            value={pluOpacity}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(e: Event, value: number | number[]) => setPLUOpacity(value as number)}
                            aria-labelledby="opacity-slider"
                            valueLabelDisplay="auto"
                            color={color === "blue" ? "lightwhite" : "primary"}
                        />
                    )}
                </>
            )}
            {QPVEnable && (
                <>
                    <ToggleButton
                        color={color}
                        label={t('prospect.map.QPV')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            window.analytics?.track('Triggered QPV Layer');
                            setQPVLayer(event.target.checked);
                        }}
                        checked={qPVLayer}
                        disabled={disabled}
                    />
                    {qPVLayer === true && (
                        <Slider
                            size="small"
                            value={QPVOpacity}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(e: Event, value: number | number[]) => setQPVOpacity(value as number)}
                            aria-labelledby="opacity-slider"
                            valueLabelDisplay="auto"
                            color={color === "blue" ? "lightwhite" : "primary"}
                        />
                    )}
                </>
            )}
            {((record || position) && isoWalkingEnable) && (
                <>
                    <ToggleButton
                        color={color}
                        label={t('prospect.map.isoWalking')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            window.analytics?.track('Triggered Isochrone Walking Layer');
                            setIsoWalkingLayer(event.target.checked);
                        }}
                        checked={isoWalkingLayer}
                        disabled={disabled}
                    />
                    {isoWalkingLayer === true && (
                        <Slider
                            size="small"
                            value={isoWalkingOpacity}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(e: Event, value: number | number[]) => setIsoWalkingOpacity(value as number)}
                            aria-labelledby="opacity-slider"
                            valueLabelDisplay="auto"
                            color={color === "blue" ? "lightwhite" : "primary"}
                        />
                    )}
                </>
            )}
            {((record || position) && isoDrivingEnable) && (
                <>
                    <ToggleButton
                        color={color}
                        label={t('prospect.map.isoDriving')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            window.analytics?.track('Triggered Isochrone Driving Layer');
                            setIsoDrivingLayer(event.target.checked);
                        }}
                        checked={isoDrivingLayer}
                        disabled={disabled}
                    />
                    {isoDrivingLayer === true && (
                        <Slider
                            size="small"
                            value={isoDrivingOpacity}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(e: Event, value: number | number[]) => setIsoDrivingOpacity(value as number)}
                            aria-labelledby="opacity-slider"
                            valueLabelDisplay="auto"
                            color={color === "blue" ? "lightwhite" : "primary"}
                        />
                    )}
                </>
            )}
            {threeDEnable && (
                <ToggleButton
                    color={color}
                    label={t('prospect.map.threeD')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        window.analytics?.track('Triggered 3D Layer');
                        setThreeDLayer(event.target.checked);
                    }}
                    checked={threeDEnable}
                    disabled={disabled}
                />
            )}
        </div>
        {cadastreLayer && (
            <CadastreLayer opacity={cadastreOpacity} />
        )}
        {props.selectedParcel && (
            <SelectedLayer selectedParcel={props.selectedParcel} />
        )}
        {isoWalkingLayer && isowalkingData?.features?.map((feature: any, index: number) => {
            return <Source id={`isowalking-${index}`} type="geojson" data={feature} >
                <Layer key={index} type="fill" paint={{
                    "fill-color": feature?.properties?.fillColor,
                    "fill-opacity": isoWalkingOpacity
                }} />
            </Source>
        })}
        {isoDrivingLayer && isodrivingData?.features?.map((feature: any, index: number) => {
            return <Source id={`isodriving-${index}`} type="geojson" data={feature} >
                <Layer key={index} type="fill" paint={{
                    "fill-color": feature?.properties?.fillColor,
                    "fill-opacity": isoDrivingOpacity
                }} />
            </Source>
        })}
        {pluLayer && (
            <>
                <PluLayer opacity={pluOpacity} />
            </>
        )}
        {qPVLayer && (
            <>
                <QPVLayer opacity={QPVOpacity} />
            </>
        )}
        {(isoDrivingLayer || isoWalkingLayer) && (
            <motion.div
                className="legend bg-white p-2 rounded-sm mt-2 shadow-md text-black"
                initial="hidden"
                animate="visible"
                variants={legendVariants}
                transition={{ duration: 0.2 }}
            >
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-green-500"></div>
                    <span>10 min</span>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-orange-500"></div>
                    <span>20 min</span>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-red-500"></div>
                    <span>30 min</span>
                </div>
            </motion.div>
        )}
        {threeDLayer &&
            <ThreeDLayer />
        }
    </>
    )
}

LayersController.defaultProps = {
    color: "blue",
    isoWalkingEnable: false,
    isoDrivingEnable: false,
    pluEnable: true,
    QPVEnable: false,
    cadastreEnable: true,
    threeDEnable: false
}

export default React.memo(LayersController);