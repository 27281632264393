import React, { useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

type FormData = {
    minParcelSurface?: number;
    ownerTypes?: string[];
    siren?: string;
};

export const ParcelsFilter = (props: {
    onChange: (data: FormData) => void;
}) => {
    const { onChange } = props;
    const types = ['Privé', 'Public'];

    const { control } = useForm<FormData>({
        defaultValues: {
            minParcelSurface: 0, // default value for the slider
            ownerTypes: [], // default value for the select
            siren: '', // default value for the input
        },
    });

    // Watch all form values
    const formValues = useWatch<FormData>({ control });

    // Trigger the onChange prop whenever form values change
    useEffect(() => {
        onChange(formValues);
    }, [formValues, onChange]);

    return (
        <form >
            <div>Surface minimum</div>
            <Controller
                name="minParcelSurface"
                control={control}
                render={({ field }) => (
                    <Slider
                        {...field}
                        valueLabelDisplay="auto"
                        min={0}
                        max={10000}
                        step={1000}
                    />
                )}
            />

            <div>Type de propriétaire</div>
            <Controller
                name="ownerTypes"
                control={control}
                render={({ field }) => (
                    <Select
                        {...field}
                        multiple
                        input={<OutlinedInput label="Name" />}
                    >
                        {types.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {/* Text input for siren */}
            <Controller
                name="siren"
                control={control}
                render={({ field }) => (
                    <OutlinedInput {...field} label="Name" placeholder='SIREN' />
                )}
            />
        </form>
    );
};