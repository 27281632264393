import { Show, SimpleShowLayout, TextField, useTranslate, FunctionField, ImageField, UrlField, NumberField } from 'react-admin';
import BooleanField from '../../components/Fields/BooleanField';

const OrganisationShow = (props: any) => {
    const t = useTranslate();

    return (
        <Show>
            <SimpleShowLayout>
                <TextField label={t('organisation.property.name')} source="name" />
                <ImageField source="imageUrl" title="title" />
                <UrlField source="siteUrl" />
                <TextField label={t("organisation.property.description")} source="description" sx={{ minWidth: "450px" }} />
                <FunctionField source="type"
                    render={(record: any) => t(`organisation.types.${record.type}`)}
                />
                <NumberField label={"Priority"} source="attributionPriority" />
                <BooleanField label={t("organisation.property.isActive")} source="isActive" />
                <BooleanField label={t("organisation.property.isWeeklyReportActivated")} source="isWeeklyReportActivated" />
                <BooleanField label={t("organisation.property.isNotificationReceiver")} source="isNotificationReceiver" />
                <BooleanField label={t("organisation.property.hasExclusivity")} source="hasExclusivity" />
            </SimpleShowLayout>
        </ Show>
    )
}

export default OrganisationShow;