export const PluStatus = (props: { plu: string }) => {
    let color;
    if (props.plu === "A") {
        color = "yellow";
    } else if (props.plu === "N") {
        color = "green";
    } else {
        color = "red";
    }
    return <div style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: color }}>
    </div>
}