import { useController } from 'react-hook-form';
import { MuiColorInput } from 'mui-color-input'

export const ColorPickerInput = (props: { source: string, randomColor?: string }) => {

    const {
        field,
    } = useController({ name: props.source, defaultValue: props.randomColor });

    return (
        < MuiColorInput
            {...field}
            format="hex"
        />
    );
};
