import { Edit, useTranslate } from 'react-admin';
import { UserForm } from "./UserForm";

const UserEdit = () => {
    const t = useTranslate();

    return <Edit redirect="show" title={t('menu.users')}>
        <UserForm buttonLabel={t("ra.action.edit")} mode="edit" />
    </Edit>
};

export default UserEdit;
