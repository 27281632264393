export const teamMessages = {
    team: {
        property: {
            name: "Nom de l'équipe",
            createdAt: "Créée le",
        },
        actions: {
            create: "Créer une équipe",
            edit: "Modifier",
        }
    }
}