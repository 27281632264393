import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const CustomChip = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    color: 'white',
    fontSize: '0.6rem',
    position: 'relative',
    padding: '1px 4px',
    textWrap: 'nowrap',
}));

const Corner = styled(Box)({
    content: '""',
    position: 'absolute',
    backgroundColor: 'white',
    width: '3px',
    height: '3px',
});

const TopLeftCorner = styled(Corner)({
    top: 0,
    left: 0,
    borderBottomRightRadius: '10px',
});

const TopRightCorner = styled(Corner)({
    top: 0,
    right: 0,
    borderBottomLeftRadius: '10px',
});

const BottomLeftCorner = styled(Corner)({
    bottom: 0,
    left: 0,
    borderTopRightRadius: '10px',
});

const BottomRightCorner = styled(Corner)({
    bottom: 0,
    right: 0,
    borderTopLeftRadius: '10px',
});

export const Tag = (props: { label: string, color: string }) => {
    return <CustomChip sx={{ backgroundColor: props.color }} >
        {props.label}
        <TopLeftCorner />
        <TopRightCorner />
        <BottomLeftCorner />
        <BottomRightCorner />
    </CustomChip>
}