import { useContext, useEffect, useState, memo } from "react";
import { Layer, Source } from "react-map-gl";
import centerOfMass from "@turf/center-of-mass";
import { some } from "lodash";

export const CommunePluLayer = (props: { pluTypeZones: string[], pluLibelles?: string[], opacity?: number, pluData?: any }) => {
    const [pluLayer, setPluLayer] = useState<any>();
    const [symbolLayer, setSymbolLayer] = useState<any>();
    const { pluTypeZones, pluLibelles, pluData } = props;




    const getFilteredPluData = async () => {
        let data = {
            type: "FeatureCollection",
            features: []
        }

        data.features = pluData?.filter((feature: any) =>
            pluTypeZones.includes(feature.properties.typezone) &&
            ((!pluLibelles || pluLibelles?.length === 0) || some(pluLibelles, (libelle: string) => feature.properties.libelle.startsWith(libelle)))
        );

        //For each feature, create a symbol with the libelle as description. Calculate the center of the feature
        let symbols = data?.features.map((feature: any) => {
            let center = centerOfMass(feature);
            return {
                type: 'Feature',
                geometry: center.geometry,
                properties: {
                    description: feature.properties.libelle
                }
            }
        });

        setPluLayer(data);
        setSymbolLayer({
            type: 'FeatureCollection',
            features: symbols
        });
    }

    useEffect(() => {
        getFilteredPluData();
    }, [pluTypeZones, pluLibelles, pluData]);

    //Color the layer. Depends on the type of the zone, in the typezone property
    let pluPaint = {
        "fill-color": [
            'case',
            ['==', ['get', 'typezone'], 'AUc'],
            '#A44',
            ['==', ['get', 'typezone'], 'AUs'],
            '#A88',
            ['==', ['get', 'typezone'], 'U'],
            '#FF0000',
            ['==', ['get', 'typezone'], 'N'],
            '#00FF00',
            ['==', ['get', 'typezone'], 'A'],
            '#FFFF00',
            '#0000FF'
        ],
        "fill-opacity": props.opacity ?? 0.5
    }

    return (<>
        <Source id="commune-plu" type="geojson" data={pluLayer} >
            <Layer id="commune-plu" type="fill"
                // @ts-ignore
                paint={pluPaint}
            />
            <Layer id="border" type="line" paint={{
                "line-color": [
                    'case',
                    ['==', ['get', 'isFavourite'], true],
                    '#FC814F',
                    '#46A097'
                ],
                "line-width": 2
            }} />
        </Source>
        <Source id="commune-plu-symbol" type="geojson" data={symbolLayer}>
            <Layer
                minzoom={13}
                id="commune-plu-symbol"
                type="symbol"
                layout={{
                    'text-field': ['get', 'description'],
                    'text-size': 16,
                    'text-anchor': 'center',
                    'text-allow-overlap': true
                }}
            />
        </Source>
    </>
    )
}
