import { useState } from 'react';
import {
    BulkDeleteButton,
    CreateButton,
    Link,
    usePermissions,
    useStore,
    useTranslate,
} from 'react-admin';

//Mui
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import MUIButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import { DropdownActionsButton } from '../Buttons/DropdownActionsButton';
import { ArrowIcon } from '../Icons/ArrowIcon';
import theme from '../../theme';
import { CustomCard } from '../layout/CustomCard';
import { Divider } from '../layout/Divider';
import { isAdmin } from '../../helpers/helpers';

const UsersBulkButton = () => {
    const t = useTranslate();
    const [userIds] = useStore('users.selectedIds');
    const { permissions } = usePermissions();
    const admin = isAdmin(permissions);

    if (!admin) return null;
    if (!userIds?.length || userIds.length === 0) return <CreateButton label={t('user.actions.create')} resource="users" />;

    return <>
        <Typography variant="subtitle1" className="mr-4">{t('ra.action.bulk_actions', { smart_count: userIds?.length })}</Typography>
        <Link to="/teams/create">
            <CreateButton label={t('team.actions.create')} resource="teams" state={{ record: { userIds } }} />
        </Link>
        <BulkDeleteButton />
    </>
}

export const UsersToolbar = (props: { displayType: string, handleDisplayTypeChange: (val: any) => void }) => {
    const t = useTranslate();
    const [openType, setOpenType] = useState(false);

    let displayTypes = ["users", "teams"];


    return <CustomCard noShadow={true} className={'px-6'}>
        <Box
            className='flex w-full items-center gap-4'
        >
            <Box className="flex gap-2 items-center">
                <DropdownActionsButton
                    open={openType}
                    handleClose={() => setOpenType(false)}
                    actions={displayTypes.map((type: string) => {
                        return {
                            label: t(`user.displayTypes.${type}`),
                            onClick: () => props.handleDisplayTypeChange(type)
                        }
                    })}
                    button={
                        <MUIButton id="open-list-type" onClick={() => setOpenType(true)}>
                            <div>{t(`user.displayTypes.${props.displayType}`)}</div>
                            <ArrowIcon stroke={theme.palette.primary.main} sx={{ transform: 'rotate(-90deg)' }} />
                        </MUIButton>}
                />
                <Divider />
            </Box>
            <UsersBulkButton />
        </Box>
    </CustomCard>
}