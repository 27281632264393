// Librairies
import { required, useTranslate, SelectInput, TextInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

// Mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Types
import { IParcel } from '../../types/Geography';
import { IProspect } from '../../types/Prospect';

export const OwnerForm = (props: { prospect?: IProspect, mode: 'create' | 'edit' }) => {
    const { prospect, mode } = props;
    const t = useTranslate();
    const genderChoices = [
        { id: "man", name: t('prospect.owners.genders.man') },
        { id: "woman", name: t('prospect.owners.genders.woman') },
        { id: "womanAndMan", name: t('prospect.owners.genders.womanAndMan') }
    ]
    const form = useFormContext();
    const parcels = useWatch({ name: 'parcels' });

    const handleSameAddress = () => {
        if (parcels && parcels.length === 1) {
            let selectedParcel = prospect?.parcels?.find((parcel: IParcel) => parcel.id === parcels[0]);

            if (!selectedParcel) return;
            //Remove the cityName and zipCode from the address string
            let addressAlone = selectedParcel.address;
            if (addressAlone && selectedParcel.zipCode) {
                addressAlone = addressAlone.replace(selectedParcel.zipCode, '');
            }
            if (addressAlone && selectedParcel.cityName) {
                addressAlone = addressAlone.replace(selectedParcel.cityName, '');
            }
            form.setValue('address', addressAlone);
            form.setValue('cityName', selectedParcel.cityName);
            form.setValue('zipCode', selectedParcel.zipCode);
        }
    }

    return (
        <>
            <Box className="flex gap-x-2 items-end ">
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.owners.gender')}</Typography>
                    <SelectInput sx={{ margin: "0px" }} label={false} source="gender" choices={genderChoices} />
                </Box>
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.property.name')} *</Typography>
                    <TextInput source="lastName" label={false} validate={[required()]} />
                </Box>
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('user.property.firstName')}</Typography>
                    <TextInput source="firstName" label={false} />
                </Box>
            </Box >
            <Box className="flex gap-x-2 items-end ">
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.property.email')}</Typography>
                    <TextInput source="email" label={false} />
                </Box>
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.property.phoneNumber')}</Typography>
                    <TextInput source="phoneNumber" label={false} />
                </Box>
            </Box>
            {mode === 'create' &&
                <Button disabled={parcels?.length !== 1} onClick={() => handleSameAddress()}>Même adresse que la parcelle</Button >
            }
            <Box className="flex gap-x-2 items-end ">
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.property.address')}</Typography>
                    <TextInput source="address" label={false} />
                </Box>
                <Box className="flex flex-col">
                    <Typography variant="h6">{t('prospect.property.zipCode')}</Typography>
                    <TextInput source="zipCode" label={false} />
                </Box>
                <Box className="flex flex-col">
                    <Box className="flex flex-col">
                        <Typography variant="h6">{t('prospect.property.cityName')}</Typography>
                        <TextInput source="cityName" label={false} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};