export default function Avatar(theme) {
    return {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    border: `1px solid #E6E6E6`,
                },
            },
        }
    };
}
