// Components
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '@mui/material/Typography';
import { CustomTextInput } from './CustomTextInput';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
dayjs.extend(updateLocale);
dayjs.updateLocale('fr', {
    weekStart: 1,
});

export const CustomDateTimePicker = (props: any) => {
    return (<div className={props.className}>
        <div className="flex flex-col justify-between">
            <Typography variant="h6">{props.title}</Typography>
            <LocalizationProvider adapterLocale={dayjs.locale()} dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    renderInput={(props: any) => <CustomTextInput {...props} />}
                    value={props.value}
                    onChange={(value: any) => props.onChange(value)}
                    {...props}
                    format='DD/MM/YYYY HH:mm'
                />
            </LocalizationProvider>
        </div>
    </div >
    )
}