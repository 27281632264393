import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CommentIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox='0 0 30 30' fill="none" {...props} stroke="currentColor">
            <path d="M9.38782 24.7522C11.0397 25.7049 12.9563 26.2499 15.0002 26.2499C21.2134 26.2499 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 17.0439 4.29505 18.9605 5.24774 20.6123L5.24854 20.6137C5.34213 20.776 5.38904 20.8573 5.4102 20.9339C5.42995 21.0053 5.43647 21.0691 5.43141 21.143C5.42605 21.2215 5.39893 21.3029 5.34536 21.4636L5.34335 21.4696L4.38232 24.3527L4.38157 24.3549C4.17851 24.9641 4.07693 25.2688 4.14925 25.4716C4.21226 25.6484 4.3515 25.7874 4.5282 25.8505C4.73125 25.9229 5.03691 25.8211 5.64758 25.6176L8.53067 24.6565L8.53261 24.6559C8.69599 24.6014 8.77764 24.5742 8.85677 24.5688C8.93072 24.5637 8.99472 24.5701 9.06616 24.5898C9.14292 24.6111 9.22462 24.6581 9.38782 24.7522Z" stroke-width="1.625" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <circle cx="15" cy="15" r="1" fill="currentColor" />
            <circle cx="10" cy="15" r="1" fill="currentColor" />
            <circle cx="20" cy="15" r="1" fill="currentColor" />
        </SvgIcon >
    );
}
