import { useEffect, useState } from 'react';
import center from '@turf/center';
import { useTranslate } from 'react-admin';

// Mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// Icons 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { POIcon } from '../Icons/POIcon';

// Helpers
import { getNearbyPOIs } from '../../helpers/geo';
import { useMap } from 'react-map-gl';
import { POI } from '../../types/Geography';

export const ParcelPOIS = (props: { selectedParcel: any, setParcelPOI: any }) => {
    const { selectedParcel, setParcelPOI } = props;
    const t = useTranslate();
    const [pois, setPois] = useState<POI[]>([]);
    const { current: map } = useMap();

    useEffect(() => {
        if (!selectedParcel || !selectedParcel.geometry?.coordinates) return;
        let parcelCenter = center(selectedParcel.geometry);
        getNearbyPOIs(parcelCenter.geometry.coordinates[1], parcelCenter.geometry.coordinates[0])
            .then((data: any) => {
                setPois(data);
            });
    }, [selectedParcel]);

    const handleMouseEnter = (props: { lon?: number, lat?: number }) => {
        setParcelPOI({ lon: props.lon, lat: props.lat });
    };

    const handleCLick = (props: { lon?: number, lat?: number }) => {
        map && props.lon && props.lat && map.flyTo({ center: [props.lon, props.lat], zoom: 16 });
    }

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h4">{t('parcel.property.nearbyPOIs')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="flex flex-col gap-2">
                        {pois?.map((poi: POI) => {
                            return <>{(poi?.tags?.name || poi?.tags?.alt_name || poi?.tags?.official_name) &&
                                <Box key={poi.id} onClick={() => handleCLick({ lon: poi.lon, lat: poi.lat })} onMouseEnter={() => handleMouseEnter({ lon: poi.lon, lat: poi.lat })} className={`rounded-lg border-solid border-4  px-4 py-2 flex justify-between items-center ${poi?.tags?.amenity === "school" ? "border-yellow-600" : "border-indigo-600"}`}>
                                    <Box>
                                        <div>{poi.tags?.name ?? (poi.tags?.alt_name || poi?.tags?.official_name)}</div>
                                        <div>{parseInt(poi?.distance?.toString() || '0')} m</div>
                                    </Box>
                                    <POIcon icon={poi.tags?.amenity ?? poi.tags?.station ?? poi.tags?.operator ?? 'default-icon'} />
                                </Box>
                            }</>
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
};