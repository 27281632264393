import { useRecordContext, useGetIdentity } from "react-admin"

// Mui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

// Icons
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

// Types
import { IProspect } from "../../../types/Prospect"

// Styles
import theme from "../../../theme";

export const CheckListCircularProgressBar = (props: { size?: 'small' | 'medium' }) => {
    const prospect = useRecordContext<IProspect>();
    const user = useGetIdentity();

    if (!prospect) return null;

    const checkList = user?.data?.checkList;
    const total = checkList?.length ?? 100;
    const accepted = prospect.checkListItems?.filter((check: any) => check.status === 'accepted').length ?? 0
    const rejected = prospect.checkListItems?.filter((check: any) => check.status === 'rejected').length ?? 0

    const acceptedValue = (accepted / total) * 100;
    const rejectedValue = (rejected / total) * 100;

    const size = props.size === 'small' ? '24px !important' : '40px !important';

    const commonSx = {
        position: 'absolute',
        left: 0,
        top: 0,
        width: size,
        height: size,
    };

    const baseCircle = (
        <CircularProgress
            variant="determinate"
            value={100}
            thickness={6}
            sx={{
                ...commonSx,
                color: theme.palette.grey[300],
            }}
        />
    );

    const acceptedCircle = (
        <CircularProgress
            variant="determinate"
            value={acceptedValue}
            thickness={6}
            sx={{
                ...commonSx,
                color: theme.palette.success.main,
            }}
        />
    );

    const rejectedCircle = (
        <CircularProgress
            variant="determinate"
            value={rejectedValue}
            thickness={6}
            sx={{
                ...commonSx,
                color: theme.palette.error.main,
                transform: `rotate(${(((acceptedValue / 100)) * 360) - 90}deg) !important`,
                transformOrigin: 'center'
            }}
        />
    );

    return <Tooltip placement='top' title={`${accepted} tâches validées sur ${total}`}>
        <Box className={`${props.size === 'small' ? 'w-6 h-6' : 'w-10 h-10'} relative`}>
            {baseCircle}
            {acceptedCircle}
            {rejectedCircle}
            {accepted === total && (
                <Box className="flex items-center justify-center bg-success" sx={{ ...commonSx, color: 'white', borderRadius: '100%' }}>
                    <CheckRoundedIcon sx={{ ...commonSx, stroke: 'white', width: '24px', height: '24px', position: 'relative', marginRight: 0 }} />
                </Box>
            )}
        </Box>
    </Tooltip>
}

CheckListCircularProgressBar.defaultProps = {
    size: 'medium'
}