import { useState } from "react";
import { useTranslate, useRecordContext, Create, SimpleForm, SelectArrayInput } from "react-admin";
//Mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
//Components

import { IProspect } from "../../../types/Prospect";
import { OwnerList } from "./OwnerList";
import { OwnerForm } from "../../Forms/OwnerForm";
import { BackButton } from "../../Buttons/BackButton";
import { KaptcherOwnerInformation } from "./KaptcherOwnerInformation";
import { SelectAnOwner } from "../../Forms/SelectAnOwnerForm";

export const OwnerTab = (props:
    {
        displayType: 'list' | 'form',
        onMouseEnter: (ownerId: string) => void,
        onMouseLeave: (ownerId: string) => void,
        onSuccess: () => void,
        onBack: () => void,
        selectedParcelIds: string[]
    }) => {
    const prospect = useRecordContext<IProspect>();
    const { displayType, onMouseEnter, onMouseLeave, onSuccess, onBack } = props;
    const t = useTranslate();
    const [addNewOwner, setAddNewOwner] = useState<boolean>(false);
    const transform = (data: any) => {
        const formValues = data;
        const gender = formValues.gender ? t(`prospect.owners.gendersShort.${formValues.gender}`) : "";
        const fullName = `${gender} ${formValues.lastName} ${formValues.firstName ?? ""}`;
        setAddNewOwner(false);
        return {
            ...data,
            fullName, gender: !data.gender ? undefined : data.gender
        }
    };

    if (prospect?.origin !== 'creation' && prospect?.origin !== 'terrafinder') {
        return <KaptcherOwnerInformation />
    }
    return (
        <>
            {displayType === 'list' &&
                <Box className="max-h-[540px] overflow-auto">
                    {props.selectedParcelIds.length > 0 &&
                        <Box className="mb-2">Parcelles :
                            {props.selectedParcelIds.map(parcelId => {
                                const parcel = prospect?.parcels?.find(p => p.id === parcelId);
                                return parcel && `${parcel.properties.section} ${parcel.properties.numero}`;
                            }).join(', ')}
                        </Box>
                    }
                    <OwnerList onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} selectedParcelIds={props.selectedParcelIds} />
                </Box>
            }

            {displayType === 'form' &&
                <>
                    {prospect.owners?.length === 0 || addNewOwner === true ?

                        <Create
                            transform={transform}
                            title={' '}
                            mutationOptions={{
                                onSuccess: () => {
                                    onSuccess();
                                }
                            }}
                            resource={`prospects/${prospect?.id}/owners`}
                            redirect={false}
                            sx={{
                                "& .RaCreate-main": {
                                    marginBottom: 0
                                },
                                "& .MuiToolbar-root": {
                                    padding: 0
                                }
                            }}
                        >
                            <Box className="flex items-center gap-2 py-2 mb-4 border-b-2 border-neutral-100">
                                <BackButton onClick={() => { onBack(); setAddNewOwner(false); }} />
                                <Box className="font-semibold text-sm">{t('prospect.owners.addOwner')}</Box>
                            </Box>
                            <SimpleForm
                                defaultValues={{
                                    parcels: props.selectedParcelIds
                                }}
                                sx={{ padding: 0, }}
                                sanitizeEmptyValues={true}
                            >
                                <Typography variant="h6">{t('prospect.property.parcels')}</Typography>
                                <SelectArrayInput label={false} source="parcels" choices={prospect.parcels} optionText={(parcel) => `${parcel.properties.section} ${parcel.properties.numero}`} optionValue={"id"} />
                                <OwnerForm prospect={prospect} mode="create" />
                            </SimpleForm>

                        </Create>

                        :
                        <>
                            <Create
                            >
                                <Box className="flex items-center gap-2 py-2 mb-4 border-b-2 border-neutral-100">
                                    <BackButton onClick={onBack} />
                                    <Box className="font-semibold text-sm">{t('prospect.owners.addOwner')}</Box>
                                </Box>
                                <SimpleForm
                                    toolbar={false}
                                    sx={{ padding: 0, }}
                                    sanitizeEmptyValues={true}
                                    defaultValues={{
                                        selectedParcels: props.selectedParcelIds,
                                        selectedOwners: [],
                                    }}
                                >
                                    <Typography variant="h6">{t('prospect.property.parcels')}*</Typography>
                                    <SelectArrayInput
                                        sx={{
                                            width: '100%',
                                            "& .MuiInputBase-root": { height: "100%" },
                                            "& .MuiSelect-select": { height: "100%" },
                                            "& .MuiInputBase-input": { height: "100%" }
                                        }}
                                        label={false}
                                        source="selectedParcels"
                                        choices={prospect.parcels}
                                        optionText={(parcel) => `${parcel.properties.section} ${parcel.properties.numero}`}
                                        optionValue={"id"} />
                                    <SelectAnOwner onBack={onBack} setAddNewOwner={setAddNewOwner} prospect={prospect} />
                                </SimpleForm>
                            </Create>

                        </>
                    }
                </>
            }
        </>
    );
};