import { useTranslate } from 'react-admin';
// Mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components
import { CorportationDetails } from '../Corporations/CorporationDetails';

export const CorporationOwners = (props: { parcel: any }) => {
    const { parcel } = props;
    const t = useTranslate();

    if (!parcel?.corporationOwners || parcel?.corporationOwners.length <= 0) return <></>
    return <div className="flex flex-col gap-2">
        <Typography variant="h4">{t('parcel.property.corporationOwners')}</Typography>
        {parcel.corporationOwners.map((owner: any) => {
            return <Box key={owner.siren} className="rounded-lg border-solid border-4 border-bg px-4 py-2">
                <h1>{owner.denomination}</h1>
                <CorportationDetails siren={owner.siren} />
            </Box>
        })}
    </div>
}
