import { lazy, Suspense, useState } from 'react';
import {
    ExportButton,
    downloadCSV,
    Button,
    useTranslate,
    useGetIdentity,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

//Mui
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';

// Components
import { DropdownActionsButton } from '../Buttons/DropdownActionsButton';
import { SavedViewsList } from './CustomViews/SavedViewsList';
import { Divider } from '../layout/Divider';
import { CustomCard } from '../layout/CustomCard';
import { SelectColumnsButton } from '../Buttons/SelectColumnsButtons';
import theme from '../../theme';

// Icons
import { ArrowIcon } from '../Icons/ArrowIcon';
import UploadIcon from '@mui/icons-material/Upload';
import { getPipeStatusLabel } from '../../helpers/prospects';

//Lazy imports
const SpreadsheetImport = lazy(() => import("./SpreadsheetImport"));

export const ProspectToolBar = (props: { type: string, displayType: string, handleDisplayTypeChange: (e: any, val: any) => void }) => {
    const t = useTranslate();
    const [openType, setOpenType] = useState(false);
    const pipeStatuses = useGetIdentity()?.data?.pipeStatuses;

    const exporter = (items: any) => {
        window.analytics?.track('Exported Prospects', { type: props.type });
        const itemsForExport = items.map((item: any) => {
            const { id, pluInfo, formResultId, organisationId, location, verifiedEmail, userIds, createdBy, verifiedPhone, checkListItems, events, teamIds, attributedAt, externalId, contactIds, treatedAt, tags, fileIds, isOnAutopilot, rejectionReason, status, ...itemForExport } = item;
            if (props.type === "new") {
                delete itemForExport["email"];
                delete itemForExport["phoneNumber"];
            }
            itemForExport["type"] = t(`prospect.type.${itemForExport["type"]}`);
            itemForExport["pipeStatus"] = getPipeStatusLabel('prospect', t, pipeStatuses, itemForExport["pipeStatus"]);
            return itemForExport;
        });
        jsonExport(itemsForExport, {
        }, (err: any, csv: any) => {
            downloadCSV(csv, 'prospects');
        });
    };

    let displayTypes = props.type === "current" ? ["list", "splitted", "kanban"] : ["list", "splitted"];

    return <CustomCard noShadow={true} className={'px-6'}>
        <Box
            className='flex w-full justify-between items-center'
        >
            <Box className="flex gap-2 items-center">
                <DropdownActionsButton
                    open={openType}
                    handleClose={() => setOpenType(false)}
                    actions={displayTypes.map((type: string) => {
                        return {
                            label: t(`prospect.displayType.${type}`),
                            onClick: () => props.handleDisplayTypeChange(null, type)
                        }
                    })}
                    button={
                        <MUIButton id="open-list-type" onClick={() => setOpenType(true)}>
                            <div>{t(`prospect.displayType.${props.displayType}`)}</div>
                            <ArrowIcon stroke={theme.palette.primary.main} sx={{ transform: 'rotate(-90deg)' }} />
                        </MUIButton>}
                />
                <Divider />
                <Box className="grow">
                    <SavedViewsList />
                </Box>
            </Box>
            <div>
                {props.type === "current" &&
                    <SelectColumnsButton />
                }
                <ExportButton exporter={exporter} />
                <Suspense fallback={<Button
                    startIcon={<UploadIcon />}
                    variant="text"
                    color="primary"
                    onClick={() => { }}
                    label='Importer Mes Dossiers'
                    sx={{
                        textWrap: 'nowrap',
                    }}
                />}>
                    <SpreadsheetImport />
                </Suspense>
            </div>
        </Box>
    </CustomCard >
}