import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme) {

  return {
    MuiBackdrop: {
    }
  };
}
