
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate, Confirm } from 'react-admin';

// Icons
import { CircleCheck } from "../Icons/CircleCheck";
import { CloseIcon } from "../Icons/CloseIcon";

export const RejectForm = (props: any) => {
    const t = useTranslate();

    const [reason, setReason] = useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setReason(event.target.value as string);
    };


    return <Confirm
        sx={{
            padding: "16px",
            "& .MuiDialogContent-root": {
                paddingTop: "6px !important"
            },
            "& .MuiButton-root svg": {
                fontSize: "2.5em"
            },

        }}
        confirmColor="primary"
        ConfirmIcon={CircleCheck}
        CancelIcon={CloseIcon}
        isOpen={props.isOpen}
        title="Supprimer un prospect"
        content={
            <FormControl fullWidth>
                <InputLabel id="reject-reason-label">{t('buttons.selectRejectReason')}</InputLabel>
                <Select
                    labelId="reject-reason-label"
                    id="reject-reason"
                    value={reason}
                    label={t('buttons.selectRejectReason')}
                    onChange={handleChange}
                >
                    <MenuItem value={"constructibility"}>{t('prospect.rejectionReason.constructibility')}</MenuItem>
                    <MenuItem value={"potential"}>{t('prospect.rejectionReason.potential')}</MenuItem>
                    <MenuItem value={"contactinfos"}>{t('prospect.rejectionReason.contactinfos')}</MenuItem>
                    <MenuItem value={"other"}>{t('prospect.rejectionReason.other')}</MenuItem>
                </Select>
            </FormControl>
        }
        onConfirm={() => props.onConfirm(reason)}
        onClose={props.onClose}
    />
}