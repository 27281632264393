import { Layer, Source } from "react-map-gl"

export const SelectedLayer = (props: { selectedParcel?: any }) => {

    return (
        <>
            <Source id="selected-parcel-source" type="geojson" data={props.selectedParcel}>
                <Layer id="selected-parcel-layer" type="fill" source="selected-parcel-source" paint={{ 'fill-color': '#FF0000', 'fill-opacity': 0.5 }} />
            </Source>
        </>
    );
};
