import { useController } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextInput from "@mui/material/TextField";

export const RATextInput = (props: any) => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useController({ name: props.source, defaultValue: '' });

    return (
        <div className={props.className}>
            <FormControl fullWidth>
                <Typography variant="h6">{props.title} {props.required ? " *" : ""}</Typography>
                <TextInput {...field}
                    placeholder={props.placeholder}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '48px'
                        }
                    }}
                />
            </FormControl>
        </div>
    );
};