import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

export const DropdownActionsButton = (props: { open: boolean, button: JSX.Element, actions: { label: string | JSX.Element, onClick: () => void, disabled?: boolean }[], handleClose: () => void }) => {
    const { handleClose } = props;
    const id = props.open ? 'dropdown-popover' : undefined;

    return <>
        {props.button}
        <Popover
            id={id}
            open={props.open}
            anchorEl={document.getElementById(props.button.props.id ?? "action-button")}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="flex flex-col gap-1 p-1">
                {props.actions.map((action, index) => {
                    return <Button key={index} onClick={() => { handleClose(); action.onClick() }} disabled={action.disabled}>
                        {action.label}
                    </Button>
                })}
            </div>
        </Popover>
    </>
}