import { Form, useCreate, useNotify, useRecordContext, useRefresh, useGetIdentity, useShowContext } from "react-admin"
import { AudioRecorder } from 'react-audio-voice-recorder';
import { SplitClient } from '@splitsoftware/splitio-react';
import { IProspect } from "../../types/Prospect";
import { SingleSplitTreatment } from '../../components/layout/SingleSplitTreatment';
import '../../styles/audioRecorder.css';

export const VoiceUploader = () => {
    const [addOne, { isLoading: isCreateLoading }] = useCreate();
    const { record: prospect, refetch } = useShowContext<IProspect>();
    const notify = useNotify();
    const user = useGetIdentity();

    const addAudioElement = (blob: Blob) => {
        if (!prospect) return;

        const date = new Date();
        const timeStamp = date.getTime().toString();
        addOne(`prospects/${prospect.id}/files`, {
            data: {
                file: {
                    rawFile: blob, type: "audioFile", fileName: timeStamp
                },
            }
        },
            {
                onSuccess: (data) => {
                    window.analytics?.track('Added a voice note');
                    notify(`notifications.sent`, { type: "success" });
                    refetch();
                },
                onError: () => {
                    notify('BasicError', { type: "warning" })
                }
            });
    }

    return <SplitClient splitKey={user?.data?.organisationId} trafficType='account'>
        <SingleSplitTreatment
            name="record-voice-note"
            render={
                <Form>
                    <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                        }}
                        onNotAllowedOrFound={(err) => console.table(err)}
                        downloadOnSavePress={false}
                        downloadFileExtension="wav"
                        mediaRecorderOptions={{
                            audioBitsPerSecond: 128000,
                        }}
                    />
                </Form>
            }
            offRender={<></>}
        />
    </SplitClient>

}
