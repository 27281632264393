import { Create, CreateButton, Form, NumberInput, SaveButton, TextInput, useGetList, useGetOne, useNotify, useTranslate } from 'react-admin';
import center from '@turf/center';
import dayjs from 'dayjs';
// Mui
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';

// Components
import { PluStatus } from '../Chips/PluStatus';
import { ParcelPOIS } from './ParcelPOIS';
import { CorporationOwners } from './CorporationOwners';
import { PappersLink } from '../Fields/PappersLink';
import { Divider } from '../layout/Divider';
import { IParcel } from '../../types/Geography';
import { ParcelTaxes } from './ParcelTaxes';
import { ArrowIcon } from '../Icons/ArrowIcon';
import theme from '../../theme';
import { useMap } from 'react-map-gl';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { centerOfMass } from '@turf/turf';
import { getAddressDetails } from '../../helpers/geo';
import { ProspectForm } from '../Forms/ProspectForm';

const ParcelDetails = (props: { selectedParcel: IParcel, setParcelPOI: any }) => {
    const { selectedParcel, setParcelPOI } = props;
    const notify = useNotify();
    const { data: parcel } = useGetOne('parcels', { id: selectedParcel.properties?.idu });
    const { current: map } = useMap();
    const t = useTranslate();
    const [createFolder, setCreateFolder] = useState<boolean>(false)
    const handleCLick = () => {
        map && map.flyTo({ center: [selectedParcel?.center[0], selectedParcel?.center[1]], zoom: 16 });
    }
    const getParcelsAddress = async (selectedParcel: IParcel) => {
        const coords = center(selectedParcel?.geometry).geometry.coordinates;
        if (coords.length > 0) {
            const infos = await getAddressDetails(coords[0], coords[1]);
            return infos;
        }
    };
    useEffect(() => {
        const fetchAddress = async () => {
            const addressDetails = await getParcelsAddress(selectedParcel);
            selectedParcel.address = addressDetails?.street;
        };
        fetchAddress();
    }, [selectedParcel]);

    return <div>
        <Button onClick={() => setCreateFolder(!createFolder)} >Créer un dossier Kaptcher</Button>
        <Dialog
            open={createFolder}
            onClose={() => setCreateFolder(false)}

        >
            <Create
                redirect={false}
                title={t(`menu.prospects.current`)}
                mutationOptions={{
                    onSuccess: () => {
                        setCreateFolder(false)
                        notify("Le dossier a bien été créé");
                    },
                }
                }
                transform={(data: any) => {
                    return {
                        ...data,
                        parcels: [selectedParcel],
                        type: data.type ?? "field",
                        location: {
                            type: "Point",
                            coordinates: centerOfMass(selectedParcel.geometry).geometry
                                .coordinates,
                        },
                        notes: data.note && [
                            {
                                title: data.note && "Commentaire",
                                description: data.note ?? undefined
                            }
                        ]
                    };
                }}
                resource="prospects"
            >
                <Form className="flex flex-col items-center" >
                    <ProspectForm selectedParcel={selectedParcel} />
                    <SaveButton alwaysEnable className="w-min" />
                </Form>
            </Create>
        </Dialog >
        <div>
            <p>{`${selectedParcel.properties.section} ${selectedParcel.properties.numero}`}</p>
            <p>{selectedParcel.properties.contenance}m²</p>
        </div>
        {
            parcel &&
            <>
                <div className="flex flex-col gap-2">
                    <div className='flex justify-between items-center'>
                        <PappersLink parcel={selectedParcel} coordinates={center(parcel).geometry.coordinates ?? undefined} />
                        <div>
                            <IconButton
                                onClick={() => handleCLick()}
                                color="primary"
                                size="large"
                                sx={{ backgroundColor: 'white' }}
                            >
                                <GpsFixedIcon
                                    stroke={theme.palette.primary.main}
                                />
                            </IconButton>
                        </div>
                    </div>

                    <CorporationOwners parcel={parcel} />
                    <ParcelPOIS selectedParcel={selectedParcel} setParcelPOI={setParcelPOI} />
                </div>
            </>
        }
    </div >
};

export const ParcelDrawer = (props: { selectedParcel: IParcel, setSelectedParcel: any, selectedPLU: any, setSelectedPLU: any, setParcelPOI: any }) => {
    const { selectedParcel, selectedPLU, setParcelPOI } = props;
    const t = useTranslate();

    return <Drawer
        open={selectedParcel !== null}
        onClose={() => { props.setSelectedParcel(null) }}
        sx={{
            '& .MuiBackdrop-root': {
                display: 'none',
            },
            width: 'fit-content',

        }}
    >
        <div className="w-96 p-2 pb-16">
            <div className="absolute right-2 top-2 z-50">
                <IconButton
                    onClick={() => { props.setSelectedParcel(null); props.setParcelPOI({ lon: null, lat: null }) }}
                    color="primary"
                    size="large"
                    sx={{ backgroundColor: 'white' }}
                >
                    <ArrowIcon
                        stroke={theme.palette.primary.main}
                    />
                </IconButton>
            </div>
            {selectedParcel &&
                <div className="flex flex-col gap-4">
                    <ParcelDetails selectedParcel={selectedParcel} setParcelPOI={setParcelPOI} />
                    <ParcelTaxes selectedParcel={selectedParcel} />
                </div>
            }
            {selectedPLU &&
                <Box className="mb-4">
                    <div className="flex gap-x-2 items-center mb-4">
                        <PluStatus plu={selectedPLU.libelle} />
                        {selectedPLU.properties?.libelle}
                    </div>
                    <div>
                        {selectedPLU.properties?.libelong}
                    </div>
                    <div>
                        {selectedPLU.properties?.typezone}
                    </div>
                    <div>
                        Dernière date de révision : {dayjs(selectedPLU.properties?.gpu_timestamp).format('DD/MM/YYYY')}
                    </div>
                    <div className="flex mt-2">
                        <a target="_blank" href={`https://www.geoportail-urbanisme.gouv.fr/api/document/${selectedPLU?.properties?.gpu_doc_id}/download-file/${selectedPLU?.properties?.nomfic}`} >
                            <Button variant="text" color="primary" size="small" >
                                {t('prospect.actions.goToPLUDocument')}
                            </Button>
                        </a>
                    </div>
                </Box>
            }
        </div>
    </Drawer >
}