import { CurrentProspectsDataTable } from './CurrentProspectDataTable';
import { NewProspectsDataTable } from './NewProspectsDataTable';

export const ProspectsDataTable = (props:
    {
        type: string,
        permissions: string[],
        filter: any,
        onOpenPreview: (id: string) => void,
        displayCheckList?: boolean
    }) => {

    return props.type === "current" ?
        <CurrentProspectsDataTable {...props} /> :
        <NewProspectsDataTable {...props} />
}

ProspectsDataTable.defaultProps = {
    displayCheckList: false
}