export const taxesMessages = {
    taxe: {
        property: {
            siren: "SIREN",
            nic: "NIC",
            siren_beneficiaire: "SIREN bénéficiaire",
            nic_beneficiaire: "NIC bénéficiaire",
            date_effet: "Date d'effet",
            "DEF/PROV": "DEF/PROV",
            zone_application: "Zone d'application",
            code_dep: "Code département",
            code_com: "Code commune",
            libelle_com: "Libellé commune",
            taux: "Taux",
            prefixe: "Préfixe",
            section: "Section",
            numero: "Numéro",
            stationnement: "Stationnement",
            locaux_habitation: "Locaux d'habitation",
            locaux_pret_sans_interet: "Locaux prêt sans intérêt",
            locaux_artisanat: "Locaux d'artisanat",
            commerce_detail: "Commerce de détail",
            immeuble_classe: "Immeuble classé",
            abris_jardin: "Abris de jardin",
            maisons_sante: "Maisons de santé",
            terrains_rehabilités: "Terrains réhabilités"
        },
        applicationZones: {
            commune: "communale",
            departement: "départementale",
            region: "régionale"
        }
    }
}