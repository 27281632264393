import EmailOutlined from '@mui/icons-material/EmailOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Close from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';

export const ContactPreferenceIcon = (props: { type: string, color: "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" }) => {
    switch (props.type) {
        case "email":
            return <EmailOutlined color={props.color} />;
        case "SMS":
            return <SmsOutlined color={props.color} />;
        case "phoneCall":
            return <PhoneOutlined color={props.color} />;
        case "chat":
            return <ChatIcon color={props.color} />;
        case "none":
            return <Close color={props.color} />;
        default:
            return <Close color={props.color} />;
    }
}