import { useState } from "react";

//Mui
import Box from '@mui/material/Box';

import { Confirm, useTranslate, useNotify, useUpdate, useCreate, useDelete, useShowContext } from 'react-admin';
import { CircleCheck } from "../Icons/CircleCheck";
import { CloseIcon } from "../Icons/CloseIcon";
import IconButton from '@mui/material/IconButton';
import { NoteForm } from "../Forms/NoteForm";
import { TaskForm } from "../Forms/TaskForm";
import { EventForm } from "../Forms/EventForm";

//Icons
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const EventActionButton = (props:
    {
        action: "edit" | "create" | "delete",
        id: string,
        crmType: "notes" | "note" | "events" | "event" | "tasks" | "task",
        event: any,
        sx?: any,
        icon?: boolean,
        children?: JSX.Element
    }) => {
    const t = useTranslate();
    const notify = useNotify();
    const { refetch } = useShowContext();

    const [updateOne, { isLoading: isUpdateLoading }] = useUpdate();
    const [addOne, { isLoading: isCreateLoading }] = useCreate();
    const [deleteOne, { isLoading: isDeleteLoading }] = useDelete();

    const [open, setOpen] = useState(false);
    const [newData, setNewData] = useState(props.event ? { ...props.event } : {});


    const addEvent = () => {
        addOne(`prospects/${props.id}/${props.crmType}`, { data: newData },
            {
                onSuccess: (data) => {
                    setOpen(false);
                    refetch();
                },
                onError: (data: any) => {
                    notify(data.body.name ?? 'BasicError', { type: "warning" })
                }
            });
    }

    const updateEvent = (id: string, event: any) => {
        updateOne(`prospects/${props.id}/${props.crmType}`, { id, data: event }, {
            mutationMode: 'pessimistic',
            onSuccess: (data) => {
                setOpen(false);
                refetch();
            }, onError: (data: any) => {
                notify(data.body.name ?? 'BasicError', { type: "error" })
            }
        });
    };

    const deleteEvent = () => {
        deleteOne(`prospects/${props.id}/${props.crmType}`, { id: props.event.id }, {
            onSuccess: () => {
                refetch();
            }, onError: (data: any) => {
                notify(data.body.name ?? 'BasicError', { type: "error" })
            }
        });
    };

    const handleDialogClose = () => { setOpen(false); };

    const handleClick = () => {
        if (!isCreateLoading && !isUpdateLoading && !isDeleteLoading) {
            if (props.action === "delete") {
                deleteEvent();
                return;
            }
            setOpen(true)
        }
    };

    const handleConfirm = () => {
        if (!isCreateLoading && !isUpdateLoading && !isDeleteLoading) {
            if (props.action === "create") {
                addEvent();
                return;
            }
            updateEvent(props.event._id, newData);
        }
    };

    let form;
    switch (props.crmType) {
        case "notes":
        case "note":
            form = <NoteForm
                event={props.event}
                onChange={(data: any) => setNewData(data)}
            />
            break;
        case "tasks":
        case "task":
            form = <TaskForm
                event={props.event}
                onChange={(data: any) => setNewData(data)}
            />
            break;
        case "events":
        case "event":
            form = <EventForm
                event={props.event}
                onChange={(data: any) => setNewData(data)}
            />
            break;
    }

    return (<>
        {props.icon ?
            <IconButton sx={props.sx} color="primary" size="small" onClick={(e) => { e.stopPropagation(); handleClick(); }}  >
                {props.action === "delete" ? <DeleteOutlinedIcon /> :
                    props.action === "create" ? <AddCircleOutlineIcon /> :
                        <EditOutlinedIcon />
                }
            </IconButton>
            : <Box onClick={(e) => { e.stopPropagation(); handleClick(); }}  >
                {props.children}
            </Box>
        }
        {props.action !== "delete" &&
            <Confirm
                sx={{
                    "& .MuiDialogContent-root": {
                        paddingTop: "6px !important"
                    },
                    "& .MuiButton-root svg": {
                        fontSize: "2.5em"
                    },
                    "& .MuiDialog-paper": {
                        minWidth: {
                            xs: "80%",
                            md: "800px"
                        }
                    }
                }}
                confirmColor="primary"
                ConfirmIcon={CircleCheck}
                CancelIcon={CloseIcon}
                isOpen={open}
                title={t(`prospect.actions.${props.action}${props.crmType}`)}
                content={form}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                confirm={'Valider'}
                cancel={'Annuler'}
                loading={isUpdateLoading || isCreateLoading || isDeleteLoading}
            />
        }
    </>)
}

EventActionButton.defaultProps = {
    icon: true
}