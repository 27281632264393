import Box from "@mui/material/Box";
import theme from "../../theme";

export const KanbanColumn = ({ children }: { children: React.ReactNode }) => {
    return <Box
        className="flex flex-col"
        sx={{
            minWidth: "240px",
            flex: 1,
            padding: "20px",
            bgcolor: theme.palette.background.default,
            borderRadius: '16px',
            height: "100%",
        }}
    >
        {children}
    </Box>
}