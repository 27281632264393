import { Layer, Source } from "react-map-gl"

export const ThreeDLayer = (props: { opacity?: number }) => {
    const paint = {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
        ],
        'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
        ],
        'fill-extrusion-opacity': 0.6
    }

    return (
        //@ts-ignore
        <Layer type="fill-extrusion" minzoom={15} filter={['==', 'extrude', 'true']} source-layer="building" id="three-d-layer" source="composite" paint={paint} />
    )
}
