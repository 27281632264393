import Box from "@mui/material/Box";

export const KanbanTitle = (props: { count: number, title: JSX.Element, color: string }) => {
    const { count, title, color } = props;

    return (
        <Box className="flex gap-2 items-center pb-5 mb-6" sx={{ borderBottom: `3px solid ${color}` }}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill={color} />
            </svg>
            {title}
            {count > 0 &&
                <div className="rounded-full w-5 h-5 bg-neutral-300 flex items-center justify-center text-neutral-700 text-sm">
                    {count}
                </div>
            }
        </Box>
    )
}