export const dashboardMessages = {
    dashboard: {
        charts: {
            titles: {
                byDate: "Nouveaux prospects",
                byType: "Par type de bien",
                byCity: "Par Ville",
                byZipcode: "Par Code Postal",
                byUser: "Par Utilisateur",
                byPoject: "Projets en cours",
            }
        },
        dateRanges: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            last7Days: "7 derniers jours",
            lastMonth: "Mois dernier",
            last3Months: "3 derniers mois",
            last6Months: "6 derniers mois"
        },
        buttons: {
            noNewProspects: "Tous vos nouveaux prospects sont traités",
            accessCurrentProspects: "Suivez vos dossiers en cours",
        }
    }
}