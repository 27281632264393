import { IProspect } from "../../types/Prospect";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import IconButton from '@mui/material/IconButton';
import { useRedirect, useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ShowIcon } from "../Icons/ShowIcon";
import { ProspectTypeIcon } from "../Icons/ProspectTypeIcon";
import theme from "../../theme";
import { SurfaceIcon } from "../Icons/SurfaceIcon";


export const KanbanCard = ({ prospect, position, onOpenPreview }: { prospect: IProspect; position: number, onOpenPreview: (prospect: IProspect) => void }) => {
    const redirect = useRedirect();
    const t = useTranslate();
    const handleClick = (prospect: IProspect) => {
        redirect(`/prospects/${prospect.id}/show`);
    };

    return (<Draggable draggableId={String(prospect.id)} index={position}>
        {(provided, snapshot) => (
            <Box
                sx={{ marginBottom: 1 }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                onClick={() => handleClick(prospect)}
            >
                <Box key={prospect.id} className={`bg-white transition-all duration-300 ease-in-out hover:shadow-md rounded-lg ${snapshot.isDragging ? "shadow-lg rotate-12" : ""}`}>
                    <Box className="p-5 flex flex-col gap-2 group relative">
                        <Box className="absolute top-0 right-0 hidden group-hover:block" >
                            <IconButton color="primary" onClick={(e) => { e.stopPropagation(); onOpenPreview(prospect) }}><ShowIcon stroke={theme.palette.primary.main} /></IconButton >
                        </Box>
                        <Box className="flex w-min items-center gap-x-1 px-2 py-1 rounded-md" sx={{ backgroundColor: theme.palette.background.default }}>
                            <ProspectTypeIcon type={prospect.type} />
                            <div className="font-medium text-sm">
                                {t(`prospect.type.${prospect.type}`)}
                            </div>
                        </Box>
                        <div className="font-semibold text-darker">
                            {prospect?.name}
                        </div>
                        {prospect?.fieldSurface &&
                            <div className="flex items-center gap-x-1">
                                <SurfaceIcon stroke={theme.palette.primary.main} />
                                <div>
                                    {`${prospect?.fieldSurface} m²`}
                                </div>
                            </div>
                        }
                        <div className="text-sm text-gray-600">
                            {prospect?.cityName}
                        </div>
                        <div className="text-right">
                            <Typography variant="subtitle2">
                                {new Date(prospect.createdAt)?.toLocaleDateString()}
                            </Typography>
                        </div>
                    </Box>
                </Box>
            </Box>
        )
        }
    </Draggable >
    )
}