import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { HttpError } from 'react-admin';

export const checkRefresh = async () => {
    let access_token = localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token") ?? "")
        : null;
    const refresh_token = localStorage.getItem("refresh_token")
        ? JSON.parse(localStorage.getItem("refresh_token") ?? "")
        : null;

    // There is no access token yet
    if (!access_token) {
        return null;
    }

    const user: any = jwt_decode(access_token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs(), "hour") < 1;

    // Has a valid Token
    if (!isExpired) {
        return access_token;
    }

    // Send a Refresh operation
    const request = new Request(process.env.REACT_APP_API_BASE_URL + "/auth/token/refresh", {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${refresh_token}` }),
    });

    try {
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            const status = response.status;
            if (status === 401) {
                localStorage.removeItem("auth");
                //return Promise.reject({ redirectTo: '/unauthorized', logoutUser: false });
                return Promise.reject();
            }
            // other error code (404, 500, etc): no need to log out
            return Promise.resolve();
        }
        const auth = await response.json();
        localStorage.setItem("access_token", JSON.stringify(auth.access_token));
        return auth.access_token;
    } catch (e) {
        throw new HttpError(
            "error",
            401,
        )
    }
};