import { useCallback, useEffect, useState } from "react";
import { Layer, Source, useMap } from "react-map-gl"

import theme from "../../../theme";

const protoRoot = require('../../../geojson.proto.js'); // Adjust path as necessary

export const SearchParcelsLayer = (props: {
    opacity?: number,
    filter: any
}) => {
    const { current: map } = useMap();
    const { filter } = props;
    const [protoLayer, setProtoLayer] = useState<any>();

    useEffect(() => {
        loadProtobufData();
    }, [filter.minParcelSurface, filter.ownerTypes?.length, filter.siren]);

    const protobufToGeoJSON = (protobufData: any) => {
        const features = protobufData.features.map((feature: any) => {
            const coordinates = feature.geometry.polygons.map((polygon: any) =>
                polygon.rings.map((ring: any) =>
                    ring.points.map((point: any) => [point.longitude, point.latitude])
                )
            );

            return {
                type: 'Feature',
                geometry: {
                    type: 'MultiPolygon',
                    coordinates,
                },
                properties: feature.properties,
            };
        });

        return {
            type: 'FeatureCollection',
            features,
        };
    };

    // Function to load Protobuf data from the server
    const loadProtobufData = useCallback(async () => {
        try {
            const bbox = map?.getBounds();
            let filters: any = { 'properties.contenance': { $gte: filter.minParcelSurface }, ownerTypes: filter.ownerTypes };
            if (filter.siren && filter.siren.length > 0) {
                filters['corporationOwners.siren'] = filter.siren;
            }

            let encodedFilters = encodeURIComponent(JSON.stringify({ ...filters, bbox }));

            const response = await fetch(`${process.env.REACT_APP_DATA_API_BASE_URL}/parcels/geojson-protobuf?filter=${encodedFilters}`,
                {
                    headers: {
                        authorization: `Bearer ${process.env.REACT_APP_DATA_API_KEY}`,
                    }
                });
            const arrayBuffer = await response.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);

            // Decode the protobuf data using the generated schema
            // const FeatureCollection = protoRoot.lookupType('FeatureCollection');
            const decodedData = protoRoot.FeatureCollection.decode(uint8Array);

            // Convert the decoded data into GeoJSON
            const geojsonData = protobufToGeoJSON(decodedData);

            // Access the Mapbox instance and add GeoJSON source and layer
            setProtoLayer(geojsonData);

        } catch (err) {
            console.error('Error loading or decoding protobuf data:', err);
        }
    }, [filter, filter.minParcelSurface, map]);

    return (
        <>
            <Source promoteId={'idu'} id="search-parcels" type="geojson" data={protoLayer} >
                <Layer minzoom={10} id="search-parcels" type="fill"
                    paint={{
                        //Case depend on the hasOwner property
                        'fill-color': ['case', ['==', ['get', 'hasOwner'], true], theme.palette.success.main, theme.palette.primary.main],
                        //change opactiy when hovered
                        'fill-opacity': ['case', ['boolean', ['feature-state', 'select'], false], 1 * (props?.opacity ?? 1), 0.5 * (props?.opacity ?? 1)],
                    }}
                />
                <Layer minzoom={10} id="search-parcels-border" type="line" paint={{
                    "line-color": [
                        'case',
                        ['==', ['get', 'isFavourite'], true],
                        '#FC814F',
                        '#46A097'
                    ],
                    "line-width": 1
                }} />
            </Source>
        </>
    )
}