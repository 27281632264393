import { useGetList, useGetOne, useRecordContext } from "react-admin"
import { IProspect } from "../../../types/Prospect";
import IconButton from '@mui/material/IconButton';
import Popover from "@mui/material/Popover";
import { useState } from "react";
import theme from "../../../theme";
import { TagIcon } from "../../Icons/TagIcon";
import { TagForm } from "./TagForm";
import { LightTooltip } from "../../layout/LightTooltip";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Tag } from "../../Chips/Tag";
import { ProspectTag } from "../../../types/ProspectTag";

export const TagManager = (props: { maxItems: number }) => {
    const prospect = useRecordContext<IProspect>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { data: prospectTags } = useGetList<ProspectTag>('prospectTags');

    const tags: { id: string, name: string, bgColor: string }[] = prospect?.tags?.filter((tag: string) => prospectTags?.find((item: { id: string }) => item.id === tag)).map((tag: string) => prospectTags?.find((item: { id: string }) => item.id === tag)) as { id: string, name: string, bgColor: string }[];

    const handleOpen = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        //Get parent element of event target, that respect the query selector #tag-manager-wrapper-${prospect?.id}
        let elem = event.target;
        while (elem && elem.parentElement && !elem.parentElement.querySelector(`#tag-manager-wrapper-${prospect?.id}`)) {
            elem = elem.parentElement;
            if (elem === document.body) {
                break;
            }
        }
        setAnchorEl(elem as HTMLButtonElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!prospect || prospect.status !== "accepted" || !prospectTags) return <></>;

    return <>
        <Box id={`tag-manager-wrapper-${prospect?.id}`}>
            {tags?.length > 0 ?
                <Box className="flex gap-2 items-center cursor-pointer" onClick={handleOpen} >
                    {tags.length > 0 && tags.slice(0, props.maxItems).map((tag: { id: string, name: string, bgColor: string }) => {
                        return <Box key={tag.id}>
                            <Tag key={tag.id} label={tag.name} color={tag.bgColor} />
                        </Box>
                    }
                    )}
                    {tags.length > props.maxItems &&
                        <LightTooltip
                            onClick={handleOpen}
                            title={
                                <Box className="flex gap-1 items-start">
                                    {tags.slice(props.maxItems).map((tag: { id: string, name: string, bgColor: string }) => <Tag key={tag.id} label={tag.name} color={tag.bgColor} />)}
                                </Box>
                            }
                        >
                            <Chip sx={{ height: "24px", "& .MuiChip-label": { paddingLeft: "6px", paddingRight: "6px", fontSize: "11px" } }} label={`+${tags.length - props.maxItems}`} />
                        </LightTooltip>
                    }
                </Box >
                :
                <IconButton size="small" sx={{ width: "24px", height: "24px", borderRadius: "8px", bgcolor: theme.palette.grey[200], color: "black" }} aria-describedby={id}
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleOpen(e) }}
                >
                    <TagIcon fontSize="small" />
                </IconButton >
            }
        </Box>
        <Popover
            onClick={(e) => e.stopPropagation()}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'left',
                }
            }
        >
            <TagForm />
        </Popover>
    </>
}