export default function Pagination(theme) {
    return {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    boxSizing: 'border-box',
                    margin: '0px -1px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    color: theme.palette.primary.main,
                    "&.MuiPaginationItem-previousNext": {
                        color: theme.palette.grey[800],
                    },
                    padding: '4px 8px',
                    width: '31px',
                    height: '31px',
                    background: 'white',
                    border: '1px solid',
                    borderRadius: '0px',
                    "&.Mui-selected": {
                        background: theme.palette.primary.main,
                        color: 'white',
                        "&:hover": {
                            background: theme.palette.primary.dark,
                        }
                    }
                }
            }
        }
    }
}