import React, { useEffect, useState } from 'react';
import { Form, LinearProgress, useCreate, useGetIdentity, useNotify, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
    RichTextInput,
    RichTextInputToolbar,
    FormatButtons,
    LinkButtons,
    ClearButtons,
    ColorButtons,
} from 'ra-input-rich-text';

export const EmailEditor = (props: { prospectIds: string[], onSubmit: any, channel: "email" | "postMail" }) => {
    const notify = useNotify();
    const t = useTranslate();
    const user = useGetIdentity();

    const [generateText, { isLoading: isAILoading }] = useCreate();
    const [textFieldValue, setTextFieldValue] = useState<string>('');
    // AI Text
    const [AIText, setAIText] = useState<string>('');
    //Input Text, default or ai
    const [inputText, setInputText] = useState<string>('default');

    const setInitialText = () => {
        return props.channel === "email" ?
            t('prospect.actions.sendNotification.email.defaultcontent', { firstName: user?.data?.firstName, lastName: user?.data?.lastName, email: user?.data?.email, phoneNumber: user?.data?.phoneNumber })
            : t('prospect.actions.sendNotification.postMail.defaultcontent', { firstName: user?.data?.firstName, lastName: user?.data?.lastName, email: user?.data?.email, phoneNumber: user?.data?.phoneNumber });
    }

    const GenerateAIText = async (id: string) => {
        generateText(`prospects/${id}/generate-text`, { data: { channel: "email" } },
            {
                onSuccess: (data) => {
                    setAIText(data?.text);
                },
                onError: (data: any) => {
                    notify(data?.body?.name ?? 'BasicError', { type: "error" });
                }
            }
        );
    }

    //When AI Text is generated, set it to the text field if inputText is ai
    useEffect(() => {
        if (AIText !== '' && inputText === 'ai') {
            setTextFieldValue(AIText);
        }
    }, [AIText, inputText]);

    //When dialog opens, load AIText in the background
    useEffect(() => {
        setTextFieldValue(setInitialText());
        if (props.prospectIds?.length === 1) {
            GenerateAIText(props.prospectIds[0]);
        }
        //Reset to default text when dialog closes
        else {
            setInputText('default');
            setAIText('');
        }
    }, []);

    return <div className="relative">
        <div>
            {(isAILoading && inputText == 'ai') &&
                <div className='z-20 flex backdrop-blur-sm flex-col items-center justify-center absolute m-[-8px] right-0 left-0 top-0 bottom-0'>
                    <div className="bg-white flex gap-1 items-center rounded-lg group shadow-lg whitespace-nowrap flex-nowrap ">
                        <Box sx={{ background: 'linear-gradient(180deg, #FBFBFF 0%, #3B54D6 100%);' }} className='p-3 ml-[-16px] text-white rounded-full bg-primary w-4 h-4 flex items-center justify-center box-content'>
                            <AutoAwesomeIcon color='inherit' />
                        </Box>
                        <div className="w-[140px]">
                            {t('prospect.actions.sendNotification.ai')}
                        </div>
                    </div>
                    <LinearProgress sx={{ borderRadius: '20px' }} />
                </div>
            }
            <Form defaultValues={{ body: textFieldValue }} id="rich-form" onSubmit={props.onSubmit}>
                <RichTextInput
                    fullWidth
                    toolbar={
                        <Box className="sticky top-0 z-10 bg-white flex justify-between">
                            <RichTextInputToolbar>
                                <FormatButtons size={"medium"} />
                                <ColorButtons size={"medium"} />
                                <LinkButtons size={"medium"} />
                                <ClearButtons size={"medium"} />
                            </RichTextInputToolbar>
                            {props.prospectIds?.length === 1 &&
                                <button disabled={(isAILoading && inputText == 'ai')} onClick={(e) => { e.preventDefault(); setInputText('ai') }} color="primary">
                                    <div className="flex items-center transition-all ease-in-out duration-500 rounded-lg group hover:shadow-lg whitespace-nowrap flex-nowrap">
                                        <Box sx={{ background: 'linear-gradient(180deg, #FBFBFF 0%, #3B54D6 100%);' }} className='p-3 ml-[-16px] text-white rounded-full bg-primary w-4 h-4 flex items-center justify-center box-content'>
                                            <AutoAwesomeIcon color='inherit' />
                                        </Box>
                                        <div className="w-0 group-hover:w-[140px] transition-all duration-500 ease-in-out overflow-hidden">
                                            {t('prospect.actions.sendNotification.ai')}
                                        </div>
                                    </div>
                                </button>
                            }
                        </Box>
                    }
                    source="body" />
            </Form>
        </div>
    </div>
}