export const Loader = () => {

    return <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 111.9 111.9" style={{ animation: "animateDash 3s ease-in-out forwards infinite", width: "64px", height: "64px" }} >
        <style>
            {/* .st4{fill:"#fa814c"} */}
        </style>
        <defs>
            <path id="SVGID_1_" d="M9 0h94v111.9H9z" />
        </defs>
        <clipPath id="SVGID_00000180350861950023835180000009290999392391230386_">
            <use style={{ overflow: "visible" }} />
        </clipPath>
        <g >
            <path d="M44.4 62.7 81.9 50c12.2-4.1 18.7-17.4 14.6-29.5C92.4 8.2 79.2 1.7 67 5.8L29.5 18.5C17.3 22.6 10.8 35.9 14.9 48l2.9 6.8" style={{ fill: "none", stroke: "#fa814c", strokeWidth: 8.6763, strokeMiterlimit: 10 }} />
            <path d="M44.3 38C30.6 42.8 21.2 44.2 16 54.8c-5.9 11.8-1.1 26.1 10.8 32l36.3 18.1c11.8 5.9 26.1 1.1 32-10.8 5.9-11.8 1.1-26.1-10.8-32L68.5 54" style={{ fill: "none", stroke: "#469f96", strokeWidth: 8.6763, strokeMiterlimit: 10 }} />
            <path style={{ fill: "#fa814c" }} className="st4" d="M77 47 36.3 60.8c-2.3.8-3.5 3.3-2.7 5.6.8 2.3 3.3 3.5 5.6 2.7L80 55.3c2.3-.8 3.5-3.3 2.7-5.6-.9-2.3-3.4-3.5-5.7-2.7z" />
            <path style={{ fill: "#fa814c" }} className="st4" d="m75.5 79.4-36.2-18c-2.1-1.1-4.8-.2-5.8 2-1.1 2.1-.2 4.8 2 5.8l36.2 18c2.1 1.1 4.8.2 5.8-2 1.1-2.2.2-4.8-2-5.8z" />
            <path d="M71.7 24.1 32.6 37.4c-2.3.8-3.5 3.2-2.7 5.5.8 2.3 3.2 3.5 5.5 2.7l39.2-13.3c2.3-.8 3.5-3.2 2.7-5.5-.8-2.3-3.3-3.5-5.6-2.7z" style={{ fill: "#439d95" }} />
        </g>
    </svg>

}