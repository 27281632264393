import { styled } from '@mui/system';
import { Datagrid, DatagridConfigurable } from 'react-admin';
import theme from '../../theme';

export const StyledDataGrid = styled(Datagrid)({
    "& .RaDatagrid-root": {
        paddingTop: "16px"
    },
    "& .RaDatagrid-headerCell, .RaDatagrid-headerCell .Mui-active": {
        color: theme.palette.primary.light
    },
    "& .RaDatagrid-rowCell, .RaDatagrid-headerCell": {
        borderBottomColor: '#eeeeee',
        height: "48px",
    },
    //when not checked
    "& .MuiButtonBase-root.MuiCheckbox-root:not(.Mui-checked)": {
        color: theme.palette.primary.light,
    },
    "& .MuiTableCell-paddingCheckbox": {
        padding: "0 12px 0 0 !important"
    }
})

export const StyledConfigurableDataGrid = styled(DatagridConfigurable)({
    "& .RaDatagrid-root": {
        paddingTop: "16px"
    },
    "& .RaDatagrid-headerCell, .RaDatagrid-headerCell .Mui-active": {
        color: theme.palette.primary.light
    },
    "& .RaDatagrid-rowCell, .RaDatagrid-headerCell": {
        borderBottomColor: '#eeeeee',
        height: "48px",
    },
    //when not checked
    "& .MuiButtonBase-root.MuiCheckbox-root:not(.Mui-checked)": {
        color: theme.palette.primary.light,
    },
    "& .MuiTableCell-paddingCheckbox": {
        padding: "0 12px 0 0 !important"
    }
})