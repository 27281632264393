import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useListContext } from 'ra-core';
import isEqual from 'lodash/isEqual';
import { SavedQueriesListProps, useSavedQueries, extractValidSavedQueries } from 'react-admin';
import { AddViewButton } from './AddSavedQueryButton';
import theme from '../../../theme';
import { RemoveSavedViewButton } from './RemoveSavedViewButton';
import { SavedViewItem } from './SavedViewItem';

export const SavedViewsList = ({
}: SavedQueriesListProps) => {
    const {
        resource,
        filterValues,
        displayedFilters,
        sort,
        perPage,
    } = useListContext();

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }

    const [savedQueries] = useSavedQueries(resource);

    const validSavedQueries = extractValidSavedQueries(savedQueries);
    const hasSavedCurrentFilterValue = validSavedQueries.some(savedQuery =>
        isEqual(savedQuery.value, {
            filter: filterValues,
            sort,
            perPage,
            displayedFilters,
        })
    );
    const hasFilterValues = !isEqual(filterValues, {});

    if (resource !== 'prospects') {
        return null;
    }

    return (
        <Box className="flex gap-2 items-center">
            {validSavedQueries.map((savedQuery, index) => (
                <SavedViewItem
                    label={savedQuery.label}
                    value={savedQuery.value}
                    key={index}
                    sx={{
                        width: 'auto',
                        "&.Mui-selected": {
                            backgroundColor: "transparent",
                            color: theme.palette.primary.main,
                            borderBottom: `2px solid ${theme.palette.primary.main}`,
                        },
                        "& .MuiListItemButton-root": {
                            paddingLeft: "8px",
                            paddingRight: "40px",
                        }
                    }}
                />
            ))}
            <AddViewButton />
            {hasSavedCurrentFilterValue &&
                <RemoveSavedViewButton />
            }
        </Box>
    );
};

const PREFIX = 'RaSavedQueriesList';

export const SavedQueriesListClasses = {
    floatingIcon: `${PREFIX}-floatingIcon`,
    floatingTooltip: `${PREFIX}-floatingTooltip`,
    titleContainer: `${PREFIX}-titleContainer`,
    titleIcon: `${PREFIX}-titleIcon`,
};
