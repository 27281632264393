import { IProspect, OriginType } from "../types/Prospect";
import { allPipeStatuses } from "./constants";

export type ProspectsByStatus = Record<IProspect["pipeStatus"], IProspect[]>;

export const getProspectsByStatus = (unorderedProspects: IProspect[]) => {
    const prospectsByStatus: ProspectsByStatus = unorderedProspects.reduce(
        (acc, post) => {
            acc[post.pipeStatus as keyof ProspectsByStatus]?.push(post);
            return acc;
        },
        allPipeStatuses.reduce(
            (obj, status) => ({ ...obj, [status]: [] }),
            {} as ProspectsByStatus
        )
    );
    return prospectsByStatus;
};

export const updateProspectStatusLocal = (
    sourceProspect: IProspect,
    source: { status: IProspect["pipeStatus"]; index: number },
    destination: {
        status: IProspect["pipeStatus"];
        index?: number; // undefined if dropped after the last item
    },
    prospectsByStatus: ProspectsByStatus
) => {
    if (source.status === destination.status) {
        // moving deal inside the same column
        const column = prospectsByStatus[source.status];
        column.splice(source.index, 1);
        column.splice(destination.index ?? column.length + 1, 0, sourceProspect);
        return {
            ...prospectsByStatus,
            [destination.status]: column,
        };
    } else {
        // moving deal across columns
        const sourceColumn = prospectsByStatus[source.status];
        const destinationColumn = prospectsByStatus[destination.status];
        sourceColumn.splice(source.index, 1);
        destinationColumn.splice(
            destination.index ?? destinationColumn.length + 1,
            0,
            sourceProspect
        );
        return {
            ...prospectsByStatus,
            [source.status]: sourceColumn,
            [destination.status]: destinationColumn,
        };
    }
};

export const isFromLeadGen = (origin: OriginType) => {
    return origin === "kaptcher" || origin === "JVMI";
}

export const getPipeStatusLabel = (
    resourceType: 'prospect' | 'owner',
    t: (key: string) => string,
    pipeStatuses: { name: string, value: string }[],
    status: string
) => {
    return pipeStatuses?.length > 0 && resourceType === 'prospect' ?
        pipeStatuses?.find((pipeStatus) => pipeStatus.value === status)?.name :
        (resourceType === 'owner' ? t(`prospect.owners.statuses.${status}`) : t(`prospect.statuses.${status}`));
};