import { useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslate } from "react-admin";
import TextField from '@mui/material/TextField';

export const PCsFilter = (props: {
    onPCStateChagne: (value: string[]) => void,
    onYearChange: (value: number | undefined) => void,
    onNbUnitsChange: (value: number | undefined) => void
}) => {

    const t = useTranslate();
    const [pcState, setPCState] = useState({
        2: false,
        5: false,
        6: false,
        4: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newState = { ...pcState, [event.target.name]: event.target.checked };
        setPCState(newState);

        //Send value that are true in an array string
        //@ts-ignore
        props.onPCStateChagne(Object.keys(newState).filter((key: string) => newState[key] === true));
    };

    return <>
        {["2", "4", "5", "6"].map((zone: string) => (
            <FormControlLabel
                control={
                    <Switch
                        // @ts-ignore
                        checked={pcState[zone]}
                        onChange={handleChange}
                        name={zone}
                        color="primary"
                    />
                }
                label={t(`permit.Etat_DAU.${zone}`)}
            />
        ))
        }
        <TextField type="number" onChange={(e) => props.onYearChange(Number.isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value))} placeholder="Année de dépôt" />
        <TextField type="number" onChange={(e) => props.onNbUnitsChange(Number.isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value))} placeholder="Nb lots minimum" />
    </>
}