import { FunctionField, ReferenceArrayField, TextField, Datagrid, SimpleForm, TextInput, required, useGetList, useRecordContext, useTranslate } from 'react-admin';
import { RoleChip } from '../../components/Chips/RoleChip';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import { PageTitle } from '../../components/layout/PageTitle';
import { BackButton } from '../../components/Buttons/BackButton';

export const TeamForm = (props: { mode: "create" | "edit" }) => {
    const t = useTranslate();
    const record = useRecordContext();
    const { data: users } = useGetList('users', { filter: { teamIds: [record?.id] } });

    if (!record) return null;

    if (props.mode === "edit") record.userIds = users?.map((user: any) => user.id);

    return <SimpleForm>
        <Box className="flex gap-2 mb-4">
            <BackButton />
            <PageTitle title={t(`team.actions.${props.mode}`)} />
        </Box>
        <TextInput label={t("team.property.name")} source="name" validate={[required()]} />
        <ReferenceArrayField label={t("team.property.userIds")} source="userIds" reference="users" sort={{ field: "role", order: 'ASC' }}>
            <Datagrid header={<TableHead sx={{ display: "none" }} />} bulkActionButtons={false}>
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <FunctionField label={t('user.property.role')} source="role" render={(record: any) => <RoleChip role={record.role} />} />
            </Datagrid>
        </ReferenceArrayField>
    </SimpleForm >
}