/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Point = $root.Point = (() => {

    /**
     * Properties of a Point.
     * @exports IPoint
     * @interface IPoint
     * @property {number|null} [latitude] Point latitude
     * @property {number|null} [longitude] Point longitude
     */

    /**
     * Constructs a new Point.
     * @exports Point
     * @classdesc Represents a Point.
     * @implements IPoint
     * @constructor
     * @param {IPoint=} [properties] Properties to set
     */
    function Point(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Point latitude.
     * @member {number} latitude
     * @memberof Point
     * @instance
     */
    Point.prototype.latitude = 0;

    /**
     * Point longitude.
     * @member {number} longitude
     * @memberof Point
     * @instance
     */
    Point.prototype.longitude = 0;

    /**
     * Creates a new Point instance using the specified properties.
     * @function create
     * @memberof Point
     * @static
     * @param {IPoint=} [properties] Properties to set
     * @returns {Point} Point instance
     */
    Point.create = function create(properties) {
        return new Point(properties);
    };

    /**
     * Encodes the specified Point message. Does not implicitly {@link Point.verify|verify} messages.
     * @function encode
     * @memberof Point
     * @static
     * @param {IPoint} message Point message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Point.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
        if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
        return writer;
    };

    /**
     * Encodes the specified Point message, length delimited. Does not implicitly {@link Point.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Point
     * @static
     * @param {IPoint} message Point message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Point.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Point message from the specified reader or buffer.
     * @function decode
     * @memberof Point
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Point} Point
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Point.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Point();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    message.latitude = reader.double();
                    break;
                }
                case 2: {
                    message.longitude = reader.double();
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a Point message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Point
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Point} Point
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Point.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Point message.
     * @function verify
     * @memberof Point
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Point.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.latitude != null && message.hasOwnProperty("latitude"))
            if (typeof message.latitude !== "number")
                return "latitude: number expected";
        if (message.longitude != null && message.hasOwnProperty("longitude"))
            if (typeof message.longitude !== "number")
                return "longitude: number expected";
        return null;
    };

    /**
     * Creates a Point message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Point
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Point} Point
     */
    Point.fromObject = function fromObject(object) {
        if (object instanceof $root.Point)
            return object;
        let message = new $root.Point();
        if (object.latitude != null)
            message.latitude = Number(object.latitude);
        if (object.longitude != null)
            message.longitude = Number(object.longitude);
        return message;
    };

    /**
     * Creates a plain object from a Point message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Point
     * @static
     * @param {Point} message Point
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Point.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.latitude = 0;
            object.longitude = 0;
        }
        if (message.latitude != null && message.hasOwnProperty("latitude"))
            object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
        if (message.longitude != null && message.hasOwnProperty("longitude"))
            object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
        return object;
    };

    /**
     * Converts this Point to JSON.
     * @function toJSON
     * @memberof Point
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Point.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Point
     * @function getTypeUrl
     * @memberof Point
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Point.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Point";
    };

    return Point;
})();

export const LinearRing = $root.LinearRing = (() => {

    /**
     * Properties of a LinearRing.
     * @exports ILinearRing
     * @interface ILinearRing
     * @property {Array.<IPoint>|null} [points] LinearRing points
     */

    /**
     * Constructs a new LinearRing.
     * @exports LinearRing
     * @classdesc Represents a LinearRing.
     * @implements ILinearRing
     * @constructor
     * @param {ILinearRing=} [properties] Properties to set
     */
    function LinearRing(properties) {
        this.points = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LinearRing points.
     * @member {Array.<IPoint>} points
     * @memberof LinearRing
     * @instance
     */
    LinearRing.prototype.points = $util.emptyArray;

    /**
     * Creates a new LinearRing instance using the specified properties.
     * @function create
     * @memberof LinearRing
     * @static
     * @param {ILinearRing=} [properties] Properties to set
     * @returns {LinearRing} LinearRing instance
     */
    LinearRing.create = function create(properties) {
        return new LinearRing(properties);
    };

    /**
     * Encodes the specified LinearRing message. Does not implicitly {@link LinearRing.verify|verify} messages.
     * @function encode
     * @memberof LinearRing
     * @static
     * @param {ILinearRing} message LinearRing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LinearRing.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.points != null && message.points.length)
            for (let i = 0; i < message.points.length; ++i)
                $root.Point.encode(message.points[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified LinearRing message, length delimited. Does not implicitly {@link LinearRing.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LinearRing
     * @static
     * @param {ILinearRing} message LinearRing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LinearRing.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LinearRing message from the specified reader or buffer.
     * @function decode
     * @memberof LinearRing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LinearRing} LinearRing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LinearRing.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LinearRing();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (!(message.points && message.points.length))
                        message.points = [];
                    message.points.push($root.Point.decode(reader, reader.uint32()));
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a LinearRing message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LinearRing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LinearRing} LinearRing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LinearRing.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LinearRing message.
     * @function verify
     * @memberof LinearRing
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LinearRing.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.points != null && message.hasOwnProperty("points")) {
            if (!Array.isArray(message.points))
                return "points: array expected";
            for (let i = 0; i < message.points.length; ++i) {
                let error = $root.Point.verify(message.points[i]);
                if (error)
                    return "points." + error;
            }
        }
        return null;
    };

    /**
     * Creates a LinearRing message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LinearRing
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LinearRing} LinearRing
     */
    LinearRing.fromObject = function fromObject(object) {
        if (object instanceof $root.LinearRing)
            return object;
        let message = new $root.LinearRing();
        if (object.points) {
            if (!Array.isArray(object.points))
                throw TypeError(".LinearRing.points: array expected");
            message.points = [];
            for (let i = 0; i < object.points.length; ++i) {
                if (typeof object.points[i] !== "object")
                    throw TypeError(".LinearRing.points: object expected");
                message.points[i] = $root.Point.fromObject(object.points[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a LinearRing message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LinearRing
     * @static
     * @param {LinearRing} message LinearRing
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LinearRing.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.points = [];
        if (message.points && message.points.length) {
            object.points = [];
            for (let j = 0; j < message.points.length; ++j)
                object.points[j] = $root.Point.toObject(message.points[j], options);
        }
        return object;
    };

    /**
     * Converts this LinearRing to JSON.
     * @function toJSON
     * @memberof LinearRing
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LinearRing.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LinearRing
     * @function getTypeUrl
     * @memberof LinearRing
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LinearRing.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LinearRing";
    };

    return LinearRing;
})();

export const Polygon = $root.Polygon = (() => {

    /**
     * Properties of a Polygon.
     * @exports IPolygon
     * @interface IPolygon
     * @property {Array.<ILinearRing>|null} [rings] Polygon rings
     */

    /**
     * Constructs a new Polygon.
     * @exports Polygon
     * @classdesc Represents a Polygon.
     * @implements IPolygon
     * @constructor
     * @param {IPolygon=} [properties] Properties to set
     */
    function Polygon(properties) {
        this.rings = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Polygon rings.
     * @member {Array.<ILinearRing>} rings
     * @memberof Polygon
     * @instance
     */
    Polygon.prototype.rings = $util.emptyArray;

    /**
     * Creates a new Polygon instance using the specified properties.
     * @function create
     * @memberof Polygon
     * @static
     * @param {IPolygon=} [properties] Properties to set
     * @returns {Polygon} Polygon instance
     */
    Polygon.create = function create(properties) {
        return new Polygon(properties);
    };

    /**
     * Encodes the specified Polygon message. Does not implicitly {@link Polygon.verify|verify} messages.
     * @function encode
     * @memberof Polygon
     * @static
     * @param {IPolygon} message Polygon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Polygon.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.rings != null && message.rings.length)
            for (let i = 0; i < message.rings.length; ++i)
                $root.LinearRing.encode(message.rings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Polygon message, length delimited. Does not implicitly {@link Polygon.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Polygon
     * @static
     * @param {IPolygon} message Polygon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Polygon.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Polygon message from the specified reader or buffer.
     * @function decode
     * @memberof Polygon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Polygon} Polygon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Polygon.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Polygon();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (!(message.rings && message.rings.length))
                        message.rings = [];
                    message.rings.push($root.LinearRing.decode(reader, reader.uint32()));
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a Polygon message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Polygon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Polygon} Polygon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Polygon.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Polygon message.
     * @function verify
     * @memberof Polygon
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Polygon.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.rings != null && message.hasOwnProperty("rings")) {
            if (!Array.isArray(message.rings))
                return "rings: array expected";
            for (let i = 0; i < message.rings.length; ++i) {
                let error = $root.LinearRing.verify(message.rings[i]);
                if (error)
                    return "rings." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Polygon
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Polygon} Polygon
     */
    Polygon.fromObject = function fromObject(object) {
        if (object instanceof $root.Polygon)
            return object;
        let message = new $root.Polygon();
        if (object.rings) {
            if (!Array.isArray(object.rings))
                throw TypeError(".Polygon.rings: array expected");
            message.rings = [];
            for (let i = 0; i < object.rings.length; ++i) {
                if (typeof object.rings[i] !== "object")
                    throw TypeError(".Polygon.rings: object expected");
                message.rings[i] = $root.LinearRing.fromObject(object.rings[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Polygon message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Polygon
     * @static
     * @param {Polygon} message Polygon
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Polygon.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.rings = [];
        if (message.rings && message.rings.length) {
            object.rings = [];
            for (let j = 0; j < message.rings.length; ++j)
                object.rings[j] = $root.LinearRing.toObject(message.rings[j], options);
        }
        return object;
    };

    /**
     * Converts this Polygon to JSON.
     * @function toJSON
     * @memberof Polygon
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Polygon.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Polygon
     * @function getTypeUrl
     * @memberof Polygon
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Polygon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Polygon";
    };

    return Polygon;
})();

export const MultiPolygon = $root.MultiPolygon = (() => {

    /**
     * Properties of a MultiPolygon.
     * @exports IMultiPolygon
     * @interface IMultiPolygon
     * @property {Array.<IPolygon>|null} [polygons] MultiPolygon polygons
     */

    /**
     * Constructs a new MultiPolygon.
     * @exports MultiPolygon
     * @classdesc Represents a MultiPolygon.
     * @implements IMultiPolygon
     * @constructor
     * @param {IMultiPolygon=} [properties] Properties to set
     */
    function MultiPolygon(properties) {
        this.polygons = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiPolygon polygons.
     * @member {Array.<IPolygon>} polygons
     * @memberof MultiPolygon
     * @instance
     */
    MultiPolygon.prototype.polygons = $util.emptyArray;

    /**
     * Creates a new MultiPolygon instance using the specified properties.
     * @function create
     * @memberof MultiPolygon
     * @static
     * @param {IMultiPolygon=} [properties] Properties to set
     * @returns {MultiPolygon} MultiPolygon instance
     */
    MultiPolygon.create = function create(properties) {
        return new MultiPolygon(properties);
    };

    /**
     * Encodes the specified MultiPolygon message. Does not implicitly {@link MultiPolygon.verify|verify} messages.
     * @function encode
     * @memberof MultiPolygon
     * @static
     * @param {IMultiPolygon} message MultiPolygon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiPolygon.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.polygons != null && message.polygons.length)
            for (let i = 0; i < message.polygons.length; ++i)
                $root.Polygon.encode(message.polygons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified MultiPolygon message, length delimited. Does not implicitly {@link MultiPolygon.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MultiPolygon
     * @static
     * @param {IMultiPolygon} message MultiPolygon message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiPolygon.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiPolygon message from the specified reader or buffer.
     * @function decode
     * @memberof MultiPolygon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MultiPolygon} MultiPolygon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiPolygon.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MultiPolygon();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (!(message.polygons && message.polygons.length))
                        message.polygons = [];
                    message.polygons.push($root.Polygon.decode(reader, reader.uint32()));
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a MultiPolygon message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MultiPolygon
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MultiPolygon} MultiPolygon
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiPolygon.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiPolygon message.
     * @function verify
     * @memberof MultiPolygon
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiPolygon.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.polygons != null && message.hasOwnProperty("polygons")) {
            if (!Array.isArray(message.polygons))
                return "polygons: array expected";
            for (let i = 0; i < message.polygons.length; ++i) {
                let error = $root.Polygon.verify(message.polygons[i]);
                if (error)
                    return "polygons." + error;
            }
        }
        return null;
    };

    /**
     * Creates a MultiPolygon message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MultiPolygon
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MultiPolygon} MultiPolygon
     */
    MultiPolygon.fromObject = function fromObject(object) {
        if (object instanceof $root.MultiPolygon)
            return object;
        let message = new $root.MultiPolygon();
        if (object.polygons) {
            if (!Array.isArray(object.polygons))
                throw TypeError(".MultiPolygon.polygons: array expected");
            message.polygons = [];
            for (let i = 0; i < object.polygons.length; ++i) {
                if (typeof object.polygons[i] !== "object")
                    throw TypeError(".MultiPolygon.polygons: object expected");
                message.polygons[i] = $root.Polygon.fromObject(object.polygons[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a MultiPolygon message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MultiPolygon
     * @static
     * @param {MultiPolygon} message MultiPolygon
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiPolygon.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.polygons = [];
        if (message.polygons && message.polygons.length) {
            object.polygons = [];
            for (let j = 0; j < message.polygons.length; ++j)
                object.polygons[j] = $root.Polygon.toObject(message.polygons[j], options);
        }
        return object;
    };

    /**
     * Converts this MultiPolygon to JSON.
     * @function toJSON
     * @memberof MultiPolygon
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiPolygon.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiPolygon
     * @function getTypeUrl
     * @memberof MultiPolygon
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiPolygon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MultiPolygon";
    };

    return MultiPolygon;
})();

export const Properties = $root.Properties = (() => {

    /**
     * Properties of a Properties.
     * @exports IProperties
     * @interface IProperties
     * @property {string|null} [numero] Properties numero
     * @property {number|null} [feuille] Properties feuille
     * @property {string|null} [section] Properties section
     * @property {string|null} [codeDep] Properties codeDep
     * @property {string|null} [nomCom] Properties nomCom
     * @property {string|null} [codeCom] Properties codeCom
     * @property {string|null} [comAbs] Properties comAbs
     * @property {string|null} [codeArr] Properties codeArr
     * @property {string|null} [idu] Properties idu
     * @property {string|null} [codeInsee] Properties codeInsee
     * @property {number|null} [contenance] Properties contenance
     * @property {boolean|null} [hasOwner] Properties hasOwner
     */

    /**
     * Constructs a new Properties.
     * @exports Properties
     * @classdesc Represents a Properties.
     * @implements IProperties
     * @constructor
     * @param {IProperties=} [properties] Properties to set
     */
    function Properties(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Properties numero.
     * @member {string} numero
     * @memberof Properties
     * @instance
     */
    Properties.prototype.numero = "";

    /**
     * Properties feuille.
     * @member {number} feuille
     * @memberof Properties
     * @instance
     */
    Properties.prototype.feuille = 0;

    /**
     * Properties section.
     * @member {string} section
     * @memberof Properties
     * @instance
     */
    Properties.prototype.section = "";

    /**
     * Properties codeDep.
     * @member {string} codeDep
     * @memberof Properties
     * @instance
     */
    Properties.prototype.codeDep = "";

    /**
     * Properties nomCom.
     * @member {string} nomCom
     * @memberof Properties
     * @instance
     */
    Properties.prototype.nomCom = "";

    /**
     * Properties codeCom.
     * @member {string} codeCom
     * @memberof Properties
     * @instance
     */
    Properties.prototype.codeCom = "";

    /**
     * Properties comAbs.
     * @member {string} comAbs
     * @memberof Properties
     * @instance
     */
    Properties.prototype.comAbs = "";

    /**
     * Properties codeArr.
     * @member {string} codeArr
     * @memberof Properties
     * @instance
     */
    Properties.prototype.codeArr = "";

    /**
     * Properties idu.
     * @member {string} idu
     * @memberof Properties
     * @instance
     */
    Properties.prototype.idu = "";

    /**
     * Properties codeInsee.
     * @member {string} codeInsee
     * @memberof Properties
     * @instance
     */
    Properties.prototype.codeInsee = "";

    /**
     * Properties contenance.
     * @member {number} contenance
     * @memberof Properties
     * @instance
     */
    Properties.prototype.contenance = 0;

    /**
     * Properties hasOwner.
     * @member {boolean} hasOwner
     * @memberof Properties
     * @instance
     */
    Properties.prototype.hasOwner = false;

    /**
     * Creates a new Properties instance using the specified properties.
     * @function create
     * @memberof Properties
     * @static
     * @param {IProperties=} [properties] Properties to set
     * @returns {Properties} Properties instance
     */
    Properties.create = function create(properties) {
        return new Properties(properties);
    };

    /**
     * Encodes the specified Properties message. Does not implicitly {@link Properties.verify|verify} messages.
     * @function encode
     * @memberof Properties
     * @static
     * @param {IProperties} message Properties message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Properties.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.numero != null && Object.hasOwnProperty.call(message, "numero"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.numero);
        if (message.feuille != null && Object.hasOwnProperty.call(message, "feuille"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.feuille);
        if (message.section != null && Object.hasOwnProperty.call(message, "section"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.section);
        if (message.codeDep != null && Object.hasOwnProperty.call(message, "codeDep"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.codeDep);
        if (message.nomCom != null && Object.hasOwnProperty.call(message, "nomCom"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.nomCom);
        if (message.codeCom != null && Object.hasOwnProperty.call(message, "codeCom"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.codeCom);
        if (message.comAbs != null && Object.hasOwnProperty.call(message, "comAbs"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.comAbs);
        if (message.codeArr != null && Object.hasOwnProperty.call(message, "codeArr"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.codeArr);
        if (message.idu != null && Object.hasOwnProperty.call(message, "idu"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.idu);
        if (message.codeInsee != null && Object.hasOwnProperty.call(message, "codeInsee"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.codeInsee);
        if (message.contenance != null && Object.hasOwnProperty.call(message, "contenance"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.contenance);
        if (message.hasOwner != null && Object.hasOwnProperty.call(message, "hasOwner"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hasOwner);
        return writer;
    };

    /**
     * Encodes the specified Properties message, length delimited. Does not implicitly {@link Properties.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Properties
     * @static
     * @param {IProperties} message Properties message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Properties.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Properties message from the specified reader or buffer.
     * @function decode
     * @memberof Properties
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Properties} Properties
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Properties.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Properties();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    message.numero = reader.string();
                    break;
                }
                case 2: {
                    message.feuille = reader.int32();
                    break;
                }
                case 3: {
                    message.section = reader.string();
                    break;
                }
                case 4: {
                    message.codeDep = reader.string();
                    break;
                }
                case 5: {
                    message.nomCom = reader.string();
                    break;
                }
                case 6: {
                    message.codeCom = reader.string();
                    break;
                }
                case 7: {
                    message.comAbs = reader.string();
                    break;
                }
                case 8: {
                    message.codeArr = reader.string();
                    break;
                }
                case 9: {
                    message.idu = reader.string();
                    break;
                }
                case 10: {
                    message.codeInsee = reader.string();
                    break;
                }
                case 11: {
                    message.contenance = reader.int32();
                    break;
                }
                case 12: {
                    message.hasOwner = reader.bool();
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a Properties message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Properties
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Properties} Properties
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Properties.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Properties message.
     * @function verify
     * @memberof Properties
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Properties.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.numero != null && message.hasOwnProperty("numero"))
            if (!$util.isString(message.numero))
                return "numero: string expected";
        if (message.feuille != null && message.hasOwnProperty("feuille"))
            if (!$util.isInteger(message.feuille))
                return "feuille: integer expected";
        if (message.section != null && message.hasOwnProperty("section"))
            if (!$util.isString(message.section))
                return "section: string expected";
        if (message.codeDep != null && message.hasOwnProperty("codeDep"))
            if (!$util.isString(message.codeDep))
                return "codeDep: string expected";
        if (message.nomCom != null && message.hasOwnProperty("nomCom"))
            if (!$util.isString(message.nomCom))
                return "nomCom: string expected";
        if (message.codeCom != null && message.hasOwnProperty("codeCom"))
            if (!$util.isString(message.codeCom))
                return "codeCom: string expected";
        if (message.comAbs != null && message.hasOwnProperty("comAbs"))
            if (!$util.isString(message.comAbs))
                return "comAbs: string expected";
        if (message.codeArr != null && message.hasOwnProperty("codeArr"))
            if (!$util.isString(message.codeArr))
                return "codeArr: string expected";
        if (message.idu != null && message.hasOwnProperty("idu"))
            if (!$util.isString(message.idu))
                return "idu: string expected";
        if (message.codeInsee != null && message.hasOwnProperty("codeInsee"))
            if (!$util.isString(message.codeInsee))
                return "codeInsee: string expected";
        if (message.contenance != null && message.hasOwnProperty("contenance"))
            if (!$util.isInteger(message.contenance))
                return "contenance: integer expected";
        if (message.hasOwner != null && message.hasOwnProperty("hasOwner"))
            if (typeof message.hasOwner !== "boolean")
                return "hasOwner: boolean expected";
        return null;
    };

    /**
     * Creates a Properties message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Properties
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Properties} Properties
     */
    Properties.fromObject = function fromObject(object) {
        if (object instanceof $root.Properties)
            return object;
        let message = new $root.Properties();
        if (object.numero != null)
            message.numero = String(object.numero);
        if (object.feuille != null)
            message.feuille = object.feuille | 0;
        if (object.section != null)
            message.section = String(object.section);
        if (object.codeDep != null)
            message.codeDep = String(object.codeDep);
        if (object.nomCom != null)
            message.nomCom = String(object.nomCom);
        if (object.codeCom != null)
            message.codeCom = String(object.codeCom);
        if (object.comAbs != null)
            message.comAbs = String(object.comAbs);
        if (object.codeArr != null)
            message.codeArr = String(object.codeArr);
        if (object.idu != null)
            message.idu = String(object.idu);
        if (object.codeInsee != null)
            message.codeInsee = String(object.codeInsee);
        if (object.contenance != null)
            message.contenance = object.contenance | 0;
        if (object.hasOwner != null)
            message.hasOwner = Boolean(object.hasOwner);
        return message;
    };

    /**
     * Creates a plain object from a Properties message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Properties
     * @static
     * @param {Properties} message Properties
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Properties.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.numero = "";
            object.feuille = 0;
            object.section = "";
            object.codeDep = "";
            object.nomCom = "";
            object.codeCom = "";
            object.comAbs = "";
            object.codeArr = "";
            object.idu = "";
            object.codeInsee = "";
            object.contenance = 0;
            object.hasOwner = false;
        }
        if (message.numero != null && message.hasOwnProperty("numero"))
            object.numero = message.numero;
        if (message.feuille != null && message.hasOwnProperty("feuille"))
            object.feuille = message.feuille;
        if (message.section != null && message.hasOwnProperty("section"))
            object.section = message.section;
        if (message.codeDep != null && message.hasOwnProperty("codeDep"))
            object.codeDep = message.codeDep;
        if (message.nomCom != null && message.hasOwnProperty("nomCom"))
            object.nomCom = message.nomCom;
        if (message.codeCom != null && message.hasOwnProperty("codeCom"))
            object.codeCom = message.codeCom;
        if (message.comAbs != null && message.hasOwnProperty("comAbs"))
            object.comAbs = message.comAbs;
        if (message.codeArr != null && message.hasOwnProperty("codeArr"))
            object.codeArr = message.codeArr;
        if (message.idu != null && message.hasOwnProperty("idu"))
            object.idu = message.idu;
        if (message.codeInsee != null && message.hasOwnProperty("codeInsee"))
            object.codeInsee = message.codeInsee;
        if (message.contenance != null && message.hasOwnProperty("contenance"))
            object.contenance = message.contenance;
        if (message.hasOwner != null && message.hasOwnProperty("hasOwner"))
            object.hasOwner = message.hasOwner;
        return object;
    };

    /**
     * Converts this Properties to JSON.
     * @function toJSON
     * @memberof Properties
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Properties.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Properties
     * @function getTypeUrl
     * @memberof Properties
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Properties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Properties";
    };

    return Properties;
})();

export const Feature = $root.Feature = (() => {

    /**
     * Properties of a Feature.
     * @exports IFeature
     * @interface IFeature
     * @property {IMultiPolygon|null} [geometry] Feature geometry
     * @property {IProperties|null} [properties] Feature properties
     * @property {string|null} [id] Feature id
     */

    /**
     * Constructs a new Feature.
     * @exports Feature
     * @classdesc Represents a Feature.
     * @implements IFeature
     * @constructor
     * @param {IFeature=} [properties] Properties to set
     */
    function Feature(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Feature geometry.
     * @member {IMultiPolygon|null|undefined} geometry
     * @memberof Feature
     * @instance
     */
    Feature.prototype.geometry = null;

    /**
     * Feature properties.
     * @member {IProperties|null|undefined} properties
     * @memberof Feature
     * @instance
     */
    Feature.prototype.properties = null;

    /**
     * Feature id.
     * @member {string} id
     * @memberof Feature
     * @instance
     */
    Feature.prototype.id = "";

    /**
     * Creates a new Feature instance using the specified properties.
     * @function create
     * @memberof Feature
     * @static
     * @param {IFeature=} [properties] Properties to set
     * @returns {Feature} Feature instance
     */
    Feature.create = function create(properties) {
        return new Feature(properties);
    };

    /**
     * Encodes the specified Feature message. Does not implicitly {@link Feature.verify|verify} messages.
     * @function encode
     * @memberof Feature
     * @static
     * @param {IFeature} message Feature message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Feature.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.geometry != null && Object.hasOwnProperty.call(message, "geometry"))
            $root.MultiPolygon.encode(message.geometry, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.properties != null && Object.hasOwnProperty.call(message, "properties"))
            $root.Properties.encode(message.properties, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
        return writer;
    };

    /**
     * Encodes the specified Feature message, length delimited. Does not implicitly {@link Feature.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Feature
     * @static
     * @param {IFeature} message Feature message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Feature.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Feature message from the specified reader or buffer.
     * @function decode
     * @memberof Feature
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Feature} Feature
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Feature.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Feature();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    message.geometry = $root.MultiPolygon.decode(reader, reader.uint32());
                    break;
                }
                case 2: {
                    message.properties = $root.Properties.decode(reader, reader.uint32());
                    break;
                }
                case 3: {
                    message.id = reader.string();
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a Feature message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Feature
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Feature} Feature
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Feature.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Feature message.
     * @function verify
     * @memberof Feature
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Feature.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.geometry != null && message.hasOwnProperty("geometry")) {
            let error = $root.MultiPolygon.verify(message.geometry);
            if (error)
                return "geometry." + error;
        }
        if (message.properties != null && message.hasOwnProperty("properties")) {
            let error = $root.Properties.verify(message.properties);
            if (error)
                return "properties." + error;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        return null;
    };

    /**
     * Creates a Feature message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Feature
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Feature} Feature
     */
    Feature.fromObject = function fromObject(object) {
        if (object instanceof $root.Feature)
            return object;
        let message = new $root.Feature();
        if (object.geometry != null) {
            if (typeof object.geometry !== "object")
                throw TypeError(".Feature.geometry: object expected");
            message.geometry = $root.MultiPolygon.fromObject(object.geometry);
        }
        if (object.properties != null) {
            if (typeof object.properties !== "object")
                throw TypeError(".Feature.properties: object expected");
            message.properties = $root.Properties.fromObject(object.properties);
        }
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    /**
     * Creates a plain object from a Feature message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Feature
     * @static
     * @param {Feature} message Feature
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Feature.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.geometry = null;
            object.properties = null;
            object.id = "";
        }
        if (message.geometry != null && message.hasOwnProperty("geometry"))
            object.geometry = $root.MultiPolygon.toObject(message.geometry, options);
        if (message.properties != null && message.hasOwnProperty("properties"))
            object.properties = $root.Properties.toObject(message.properties, options);
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        return object;
    };

    /**
     * Converts this Feature to JSON.
     * @function toJSON
     * @memberof Feature
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Feature.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Feature
     * @function getTypeUrl
     * @memberof Feature
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Feature.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Feature";
    };

    return Feature;
})();

export const FeatureCollection = $root.FeatureCollection = (() => {

    /**
     * Properties of a FeatureCollection.
     * @exports IFeatureCollection
     * @interface IFeatureCollection
     * @property {Array.<IFeature>|null} [features] FeatureCollection features
     */

    /**
     * Constructs a new FeatureCollection.
     * @exports FeatureCollection
     * @classdesc Represents a FeatureCollection.
     * @implements IFeatureCollection
     * @constructor
     * @param {IFeatureCollection=} [properties] Properties to set
     */
    function FeatureCollection(properties) {
        this.features = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FeatureCollection features.
     * @member {Array.<IFeature>} features
     * @memberof FeatureCollection
     * @instance
     */
    FeatureCollection.prototype.features = $util.emptyArray;

    /**
     * Creates a new FeatureCollection instance using the specified properties.
     * @function create
     * @memberof FeatureCollection
     * @static
     * @param {IFeatureCollection=} [properties] Properties to set
     * @returns {FeatureCollection} FeatureCollection instance
     */
    FeatureCollection.create = function create(properties) {
        return new FeatureCollection(properties);
    };

    /**
     * Encodes the specified FeatureCollection message. Does not implicitly {@link FeatureCollection.verify|verify} messages.
     * @function encode
     * @memberof FeatureCollection
     * @static
     * @param {IFeatureCollection} message FeatureCollection message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FeatureCollection.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.features != null && message.features.length)
            for (let i = 0; i < message.features.length; ++i)
                $root.Feature.encode(message.features[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified FeatureCollection message, length delimited. Does not implicitly {@link FeatureCollection.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FeatureCollection
     * @static
     * @param {IFeatureCollection} message FeatureCollection message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FeatureCollection.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FeatureCollection message from the specified reader or buffer.
     * @function decode
     * @memberof FeatureCollection
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FeatureCollection} FeatureCollection
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FeatureCollection.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FeatureCollection();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
                case 1: {
                    if (!(message.features && message.features.length))
                        message.features = [];
                    message.features.push($root.Feature.decode(reader, reader.uint32()));
                    break;
                }
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    };

    /**
     * Decodes a FeatureCollection message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FeatureCollection
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FeatureCollection} FeatureCollection
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FeatureCollection.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FeatureCollection message.
     * @function verify
     * @memberof FeatureCollection
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FeatureCollection.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.features != null && message.hasOwnProperty("features")) {
            if (!Array.isArray(message.features))
                return "features: array expected";
            for (let i = 0; i < message.features.length; ++i) {
                let error = $root.Feature.verify(message.features[i]);
                if (error)
                    return "features." + error;
            }
        }
        return null;
    };

    /**
     * Creates a FeatureCollection message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FeatureCollection
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FeatureCollection} FeatureCollection
     */
    FeatureCollection.fromObject = function fromObject(object) {
        if (object instanceof $root.FeatureCollection)
            return object;
        let message = new $root.FeatureCollection();
        if (object.features) {
            if (!Array.isArray(object.features))
                throw TypeError(".FeatureCollection.features: array expected");
            message.features = [];
            for (let i = 0; i < object.features.length; ++i) {
                if (typeof object.features[i] !== "object")
                    throw TypeError(".FeatureCollection.features: object expected");
                message.features[i] = $root.Feature.fromObject(object.features[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a FeatureCollection message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FeatureCollection
     * @static
     * @param {FeatureCollection} message FeatureCollection
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FeatureCollection.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.features = [];
        if (message.features && message.features.length) {
            object.features = [];
            for (let j = 0; j < message.features.length; ++j)
                object.features[j] = $root.Feature.toObject(message.features[j], options);
        }
        return object;
    };

    /**
     * Converts this FeatureCollection to JSON.
     * @function toJSON
     * @memberof FeatureCollection
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FeatureCollection.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FeatureCollection
     * @function getTypeUrl
     * @memberof FeatureCollection
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FeatureCollection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FeatureCollection";
    };

    return FeatureCollection;
})();

export { $root as default };
