// Librairies
import * as React from "react";
import { Show, useRecordContext, useTranslate, useRedirect, useRefresh, useGetList, useGetIdentity } from 'react-admin';
import { useState, lazy, Suspense } from "react";
import dayjs from 'dayjs';
import { motion } from "framer-motion";
import { SplitTreatments, SplitClient } from "@splitsoftware/splitio-react";

// Mui Components
import Grid from "@mui/material/Grid";
import StatusButtons from "../../components/Buttons/StatusButtons";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

// Custom components
import { PluStatus } from "../../components/Chips/PluStatus";
import { StyledTimestamp } from "../../components/Fields/StyledTimestamp";
import { InfoChip } from "../../components/Chips/InfoChip";
import { TypeHouseIcon } from "../../components/Icons/TypeHouseIcon";
import { DPEIcon } from "../../components/Icons/DPEIcon";
import { SurfaceIcon } from "../../components/Icons/SurfaceIcon";
import { ConstructionIcon } from "../../components/Icons/ConstructionIcon";
import { EmojiIcon } from "../../components/Icons/EmojiIcon";
import { DVFLink } from "../../components/Fields/DVFLink";
import { PLUPopupInfos } from "../../components/Prospects/PLUPopupInfos";
import { IPLUData, IProspect } from "../../types/Prospect";
import TransferProspectButton from "../../components/Buttons/TransferProspectButton";
import { PappersLink } from "../../components/Fields/PappersLink";
import { ProspectDetailsTabs } from "../../components/Prospects/DetailsTabs";
import { ChatButton } from "../../components/Buttons/ChatButton";
import { OwnerList } from "../../components/Prospects/Owners/OwnerList";
import { KaptcherOwnerInformation } from "../../components/Prospects/Owners/KaptcherOwnerInformation";
import { CustomTextField } from "../../components/Fields/CustomTextField";
import { ProspectCRMLayout } from "../../components/layout/Prospect/ProspectCRMLayout";
import { ProspectShowHeader } from "../../components/layout/Prospect/ProspectShowHeader";

// Icons
import MapIcon from '@mui/icons-material/Map';
import { FileIcon } from "../../components/Icons/FileIcon";

// style
import theme from "../../theme";

// Locals
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/fr')
dayjs.locale("fr");

// Lazy loading
const MapField = lazy(() => import("../../components/Map/ProspectDetailMap"));


const ProspectShowLayout = (props: {
    variant?: "compact" | "full"
}) => {
    const { data: partners } = useGetList('organisations/partners', {},
        //3 hours cache
        { staleTime: 180 * 60 * 1000, gcTime: 240 * 60 * 1000 }
    );
    const t = useTranslate();
    const user = useGetIdentity();
    const prospect: (IProspect & { isRecommendation: boolean } & { pluData: IPLUData }) | undefined = useRecordContext();
    const redirect = useRedirect();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [isMapFullScreen, setIsMapFullScreen] = useState(false);
    const refresh = useRefresh();
    const [hoveredOwnerId, setHoveredOwnerId] = useState<string | null>(null);
    const [selectedParcelIds, setSelectedParcelIds] = useState<string[]>([]);

    const handleMouseEnter = (ownerId: string) => {
        setHoveredOwnerId(ownerId);
    };

    const handleMouseLeave = () => {
        setHoveredOwnerId(null);
    };

    return (<>
        {prospect ?
            <SplitClient splitKey={user?.data?.organisationId} trafficType='account'>
                <SplitTreatments names={["developer-layout"]} >
                    {({ isReady, treatments }) => {
                        if (!isReady) return null;
                        let isDeveloperLayout = treatments["developer-layout"].treatment === "on";

                        return (<>
                            {isMapFullScreen &&
                                <Dialog open={isMapFullScreen} onClose={() => setIsMapFullScreen(false)} maxWidth="xl" fullWidth>
                                    <Suspense>
                                        <div className="h-[90vh]">
                                            <MapField
                                                isFullScreen={isMapFullScreen}
                                                enableIsoDriving={true}
                                                enableIsoWalking={true}
                                                onFullScreenClick={() => { }}
                                                hoveredId={hoveredOwnerId}
                                                onSelectedParcelChange={setSelectedParcelIds}
                                            />
                                        </div>
                                    </Suspense>
                                    {isDesktop &&
                                        <Box className="absolute right-0 pt-28 h-full max-w-sm overflow-auto">
                                            <Box className="rounded-md h-full overflow-auto">
                                                <OwnerList
                                                    onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                    selectedParcelIds={selectedParcelIds}
                                                    variant="compact"
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Dialog>
                            }
                            <Grid container columnSpacing={2}>

                                {/* Left Side */}
                                <Grid item xs={12} md={7} xl={8}>
                                    <motion.div
                                        initial={{ opacity: 0, x: -100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <Card>
                                            {(!isDeveloperLayout || !isDesktop) &&
                                                <Box className="px-1 pt-1 md:px-4">
                                                    <ProspectShowHeader />
                                                </Box>
                                            }
                                            <Suspense fallback={<></>}>
                                                <Box className={`${(isDeveloperLayout && isDesktop) ? 'min-h-[80vh]' : 'min-h-[50vh]'} flex justify-between items-center`}>
                                                    <MapField
                                                        isFullScreen={isMapFullScreen}
                                                        onFullScreenClick={() => setIsMapFullScreen(true)}
                                                        enableIsoDriving={isDeveloperLayout && isDesktop}
                                                        enableIsoWalking={isDeveloperLayout && isDesktop}
                                                        hoveredId={hoveredOwnerId}
                                                        onSelectedParcelChange={setSelectedParcelIds}
                                                        minHeigth={`${(isDeveloperLayout && isDesktop) ? '80vh' : '50vh'}`}
                                                    />
                                                </Box>
                                            </Suspense>
                                            <div className="p-1 md:p-4">
                                                <Grid container columnSpacing={4}>
                                                    <Grid item xs={6} md={6}>
                                                        <Box className="flex flex-gap-2 justify-between">
                                                            <CustomTextField variant="h6" source="plu" render={() => 'Données PLU'} icon={<FileIcon stroke={theme.palette.primary.main} />} />
                                                            {prospect?.pluData &&
                                                                <PLUPopupInfos />
                                                            }
                                                        </Box>
                                                        {prospect?.pluInfo?.libelle ?
                                                            <Box className="mb-4">
                                                                <div className="flex gap-x-2 items-center mb-4">
                                                                    <CustomTextField source="type" variant="subtitle1" render={(prospect: any) => `Type de zone:`} />
                                                                    <PluStatus plu={prospect.pluInfo.libelle} />
                                                                    <CustomTextField source="libelle" variant="subtitle1" render={(prospect: any) => `${prospect.pluInfo.libelle}`} />
                                                                </div>
                                                                <div>
                                                                    {prospect.pluInfo.libelong}
                                                                </div>
                                                                <div className="flex mt-2">
                                                                    <a target="_blank" href={prospect?.pluInfo?.urlfic} >
                                                                        <Button variant="text" color="primary" size="small" startIcon={<MapIcon />} >
                                                                            {t('prospect.actions.goToPLUDocument')}
                                                                        </Button>
                                                                    </a>
                                                                </div>
                                                            </Box>
                                                            : <Typography variant="subtitle1" >Aucune donnée PLU disponible</Typography>
                                                        }
                                                        {prospect?.formResultId?.parcelleNumber && (
                                                            <Box className="mb-4">
                                                                <CustomTextField variant="h6" source="numero" render={() => 'Numéro de la parcelle'} icon={<SurfaceIcon stroke={theme.palette.primary.main} />} />
                                                                <Typography variant="subtitle1" >{prospect?.formResultId?.parcelleNumber}</Typography>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <Box className="flex gap-2 flex-wrap">
                                                            <Box className="flex flex-col gap-2">
                                                                <DVFLink />
                                                                <PappersLink />
                                                            </Box>
                                                            {prospect?.formResultId?.numberRooms && (
                                                                <InfoChip label={`${prospect?.formResultId?.numberRooms} pièces`} icon={<TypeHouseIcon stroke={theme.palette.primary.main} />} />
                                                            )}
                                                            {prospect?.formResultId?.generalState && (
                                                                <InfoChip label={t(`prospect.generalStates.${prospect?.formResultId?.generalState}`)} icon={<EmojiIcon emoji="good" color="primary" />} />
                                                            )}
                                                            {prospect?.formResultId?.constructionYear && (
                                                                <InfoChip label={prospect?.formResultId?.constructionYear} icon={<ConstructionIcon color="primary" type={prospect.formResultId.contactPreference} />} />
                                                            )}
                                                            {prospect?.formResultId?.dpe && (
                                                                <InfoChip label={"DPE"} icon={<DPEIcon DPE={prospect?.formResultId?.dpe} />} />
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Card >
                                    </motion.div>
                                </Grid>

                                {/* Right Side */}
                                <Grid item xs={12} md={5} xl={4}>
                                    <motion.div className=""
                                        initial={{ opacity: 0, x: 100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 0.2, delay: 0.1 }}
                                    >
                                        <Box className="flex flex-col gap-2 mt-4 md:mt-0">
                                            {prospect?.origin === "terrafinder" &&
                                                <ChatButton />
                                            }
                                            <Card>
                                                <Box className="p-1 md:p-4">
                                                    {(isDeveloperLayout && isDesktop) && <ProspectShowHeader />}
                                                    {prospect?.status === "pending" &&
                                                        <KaptcherOwnerInformation />
                                                    }
                                                    <ProspectDetailsTabs
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                        selectedParcelIds={selectedParcelIds}
                                                    />
                                                </Box>
                                            </Card>
                                            <Card>
                                                <div className="p-1 md:p-4">
                                                    {(prospect?.status === "pending" || prospect?.isRecommendation === true) &&
                                                        <Box>
                                                            <Typography variant="h6">{`N'oubliez pas de vous décider avant de partir`}</Typography>
                                                            <Box className="mt-4">
                                                                <StatusButtons isRecommendation={prospect?.isRecommendation} onReject={() => redirect('list', 'prospects')} size="large" />
                                                            </Box>
                                                        </Box>
                                                    }
                                                    {(prospect?.status === "accepted" && !prospect?.isRecommendation && partners?.length !== undefined && partners?.length > 0) &&
                                                        <Box className="flex items-center gap-2">
                                                            <Typography variant="h6">{`Transférer a une autre organisation`}</Typography>
                                                            <TransferProspectButton prospectIds={[prospect?.id]} onTransferred={refresh} />
                                                        </Box>
                                                    }
                                                    <div className="mt-1">
                                                        <StyledTimestamp label={false} source="createdAt" />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Box>
                                    </motion.div>
                                </Grid>
                            </Grid >
                            <ProspectCRMLayout variant={props.variant} />
                        </>
                        )
                    }}
                </SplitTreatments >
            </SplitClient >
            : <></>
        }
    </>)
};

export const ProspectShow = (props: { type: "new" | "current" }) => {
    const t = useTranslate();

    return <Show title={t(`menu.prospects.${props.type}`)}
        component={"div"} queryOptions={{ refetchOnWindowFocus: false }}
        sx={{
            '& .RaShow-card': {
                width: "100%",
            },
        }}>
        <ProspectShowLayout />
    </Show>
};