import { IContact, IOwner, IProspect } from "../../../types/Prospect";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PhoneField } from '../../Fields/PhoneField';
import { CustomEmailField } from '../../Fields/CustomEmailField';
import { RecordContextProvider, ReferenceField, TextField, useGetOne, useTranslate } from "react-admin";
import { PipeStatusDropdown } from "../../Buttons/PipeStatusDropdown";
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { UserCardIdIcon } from "../../Icons/UserCardIdIcon";
import theme from "../../../theme";
import { DropdownActionsButton } from "../../Buttons/DropdownActionsButton";
import { useState } from "react";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { TypeBuildingIcon } from "../../Icons/TypeBuilding";
import { CRMField } from "../../Fields/ProspectCRMField";
import { BackgroundToggleButtonGroup } from "../../Buttons/StyledToggleButtonGroup";
import ToggleButton from '@mui/material/ToggleButton';
import { EventActionButton } from "../../Buttons/EventActionButton";
import { MeetingsField } from "../../Fields/MeetingsField";


export const OwnerCard = (props:
    {
        prospect: IProspect,
        prospectId: string | undefined,
        owner: IOwner | IContact,
        onOpenEdit?: (owner: IOwner) => void,
        type: 'owner' | 'contact',
        variant: 'normal' | 'compact'
    }) => {
    const { prospectId, owner, onOpenEdit, type, variant } = props;
    const t = useTranslate();
    const [openDotsMenu, setOpenDotsMenu] = useState(false);
    const { data: prospect, refetch } = useGetOne('prospects', { id: prospectId! });
    const [eventTab, setEventTab] = useState<"events" | "notes" | "tasks">('events');

    const lastEvent = () => {
        let lastEvent = prospect.events.find((event: any) => (
            event.associatedOwners.some((associatedOwner: any) => (
                associatedOwner === owner.id
            ))
        ));
        if (lastEvent) {
            return <Typography key={lastEvent.id} variant="body2" className="w-full text-right relative left-10">
                {`Dernier échange : ${new Date(lastEvent.eventDate).toLocaleDateString('fr-FR')} avec ${lastEvent.authorName} `}
            </Typography>
        }
    }

    return <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
            <Box className="flex flex-col gap-2">
                <Box className="flex justify-between">
                    <Typography variant={variant === "normal" ? "h6" : "body1"}>{owner.fullName}</Typography>
                    {type === 'owner' &&
                        <>
                            {('pipeStatus' in owner) &&
                                <PipeStatusDropdown variant={"compact"} pipeStatus={owner?.pipeStatus} id={owner.id} resource={`prospects/${prospectId}/owners`} resourceType="owner" onChange={() => refetch()} />
                            }
                            <Box className="absolute right-0 top-3">
                                <DropdownActionsButton
                                    open={openDotsMenu}
                                    handleClose={() => setOpenDotsMenu(false)}
                                    actions={[
                                        {
                                            label: <Box sx={{ color: theme.palette.grey[600] }}>
                                                <ModeEditOutlinedIcon />
                                                {t('ra.action.edit')}
                                            </Box>,
                                            onClick: () => {
                                                setOpenDotsMenu(false);
                                                onOpenEdit && onOpenEdit(owner as IOwner);
                                            }
                                        },
                                    ]}
                                    button={<IconButton sx={{ width: '30px', height: '30px' }} id={`menu-button-${owner?.id}`} onClick={(e) => { e.stopPropagation(); setOpenDotsMenu(!openDotsMenu) }} ><MoreVertOutlinedIcon /></IconButton>}
                                />
                            </Box>
                        </>
                    }
                </Box>
                {(owner.address || owner.zipCode || owner.cityName) &&
                    <Typography variant="body2">{owner.address}, {owner.zipCode} {owner.cityName}</Typography>
                }
                <Box className="flex flex-col md:flex-row gap-2 justify-between">
                    <CustomEmailField record={owner} source="email" />
                    <PhoneField />
                </Box>
                {('role' in owner) &&
                    <Box className="flex gap-2 items-center">
                        <UserCardIdIcon fill={theme.palette.primary.main} />
                        {t(`contact.roles.${owner.role}`)}
                    </Box>
                }
                {('meetings' in owner && 'pipeStatus' in owner) &&
                    <RecordContextProvider value={prospect}>
                        <MeetingsField prospect={prospect} owner={owner} />
                    </RecordContextProvider>
                }
                {lastEvent()}
            </Box>
        </AccordionSummary>
        {owner.personType === 'professional' &&
            <AccordionDetails>
                <ReferenceField reference="entities" source="entityId">
                    <Box className="flex flex-col gap-2">
                        <Box>
                            <Box className="flex gap-2 items-center">
                                <TypeBuildingIcon stroke={theme.palette.primary.main} />
                                <TextField source="entityName" />
                            </Box>
                        </Box>
                    </Box>
                </ReferenceField>
            </AccordionDetails>
        }
        {type === "owner" &&
            <AccordionDetails>
                <Box className="overflow-auto max-h-96">
                    <RecordContextProvider value={prospect}>
                        <BackgroundToggleButtonGroup
                            value={eventTab}
                            exclusive
                            onChange={(event, value) => { if (value) setEventTab(value) }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="events" aria-label="left aligned">
                                Échanges
                                <EventActionButton action="create" id={prospect?.id?.toString()} crmType={"events"} event={{ associatedOwners: [owner?.id], type: "call" }} />
                            </ToggleButton>
                            <ToggleButton value="notes" aria-label="centered">
                                Notes
                                <EventActionButton action="create" id={prospect?.id?.toString()} crmType={"notes"} event={{ associatedOwners: [owner?.id] }} />
                            </ToggleButton>
                            <ToggleButton value="tasks" aria-label="right aligned">
                                Rappels
                                <EventActionButton action="create" id={prospect?.id?.toString()} crmType={"tasks"} event={{ associatedOwners: [owner?.id] }} />
                            </ToggleButton>
                        </BackgroundToggleButtonGroup>
                        {eventTab === 'events' &&
                            <CRMField
                                variant={"compact"}
                                crmType="events"
                                displayButtons={false}
                                displayAssociatedOwners={false}
                                dateProperty="eventDate"
                                userProperty="authorName"
                                filter={(event: { id: string, associatedOwners: string[] }) => event.associatedOwners.includes(owner?.id)}
                            />
                        }
                        {eventTab === 'notes' &&
                            <CRMField
                                variant={"compact"}
                                crmType="notes"
                                displayButtons={false}
                                displayAssociatedOwners={false}
                                dateProperty="createdAt"
                                userProperty="authorName"
                                filter={(event: { id: string, associatedOwners: string[] }) => event.associatedOwners.includes(owner.id)}
                            />
                        }
                        {eventTab === 'tasks' &&
                            <CRMField
                                variant={"compact"}
                                crmType="tasks"
                                displayButtons={false}
                                displayAssociatedOwners={false}
                                dateProperty="dueDate"
                                userProperty="assigneeName"
                                filter={(event: { id: string, associatedOwners: string[] }) => event.associatedOwners.includes(owner.id)}
                            />
                        }
                    </RecordContextProvider>
                </Box>
            </AccordionDetails>
        }
    </Accordion>
}

OwnerCard.defaultProps = {
    variant: 'normal'
}