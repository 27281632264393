export const prospectMessages = {
    prospect: {
        displayType: {
            list: "Vue Liste",
            map: "Vue Carte",
            kanban: "Vue Kanban",
            splitted: "Vue Carte",
        },
        property: {
            address: "Adresse",
            zipCode: "Code postal",
            createdAt: "Ajouté le",
            status: "Statut",
            type: "Type",
            name: "Nom",
            houseSurface: "Surface du bâti",
            fieldSurface: "Surface du terrain",
            interest: "Intérêt",
            email: "Email",
            phoneNumber: "Numéro de téléphone",
            isAttributed: "Attribué",
            isNotAttributed: "Non attribué",
            isTreated: "Traité",
            tasks: "Mes rappels",
            events: "Mes échanges",
            notes: "Mes notes",
            task: {
                title: "Titre",
                description: "Description",
                dueDate: "Date du rappel",
                createdAt: "Créée le",
            },
            note: {
                title: "Titre",
                description: "Description",
                createdAt: "Créée le",
            },
            projects: {
                addProject: "Ajouter un projet",
                title: "Titre",
                floorArea: "Surface de plancher",
                projectedRevenue: "CA prévisionnel",
                numberOfUnits: "Nombre de lots",
            },
            event: {
                title: "Titre",
                description: "Description",
                type: "Type",
                eventDate: "Date de l'échange",
            },
            associatedUsers: "Développeurs associés",
            verifiedEmail: "Email vérifié",
            verifiedPhoneNumber: "Numéro de téléphone vérifié",
            origin: "Origine",
            ownerComment: "Commentaire",
            ownerType: "Type de propriétaire",
            residencyType: "Type de résidence",
            saleTiming: "Période de vente",
            salePrice: "Prix de vente",
            weekDays: "Jours de la semaine",
            dayTimes: "Moment de la journée",
            contactPreference: "Préférence de contact",
            generalState: "État général",
            constructionYear: "Année de construction",
            dpe: "DPE",
            numberRooms: "Nombre de pièces",
            parcelleNumber: "Numéros de parcelles",
            cityName: "Ville",
            regionCode: "Département",
            country: "Pays",
            verifiedPhone: "Téléphone vérifié",
            isFavorite: "Favori",
            lastContactedAt: "Dernier échange",
            tags: "Étiquettes",
            leadScore: "Flamme",
            pipeStatus: "Statut",
            parcels: "Parcelles",
            owners: "Propriétaires",
            contacts: "Intervenants",
            files: "Documents",
            checklist: "Tâches",
            project: "Projet",
        },
        pluData: {
            maxHeight: "Hauteur maximale",
            maxSurface: "Emprise au sol maximale",
            maxNumberFloors: "Nombre d'étages maximal",
        },
        map: {
            monochrome: "Clair",
            street: "Carte",
            satellite: "Satellite",
            cadastre: "Cadastre",
            streetview: "Street View",
            plu: "PLU",
            QPV: "QPV",
            isoWalking: "Distances à pied",
            isoDriving: "Distances en voiture",
            threeD: "Visualisation 3D",
        },
        type: {
            house: "Maison",
            field: "Terrain",
            building: "Immeuble",
            garage: "Garage",
            appartment: "Appartement",
            other: "Autre",
            warehouse: "Hangar",
            industrialWasteland: "Friche industrielle",
        },
        statuses: {
            pending: "En attente",
            rejected: "Refusé",
            accepted: "Accepté",
            toContact: "À contacter",
            contacted: "Contacté",
            closed: "Clôturé",
            toBeTreated: "À traiter",
            standBy: "En attente",
            toTransfert: "À transférer",
            transferred: "Transféré",
            qualified: "Qualifié",
            visited: "Visité",
            offerSubmitted: "Remise d'offre",
            mandateSigned: "Mandat signé",
            signed: "Signé",
            notInterested: "Pas intéressé",
            notSelling: "Pas vendeur",
            wrongContact: "Mauvaises coordonnées",
            followUp: "A suivre",
            feasabilityStudy: "Etude de faisabilité",
        },
        owners: {
            addOwner: "Ajouter un propriétaire",
            gender: "Civilité",
            genders: {
                man: "Monsieur",
                woman: "Madame",
                womanAndMan: "Madame et Monsieur",
            },
            gendersShort: {
                man: "M.",
                woman: "Mme",
                womanAndMan: "Mme et M.",
            },
            statuses: {
                toContact: "À contacter",
                standBy: "Courrier d’intérêt envoyé",
                contacted: "Contact établi",
                qualified: "Vendeur",
                offerSubmitted: "Offre émise",
                mandateSigned: "Offre acceptée",
                signed: "PUV signée",
                wrongContact: "Mauvaises coordonnées",
                notSelling: "Pas vendeur",
            },
            selectAnOwner: "Sélectionner un propriétaire",
        },
        checkListItem: {
            statuses: {
                accepted: "Acceptée",
                rejected: "Rejetée",
            }
        },
        rejectionReason: {
            constructibility: "Nombre de lots potentiels trop faible",
            potential: "Aucun potentiel de construction",
            contactinfos: "Coordonnées erronées",
            other: "Autre"
        },
        eventTypes: {
            call: "Appel",
            email: "Email",
            sms: "SMS",
            visit: "Visite",
            meeting: "Rendez-vous",
            postMail: "Courrier",
            other: "Autre",
            task: "Rappel",
            note: "Note",
        },
        origins: {
            kaptcher: "Kaptcher",
            jvmi: "JVMI",
            creation: "Ajout manuel",
        },
        empty: {
            new: "Pas de nouveaux prospects",
            current: "Pas de prospect en cours"
        },
        tooltips: {
            contactInfosHidden: "Les informations de contact sont visibles pour vos dossiers en cours. Cliquez sur 'Oui' pour valider ce prospect."
        },
        actions: {
            create: "Nouveau dossier",
            edit: "Modifier un dossier",
            deletetasks: "Supprimer un rappel",
            deleteevents: "Supprimer un échange",
            deletenotes: "Supprimer une note",
            createtasks: "Ajouter un rappel",
            createnotes: "Ajouter une note",
            createevents: "Ajouter un échange",
            edittasks: "Modifier un rappel",
            editnotes: "Modifier une note",
            editevents: "Modifier un échange",
            displayClosed: "Afficher les clôturés",
            sendNotification: {
                email: {
                    title: "Envoyer un email à",
                    content: "Envoyer un email au prospect",
                    defaultcontent: `<p>Bonjour %prospect.name%,</p><p>Je me permets de vous contacter suite à votre inscription sur le site Kaptcher.fr le 5 octobre, concernant votre %prospect.type% à %prospect.cityName%.</p><p>Je suis %{firstName} %{lastName}, je travaille en tant que partenaire de Kaptcher.</p><p>Votre propriété a retenu notre attention, je vous propose d'en discuter ensemble, afin de vous accompagner dans votre projet.</p><p>Quel est le meilleur moment pour vous joindre ?</p><p>Vous pouvez répondre à ce mail, ou sur l'adresse mail suivante : <a target="_blank" rel="noopener noreferrer nofollow" href="mailto:%{email}">%{email}</a>, ou bien me joindre au %{phoneNumber}.</p><p>Cordialement, %{firstName} %{lastName}</p>`,
                    success: `Email %{title} envoyé`,
                },
                postMail: {
                    title: "Envoyer un courier à",
                    content: "Envoyer un courier au prospect",
                    defaultcontent: `<p>Bonjour %owner.fullName%,</p><p>Je me permets de vous contacter concernant votre proriété au %prospect.address%.</p><p>Votre propriété a retenu notre attention, je vous propose d'en discuter ensemble, afin de vous accompagner dans votre projet.</p><p>Quel est le meilleur moment pour vous joindre ?</p><p>Vous pouvez répondre à ce mail, ou sur l'adresse mail suivante : %{email}, ou bien me joindre au %{phoneNumber}.</p><p>Cordialement, %{firstName} %{lastName}</p>`,
                    success: `Courier %{title} généré`,
                },
                sms: {
                    title: "Envoyer un SMS",
                    content: "Envoyer un SMS au prospect",
                    defaultcontent: `Bonjour, je suis %{firstName} %{lastName}. Vous avez laissé vos coordonnées sur notre site Kaptcher. Je vous propose d'en discuter ensemble. Quel est le meilleur moment pour vous joindre ? Cdt.`,
                },
                voicemail: {
                    title: "Laisser un message vocal",
                    content: "Laisser un message vocal au prospect",
                    success: "Message vocal envoyé",
                },
                ai: "Générer un mail",
                sending: "Envoi en cours...",
                emailTemplates: {
                    title: "Modèles d'email",
                }
            },
            openPLUData: "Ouvrir les données PLU",
            goToPLUDocument: "Consulter le Plan Local d'Urbanisme",
            toTransfer: "Transférer",
            transferPopupTitle: "Transférer le prospect à %{organisationName}",
            transferPopupSubtitle: "Avant de confirmer, vous pouvez laisser un commentaire qui accompagnera votre transfert",
            updateStatus: "Mettre à jour le statut",
            importCSV: {
                title: "Importer un fichier CSV",
                content: "Importer un fichier CSV contenant des prospects",
                success: "%{count} prospects importés",
                error: "Erreur lors de l'import du fichier",
                partialSuccess: "Import de %{successCount} prospects réussi, %{errorCount} échecs : %{errorList}",
                loading: "Import en cours...",
            },
        },
        form: {
            selectParcels: "Sélectionner des parcelles",
        },
        dayTimes: {
            "morning": "Le matin",
            "lunch": "Le midi",
            "afternoon": "L'après-midi",
            "evening": "Le soir"
        },
        weekDays: {
            "weekDays": "En semaine",
            "weekEnd": "Le week-end",
            "allDays": "Tous les jours"
        },
        disponibility: "Disponibilités",
        saleTimings: {
            "asap": "Dès que possible",
            "3months": "D'ici 3 mois",
            "6months": "D'ici 6 mois",
            "12months": "D'ici 12 mois",
            "morethan12months": "Dans plus de 12 mois"
        },
        contactPreferences: {
            "email": "Par email",
            "phoneCall": "Par téléphone",
            "SMS": "Par SMS",
            "none": "Aucune préférence",
            "any": "Aucune préférence",
            "chat": "Par chat",
        },
        generalStates: {
            "new": "État neuf",
            "good": "Bon état",
            "bad": "État dégradé",
        },
        residencyTypes: {
            "principal": "Résidence principale",
            "secondary": "Résidence secondaire",
            "rental": "Location",
            "other": "Autre"
        },
        leadScores: {
            low: "Bon potentiel",
            medium: "Bon potentiel",
            high: "Fort potentiel",
        },
        filters: {
            "minHouseSurface": "Surface du bâti minimum",
            "maxHouseSurface": "Surface du bâti maximum",
            "minFieldSurface": "Surface du terrain minimum",
            "maxFieldSurface": "Surface du terrain maximum",
            "minCreatedAt": "Date de création minimum",
            "maxCreatedAt": "Date de création maximum",
        },
        errors: {
            alreadyTransferred: "Ce prospect a déjà été transféré à %{organisationName}",
        },
        notifications: {
            prospectTransferred: "Le prospect a été transféré",
        }
    }
}