import { useRecordContext, useGetIdentity } from "react-admin"

//Mui
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Components
import { CheckListItem } from "./CheckListItem";

// Types
import { IProspect } from "../../../types/Prospect"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.root}`]: {
        backgroundColor: theme.palette.error.main,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.success.main,
    },
}));

export const CheckListTab = () => {
    const prospect = useRecordContext<IProspect>();
    const user = useGetIdentity();

    if (!prospect) return null;

    const checkList = user?.data?.checkList;
    const accepted = prospect.checkListItems?.filter((check: any) => check.status === 'accepted').length;
    const rejected = prospect.checkListItems?.filter((check: any) => check.status === 'rejected').length;

    return <Box className="flex flex-col gap-4">
        <Typography variant="h6">Suivi des tâches</Typography>
        <Tooltip title={
            <Box className="flex flex-col gap-1">
                <Box>{`${accepted ?? 0} / ${checkList?.length ?? 0} tâches validées`}</Box>
                <Box>{`${rejected ?? 0} / ${checkList?.length ?? 0} tâches rejetées`}</Box>
            </Box>}
        >
            <Box className="w-full rounded-lg bg-bg">
                <Box sx={{ width: `${((accepted ?? 0) + (rejected ?? 0)) / (checkList?.length ?? 100) * 100}%` }}>
                    <BorderLinearProgress color="success" variant="determinate" value={(accepted ?? 0) / ((accepted ?? 0) + (rejected ?? 0)) * 100} className="w-full" />
                </Box>
            </Box>
        </Tooltip>
        {checkList?.map((check: any) => {
            return <CheckListItem item={check} />
        })}
    </Box >
}