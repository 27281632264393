import { styled } from '@mui/system';
import { List } from 'react-admin';
import theme from '../../theme';

export const SplittedList = styled(List)({
    position: "absolute",
    width: "calc(100% - 40px)",
    height: "calc(100% - 24px)",
    '& .RaList-main': {
        paddingBottom: "3em"
    },
    '& .RaList-content': {
        height: 'calc(100% - 200px)',
        flexGrow: 1,
    },
    "& .RaDatagrid-tableWrapper": {
        overflow: "auto",
        borderRadius: theme.shape.borderRadius,
        maxHeight: "100%"
    },
    "& .RaDatagrid-root": {
        paddingTop: "16px",
        height: "100%",
    },
    "& .MuiTablePagination-root": {
        position: "absolute",
        bottom: "0px",
        right: "0px",
    },
})