import { Show, SimpleShowLayout, TextField, EmailField, useTranslate, usePermissions, DateField, useRecordContext, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, useGetList, ArrayField, WithListContext, ShowButton, Link } from 'react-admin';
import StatusChip from '../../components/Chips/StatusChip';
import { PhoneField } from '../../components/Fields/PhoneField';
import BooleanField from '../../components/Fields/BooleanField';
import { lazy, Suspense } from 'react';
import Grid from '@mui/material/Grid';
import { ProspectOrganisationField } from '../../components/Fields/ProspectOrganisationField';
import { CustomEmailField } from '../../components/Fields/CustomEmailField';
import { DVFLink } from '../../components/Fields/DVFLink';
import { PappersLink } from '../../components/Fields/PappersLink';

// Lazy loading
const MapField = lazy(() => import("../../components/Map/ProspectDetailMap"));

const OpenProspectButton = (props: any) => {
    const t = useTranslate();
    const record = useRecordContext();

    //Return child
    return <Link to={`/prospects/${record?.id}/show`} className="flex items-center gap-x-2">
        {props.children}
    </Link>
}

const AttributionsField = () => {
    const t = useTranslate();
    const record = useRecordContext();
    //Get organisations name from id from record prospect organisationId
    const { data: orgas, isLoading } = useGetList('organisations', { filter: { id: record?.prospects?.map((prospect: any) => prospect.organisationId) ?? [] } });

    if (!record) return null;

    return (record.prospects && record.prospects[0] && record.prospects[0].id ?
        <div className="w-full">
            <div className="w-full flex items-center">
                <div>
                    {(!isLoading) &&
                        <ArrayField source="prospects" label={t("menu.organisations")} >
                            <SingleFieldList sx={{ gap: "4px", margin: "0px" }}>
                                <div className={'flex'}>
                                    <OpenProspectButton>
                                        <ProspectOrganisationField organisations={orgas} />
                                    </OpenProspectButton>
                                    <div>du :
                                        <DateField source="createdAt" label={t("prospect.property.createdAt")} /> au :
                                        <DateField source="updatedAt" label={t("prospect.property.createdAt")} />
                                    </div>
                                    <ReferenceArrayField source="userIds" reference="users" label={t("prospect.property.userIds")} >
                                        <SingleFieldList>
                                            <ChipField source="email" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                    <StatusChip statusType='pipeStatus' />
                                    <TextField source="rejectionReason" />
                                </div>
                            </SingleFieldList>
                        </ArrayField>}
                </div>
            </div>
        </div>
        : <></>
    )
}

const EmailVerifiedField = (props: any) => {
    const record = useRecordContext();
    const t = useTranslate();

    return <CustomEmailField source="email" isverified={record?.verifiedEmail} />
}

const FormResultShow = (props: any) => {
    const t = useTranslate();
    const { permissions } = usePermissions();

    return (
        <Show title={""}>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <SimpleShowLayout>
                        <TextField source="name" label={t("prospect.property.name")} />
                        <EmailVerifiedField label={t("prospect.property.email")} />
                        <PhoneField />
                        <TextField source="ownerComment" label={t("prospect.property.ownerComment")} />
                        <TextField source="saleTiming" label={t("prospect.property.saleTiming")} />
                        <TextField source="salePrice" label={t("prospect.property.salePrice")} />
                        <TextField source="ownerType" label={t("prospect.property.ownerType")} />
                        <TextField source="residencyType" label={t("prospect.property.residencyType")} />
                        <TextField source="contactPreference" label={t("prospect.property.contactPreference")} />
                        <TextField source="weekDays" label={t("prospect.property.weekDays")} />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleShowLayout>
                        <TextField source="address" label={t("prospect.property.address")} />
                        <DVFLink />
                        <PappersLink />
                        <TextField source="zipCode" label={t("prospect.property.zipCode")} />
                        <TextField source="type" label={t("prospect.property.type")} />
                        <TextField source="fieldSurface" label={t("prospect.property.fieldSurface")} />
                        <TextField source="houseSurface" label={t("prospect.property.houseSurface")} />
                        <TextField source="pluInfo.libelle" label={"PLU"} />
                        <TextField source="pluInfo.libelong" label={"PLU"} />
                        <AttributionsField />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SimpleShowLayout>
                        <TextField source="dpe" label={t("prospect.property.dpe")} />
                        <TextField source="generalState" label={t("prospect.property.generalState")} />
                        <TextField source="constructionYear" label={t("prospect.property.constructionYear")} />
                        <TextField source="numberRooms" label={t("prospect.property.numberRooms")} />
                        <DateField source="createdAt" label={t("prospect.property.createdAt")} />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
            <div className="h-[400px]">
                <Suspense fallback={<></>}>
                    <MapField isFullScreen={false} onFullScreenClick={() => { }} />
                </Suspense>
            </div>
        </Show>
    )
};

export default FormResultShow;