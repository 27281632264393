import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

const StatusButtons = (props: any) => {

    return (
        <div className='flex gap-x-2 justify-end'>
            {props.isRecommendation !== true &&
                <RejectButton onReject={props.onReject} {...props} />
            }
            <AcceptButton {...props} />
        </div>
    )
}


export default StatusButtons;