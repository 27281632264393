import { useRecordContext } from "react-admin";
import { IOwner, IProspect } from "../../types/Prospect";
import Box from '@mui/material/Box';
import { CalendarIcon } from "../Icons/CalendarIcon";
import dayjs from "dayjs";
import theme from "../../theme";
import { EventActionButton } from "../Buttons/EventActionButton";

export const MeetingsField = (props: { prospect: IProspect, owner: IOwner }) => {
    const { prospect, owner } = props;

    if (!owner || !owner.meetings || owner.meetings.length === 0) {
        return null;
    }

    return <Box className="flex items-center gap-1">
        <Box className="flex items-center">
            <Box className="text-primary">
                <CalendarIcon />
            </Box>
            <Box className="text-darker">
                Rendez-vous
            </Box>
        </Box>
        <Box className="flex gap-2">
            {owner.meetings.map((meeting: Date, index: number) => {
                return <EventActionButton icon={false} action="create" id={prospect?.id?.toString()} crmType={"tasks"} event={{ associatedOwners: [owner?.id], dueDate: dayjs(meeting), title: "Rendez-vous" }} >
                    <Box key={index} className="flex gap-2 rounded-lg border-solid border-2 border-bg p-2 text-gray-600 hover:bg-bg transition-all">
                        <Box>{dayjs(meeting).format('DD/MM/YY HH:mm')}</Box>
                    </Box>
                </EventActionButton>
            })}
        </Box>
    </Box >
}