import { useRedirect } from "react-admin";
import { CustomCard } from "../layout/CustomCard"
import { PopupAdressInfo, PopupPropertyInfo, PopupProspectInfo } from "./PopupInfo";

export const RecommendationCard = (props: any) => {
    const redirect = useRedirect();

    return <CustomCard containerClass={'rounded-md cursor-pointer hover:shadow-lg transition-all max-w-[320px]'} onClick={() => redirect('show', 'recommendations', props?.recommendation?.id)}>
        <div className="flex flex-col gap-2 px-4 py-5">
            <img src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+302dcd(${props?.recommendation?.location.coordinates[0]},${props?.recommendation?.location.coordinates[1]})/${props?.recommendation?.location.coordinates[0]},${props?.recommendation?.location.coordinates[1]},12,0/440x234?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`} alt="" />
            <PopupProspectInfo data={props?.recommendation} />
            <PopupAdressInfo data={props?.recommendation} />
            <PopupPropertyInfo data={props?.recommendation} />
        </div>
    </CustomCard>
}