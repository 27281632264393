import Popover from '@mui/material/Popover';
import Fab from '@mui/material/Fab';
import { ProspectChat } from '../Prospects/Messages/Chat';
import { useState } from 'react';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';

export const ChatButton = () => {
    const [open, setOpen] = useState(false);

    return <>
        <Fab onClick={() => setOpen(true)} id="open-chat-button" color="primary" aria-label="open-chat" sx={{ position: "fixed", bottom: "2em", right: "2em" }}>
            <ChatBubbleRoundedIcon />
        </Fab>
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={document.getElementById('open-chat-button')}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            aria-labelledby="alert-dialog-chat-title"
            aria-describedby="alert-dialog-chat-description"
            sx={{
                "& .MuiPopover-paper": {
                    width: "400px",
                    maxHeight: "80vh"
                }
            }}
        >
            <ProspectChat />
        </Popover>
    </>
}