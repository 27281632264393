import React from 'react';
import { Source, Layer } from 'react-map-gl';

export const QPVLayer = (props: { opacity: number }) => {
    return (
        <>
            <Source
                id="qpv-source"
                type="vector"
                url="mapbox://kaptcher.2snlm2h2"
            >
                <Layer
                    id="qpv2024-4326-chqsqi"
                    type="fill"
                    source="qpv-source"
                    source-layer="qpv2024-4326-chqsqi"
                    paint={{
                        'fill-color': '#FF0000',
                        'fill-opacity': props.opacity
                    }}
                />
                {/* Borders */}
                <Layer
                    id="qpv2024-4326-chqsqi-borders"
                    type="line"
                    source="qpv-source"
                    source-layer="qpv2024-4326-chqsqi"
                    paint={{
                        'line-color': '#000',
                        'line-width': 1,
                        'line-opacity': props.opacity * 4
                    }}
                />
            </Source>
            <Source
                id="qpv-buffer"
                type="vector"
                url="mapbox://kaptcher.61f9igwp"
            >
                <Layer
                    id="qpb-buffer"
                    type="fill"
                    source="qpv-source"
                    source-layer="qpv_buffer-5si016"
                    paint={{
                        'fill-color': '#FF0000',
                        'fill-opacity': props.opacity / 2
                    }}
                />
            </Source>
        </>
    );
};